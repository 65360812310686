var $ = require('jquery')
function Tablistview(tabData: any) {     
    const rowData: any = tabData.rowData;
    const colData: any = tabData.colData.filter((elem: any) => elem.Visible);
    return(
    rowData && colData && colData.length > 0 ? 
    (<table key={tabData.keys}>
        <tbody>
        {
            colData.map((elem: any,index: number) => {
            const tdValue: any = tabData.keys === "tabPopupVF_0" || tabData.keys === "tabPopupVF0_0"? $("#footer_" + elem.accessor).text() : rowData[elem.accessor];
            return (rowData[Object.keys(rowData)[0]] !=null && rowData[Object.keys(rowData)[0]].toString() !== "Sub Total") ? 
            (<tr key={"tabIVIndex_" + index}>
                <td className="graphPop4Table">{elem.Header}</td>
                <td className="graphPop4Table" style={{color: "#228B22"}}>
                {tabData.isHyperLink && (elem.accessor === (tabData.rootFlag === 4 || tabData.rootFlag === 5 || tabData.rootFlag === 16 ? "in_betamount" : tabData.rootFlag === 19 ? "in_revenueamount" : "in_payout") 
                || elem.accessor === "in_claimedamount" || elem.accessor === "in_unclaimedamount" || elem.accessor === "in_claimamount" || elem.accessor === "in_unclaimamount") && tdValue.toString() !== "0" ? 
                (<a href="#section" className="graphPop4Table" style={{textDecoration: "underline"}} onClick={(e: any) => tabData.loadListSubTableData(e,rowData)}>
                {tdValue}
                </a>) : 
                tabData.pageTitle !== undefined && tabData.pageTitle === "PaperLottery" && elem.accessor === "in_status" ? 
                (<div>
                    {tdValue.split('(')[0]}
                    {tdValue.split('(').length > 1 ? 
                    <><br /><span style={{fontSize: "10px"}}>{"(" + tdValue.split('(')[1]}</span></> : null}
                    </div>) : tdValue}
                </td>
                </tr>) : 
            elem.accessor === "in_transcount" || elem.accessor === "in_betamount" || elem.accessor === "in_winamount" || elem.accessor === "in_netamount" || elem.accessor === "in_revenueamount" || elem.accessor === "in_salecommission" || elem.accessor === "in_claimcommission"
            || elem.accessor === "in_transamount" || elem.accessor === "in_payout" || elem.accessor === "in_claimedamount" || elem.accessor === "in_unclaimedamount" || elem.accessor === "in_claimamount" || elem.accessor === "in_unclaimamount" || elem.accessor === "in_paymentamount" || elem.accessor === "dl_prizeamount" || elem.accessor === "dl_totprize" ? 
            (<tr key={"tabIVIndex_" + index}>
                <td className="graphPop4Table">{elem.Header}</td>
                <td className="graphPop4Table" style={{color: "#228B22"}}> 
                {tdValue}
                </td>
                </tr>) : null
            })
        } 
        </tbody>     
    </table>) : null
    ); 
}

export default Tablistview;
