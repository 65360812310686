import moment from "moment";
function CGamemodel() {
    return  { 
        errMsg: "",
        succFlag: undefined, 
        cGameMand: {            
            drawDate: {defValue: null,value: null,display: false},
            drawTime: {defValue: null,value: null,display: false},  
            startDate: {defValue: null,value: null,display: false},       
            endDate: {defValue: null,value: null,display: false},        
            startTime: {defValue: null,value: null,display: false},       
            endTime: {defValue: null,value: null,display: false},
            gameId: {value: "",display: false},       
            gameName: {value: "",display: false},    
            mrpValue: {value: 0,display: false}    
        },
        cGameDrop: {   //only dropdown values
            gameType: [],
            OrganizerNames: [],
            gameTypeId: {value: null,display: false},
            OrganizerName: {value: null,userId: null,display: false}    
        },
        aGame: {
            tabData: [],
            colData: [],
            filteredData: [],
            searchInput: "",
            saleOptions: [],
            pageIndex: 1,
            rowData: null,
            succFlag: undefined,
            errMsg: "",
            drawDate: {
                FdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false},        
                TdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false} 
            }
        }
    } 
};

export default CGamemodel;