import React from 'react';
import useAxios from "axios";
import apiHelper from "../../utils/ApiHelper"
import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import Breadcrumb from "../Shared/breadcrumb";
import CAgentHmodel from "./Child/c_agentHmodel";
import CommonAgentH from './Child/c_agentHcommon';

//responsive dropdown
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown,faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';
import moment from "moment"
import { withTranslation  } from 'react-i18next';

var $ = require('jquery')
library.add(faCaretDown);
library.add(faCaretUp);

export interface IProps {
    rowData: any,
    rootFlag: number,
    backClick: any,
    t:any
}
export interface IState {
    errMsg: string,
    succFlag: any,
    ccAgentHMand: any,
    cAgentHDrop: any
    // cAgentHRadio: number
}
  class CCAgentH extends React.Component<IProps, IState> {
  // ------------------------------------------^
    constructor(props: IProps) {
      super(props); 
      this.state = CAgentHmodel();
      this.onhandleTxtChange = this.onhandleTxtChange.bind(this);
      this.onSubmitData = this.onSubmitData.bind(this);
    //   this.handleToggleClick = this.handleToggleClick.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);
      this.handleDropChange = this.handleDropChange.bind(this);
    }
    componentDidMount(){
        const rowData: any = this.props.rowData;
        let resVals: any = this.state;
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        const callJsonHead: any = { 
            flag_id: 0
        }
        if(is_superuser !== null && is_superuser){
            const _this: any = this;
            _this.loadOrgNameMasterdata(resVals).then((res: any) => {
                _this.loadNameMasterdata(res,callJsonHead,"load_gameDetails","cm_game",2).then((res1: any) => {
                    if(rowData !== null){                       
                        this.updateFields(res1,rowData);
                        return
                    }
                    else{
                        this.setState(res1);
                        return
                    }     
                })
            })
        }
        else{ 
            const _this: any = this;
            _this.loadNameMasterdata(resVals,callJsonHead,"load_gameDetails","cm_game",2).then((res: any) => {            
                if(rowData !== null){
                    _this.updateFields(res,rowData);
                    return
                }
                else{
                    const callJsonHead1: any = { 
                        organizerID: userInfo["userId"],
                        flag_id: 0
                    }
                    _this.loadNameMasterdata(res,callJsonHead1,"load_superstockist","CStockist",0).then((res1: any) => {
                        _this.setState(res1);
                        return 
                    })
                }                
            })          
        }        
    }
    // main Templates dropdown change event
    // componentDidUpdate(prevProps:any) {
    //     // Typical usage (don't forget to compare props):        
    //     // if (this.props.rootFlag !== prevProps.rootFlag) {
    //     //     this.resetValues();
    //     // }
    // }
    resetValues= (flag?: number) => {
        const rowData: any = this.props.rowData;  
        $('#myfileExp').val('');
        $('#myfileExp1').val('');
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        const resVals: any = this.state;
        const initialState: any = CAgentHmodel();
        initialState.cAgentHDrop.OrganizerNames = resVals.cAgentHDrop.OrganizerNames;
        initialState.cAgentHDrop.gameType = resVals.cAgentHDrop.gameType;
        if(rowData !== null){
            if(flag === 1){
                this.setState(initialState, () => {
                    this.props.backClick(null);
                    return
                });
            }
            else{
                this.updateFields(initialState,rowData);
                return
            }             
        }
        else{
            const _this: any = this;
            if(is_superuser !== null && is_superuser){
                _this.setState(initialState);
                return 
            }
            else{                
                const callJsonHead: any = { 
                    organizerID: userInfo["userId"],
                    flag_id: 0
                }
                _this.loadNameMasterdata(initialState,callJsonHead,"load_superstockist","CStockist",0).then((res1: any) => {
                    _this.setState(res1);
                    return 
                })
            }
        } 
    }
    loadOrgNameMasterdata= (resVals: any) => {
        try{
        $("#divLoading").attr("style", "display:block")
        this.setState(CAgentHmodel());
        return new Promise((resolve, reject) => {
            //mani 22/12
            //useAxios.get(config.Url("cmo_game","get"), config.authHeaderwithParam("OrgName~@#%&1"))
            apiHelper.getAPICall("cmo_game", "get",4,"OrgName~@#%&1")
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let OrganizerNamesArr: any = [];
                if(res.data !== null && res.data.length !== 0){
                    if(res.data["organizer_details"] !== undefined && res.data["organizer_details"] !== null && res.data["organizer_details"].length !== 0){                  
                    for(var prop of res.data["organizer_details"]){
                        OrganizerNamesArr.push({value: prop["dl_organizerid"],label: prop["dl_organizername"],userId: prop["dl_userid"]})
                    }
                }
                }         
                $("#divLoading").attr("style", "display:none") 
                //$("#templaTBlid").attr("style", "display:none") 
                resVals.cAgentHDrop.OrganizerNames = OrganizerNamesArr;
                resolve(resVals)
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none") 
                reject(err)
            })
        }); 
        }
        catch(ex) {
        alert("Master Data Load Failed..");
        return
        }
    }
    /* load game names */
    loadNameMasterdata = (initialState: any,callJsonHead: any,getDtlPoint: string,methodName: string,flag: number) => {
        try{
            $("#divLoading").attr("style", "display:block")
            return new Promise((resolve, reject) => {
                //mani 22/12
                //useAxios.get(config.Url(methodName,"get"), config.authHeaderwithParamRpt(callJsonHead))
                apiHelper.getAPICall(methodName,"get",5,callJsonHead)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    let fieldNamesArr: any = [];
                    if(res.data !== null && res.data.length !== 0){
                        if(res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0){                  
                        for(var prop of res.data[getDtlPoint]){
                            if(flag === 0)
                                fieldNamesArr.push({value: prop["in_sstockistid"] + "_" + prop["in_organizerid"],label: prop["in_sstockistname"],orgId: prop["in_organizerid"]})
                            else if(flag === 2)
                                fieldNamesArr.push({value: prop["in_gamegroupid"],label: prop["in_gamegroupdesc"]})
                        }
                    }
                    }         
                    $("#divLoading").attr("style", "display:none")
                    fieldNamesArr = fieldNamesArr.length === 1 && fieldNamesArr[0].value.split('_')[0].toString() === "0" ? [] : fieldNamesArr;
                    if(flag === 0)
                        initialState.cAgentHDrop.stockistType = fieldNamesArr;
                    else if(flag === 2)
                        initialState.cAgentHDrop.gameType = fieldNamesArr;
                    //this.setState(initialState);
                    resolve(initialState);
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none") 
                    reject(null)
                }) 
            });
        }
        catch(ex) {
            alert("Load Master Data Failed..");
            return
        }
    }
    updateFields= (resVal: any,rowData: any) =>{
        try{       
            resVal.cAgentHDrop.OrganizerName.value = rowData.dl_organizerid;
            resVal.cAgentHDrop.gameTypeId.value = rowData.dl_gamegroupid;
            // resVal.cAgentHDrop.terminalTypeId.value = rowData.dl_terminalid + "&$@" + rowData.dl_organizerid;
            resVal.cAgentHDrop.commissionTypeId.value = rowData.dl_commissiontypeid;
            //if(this.props.rootFlag === 3 || this.props.rootFlag === 5 || this.props.rootFlag === 7 || this.props.rootFlag === 9){
                resVal.cAgentHDrop.stockistTypeId.value = rowData.in_ssid + "_" + rowData.dl_organizerid + "_" + rowData.in_sstockistname; //rowData.in_ssid + "_" + rowData.dl_organizerid;
                //if(this.props.rootFlag !== 3){
                    // resVal.cAgentHDrop.sstockistTypeId.value = rowData.in_sid + "_" + rowData.dl_organizerid + "_" + rowData.in_stockistname; //rowData.in_sid + "_" + rowData.dl_organizerid;
                //}
            //
            resVal.ccAgentHMand.salesComm.name = rowData.dl_salescommission;
            resVal.ccAgentHMand.claimComm.name = rowData.dl_claimcommission;
            resVal.ccAgentHMand.startDate.defValue = moment(rowData.dl_effectivefrom,'DD-MM-yyyy');
            resVal.ccAgentHMand.startDate.name = rowData.dl_effectivefrom;
            resVal.ccAgentHMand.endDate.defValue = moment(rowData.dl_effectiveto,'DD-MM-yyyy');
            resVal.ccAgentHMand.endDate.name = rowData.dl_effectiveto;
            // resVal.cAgentHRadio = rowData.dl_agenttypeid;
            this.setState(resVal);
            return 
        }
        catch(ex) {
        alert("Field Updation Failed..");
        return
        }       
    }
     /* Text change on Agent create */
    onhandleTxtChange= (e: any,fieldFlag: number,t:any) => {
        try{
        let resVal: any = this.state;
        const inputVal: any = e.target.value;
        if(inputVal === "" || parseFloat(inputVal) <= 100){
            if(fieldFlag === 1){
                resVal.ccAgentHMand.salesComm.name = inputVal;
                resVal.ccAgentHMand.salesComm.display = inputVal.trim() === "" ? true : false;
                resVal.ccAgentHMand.salesComm.errDisp = t('lblSalesCommProvided');           
            }
            else if(fieldFlag === 2){
                resVal.ccAgentHMand.claimComm.name = inputVal;
                resVal.ccAgentHMand.claimComm.display = inputVal.trim() === "" ? true : false;
                resVal.ccAgentHMand.claimComm.errDisp = t('lblClaimCommProvided');  
            }
        }  
        else{
            if(fieldFlag === 1){
                resVal.ccAgentHMand.salesComm.display = true;
                resVal.ccAgentHMand.salesComm.errDisp = t('lblSalesCommGreater');           
            }
            else if(fieldFlag === 2){
                resVal.ccAgentHMand.claimComm.display = true;
                resVal.ccAgentHMand.claimComm.errDisp = t('lblClaimCommGreater');  
            }
        }     
        resVal.errMsg = "";
        resVal.succFlag = undefined;
        this.setState(resVal)
        return
        }
        catch(ex) {
        alert("On handle text change Failed..");
        return
        }
    }
    /* Drop down change on Agent create */
    handleDropChange = (e: any,type: number) => {
        try{
            const _this: any = this;
            const resVals: any = _this.state;
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            resVals.succFlag = undefined;
            resVals.errMsg = "";
            switch(type){
                //mani 26/12
            //if(type === 1)
            case 1:
            {
                resVals.cAgentHDrop.OrganizerName.value = e.value;
                resVals.cAgentHDrop.OrganizerName.display = false;  
                resVals.cAgentHDrop.stockistType = [];
                resVals.cAgentHDrop.stockistTypeId.value = null;
                // resVals.cAgentHDrop.sstockistType= [];
                // resVals.cAgentHDrop.sstockistTypeId.value = null; 
                // resVals.cAgentHDrop.terminalType= [];
                // resVals.cAgentHDrop.terminalTypeId.value = null;            
                const callJsonHead: any = { 
                    organizerID: userInfo["is_superuser"] ? resVals.cAgentHDrop.OrganizerName.value : userInfo["userId"],
                    flag_id: 0
                }
                _this.loadNameMasterdata(resVals,callJsonHead,"load_superstockist","CStockist",0).then((res1: any) => {
                    _this.setState(res1);
                    return 
                })   
                break;          
            }
             
            //else if(type === 2)
            case 2:
            {
                resVals.cAgentHDrop.gameTypeId.value = e.value;
                resVals.cAgentHDrop.gameTypeId.display= false;
                _this.setState(resVals);
                return 
            }      

            //else if(type === 3)
            case 3:
            {
                resVals.cAgentHDrop.stockistTypeId.value = e.value;
                resVals.cAgentHDrop.stockistTypeId.display= false;
                // resVals.cAgentHDrop.sstockistType= [];
                // resVals.cAgentHDrop.sstockistTypeId.value = null;  
                // resVals.cAgentHDrop.terminalType= [];
                // resVals.cAgentHDrop.terminalTypeId.value = null;  
                // const callJsonHead: any = { 
                //     organizerID: userInfo["is_superuser"] ? resVals.cAgentHDrop.OrganizerName.value : userInfo["userId"],
                //     super_stockistID: parseInt(resVals.cAgentHDrop.stockistTypeId.value.split('_')[0]),
                //     flag_id: 0
                // }
                // _this.loadNameMasterdata(resVals,callJsonHead,"load_stockist","CSStockist",1).then((res1: any) => {
                //     _this.setState(res1);
                //     return 
                // })
                _this.setState(resVals);
                return
            }

            //else if(type === 6)
            case 6:
            {
                resVals.cAgentHDrop.commissionTypeId.value = e.value;
                resVals.cAgentHDrop.commissionTypeId.display= false;
                _this.setState(resVals);
                return 
            }
            // else if(type === 4)
           // case 4:
           // {
            //     resVals.cAgentHDrop.sstockistTypeId.value = e.value;
            //     resVals.cAgentHDrop.sstockistTypeId.display= false;
            //     // resVals.cAgentHDrop.terminalType= [];
            //     // resVals.cAgentHDrop.terminalTypeId.value = null;  
            //     // if(resVals.cAgentHRadio === 1){
            //     //     const callJsonHead: any = { 
            //     //         organizerID: userInfo["is_superuser"] ? resVals.cAgentHDrop.OrganizerName.value : userInfo["userId"],
            //     //         super_stockistID: parseInt(resVals.cAgentHDrop.stockistTypeId.value.split('_')[0]),
            //     //         stockistID: parseInt(resVals.cAgentHDrop.sstockistTypeId.value.split('_')[0]),
            //     //         flag_id: 0
            //     //     }
            //     //     _this.loadNameMasterdata(resVals,callJsonHead,"load_terminal","CTerminal",3).then((res1: any) => {
            //     //         _this.setState(res1);
            //     //         return 
            //     //     })
            //     // }
            //     // else{
            //         _this.setState(resVals);
            //         return 
            //     // }
            //break;
            // }          
            // else if(type === 5)
                //case 5:
            //{
            //     resVals.cAgentHDrop.terminalTypeId.value = e.value;
            //     resVals.cAgentHDrop.terminalTypeId.display= false;
            //     _this.setState(resVals);
            //     return 
            // } 
        }          
        }
        catch(ex) {
          alert("On Dropdown Change Failed..");
          return
        }
    }
    onSubmitData= (e: any,Type: string) => {
        try{
        e.preventDefault();
        let resVal: any = this.state;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        let isErrData: boolean = false;
        $("#divLoading").attr("style", "display:block")
        //validation part-Start
        Object.entries(resVal.ccAgentHMand).forEach(([key, value],index: number) => {
            let val = resVal.ccAgentHMand[key].name;
            if(val === null || val === "" || resVal.ccAgentHMand[key].display){
                resVal.ccAgentHMand[key].display = true
                isErrData = true
            }
            else if((key === "startDate" || key === "endDate") && this.props.rowData !== null){
                const currDate: any = moment().format("yyyy-MM-DD");
                const isValid: boolean = moment(currDate).isBefore(moment(resVal.ccAgentHMand[key].value,"DD-MM-yyyy").format("yyyy-MM-DD")) || moment(currDate).isSame(moment(resVal.ccAgentHMand[key].value,"DD-MM-yyyy").format("yyyy-MM-DD"));
                if(!isValid){ //minimal date calculation
                    resVal.ccAgentHMand[key].display = false
                    isErrData = true
                }
            }
            else{ return }
        })
        // if(resVal.cAgentHRadio === 1 && resVal.cAgentHDrop.terminalTypeId.value === null){
        //     resVal.cAgentHDrop.terminalTypeId.display = true
        //     isErrData = true
        // }
        if(resVal.cAgentHDrop.commissionTypeId.value === null){
            resVal.cAgentHDrop.commissionTypeId.display = true
            isErrData = true
        }
        if(resVal.cAgentHDrop.gameTypeId.value === null){ //drop down validation
            resVal.cAgentHDrop.gameTypeId.display = true
            isErrData = true
        }
        if(resVal.cAgentHDrop.stockistTypeId.value === null){ //drop down validation
            resVal.cAgentHDrop.stockistTypeId.display = true
            isErrData = true
        }
        // if(resVal.cAgentHDrop.sstockistTypeId.value === null){ //drop down validation
        //     resVal.cAgentHDrop.sstockistTypeId.display = true
        //     isErrData = true
        // }      
        if(userInfo["is_superuser"] && resVal.cAgentHDrop.OrganizerName.value === null){ //drop down validation
            resVal.cAgentHDrop.OrganizerName.display = true
            isErrData = true
        }
        resVal.succFlag = undefined;
        //validation part-End
        if(isErrData){
            $("#divLoading").attr("style", "display:none") 
            //resVal.errMsg = "Invalid data..!"
            this.setState(resVal);
            // $('.overlay').fadeIn();
            // $('#lightBox2').fadeIn(); 
            return 
        }else{
            if(Type === "C"){
                const userInfoString: any = sessionStorage.getItem('userInfo');
                const userInfo: any = JSON.parse(userInfoString);
                const userIp: string = $("#userIp").text();                
                const jsonData: any = {
                    "organizerID": userInfo["is_superuser"] ? resVal.cAgentHDrop.OrganizerName.value : userInfo["userId"],
                    "super_stockistID": resVal.cAgentHDrop.stockistTypeId.value === null ? 0 : parseInt(resVal.cAgentHDrop.stockistTypeId.value.split('_')[0]),
                    "stockistID": resVal.cAgentHDrop.sstockistTypeId.value === null ? 0 : parseInt(resVal.cAgentHDrop.sstockistTypeId.value.split('_')[0]),
                    "terminalID": resVal.cAgentHRadio === 0 ? "" : resVal.cAgentHDrop.terminalTypeId.value === null ? "" : resVal.cAgentHDrop.terminalTypeId.value.split('&$@')[0],
                    "game_groupID": resVal.cAgentHDrop.gameTypeId.value,
                    "sales_commission": parseFloat(resVal.ccAgentHMand.salesComm.name).toFixed(2),
                    "claim_commission": parseFloat(resVal.ccAgentHMand.claimComm.name).toFixed(2),
                    "effect_from" : moment(resVal.ccAgentHMand.startDate.name,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    "effect_to" : moment(resVal.ccAgentHMand.endDate.name,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00", 
                    "created_by" : userInfo["userId"],
                    "ipaddress" : userIp,
                    "commission_type" : resVal.cAgentHDrop.commissionTypeId.value,
                    "flag_id" : 0, //dummy
                    "agent_type": 2 //resVal.cAgentHRadio,
                }
                //mani 22/12
                //useAxios.post(config.Url("cc_AgentH","post"), jsonData, config.authHeader())
                apiHelper.postAPICall("cc_AgentH", "post",2,jsonData)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    $("#divLoading").attr("style", "display:none")
                    if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
                        $("#divLoading").attr("style", "display:none") 
                        resVal.errMsg = res.data.result.toString().replace('successfully','');
                        resVal.succFlag = undefined;
                        this.setState(resVal);                   
                    }
                    else{
                        resVal = CAgentHmodel()
                        resVal.cAgentHDrop.OrganizerNames = this.state.cAgentHDrop.OrganizerNames;
                        resVal.cAgentHDrop.gameType = this.state.cAgentHDrop.gameType;
                        resVal.errMsg = res.data.result.toString().replace('successfully','');
                        resVal.succFlag = 0;
                        this.setState(resVal);
                    }
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    if(err !== undefined && err.response !== undefined && err.response.statusText !== undefined && err.response.statusText === 'Unprocessable Entity'){
                        resVal.errMsg = "Invalid data..!"
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                    } 
                    return 
                })
            }
            else{
                this.props.backClick(this.state);
                return
            }
        } 
        }
        catch(ex) {
        alert("Data Inserion/ Updation Failed..");
        return
        }      
    }
    // event triggers on key press on input controls
    handleKeypress = (evt: any,type: string) => {
        if (type === "integer" && evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
        //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
            evt.preventDefault();
        }
        else if (evt.which !== 46 && evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
            //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
                evt.preventDefault();
        }
    }
    maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
    }
    // handleToggleClick =(e: any,flag: number) => {
    //     try{
    //         const resVals: any = this.state;
    //         if(resVals.cAgentHRadio !== flag){
    //             resVals.succFlag = undefined;
    //             resVals.errMsg = "";
    //             resVals.cAgentHDrop.sstockistTypeId.value = null;
    //             resVals.cAgentHDrop.terminalType = [];
    //             resVals.cAgentHDrop.terminalTypeId = {value: null,display: false};
    //             resVals.cAgentHRadio = flag;
    //             this.setState(resVals);
    //             return
    //         }
    //     }
    //     catch(ex) {
    //       alert("On Change Toggle button updation Failed..");
    //       return
    //     }
    // } 
    handleDateChange = (date: any,key: string,format: string) => {
        try{
            const resVal: any = this.state;
            resVal.ccAgentHMand[key].defValue = moment(date._d);
            resVal.ccAgentHMand[key].name = moment(date._d).format(format);          
            if(key === "startDate" || key === "endDate"){
                resVal.ccAgentHMand.endDate.display = false;
                resVal.ccAgentHMand.startDate.display =(resVal.ccAgentHMand.endDate.name !== null && moment(resVal.ccAgentHMand.startDate.name,format) > moment(resVal.ccAgentHMand.endDate.name,format))
            }    
            this.setState(resVal);
            return
        }
        catch(ex) {
            alert("On handle date change Failed..");
            return
        }
    };
    render() {
    const { t } = this.props;
    const resVal: any = this.state;
    const defaultMaterialTheme = createMuiTheme({
        palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#009269',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            }
        },
    });
        const DropdownIndicator = (props: any) => {
            return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                <FontAwesomeIcon style={{fontSize: "20px"}} icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>
                </components.DropdownIndicator>
            )
            );
        };
        const styles = {
            menu: (base: any) => ({
                ...base,
                marginTop: 0
            })
        };
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
      return(
        <div className="ContainerChart scrollbar" style={{height: $(window).height() - 60 + "px"}}>
            <Lightbox errMsg= {resVal.errMsg} succFlag= {resVal.succFlag} />            
            <div className="contentclouds">
            <div className="userLogdata1">					
                <div className="userLogHead">
                <Breadcrumb Menu= "Agent Hierarchy" subMenu= {this.props.rowData === null ? "Create " + CommonAgentH.getPageTitle(this.props.rootFlag) : "View " + CommonAgentH.getPageTitle(this.props.rootFlag)} subMenu1= {this.props.rowData === null ? null : "Edit " + CommonAgentH.getPageTitle(this.props.rootFlag)} />
                <div className="userLogHeadinner">
				<h2>{(this.props.rowData === null ? "Create " : "Edit ") + CommonAgentH.getPageTitle(this.props.rootFlag)}</h2>
				<small>{t('lblHierarchyDetail')}</small>
			    </div>
                </div>
                <div className="userLogform scrollbar">
                    <div className="userLogformLft">
                        <ul>
                            <li>
								{/* <div className="userLogCloud2">
									<div className="radioBtn">
										<div className="radioBtn1">
										<label className="container">Agent based
										  <input type="radio" style={{width: "auto"}} checked={resVal.cAgentHRadio === 0 ? true : false} onChange={(e: any) => this.handleToggleClick(e,0)} name="radio" disabled={this.props.rowData === null ? false : true} />
										  <span className="checkmark"></span>
										</label>
										</div>
										<div className="radioBtn1">
										<label className="container">Terminal based
										  <input type="radio" style={{width: "auto"}} checked={resVal.cAgentHRadio === 1 ? true : false} onChange={(e: any) => this.handleToggleClick(e,1)} name="radio" disabled={this.props.rowData === null ? false : true} />
										  <span className="checkmark"></span>
										</label>
										</div>
									</div>
								</div> */}
                                {is_superuser !== null && is_superuser ? 
                                (<div className="userLogCloud3">
                                    <div>
                                        <label>{t('lblOrganizerName')} *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.OrganizerName.value === null ? null : resVal.cAgentHDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.OrganizerName.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,1)}
                                        placeholder={t('lblSelectName')}
                                        options={resVal.cAgentHDrop.OrganizerNames}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.OrganizerName.display ? "block" : "none"}}>{t('lblOrgNameProvided')}</label>
                                    </div>
                                </div>) : null}
                                <div className="userLogCloud3">
                                    <div>
                                        <label>{t('lblStockistName')} *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.stockistTypeId.value === null ? null : resVal.cAgentHDrop.stockistType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.stockistTypeId.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,3)}
                                        placeholder={this.props.rowData === null || resVal.cAgentHDrop.stockistTypeId.value === null ? 'Select Stockist Name' : resVal.cAgentHDrop.stockistTypeId.value.split('_')[2]}
                                        options={resVal.cAgentHDrop.stockistType}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.stockistTypeId.display ? "block" : "none"}}>{t('lblStockistNameProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud3">
                                    <div>
                                        <label>{t('lblGameType')} *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.gameTypeId.value === null ? null : resVal.cAgentHDrop.gameType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.gameTypeId.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,2)}
                                        placeholder={t('lblSelectGame')}
                                        options={resVal.cAgentHDrop.gameType}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.gameTypeId.display ? "block" : "none"}}>{t('lblGameProvided')}</label>
                                    </div>
                                </div>     
                            </li>                         
                            {/* <li>                               
                                <div className="userLogCloud3">
                                    <div>
                                        <label>Sub Stockist Name *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.sstockistTypeId.value === null ? null : resVal.cAgentHDrop.sstockistType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.sstockistTypeId.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,4)}
                                        placeholder={this.props.rowData === null || resVal.cAgentHDrop.sstockistTypeId.value === null ? 'Select Sub Stockist Name' : resVal.cAgentHDrop.sstockistTypeId.value.split('_')[2]}
                                        options={resVal.cAgentHRadio === 0 ? resVal.cAgentHDrop.sstockistType : resVal.cAgentHDrop.sstockistType.filter((elem: any) => !elem.value.toString().includes("0_"))}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.sstockistTypeId.display ? "block" : "none"}}>Sub Stockist Name has to be provided</label>
                                    </div>
                                </div> 
                                {resVal.cAgentHRadio === 1 ?
                                (<div className="userLogCloud3">
                                    <div>
                                        <label>Terminal Id *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.terminalTypeId.value === null ? null : resVal.cAgentHDrop.terminalType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.terminalTypeId.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,5)}
                                        placeholder={this.props.rowData === null || resVal.cAgentHDrop.terminalTypeId.value === null ? 'Select Terminal Id' : resVal.cAgentHDrop.terminalTypeId.value.split('&$@')[0]}
                                        options={resVal.cAgentHDrop.terminalType}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.terminalTypeId.display ? "block" : "none"}}>Terminal Id has to be provided</label>
                                    </div>
                                </div>) : null}                                                                  
                            </li>  */} 
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                            <li>
                                <div className="userLogCloud3">
                                    <div>
                                        <label>{t('lblCommType')} *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.commissionTypeId.value === null ? null : resVal.cAgentHDrop.commissionType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.commissionTypeId.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,6)}
                                        placeholder={t('lblSelectCommType')}
                                        options={resVal.cAgentHDrop.commissionType}
                                        // isDisabled={this.props.rowData === null ? false : true}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.commissionTypeId.display ? "block" : "none"}}>{t('lblCommTypeProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblEffectiveFrom')} * (DD-MM-YYYY)</label>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                        <DatePicker
                                            placeholder={('lblPickEffective')}
                                            minDate={new Date()}
                                            format="DD-MM-yyyy"
                                            value={resVal.ccAgentHMand.startDate.defValue}
                                            onChange={(date: any) => this.handleDateChange(date,"startDate","DD-MM-yyyy")}
                                        />
                                        </ThemeProvider>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.ccAgentHMand.startDate.display ? "block" : "none"}}>{resVal.ccAgentHMand.startDate.name === null ? t('lblEffective') : t('lblShouldnotGreater')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblEffectiveTo')} * (DD-MM-YYYY)</label>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                        <DatePicker
                                            placeholder={t('lblPickEffTo')}
                                            minDate={new Date()}
                                            format="DD-MM-yyyy"
                                            value={resVal.ccAgentHMand.endDate.defValue}
                                            onChange={(date: any) => this.handleDateChange(date,"endDate","DD-MM-yyyy")}
                                        />
                                        </ThemeProvider>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.ccAgentHMand.endDate.display ? "block" : "none"}}>{t('lblEffective')}</label>
                                    </div>
                                </div>
                            </li>
                            </MuiPickersUtilsProvider>                        
                            <li>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblSalesComm')} *</label>
                                        <input className="input_Number disableCtrl" id="colValL1" min="0" onKeyPress= {(e:any) => this.handleKeypress(e,"decimal")} value={resVal.ccAgentHMand.salesComm.name} onChange={(e: any) => this.onhandleTxtChange(e,1,t)} placeholder={t('lblEnterSales')} autoComplete="off" type="number" maxLength={5} onInput={this.maxLengthCheck} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.ccAgentHMand.salesComm.display ? "block" : "none"}}>{resVal.ccAgentHMand.salesComm.errDisp}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblClaimComm')} *</label>
                                        <input className="input_Number disableCtrl" id="colValL2" min="0" onKeyPress= {(e:any) => this.handleKeypress(e,"decimal")} value={resVal.ccAgentHMand.claimComm.name} onChange={(e: any) => this.onhandleTxtChange(e,2,t)} placeholder={t('lblEnterClaim')} autoComplete="off" type="number" maxLength={5} onInput={this.maxLengthCheck} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.ccAgentHMand.claimComm.display ? "block" : "none"}}>{resVal.ccAgentHMand.claimComm.errDisp}</label>
                                    </div>
                                </div>
                            </li>                                                            
                        </ul>                                
                        <div className="userLogBtnCont3">
                            <button className="GreyBg" id="loginBtn" onClick={() => this.resetValues(1)}><i className={"fa fa-" + (this.props.rowData === null ? "sync" : "times")} style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t('lblReset') : t('lblCancel')}</button>							
                            <button className="GreenBg" id="registerBtn" onClick={(e: any) => this.onSubmitData(e,this.props.rowData === null ? "C" : "E")}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t('lblCreate') : t('lblOk')}</button>		
                        </div>                 
                    </div>
                </div>
                </div>			
            </div>
	    </div>                   
    );
  }
}

export default withTranslation()( CCAgentH);