import React from 'react';
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
// import useAxios from "axios";
import config from "../utils/config";

import Dashboard from "./Dashboard/Dashboard"
import Notfound from "./notfound"
import COrganizer from "./Organizer/c_organizer"
import AOrganizer from "./Organizer/a_organizer"
import CGame from "./Game/c_game"
import AGame from "./Game/a_game"
import AGWReports from "./Reports/gw_reports"
import AGWPapers from "./PaperLottery/c_paperlotto"
import AGWHReports from "./Reports/gwh_reports"
import CAgentH from "./AgentHierarchy/c_agentH"
import CCAgentH from "./AgentHierarchy/cc_agentH"
import AAgentH from "./AgentHierarchy/a_agentH"
import CAuditTrail from "./AuditTrail/c_audittrail"
import DashboardNew from "./Dashboard/DashboardNew"
import gilogo from "../assets/img/logo_small.png";
import gilogo1 from "../assets/img/logo_bannner.png";
import userImg from "../assets/img/icons/user.jpg";
import CPayment from "./Payment/c_payment"
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import CommonReports from './Reports/Child/c_reportscommon';


var $ = require('jquery')
// https://iconify.design/icon-sets/uil/ -refer this url for uil-icons

interface IProps {
}
//Declaration
export interface IState {
  subMenuStyle: any
}
class Usermain extends React.Component<IProps, IState> {
  private redirectToReports: any = React.createRef;
  // ------------------------------------------^
  constructor(props: IProps) {
    super(props);
    this.redirectToReports = React.createRef();
    let is_superuser: boolean = true;
    const userInfoString: any = sessionStorage.getItem('userInfo');
    const userInfo: any = JSON.parse(userInfoString);
    if (userInfo !== null)
      is_superuser = userInfo["is_superuser"];
    this.state = {
      subMenuStyle: [
        { id: 11, parent: null, name: t('lblDeshboard'), to: "/", Show: "active", Icon: "home", Visible: true },
        { id: 66, parent: null, name: t('lblDashboardNew'), to: "/DashboardNew", Show: "", Icon: "home", Visible: true },
        { id: 0, parent: null, name: t('lblOrganizer'), to: "/C_Organizer", Show: "", Icon: "globe", Visible: is_superuser },
        { id: 1, parent: 0, name: t('lblCreateOrg'), to: "/C_Organizer", Show: "", Icon: "" },
        { id: 2, parent: 0, name: t('lblApproveorg'), to: "/A_Organizer", Show: "", Icon: "" },
        { id: 3, parent: 0, name: t('lblPushOrg'), to: "/GC_Organizer", Show: "", Icon: "" },
        { id: 4, parent: 0, name: t('lblRegenerateoOrg'), to: "/GRC_Organizer", Show: "", Icon: "" },
        { id: 18, parent: 0, name: t('lblViewOrg'), to: "/E_Organizer", Show: "", Icon: "" },
        { id: 40, parent: null, name: t('lblAgent'), to: "/C_Agent", Show: "", Icon: "user", Visible: true },
        { id: 41, parent: 40, name: t('lblCreateStock'), to: "/C_Stockist", Show: "", Icon: "" },
        { id: 42, parent: 40, name: t('lblViewStockist'), to: "/A_Stockist", Show: "", Icon: "" },
        { id: 58, parent: 40, name: t('lblCreateComm'), to: "/C_Commission", Show: "", Icon: "" },
        { id: 59, parent: 40, name: t('lblViewComm'), to: "/A_Commission", Show: "", Icon: "" },
        { id: 43, parent: 40, name: t('lblCreateSubStock'), to: "/C_SStockist", Show: "", Icon: "" },
        { id: 44, parent: 40, name: t('lblViewSubStockist'), to: "/A_SStockist", Show: "", Icon: "" },
        { id: 45, parent: 40, name: t('lblCreateTerminal'), to: "/C_Terminal", Show: "", Icon: "" },
        { id: 46, parent: 40, name: t('lblViewTerminal'), to: "/A_Terminal", Show: "", Icon: "" },
        { id: 47, parent: 40, name: t('lblCreatePlayer'), to: "/C_Player", Show: "", Icon: "" },
        { id: 48, parent: 40, name: t('lblViewPlayer'), to: "/A_Player", Show: "", Icon: "" },
        { id: 12, parent: null, name: t('lblGame'), to: "/C_Game", Show: "", Icon: "dice-d6", Visible: true },
        { id: 13, parent: 12, name: t('lblCreateGame'), to: "/C_Game", Show: "", Icon: "" },
        { id: 14, parent: 12, name: t('lblApproveGame'), to: "/A_Game", Show: "", Icon: "" },
        { id: 15, parent: 12, name: t('lblViewGame'), to: "/E_Game", Show: "", Icon: "" },
        { id: 16, parent: 12, name: t('lblGameStatus'), to: "/ES_Game", Show: "", Icon: "" },
        { id: 17, parent: 12, name: t('lblGameRes'), to: "/V_Game", Show: "", Icon: "" },
        //{id: 7,parent: null,name:"Paper Lottery",to:"/PaperLottery",Show:"",Icon: "toilet-paper",Visible: true},
        { id: 34, parent: 7, name: t('lblPurchaseInput'), to: "/GPI_Papers", Show: "", Icon: "" },
        { id: 35, parent: 7, name: t('lblUnsoldSettlement'), to: "/GUS_Papers", Show: "", Icon: "" },
        { id: 36, parent: 7, name: t('lblWinnersInput'), to: "/GWI_Papers", Show: "", Icon: "" },
        { id: 37, parent: 7, name: t('lblTicketClaim'), to: "/GTC_Papers", Show: "", Icon: "" },
        { id: 38, parent: 7, name: t('lblViewPurchase'), to: "/GVP_Papers", Show: "", Icon: "" },
        { id: 39, parent: 7, name: t('lblDeletePurchase'), to: "/GDP_Papers", Show: "", Icon: "" },
        { id: 5, parent: null, name: t('lblAuditTrail'), to: "/AuditTrail", Show: "", Icon: "table", Visible: true },
        //{id: 51,parent: 5,name:"Scheme Input",to:"/AIS_Audit",Show:"",Icon: ""},
        //{id: 52,parent: 5,name:"View Scheme",to:"/AVS_Audit",Show:"",Icon: ""},
        //{id: 53,parent: 5,name:"Result Input",to:"/AIR_Audit",Show:"",Icon: ""},
        //{id: 54,parent: 5,name:"Winner Process",to:"/AWP_Audit",Show:"",Icon: ""},
        //{id: 55,parent: 5,name:"Compare the Winner",to:"/ACW_Audit",Show:"",Icon: ""},
        { id: 56, parent: 5, name: t('lblTransValidate'), to: "/ATV_Audit", Show: "", Icon: "" },
        { id: 57, parent: 5, name: t('lblWinningValidate'), to: "/AWV_Audit", Show: "", Icon: "" },
        { id: 6, parent: null, name: t('lblReports'), to: "/Reports", Show: "", Icon: "chart-line", Visible: true },
        // {id: 19,parent: 6,name:"Game wise Sales Vs Payout",to:"/GWSP_Reports",Show:"",Icon: ""},
        { id: 19, parent: 6, name: t('lblOrgProfitVsLoss'), to: "/GOW_Reports", Show: "", Icon: "" },
        { id: 20, parent: 6, name: t('lblGameTypeProfitVsLoss'), to: "/GTWPL_Reports", Show: "", Icon: "" },
        { id: 21, parent: 6, name: t('lblGameTypeFilter'), to: "/GTWPLF_Reports", Show: "", Icon: "" },
        { id: 22, parent: 6, name: t('lblGameProfitVsLoss'), to: "/GWPL_Reports", Show: "", Icon: "" },
        { id: 23, parent: 6, name: t('lblGameFilter'), to: "/GWPLF_Reports", Show: "", Icon: "" },
        { id: 24, parent: 6, name: t('lblGameLastTrans'), to: "/GWLT_Reports", Show: "", Icon: "" },
        { id: 25, parent: 6, name: t('lblViewTransDetails'), to: "/GVT_Reports", Show: "", Icon: "" },
        { id: 26, parent: 6, name: t('lblTerminalProfilVsLoss'), to: "/GPWPL_Reports", Show: "", Icon: "" },
        { id: 27, parent: 6, name: t('lblTerminalGameTypeProfitVsLoss'), to: "/GPWPLF_Reports", Show: "", Icon: "" },
        { id: 28, parent: 6, name: t('lblOpSalesVsPayout'), to: "/GO_Reports", Show: "", Icon: "" },
        { id: 29, parent: 6, name: t('lblClaimReport'), to: "/GC_Reports", Show: "", Icon: "" },
        { id: 30, parent: 6, name: t('lblUnClaimReport'), to: "/GUC_Reports", Show: "", Icon: "" },
        { id: 49, parent: 6, name: t('lblClaimVsUnclaimWin'), to: "/GCU_Reports", Show: "", Icon: "" },
        { id: 31, parent: 6, name: t('lblChannelSalesVsPayout'), to: "/GCW_Reports", Show: "", Icon: "" },
        { id: 32, parent: 6, name: t('lblModeSlaeVaPayout'), to: "/GMW_Reports", Show: "", Icon: "" },
        { id: 33, parent: 6, name: t('lblPlayerReport'), to: "/GPW_Reports", Show: "", Icon: "" },
        { id: 50, parent: 6, name: t('lblOverallSale'), to: "/GOS_Reports", Show: "", Icon: "" },
        { id: 60, parent: 6, name: t('lblRevenueForGovt'), to: "/GRG_Reports", Show: "", Icon: "" },

        { id: 67, parent: null, name: t('lblPayment'), to: "/Payment", Show: "", Icon: "home", Visible: true },
        { id: 68, parent: 67, name: t('lblCreatePay'), to: "/C_Payment", Show: "", Icon: "", Visible: true },
        { id: 69, parent: 67, name: t('lblAddMoney'), to: "/am_history", Show: "", Icon: "", Visible: true },
        { id: 70, parent: 67, name: t('lblWithdraw'), to: "/wd_history", Show: "", Icon: "", Visible: true },

        { id: 8, parent: null, name: t('lblTaxEntry'), to: "/TaxEntry", Show: "", Icon: "envelope-open-text", Visible: true },
        { id: 71, parent: 8, name: t('lblGovtReport'), to: "/comtax", Show: "", Icon: "envelope-open-text", Visible: true },
        { id: 9, parent: null, name: t('lblWinnerList'), to: "/Winnerlist", Show: "", Icon: "thumbs-up", Visible: true },
        { id: 10, parent: null, name: t('lblAsk'), to: "/AskGI", Show: "", Icon: "comments", Visible: true },
        { id: 11, parent: null, name: t('lblMore'), to: "/More", Show: "", Icon: "ellipsis-h", Visible: true },
        //dispute analysis commented not in use
        // {id: 61,parent: 9,name:"Dispute / Query Analysis",to:"/DisputeAnalysis",Show:"",Icon: "",Visible: true},
        // {id: 62,parent: 9,name:"Transaction Dispute / Query Analysis",to:"/TransactionDisputeAnalysis",Show:"",Icon: "",Visible: true},
        // {id: 63,parent: 9,name:"Seller Registration",to:"/SellerRegistration",Show:"",Icon: "",Visible: true},
        // {id: 64,parent: 9,name:"Stockist Registration",to:"/StockistRegistration",Show:"",Icon: "",Visible: true},
        // {id: 65,parent: 9,name:"Sub Stockist Registration",to:"/SubStockistRegistration",Show:"",Icon: "",Visible: true},

        { id: 72, parent: 67, name: "PG Reports", to: "/Pg_Reports", Show: "", Icon: "envelope-open-text", Visible: true },
        { id: 73, parent: 67, name: "PG Reports Summary", to: "/Pg_Summary", Show: "", Icon: "envelope-open-text", Visible: true },
        { id: 74, parent: 8, name: "Push Data Report", to: "/lastpush", Show: "", Icon: "envelope-open-text", Visible: true },
      ]
    };
    this.openUserPopup = this.openUserPopup.bind(this);
    this.onsubMenuClick = this.onsubMenuClick.bind(this);
  }
  //initial load of page
  componentDidMount() {
    let windows: any = window;
    windows.applyDashboard();
    this.initialLoadMares();
  }
  initialLoadMares = () => {
    try {
      //const loginFlagStr: any = sessionStorage.getItem('loginFlag');
      const pathname = window.location.pathname;
      let parent: any = null;
      let subMenu: any = this.state.subMenuStyle.map((item: any) => {
        item.Show = item.to === ("/" + pathname.split("/").pop()) ? "active" : ''
        if (item.Show === "active") {
          parent = item.parent;
        }
        return item;
      });
      if (parent !== null)
        subMenu.find((elem: any) => elem.id === parent)["Show"] = "active";
      this.setState({ subMenuStyle: subMenu });
      // if(loginFlagStr !== null && loginFlagStr === '0'){
      //   $('.overlay').fadeIn(); 
      //   $('.overlay').fadeOut();
      //   // $('#lightBox2').fadeIn();
      //   sessionStorage.setItem('loginFlag', '1');
      // }
      return
    }
    catch (ex) {
      alert("Load Menu Failed..");
      return
    }
  }
  backtoDashboard = (id: any) => {
    try {
      let parent: any = null;
      let subMenu: any = this.state.subMenuStyle.map((item: any) => {
        item.Show = item.id === id ? "active" : ''
        if (item.Show === "active") {
          parent = item.parent;
        }
        return item;
      });
      if (parent !== null)
        subMenu.find((elem: any) => elem.id === parent)["Show"] = "active";
      this.setState({ subMenuStyle: subMenu });
      return
    }
    catch (ex) {
      alert("Load Menu Failed..");
      return
    }
  }
  //event triggers on side menu click
  onsubMenuClick = (ev: any, id: number) => {
    try {
      let parent: any = null;
      let subMenu: any = this.state.subMenuStyle.map((item: any) => {
        item.Show = item.id === id ? "active" : ''
        if (item.Show === "active") {
          parent = item.parent;
        }
        return item;
      });
      if (parent !== null)
        subMenu.find((elem: any) => elem.id === parent)["Show"] = "active";
      if (id === 11 && this.redirectToReports.current !== null && this.redirectToReports.current.state !== null
        && this.redirectToReports.current.state.pageIndex !== null && this.redirectToReports.current.state.pageIndex === 1) {
        this.redirectToReports.current.redirectToReports(0, null);
      }
      this.setState({ subMenuStyle: subMenu });
      $(".cateFilterCont2").toggle("slide");
      return
    }
    catch (ex) {
      alert("Bind menu active/not failed")
      return
    }
  }
  // to load inner sub menu's
  loadSubmenu(subMenu: any) {
    try {
      const themeClr = CommonReports.ThemeColour()
      const rows: any = []
      subMenu.forEach((t1: any, index1: any) => {
        rows.push(<li key={"liSub_" + index1}>
          <Link style={{ fontWeight: t1.Show === 'active' ? "bold" : "normal", color: t1.Show === 'active' ? themeClr : "#7B7B7B" }} to={t1.to} key={'a2_' + index1} onClick={(e: any) => this.onsubMenuClick(e, t1.id)} >{t1.name}</Link>
        </li>);
      })
      return rows
    }
    catch (ex) {
      alert("Load Sub Menu Failed..");
      return
    }
  }
  //event triggers on overlay div click
  closePopup = (e: any) => {
    e.preventDefault();
    var $ = require('jquery')
    if ($('#lightBox6') === undefined || $('#lightBox6').html() === undefined)
      $('.overlay').fadeOut();
    $('#lightBox2').fadeOut();
    $('#lightBox4').fadeOut();
    $('#lightBox5').fadeOut();
    $('#lightBox7').fadeOut();
    $('#lightBox8').fadeOut();
    $('#lightBox41').fadeOut();
  }
  openUserPopup = () => {
    $("#myUserPopup").toggle("slide");
  }
  render() {
    const subMenutasks: any = []
    const parentMenutasks: any = []
    const themeClr = CommonReports.ThemeColour()
    const selectColr: string = themeClr;
    // #0A7CEE
    this.state.subMenuStyle.forEach((t: any, index: any) => {
      const subMenu: any = this.state.subMenuStyle.filter((elem: any) => elem.parent === t.id);
      subMenutasks.push(
        t.parent === null && t.Visible ?
          (<li key={'li_' + index}>
            {subMenu.length === 0 ?
              (<Link to={t.to} key={'a_' + index} style={{ fontWeight: t.Show === 'active' ? "bold" : "normal", color: t.Show === 'active' ? selectColr : "#000" }} onClick={(e: any) => this.onsubMenuClick(e, t.id)}>
                <i className={'fas fa-' + t.Icon} style={{ marginRight: "5px", fontSize: "18px", color: selectColr }} />
                {t.name}
              </Link>) :
              (<a href="#section" style={{ fontWeight: t.Show === 'active' ? "bold" : "normal", color: t.Show === 'active' ? selectColr : "#000" }}>
                <i className={'fas fa-' + t.Icon} style={{ marginRight: "5px", fontSize: "18px", color: selectColr }} />
                {t.name}</a>)}
            {subMenu.length !== 0 ?
              (<ul key={'liul_' + index}>
                {this.loadSubmenu(subMenu)}
              </ul>) : null}
          </li>) : null
      );
      parentMenutasks.push(t.parent === null && t.Visible ?
        (<li key={'liP_' + index}>
          <a href="#section" style={{ textAlign: "center" }}><i className={"fas fa-" + t.Icon} style={{ fontSize: "20px", color: themeClr }}></i>&nbsp;</a>
        </li>) : null);
    });
    let userName: string = ""
    let userMail: string = ""
    const userInfoString: any = sessionStorage.getItem('userInfo');
    const userInfo: any = JSON.parse(userInfoString);
    if (userInfo !== null) {
      userName = userInfo["username"];
      userMail = userInfo["email"];
    }
    return (
      <BrowserRouter basename={config.getbaseName()}>
        <div className="mainContainer">
          <div className="overlay" onClick={(e: any) => this.closePopup(e)}></div>
          <span id="userIp" style={{ display: "none" }} />
          {/* <Lightbox errMsg = {"Welcome " + userName} succFlag = {this.state.succFlag} /> */}
          <div className="lds-roller" id="divLoading" style={{ display: "none" }}>
            <div id="centerVal">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          {/* New implementation  */}
          <div className="cateFilterCont scrollbar" onClick={(e: any) => config.onBodyClick(e)}>
            <div className="headerinner">
              <a href="#section" ><img src={gilogo1} alt="" /></a>
            </div>
            <ul className="cateFilter scrollbar" style={{ maxHeight: ($(window).height() - 60) + 'px' }}>
              {subMenutasks}
            </ul>
          </div>
          <div className="cateFilterCont3" style={{ display: "none" }}>
            <div className="headerinner">
              <a href="#section" ><img alt="" src={gilogo} /></a>
            </div>
            <ul className="cateFilter">
              {parentMenutasks}
            </ul>
          </div>
          <div className="conrgt">
            <div className="menu" onClick={(e: any) => config.onBodyClick(e)}>
              <a href="#section" className="toggleBtn2"><i style={{ color: "#5f5f5f" }} className="fa fa-bars" aria-hidden="true"></i>&nbsp;</a>

              <ul className="mobilemenu">
                {/* <li><span style={{color: "#fff"}}>Workspace: <b>{tmplData.defWorkspace.replace(/^([^_]+_){1}/, '')}</b></span></li>          */}
                <li><span style={{ color: "#5f5f5f" }}>{t('lblWelcome')} <b style={{ color: "#0A7CEE" }}>{userName}</b></span></li>
              </ul>
              <div className="headerUser">
                <div className="dropdown">
                  <a href="#section" className="dropbtn"><i className="fa fa-bell" aria-hidden="true"></i>&nbsp;</a>
                </div>
                <div className="dropdown">
                  <a href="#section" className="dropbtn"><i className="fa fa-envelope" aria-hidden="true"></i>&nbsp;</a>
                </div>
                <div className="dropdown">
                  <a href="#section" onClick={this.openUserPopup} className="dropbtn"><i className="fa fa-user" aria-hidden="true"></i>&nbsp;</a>
                  <div id="myUserPopup" className="dropdown-content2">
                    <ul>
                      <div className="drobHead3">
                        <div><img alt="" src={userImg} /></div>
                        <h3>{userName}</h3>
                        <span>{userMail}</span>
                      </div>
                      {/* <li>
                <span><i className="fa fa-user" style={{color:"#000", marginRight: "10px"}} aria-hidden="true"></i></span>My Account
              </li>
              <li>
                <span><i className="fas fa-money-check" style={{color:"#000", marginRight: "10px"}} aria-hidden="true"></i></span>My Balance
              </li>
              <li>
                <span><i className="fa fa-inbox" style={{color:"#000", marginRight: "10px"}} aria-hidden="true"></i></span>Inbox
              </li>
              <li>
                <span><i className="fa fa-cog" style={{color:"#000", marginRight: "10px"}} aria-hidden="true"></i></span>Account Settings
              </li> */}
                      <li onClick={config.logout}>
                        <span><i className="fas fa-sign-out-alt" style={{ color: "#000", marginRight: "10px" }} aria-hidden="true"></i></span>{t('lbllogout')}
                      </li>
                      <div className="prflBtn">
                        <button>{t('lblProfile')}</button>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="menu2" onClick={(e: any) => config.onBodyClick(e)}>
              <div className="togglemenu2">
                <a href="#section" className="toggleBtn3"><i className="fa fa-bars" aria-hidden="true"></i>&nbsp;</a>
                <div className="cateFilterCont2 scrollbar">
                  <ul className="cateFilter scrollbar" style={{ maxHeight: ($(window).height() - 60) + 'px' }}>
                    {subMenutasks}
                  </ul>
                </div>
              </div>
              <div className="logomenu2">
                <a href="#section" ><img alt="" src={gilogo1} /></a>
              </div>
              <div className="moremenu2">
                <i className="fas fa-ellipsis-h togglemenu4" aria-hidden="true"></i>
              </div>
            </div>
            {/* {this.state.isTemplateShow ? <Vtemplate selTemplate={tmplData} selTemplateName={this.state.seldroplbl} /> : null} */}
            <Switch>
              {/* Note how these two routes are ordered. The more specific
          path="/contact/:id" comes before path="/contact" so that
          route will render when viewing an individual contact */}
              {/* <Route path="/contact/:id">
        <Contact />
        </Route>
        <Route path="/contact">
        <AllContacts />
        </Route> */}

              {/* If none of the previous routes render anything,
          this route acts as a fallback.
          Important: A route with path="/" will *always* match
          the URL because all URLs begin with a /. So that's
          why we put this one last of all */}
              <Route exact path="/">
                <Dashboard ref={this.redirectToReports} backtoDashboard={this.backtoDashboard.bind(this)} />
              </Route>
              <Route exact path="/DashboardNew">
                <DashboardNew ref={this.redirectToReports} backtoDashboard={this.backtoDashboard.bind(this)} />
              </Route>
              <Route exact path="/C_Organizer">
                <COrganizer backClick={null} rowData={null} rootFlag={5} />
              </Route>
              <Route exact path="/A_Organizer">
                <AOrganizer rootFlag={1} />
              </Route>
              <Route exact path="/GC_Organizer">
                <AOrganizer rootFlag={2} />
              </Route>
              <Route exact path="/GRC_Organizer">
                <AOrganizer rootFlag={3} />
              </Route>
              <Route exact path="/E_Organizer">
                <AOrganizer rootFlag={4} />
              </Route>
              <Route exact path="/C_Game">
                <CGame backClick={null} rowData={null} rootFlag={4} />
              </Route>
              <Route exact path="/A_Game">
                <AGame rootFlag={1} />
              </Route>
              <Route exact path="/E_Game">
                <AGame rootFlag={2} />
              </Route>
              <Route exact path="/ES_Game">
                <AGame rootFlag={3} />
              </Route>
              <Route exact path="/GWSP_Reports">
                <AGWReports rootFlag={1} />
              </Route>
              <Route exact path="/GWPL_Reports">
                <AGWReports rootFlag={2} />
              </Route>
              <Route exact path="/GWPLF_Reports">
                <AGWReports rootFlag={3} />
              </Route>
              <Route exact path="/GTWPL_Reports">
                <AGWHReports rootFlag={4} />
              </Route>
              <Route exact path="/GTWPLF_Reports">
                <AGWHReports rootFlag={5} />
              </Route>
              <Route exact path="/GWLT_Reports">
                <AGWReports rootFlag={6} />
              </Route>
              <Route exact path="/GVT_Reports">
                <AGWReports rootFlag={7} />
              </Route>
              <Route exact path="/GPWPL_Reports">
                <AGWReports rootFlag={8} />
              </Route>
              <Route exact path="/GPWPLF_Reports">
                <AGWReports rootFlag={9} />
              </Route>
              <Route exact path="/GCW_Reports">
                <AGWReports rootFlag={13} />
              </Route>
              <Route exact path="/GMW_Reports">
                <AGWReports rootFlag={14} />
              </Route>
              <Route exact path="/GOW_Reports">
                <AGWReports rootFlag={15} />
              </Route>
              <Route exact path="/GC_Reports">
                <AGWHReports rootFlag={10} />
              </Route>
              <Route exact path="/GUC_Reports">
                <AGWHReports rootFlag={11} />
              </Route>
              <Route exact path="/GO_Reports">
                <AGWHReports rootFlag={12} />
              </Route>
              <Route exact path="/GPW_Reports">
                <AGWHReports rootFlag={16} />
              </Route>
              <Route exact path="/GCU_Reports">
                <AGWReports rootFlag={17} />
              </Route>
              <Route exact path="/GOS_Reports">
                <AGWHReports rootFlag={18} />
              </Route>
              <Route exact path="/GRG_Reports">
                <AGWHReports rootFlag={19} />
              </Route>
              <Route exact path="/GPI_Papers">
                <AGWPapers rootFlag={1} />
              </Route>
              <Route exact path="/GUS_Papers">
                <AGWPapers rootFlag={2} />
              </Route>
              <Route exact path="/GWI_Papers">
                <AGWPapers rootFlag={3} />
              </Route>
              <Route exact path="/GTC_Papers">
                <AGWPapers rootFlag={4} />
              </Route>
              <Route exact path="/GVP_Papers">
                <AGWPapers rootFlag={5} />
              </Route>
              <Route exact path="/GDP_Papers">
                <AGWPapers rootFlag={6} />
              </Route>
              <Route exact path="/C_Stockist">
                <CAgentH backClick={null} rowData={null} rootFlag={1} />
              </Route>
              <Route exact path="/A_Stockist">
                <AAgentH rootFlag={2} />
              </Route>
              <Route exact path="/C_SStockist">
                <CAgentH backClick={null} rowData={null} rootFlag={3} />
              </Route>
              <Route exact path="/A_SStockist">
                <AAgentH rootFlag={4} />
              </Route>
              <Route exact path="/C_Terminal">
                <CAgentH backClick={null} rowData={null} rootFlag={5} />
              </Route>
              <Route exact path="/A_Terminal">
                <AAgentH rootFlag={6} />
              </Route>
              <Route exact path="/C_Player">
                <CAgentH backClick={null} rowData={null} rootFlag={7} />
              </Route>
              <Route exact path="/A_Player">
                <AAgentH rootFlag={8} />
              </Route>
              <Route exact path="/C_Commission">
                <CCAgentH backClick={null} rowData={null} rootFlag={9} />
              </Route>
              <Route exact path="/A_Commission">
                <AAgentH rootFlag={10} />
              </Route>
              <Route exact path="/AIS_Audit">
                <CAuditTrail rootFlag={1} />
              </Route>
              <Route exact path="/AVS_Audit">
                <CAuditTrail rootFlag={2} />
              </Route>
              <Route exact path="/AIR_Audit">
                <CAuditTrail rootFlag={3} />
              </Route>
              <Route exact path="/AWP_Audit">
                <CAuditTrail rootFlag={4} />
              </Route>
              <Route exact path="/ACW_Audit">
                <CAuditTrail rootFlag={5} />
              </Route>
              <Route exact path="/ATV_Audit">
                <CAuditTrail rootFlag={6} />
              </Route>
              <Route exact path="/AWV_Audit">
                <CAuditTrail rootFlag={7} />
              </Route>
              <Route exact path="/DisputeAnalysis">
                <CAuditTrail rootFlag={7} />
              </Route>
              <Route exact path="/c_payment">
                <CPayment backClick={null} rowData={null} rootFlag={5} />
              </Route>
              <Route exact path="/am_history">
                <AGWReports rootFlag={20} />
              </Route>
              <Route exact path="/wd_history">
                <AGWReports rootFlag={21} />
              </Route>
              <Route exact path="/comtax">
                <AGWReports rootFlag={22} />
              </Route>
              <Route exact path="/Pg_Reports">
                <AGWHReports rootFlag={23} />
              </Route>
              <Route exact path="/Pg_Summary">
                <AGWHReports rootFlag={24} />
              </Route>
              {/* lastpush yk 17.12*/}
              <Route exact path="/lastpush">
                <AGWReports rootFlag={25} />
              </Route>
              <Route component={Notfound} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default withTranslation()(Usermain); 