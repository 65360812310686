import i18n, { init } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const getCookie = (name: any) => {
    try {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        let lang_val:any = 'en';
        if (parts.length === 2)
            lang_val = parts.pop()!.split(';').shift();
        console.log("selected lang : " + lang_val);
        return lang_val;
    }
    catch (ex) {
        return "en";
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)

init({
    lng: getCookie("lang"),
    backend: {
        /* translation file path */
        loadPath: './i18n/{{ns}}/{{lng}}.json'
    },
    ns: ['translations'],
    defaultNS: 'translations',
    fallbackLng: "en",
    debug: true,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ','
    },
    /* react: {
        wait: true
      } */
})

export default i18n;