import apiHelper from "../../../utils/ApiHelper"
import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { extendMoment } from "moment-range";
import axios from "axios";
import config from "../../../utils/config";
import { t } from "i18next";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);


const Moment = require('moment');
const moment = extendMoment(Moment);

const dataaaa2: any = {
    labels: ['GI' , 'PMC', 'EDITEC'  ],
    datasets: [
        {
            label:'ALR' ,
         backgroundColor: '#058504',
            borderColor: '#0000FF',
            data: [10, 60, 25],
            
        }, {
            label: 'PLR',     
            backgroundColor: '#bbf914',
            borderColor: '#90EE90',
            data: [60, 50, 40],
            
        }, {
            label:'LOTTERY' ,
            backgroundColor: '#b6cc20',
            borderColor: '#41B883',
            data: [30, 40, 70 ],    
        },
        {
            label:'CASINO' ,
            backgroundColor: '#b0d288',
            borderColor: '#41B883',
            data: [20, 14, 2],  
        },
        {
            label:'INSTANT' ,
            backgroundColor: '#2ffc7f',
            borderColor: '#41B883',
            data: [50, 45, 20],   
        },
       
    ]
}    




const options2: any = {
    type:"bar",
    responsive: true,
    plugins: {
        legend: {
            // position: 'top',
            // labels: {
            //     color:"#000000" ,
            // }
         }
        },
    interaction: {
        mode: 'index',
        intersect: false
                 },
    scales: {
        x: {
            display: true,
             title: {
                 display: true,
                 text: 'Provider',
            
             }        
        },
        y: {
            display: true,
            title: {
                display: true,
                text:'Sale Amount',
            
            }
        }
    }   
};
    

export interface IProps {
    startdate:any,
     enddate:any,
     gameID:any,
     organizername:any
}

export interface IState {
    //createOrganizer: any
    chartData: any,
    myTitle:any,
    options:any
}

class ProWiseGameSale extends React.Component <IProps, IState>{

    constructor(props:IProps) {
        super(props);
        this.state = {
            chartData: { labels: [], datasets: [] } ,            
            myTitle: "Provider And Games Wise Sale By Day",
            options:{
                type:"bar",
                responsive: true,
                plugins: {
                    legend: {
                        // position: 'top',
                        // labels: {
                        //     color:"#000000" ,
                        // }
                     }
                    },
                interaction: {
                    mode: 'index',
                    intersect: false
                             },
                scales: {
                    x: {
                        display: true,
                         title: {
                             display: true,
                             text: 'Provider',
                        
                         }        
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text:'Sale Amount',
                        
                        }
                    }
                }

            }
        };
      
    }

  initData = () => {
        let callMethod: string = "";
        let callJsonHead:any = null;
        let getDtlPoint: any = "get_rptDetails";
        let getDtlDtPoint: any = "get_rptGenTime";
        let Gameid = typeof (this.props.gameID) === 'string' ? (this.props.gameID).split('_')[0] : 0 ;
        callMethod = "providewisegame-day";

        callJsonHead = {
            organizerID:this.props.organizername,
            FromDate:moment(this.props.startdate).format('yyyy-MM-DD') + " 00:00:00",
            ToDate:moment(this.props.enddate).format('yyyy-MM-DD') + " 00:00:00",    
            GameId:Gameid,
            
        }
        //mani 22/12
        //axios.get(config.Url(callMethod, "get"), config.authHeaderwithParamRpt(callJsonHead))
        apiHelper.getAPICall(callMethod, "get",5,callJsonHead)
            .then((res)=> {
      if (res !== null && res.data !== null && res.data.result === undefined) {
        let xAxis: any = [];
        let ydatasets: any = [];
        let tempArr: any = [];
        let yname: number = 0;
        let dateAxis:any =[];
        let yAxis:any=[];
        let amtAxis:any=[];
        let colDataArr: any = res.data["get_rptDetails"]; 

        // "organizion": "LONASE EDITECH",
        // "gamename": "PRIX VILLE LAVAL",
        // "drawdate": "11-01-2022",
        // "saleamount": 51961650.0
    
        //get all x-axis unique vaule 
        xAxis =  colDataArr.map((item:any) => item.organizion).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
        //get all y-axis unique vaule   game  show label
         yAxis =  colDataArr.map((item:any) => item.gamename).filter((value:any, index:any, self:any) => self.indexOf(value) === index)

        amtAxis = [...colDataArr.map((item: any) => item.saleamount)];
        var uniqueamount = amtAxis.filter((v:any, i:any, a:any) => a.indexOf(v) === i);
     

         dateAxis = [...colDataArr.map((item: any) => item.drawdate)];
         var uniquedate = dateAxis.filter((v:any, i:any, a:any) => a.indexOf(v) === i);
          //gamename label
       yAxis.forEach((fobj: any) => {
                                   
            //xaxis organizer
            xAxis.forEach((fdate: any) => {
                                                                       //     objlst  gamename===yaxis gamename obj                                                   
                const objLst = colDataArr.filter((item: any,i:any) => { return item.gamename == fobj  && item.organizion == fdate });
              

                if (objLst.length > 0)
                    tempArr.push(objLst[0].saleamount); //amount
                else
                    tempArr.push(0);
            });
            
            //var mycolor =['#058504','#bbf914','#b6cc20', '#b0d288',];
            var color = Math.floor(Math.random() * 16777216).toString(16);
            var colour1= '#000000'.slice(0, -color.length) + color;
            

            ydatasets.push(
                {
                    label: fobj,
                    fill: false,
                    backgroundColor:  colour1, //['#bbf914','#058504' ],
                    borderColor:   ['#bbf914','#058504' ],
                    data: tempArr,
                    
                }                    
            )
               
                //yname++;
            tempArr = [];
        })
        this.setState({ chartData: 
            { labels: xAxis, 
                datasets: ydatasets
             } 
            });
        //return  { labels: xAxis, datasets: ydatasets } ;
    }
})
.catch((err) => {
    console.log("AXIOS ERROR: ", err.response);
    return  { labels:[], datasets:[] } ;
})
}

componentDidUpdate(prevProps:any) {
    if (prevProps.startdate !== this.props.startdate) {
        this.initData()
    }
  }
componentDidMount() {    
    this.initData()
 }
   
    render(): React.ReactNode {        
    return(
        <div>
            <h4>{t('lblProvideGameSaleByDay')}</h4>
        <Bar   options={this.state.options} data={this.state.chartData} /> 
        </div>
    )}
};

export default ProWiseGameSale;


