import React from 'react'
import Usermain from './components/user_main';
import Login from './components/login';
import useToken from './utils/useToken';
import config from './utils/config';
// import './assets/css/metro-all.min.css';
import './assets/css/style.css';
import './assets/css/font-awesome.min.css';
var Crypto = require("cryptr");
const cryptr = new Crypto(config.secretKey());

function getCookie(key: any) {
  var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
  return keyValue ? keyValue[2] : "";
}

function App() {  
  const { token, setToken } = useToken();
  const userInfoString: any = sessionStorage.getItem('userInfo');
  const userInfo: any = JSON.parse(userInfoString);
  //Remember Me- End
  if(!token || token === "-1") {
      //Remember Me- Start
      let cookieLoginInfo: any = getCookie('loginInfo');  
      let isChecked: boolean = false;
      let loginInfo: any = null;
      if(cookieLoginInfo && cookieLoginInfo !== null && cookieLoginInfo !== ""){
        loginInfo = JSON.parse(cryptr.decrypt(cookieLoginInfo));
        isChecked = true;
      }
    return <Login setToken={setToken} remMeUser={loginInfo ? loginInfo?.uId : ""} remMePass={loginInfo ? loginInfo?.pW : ""} isChecked={isChecked} />
  }
  else {  
    return(
      userInfo !== null ? <Usermain /> : null
    );
  }  
}

export default App;