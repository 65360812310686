import React from 'react';
import useAxios from "axios";
import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import Breadcrumb from "../Shared/breadcrumb";
import CGamemodel from "./Child/c_gamemodel";
import moment from "moment"

//responsive dropdown
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown,faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider,TimePicker, DatePicker } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';
import { withTranslation  } from 'react-i18next';
import apiHelper from '../../utils/ApiHelper';

var $ = require('jquery')
library.add(faCaretDown);
library.add(faCaretUp);

export interface IProps {
    rowData: any,
    rootFlag: number,
    backClick: any,
    t:any
}
export interface IState {
    createGame: any
}
  class CGame extends React.Component<IProps, IState> {
  // ------------------------------------------^
    constructor(props: IProps) {
      super(props);  
      this.state = {
        createGame: CGamemodel()
        };
      this.onSubmitData = this.onSubmitData.bind(this);
      this.onhandleTxtChange = this.onhandleTxtChange.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);
    }
    componentDidMount(){
        const rowData: any = this.props.rowData;
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        if(is_superuser !== null && is_superuser){
            this.loadNameMasterdata();
        }
        if(rowData !== null){
            this.updateFields(rowData);
        }
        else{
            this.loadTypeMasterdata();
        }
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):
        const rowData: any = this.props.rowData;  
        if (this.props.rootFlag !== prevProps.rootFlag && rowData !== null) {
            //this.loadTypeMasterdata();
            if(rowData !== null){
                let is_superuser: any = null;
                const userInfoString: any = sessionStorage.getItem('userInfo');
                const userInfo: any = JSON.parse(userInfoString);
                if(userInfo !== null)
                    is_superuser = userInfo["is_superuser"];
                if(is_superuser !== null && is_superuser){
                    this.loadNameMasterdata();
                }
                this.updateFields(rowData); 
            } 
        }
    }
    updateFields= (rowData: any) =>{
        try{
        const resVal: any = this.state.createGame;          
        resVal.cGameMand.drawDate.defValue = moment(rowData.in_drawdate,'DD-MM-yyyy');
        resVal.cGameMand.drawDate.value = rowData.in_drawdate;
        resVal.cGameMand.drawTime.defValue = moment(rowData.in_drawtime,'hh:mm A');
        resVal.cGameMand.drawTime.value = rowData.in_drawtime;
        resVal.cGameMand.startDate.defValue = moment(rowData.in_startdate,'DD-MM-yyyy');
        resVal.cGameMand.startDate.value = rowData.in_startdate;
        resVal.cGameMand.endDate.defValue = moment(rowData.in_enddate,'DD-MM-yyyy');
        resVal.cGameMand.endDate.value = rowData.in_enddate;
        resVal.cGameMand.startTime.defValue = moment(rowData.in_starttime,'HH:mm:ss');
        resVal.cGameMand.startTime.value = moment(rowData.in_starttime,'HH:mm:ss').format('HH:mm');
        resVal.cGameMand.endTime.defValue = moment(rowData.in_endtime,'HH:mm:ss');
        resVal.cGameMand.endTime.value = moment(rowData.in_endtime,'HH:mm:ss').format('HH:mm');
        resVal.cGameMand.gameId.value = rowData.in_gameid;
        resVal.cGameMand.gameName.value = rowData.in_gamename;
        resVal.cGameMand.mrpValue.value = rowData.in_mrpvalue;

        resVal.cGameDrop.gameTypeId.value = rowData.in_gamedesc;
        resVal.cGameDrop.OrganizerName.value = rowData.in_organizerid;
        // resVal.cGameDrop.OrganizerName.userId = rowData.in_organizeruserid;
        this.setState({createGame: resVal}); 
        }
        catch(ex) {
        alert("Field Updation Failed..");
        return
        }      
    }
    loadTypeMasterdata= () => {
        try{
        $("#divLoading").attr("style", "display:block")
        //this.setState({createGame: CGamemodel()});
        // mani 23/12
        //useAxios.get(config.Url("cm_game","get"), config.authHeaderwithParam("GameType~@#%&1"))
          apiHelper.getAPICall("cm_game", "get",4,"GameType~@#%&1")
        .then((res) => {
            console.log("RESPONSE RECEIVED: ", res);
            let gameTypeArr: any = [];
            if(res.data !== null && res.data.length !== 0){
                if(res.data["load_gameDetails"] !== undefined && res.data["load_gameDetails"] !== null && res.data["load_gameDetails"].length !== 0){                  
                for(var prop of res.data["load_gameDetails"]){
                    gameTypeArr.push({value: prop["in_gamegroupid"],label: prop["in_gamegroupdesc"]})
                }
              }
            }         
            $("#divLoading").attr("style", "display:none") 
            //$("#templaTBlid").attr("style", "display:none") 
            const gameDet: any = this.state.createGame;
            gameDet.cGameDrop.gameType = gameTypeArr;
            this.setState({createGame: gameDet});
            return
        })
        .catch((err) => {
            console.log("AXIOS ERROR: ", err.response);
            $("#divLoading").attr("style", "display:none") 
            return false
        }) 
        }
        catch(ex) {
        alert("Master Data load Failed..");
        return
        }
    }
    loadNameMasterdata= () => {
        try{
        $("#divLoading").attr("style", "display:block")
        this.setState({createGame: CGamemodel()});
        //mani 23/12
        //useAxios.get(config.Url("cmo_game","get"), config.authHeaderwithParam("OrgName~@#%&1"))
        apiHelper.getAPICall("cmo_game", "get",4,"OrgName~@#%&1")
        .then((res) => {
            console.log("RESPONSE RECEIVED: ", res);
            let OrganizerNamesArr: any = [];
            if(res.data !== null && res.data.length !== 0){
                if(res.data["organizer_details"] !== undefined && res.data["organizer_details"] !== null && res.data["organizer_details"].length !== 0){                  
                for(var prop of res.data["organizer_details"]){
                    OrganizerNamesArr.push({value: prop["dl_organizerid"],label: prop["dl_organizername"],userId: prop["dl_userid"]})
                }
              }
            }         
            $("#divLoading").attr("style", "display:none") 
            //$("#templaTBlid").attr("style", "display:none") 
            const gameDet: any = this.state.createGame;
            gameDet.cGameDrop.OrganizerNames = OrganizerNamesArr;
            this.setState({createGame: gameDet});
            return
        })
        .catch((err) => {
            console.log("AXIOS ERROR: ", err.response);
            $("#divLoading").attr("style", "display:none") 
            return false
        }) 
        }
        catch(ex) {
        alert("Master Data Load Failed..");
        return
        }
    }
    onSubmitData= (e: any,Type: string) => {
        try{
        e.preventDefault();
        let resVal: any = this.state.createGame;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        let isErrData: boolean = false;
        $("#divLoading").attr("style", "display:block")
        //validation part-Start
        Object.entries(resVal.cGameMand).forEach(([key, value]) => {
            let val = resVal.cGameMand[key].value;
            if(val === null || val === "" || resVal.cGameMand[key].display){
                resVal.cGameMand[key].display = true
                isErrData = true
            }
            else if((key === "drawDate" || key === "startDate" || key === "endDate") && this.props.rowData !== null){
                const currDate: any = moment().format("yyyy-MM-DD");
                const isValid: boolean = moment(currDate).isBefore(moment(resVal.cGameMand[key].value,"DD-MM-yyyy").format("yyyy-MM-DD")) || moment(currDate).isSame(moment(resVal.cGameMand[key].value,"DD-MM-yyyy").format("yyyy-MM-DD"));
                if(!isValid){ //minimal date calculation
                    resVal.cGameMand[key].display = false
                    isErrData = true
                }
            }else{ return }
        })
        if(resVal.cGameDrop.gameTypeId.value === null){
            resVal.cGameDrop.gameTypeId.display = true
            isErrData = true
        }
        if(userInfo["is_superuser"] && resVal.cGameDrop.OrganizerName.value === null){
            resVal.cGameDrop.OrganizerName.display = true
            isErrData = true
        }
        resVal.succFlag = undefined;
        //validation part-End
        if(isErrData){
            $("#divLoading").attr("style", "display:none") 
            //resVal.errMsg = "Invalid data..!"
            this.setState({createGame: resVal});
            // $('.overlay').fadeIn();
            // $('#lightBox2').fadeIn(); 
            return 
        }else{ 
            if(Type === "C"){
                const userIp: string = $("#userIp").text();
                const closeTime: any = resVal.cGameMand.endTime.value;
                const cvrtSeconds = moment(closeTime, 'HH:mm').diff(moment().startOf('day'), 'seconds');
                const jsonData: any = {
                    "GameTypeID": parseInt(resVal.cGameDrop.gameTypeId.value), // dropdown
                    "organizerID": userInfo["is_superuser"] ? resVal.cGameDrop.OrganizerName.value : userInfo["userId"], // dropdown
                    "GameID": resVal.cGameMand.gameId.value, 
                    "GameName": resVal.cGameMand.gameName.value,
                    "MrpValue": parseFloat(resVal.cGameMand.mrpValue.value).toFixed(2),
                    "DrawDate": moment(resVal.cGameMand.drawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00", 
                    "DrawTime": resVal.cGameMand.drawTime.value, 
                    "StartDate":  moment(resVal.cGameMand.startDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    "EndDate":  moment(resVal.cGameMand.endDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00", 
                    "StartTime": resVal.cGameMand.startTime.value + ":00",
                    "EndTime": closeTime + ":00", 
                    "DrawCloseTime": cvrtSeconds, //calculate field 5.30 5*3600 + 30*60 close time edit
                    "username": userInfo["userId"],
                    "ipaddress": userIp
                };
                //MANI 23/12
                //useAxios.post(config.Url("c_game","post"), jsonData, config.authHeader())
                apiHelper.postAPICall("c_game", "post",2,jsonData)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    $("#divLoading").attr("style", "display:none")
                    if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
                        $("#divLoading").attr("style", "display:none") 
                        resVal.errMsg = res.data.result.toString().replace('successfully','');
                        resVal.succFlag = undefined;
                        this.setState({createGame: resVal});                   
                    }
                    else{
                        const initialState: any = resVal.cGameDrop;
                        resVal = CGamemodel();
                        resVal.cGameDrop.gameType = initialState.gameType;
                        resVal.cGameDrop.OrganizerNames = initialState.OrganizerNames;
                        resVal.succFlag = 0;
                        resVal.errMsg = res.data.result.toString().replace('successfully','');
                        this.setState({createGame: resVal});
                    }
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    if(err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.includes('duplicate key')){
                        resVal.errMsg = "Game Id already exists."
                        this.setState({createGame: resVal});
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                    } 
                    return 
                })
            }
            else{
                this.props.backClick(this.state.createGame);
                return
            }
        } 
        }
        catch(ex) {
        alert("Data Insertion/ Updation Failed..");
        return
        }      
    }
    // event triggers on key press on input controls
    handleKeypress = (evt: any,type: string) => {
        if (type === "integer" && evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
        //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
            evt.preventDefault();
        }
        else if (evt.which !== 46 && evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
            //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
                evt.preventDefault();
        }
    }
    maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
    }
    onhandleTxtChange= (e: any,key: string) => {
        try{
        let resVal: any = this.state.createGame;
        resVal.errMsg = "";
        resVal.cGameMand[key].value = e.target.value;
        resVal.cGameMand[key].display = e.target.value.trim() === "" ? true : false;
        resVal.succFlag = undefined;
        if(resVal.errMsg !== ""){
            $("#divLoading").attr("style", "display:none") 
            this.setState({createGame: resVal});
            $('.overlay').fadeIn();
            $('#lightBox2').fadeIn(); 
            return 
        }else{
            this.setState({createGame: resVal})
            return
        }
        }
        catch(ex) {
        alert("On handle text change Failed..");
        return
        }
    }
    handleDateChange = (date: any,key: string,format: string) => {
        try{
        const resVal: any = this.state.createGame;
        resVal.cGameMand[key].defValue = moment(date._d);
        resVal.cGameMand[key].value = moment(date._d).format(format);
        if(key === "endTime" && moment(resVal.cGameMand[key].value,format) > moment(resVal.cGameMand.drawTime.value,"hh:mm A")){
            resVal.cGameMand[key].display = true;
        }
        else{            
            if(key === "drawTime"){
                resVal.cGameMand["endTime"].defValue = moment(date._d).subtract(10, 'minutes');
                resVal.cGameMand["endTime"].value = moment(date._d).subtract(10, 'minutes').format("HH:mm");
                resVal.cGameMand["endTime"].display = false;
                resVal.cGameMand[key].display= false;
            }
            else if(key === "startDate" || key === "endDate"){
                resVal.cGameMand.endDate.display = false;
                resVal.cGameMand.startDate.display =(resVal.cGameMand.endDate.value !== null && moment(resVal.cGameMand.startDate.value,format) > moment(resVal.cGameMand.endDate.value,format))
            }
            else if(key === "startTime" || key === "endTime"){
                resVal.cGameMand.endTime.display = false;
                resVal.cGameMand.startTime.display =(resVal.cGameMand.endTime.value !== null && moment(resVal.cGameMand.startTime.value,format) > moment(resVal.cGameMand.endTime.value,format));
            }
            else{
                resVal.cGameMand[key].display= false;
            }     
        }
        this.setState({createGame: resVal});
        return
        }
        catch(ex) {
        alert("On handle date change Failed..");
        return
        }
    };
    render() {
        const { t } = this.props;
        const resVal: any = this.state.createGame;
        const defaultMaterialTheme = createMuiTheme({
            palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#009269',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
        });
        const DropdownIndicator = (props: any) => {
            return (
              components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                  <FontAwesomeIcon style={{fontSize: "20px"}} icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>
                </components.DropdownIndicator>
              )
            );
          };
        const styles = {
            menu: (base: any) => ({
                ...base,
                marginTop: 0
            })
        };
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
      return(
        <div className="ContainerChart scrollbar" style={{height: $(window).height() - 60 + "px"}}>
            <Lightbox errMsg= {resVal.errMsg} succFlag= {resVal.succFlag} />            
            <div className="contentclouds">
            <div className="userLogdata1">					
                <div className="userLogHead">
                <Breadcrumb Menu= "Game" subMenu= {this.props.rowData === null ? "Create Game" : "View Game"} subMenu1= {this.props.rowData === null ? null : "Edit Game"} />
                <div className="userLogHeadinner">
				<h2>{this.props.rowData === null ? "Create" : "Edit"} Game</h2>
				<small>{t('lblGameDetails')}</small>
			    </div>
                </div>
                <div className="userLogform scrollbar">
                    <div className="userLogformLft">
                        <ul>
                            <li>
                                {is_superuser !== null && is_superuser ? (<div className="userLogCloud3">
                                    <div>
                                        <label>{t('lblOrganizerName')} *</label>
                                        <Select
                                        value={resVal.cGameDrop.OrganizerName.value === null ? null : resVal.cGameDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.cGameDrop.OrganizerName.value.toString())}
                                        onChange={(e: any) => {
                                            const resVals: any = this.state.createGame;
                                            resVals.cGameDrop.OrganizerName.value = e.value;
                                            resVals.cGameDrop.OrganizerName.display = false;
                                            //resVals.cGameDrop.OrganizerName.userId = resVal.cGameDrop.OrganizerNames.filter((option: any) => option.value.toString() === e.value.toString())[0].userId
                                            this.setState({createGame: resVals})
                                        }}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        placeholder={t('lblSelectName')}
                                        options={resVal.cGameDrop.OrganizerNames}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameDrop.OrganizerName.display ? "block" : "none"}}>{t('lblOrgNameProvided')}</label>
                                    </div>
                                </div>) : null}
                                <div className="userLogCloud3">
                                    <div>
                                        <label>{t('lblGameType')}*</label>
                                        <Select
                                        value={resVal.cGameDrop.gameTypeId.value === null ? null : resVal.cGameDrop.gameType.filter((option: any) => option.value.toString() === resVal.cGameDrop.gameTypeId.value.toString())}
                                        onChange={(e: any) => {
                                            const resVals: any = this.state.createGame;
                                            resVals.cGameDrop.gameTypeId.value = e.value;
                                            resVals.cGameDrop.gameTypeId.display = false;
                                            this.setState({createGame: resVals})
                                        }}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        placeholder={this.props.rowData === null ? t('lblSelectGame') : resVal.cGameDrop.gameTypeId.value}
                                        options={resVal.cGameDrop.gameType}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameDrop.gameTypeId.display ? "block" : "none"}}>{t('lblGameProvided')}</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblGameId')}*</label>
                                        <input className="input_Number disableCtrl" id="colValL2" min="0" onKeyPress= {(e:any) => this.handleKeypress(e,"integer")} value={resVal.cGameMand.gameId.value} onChange={(e: any) => this.onhandleTxtChange(e,"gameId")} placeholder={t('lblEnterGameId')} autoComplete="off" type="number" maxLength={5} onInput={this.maxLengthCheck} disabled={this.props.rowData === null ? false : true} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameMand.gameId.display ? "block" : "none"}}>{t('lblGameIdProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblGameName')} *</label>
                                        <input className="newUserTxt" value={resVal.cGameMand.gameName.value} onChange={(e: any) => this.onhandleTxtChange(e,"gameName")} placeholder={t('lblEnterGameName')} autoComplete="off" type="text" maxLength={20}/>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameMand.gameName.display ? "block" : "none"}}>{t('lblGameNameProvided')}</label>
                                    </div>
                                </div> 
                                <div className="userLogCloud2">
                                    <div>
                                        <label>MRP *</label>
                                        <input className="input_Number" id="colValL1" min="0" onKeyPress= {(e:any) => this.handleKeypress(e,"decimal")} value={resVal.cGameMand.mrpValue.value} onChange={(e: any) => this.onhandleTxtChange(e,"mrpValue")} placeholder={t('lblEnterMRP')} autoComplete="off" type="number" maxLength={10} onInput={this.maxLengthCheck} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameMand.mrpValue.display ? "block" : "none"}}>{t('lblMRPProvided')}</label>
                                    </div>
                                </div>                                        
                            </li> 
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                            <li>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblDrawDate')} * (DD-MM-YYYY)</label>
                                        <ThemeProvider theme={defaultMaterialTheme}>                                        
                                        <DatePicker
                                            placeholder={t('lblPickDate')}
                                            minDate={new Date()}
                                            format="DD-MM-yyyy"
                                            value={resVal.cGameMand.drawDate.defValue}
                                            onChange={(date: any) => this.handleDateChange(date,"drawDate","DD-MM-yyyy")}
                                        />                                        
                                        </ThemeProvider>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameMand.drawDate.display ? "block" : "none"}}>{t('lblDrawDateProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblStartDate')} * (DD-MM-YYYY)</label>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                        <DatePicker
                                            placeholder={t('lblPickStartDate')}
                                            minDate={new Date()}
                                            format="DD-MM-yyyy"
                                            value={resVal.cGameMand.startDate.defValue}
                                            onChange={(date: any) => this.handleDateChange(date,"startDate","DD-MM-yyyy")}
                                        />
                                        </ThemeProvider>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameMand.startDate.display ? "block" : "none"}}>{resVal.cGameMand.startDate.value === null ? t('lblStartDateProvided') : t('lblStartDateGreater')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblEndDate')} * (DD-MM-YYYY)</label>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                        <DatePicker
                                            placeholder={t('lblPickEndDate')}
                                            minDate={new Date()}
                                            format="DD-MM-yyyy"
                                            value={resVal.cGameMand.endDate.defValue}
                                            onChange={(date: any) => this.handleDateChange(date,"endDate","DD-MM-yyyy")}
                                        />
                                        </ThemeProvider>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameMand.endDate.display ? "block" : "none"}}>{t('lblEndDateProvided')}</label>
                                    </div>
                                </div>
                            </li>
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                            <li>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblDrawTime')} * (HH:MM A)</label>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                        <TimePicker 
                                            placeholder={t('lblPickDrawTime')}                                           
                                            value={resVal.cGameMand.drawTime.defValue}
                                            onChange={(date: any) => this.handleDateChange(date,"drawTime","hh:mm A")}
                                        />
                                        </ThemeProvider>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameMand.drawTime.display ? "block" : "none"}}>{t('lblDrawTimeProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblStartTime')} * (HH:MM)</label>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                        <TimePicker
                                            ampm={false}
                                            placeholder={t('lblPickStartTime')} 
                                            format="HH:mm"
                                            value={resVal.cGameMand.startTime.defValue}
                                            onChange={(date: any) => this.handleDateChange(date,"startTime","HH:mm")}
                                        />
                                        </ThemeProvider>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameMand.startTime.display ? "block" : "none"}}>{resVal.cGameMand.startTime.value === null ? t('lblStartTimeProvided') : t('lblStartTimeGreater')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblEndTime')} * (HH:MM)</label>
                                        <ThemeProvider theme={defaultMaterialTheme}>
                                        <TimePicker
                                            ampm={false}
                                            placeholder={t('lblPickEndTime')}
                                            format="HH:mm"
                                            value={resVal.cGameMand.endTime.defValue}
                                            disabled={resVal.cGameMand.drawTime.defValue === null ? true : false}
                                            onChange={(date: any) => this.handleDateChange(date,"endTime","HH:mm")}
                                        />
                                        </ThemeProvider>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cGameMand.endTime.display ? "block" : "none"}}>{resVal.cGameMand.endTime.value === null ? t('lblEndTimeProvided') : t('lblEndTimeGreater')}</label>
                                    </div>
                                </div>
                            </li>
                            </MuiPickersUtilsProvider>                            
                        </ul>                                
                        <div className="userLogBtnCont3">
                            <button className="GreyBg" id="loginBtn" onClick={() => {
                                if(this.props.rowData === null){
                                const resValss: any = this.state.createGame;
                                const initialState: any = CGamemodel();
                                initialState.cGameDrop.gameType = resValss.cGameDrop.gameType;
                                initialState.cGameDrop.OrganizerNames = resValss.cGameDrop.OrganizerNames;
                                this.setState({createGame: initialState})
                                }else{
                                    this.setState({createGame: CGamemodel()})
                                    this.props.backClick(null);
                                }
                                return
                            }}><i className={"fa fa-" + (this.props.rowData === null ? "sync" : "times")} style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t('lblReset') : t('lblCancel')}</button>							
                            <button className="GreenBg" id="registerBtn" onClick={(e: any) => this.onSubmitData(e,this.props.rowData === null ? "C" : "E")}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t('lblCreate') : t('lblOk')}</button>		
                        </div>                 
                    </div>
                </div>
            </div>			
            </div>
	    </div>                   
    );
  }
}

export default  withTranslation()(CGame);