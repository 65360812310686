import React from 'react';

import ListTable from "./listtable";
import CommonReports from "../Reports/Child/c_reportscommon";
var $ = require('jquery')

export default class ListSubTable extends React.Component {
    constructor(props) {
      super(props); 
      this.state = {
        filteredData: [],
        searchInput: ""
      };
      this.globalSearch = this.globalSearch.bind(this);
      this.exportXLPdfPrintdownload = this.exportXLPdfPrintdownload.bind(this);
    }
    subTabStateReset= () => {
        this.setState({filteredData: [],searchInput: ""})
        return;
    }
    //export datas xls..,csv..,print
    exportXLPdfPrintdownload = (e,fileType) => {
    try{
        e.preventDefault();
        // if(fileType === "chart"){
        //     $('.overlay').fadeIn();
        //     $('#lightBox4').fadeIn();
        //     return
        // }
        const rootFlag = this.props.rootFlag;
        const pageTitle = CommonReports.getPageTitle(rootFlag);
        const fileName = pageTitle.replaceAll(' ','');  
        const genTime = this.props.rptGenDateSub.toString();
        const Orgdata = this.props.tabData;
        if(fileType === "pdf"){
            CommonReports.exportPDF(Orgdata,pageTitle,fileName,this.props.rootFlag,genTime,true,this.props.isSelSwitch);
        }
        else if(fileType === "csv" || fileType === "xls"){  
            CommonReports.exportExcel(Orgdata,fileName,fileType,this.props.rootFlag,genTime,true,this.props.isSelSwitch);   
        }
        else{
            CommonReports.exportPrint(Orgdata,pageTitle,this.props.rootFlag,genTime,true,this.props.isSelSwitch);
        }
        return
    }
    catch(ex) {
        alert("Export Failed..");
        return
    }
    }    
    // And in your global search
    globalSearch = (e) => {
    try{
        let initialState = this.state;
        const searchInput = e.target.value;
        const skipGT = this.props.tabData.filter((elem,index) => index !== this.props.tabData.length - 1);
        let filteredData = skipGT.filter((value) => {
        return CommonReports.getKeyByValue(value, searchInput,this.props.rootFlag,true,this.props.isSelSwitch);
        });
        initialState.filteredData = filteredData;
        initialState.searchInput = searchInput;
        this.setState(initialState);
        return
    }
    catch(ex) {
        alert("Load Search filter Sub List Load Failed..");
        return
    }        
    };
    closePopup =() => {
        $('.overlay').fadeOut();
        $('#lightBox5').fadeOut();
    }
    render() {
    const pageSize = this.props.tabData !== undefined && this.props.tabData.length > 50 ? 50 : this.props.tabData.length > 25 ? 25 : 10;
      return(
        <div id="lightBox5" className="lightBox5" style={{marginLeft: '-'+ ($('#lightBox5').width() / 2) +'px',marginTop: '0px'}}>
        <div className="userLogpreview">
            <div className="PreviewHead">
                <h2>{CommonReports.getPageTitle(this.props.rootFlag)}</h2>
                <a href="#section" className="CancelBtn" onClick={this.closePopup}><i className="fas fa-times"></i></a>
            </div>                
            <div className="ContainerChart1 scrollbar">
            <div className="contentclouds">
            {this.props.tabData !== undefined && this.props.tabData.length > 0 ?       
            (<div className="UseformNew">            
            <div className="TblError">
                <span>Report Generated On: <b>{this.props.rptGenDateSub}</b></span>
            </div>
            <div className="ContainerTable">
                <div className="tableSearchreport">
                        <div className="sportSrch">
                            <input type="text" placeholder="Search here..!" id="myInput" value={this.state.searchInput} onChange={(e) => this.globalSearch(e)} autoComplete="off" />
                        </div>
                </div>
                <div className="ButtonArea">  
                    {/* <button className="ButtonDoc" type="button" onClick={(e) => this.exportXLPdfPrintdownload(e,'chart')}>
                        <a href="#section"><i className="fas fa-chart-bar" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>Chart</a>
                    </button> */}
                    <button className="ButtonDoc" type="button" onClick={(e) => this.exportXLPdfPrintdownload(e,'pdf')}>
                        <a href="#section"><i className="fas fa-file-export" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>PDF</a>
                    </button> 
                    {/* <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'csv')}>
                        <a href="#section"><i className="fas fa-file-excel" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>CSV</a>
                    </button> */}
                    <button className="ButtonDoc" type="button" onClick={(e) => this.exportXLPdfPrintdownload(e,'xls')}>
                        <a href="#section"><i className="fas fa-file-excel" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>Excel</a>
                    </button>
                    <button className="ButtonDoc" type="button" onClick={(e) => this.exportXLPdfPrintdownload(e,'print')}>
                        <a href="#section"><i className="fas fa-print" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>Print</a>
                    </button>
                </div>
            </div>
            <div className="TableReact">            
                 <ListTable
                    sorting={this.props.sorting}
                    columns={this.props.colData}
                    pageSize={pageSize}
                    data={this.state.filteredData && this.state.searchInput !== "" ? this.state.filteredData : this.props.tabData.filter((elem,index) => index !== this.props.tabData.length - 1)}           
                />
            </div>
            </div>) : null}  
            </div>   			
            </div>
        </div>
    </div>);
  }
}