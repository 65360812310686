import React from 'react';
import useAxios from "axios";
import Select, { components } from 'react-select';
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown,faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { DateRangePicker } from "materialui-daterange-picker";
import _ from "lodash"
import CDashboardmodel from "./Child/c_dashboardmodel";
import config from "../../utils/config"
import CommonDashboard from './Child/c_dashboardcommon';
import ProImg from "../../assets/img/icons/proImg.png"
import AGWReports from "../Reports/gw_reports"
//date range
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { extendMoment } from "moment-range";
/* import { withTranslation  } from 'react-i18next'; */
import { t } from 'i18next';
import apiHelper from '../../utils/ApiHelper';

const Moment = require('moment');
const moment = extendMoment(Moment);

var $ = require("jquery");//
library.add(faCaretDown);
library.add(faCaretUp);

export interface IProps {
    backtoDashboard: any
}
//Declaration
export interface IState {
    succFlag: any,
    errMsg: string,
    aGWReportsDt: any,
    aGWReportsDrop: any,
    aGWReportsCt: any,
    pageIndex: number,
    organsid: any,
    aGWReportsTab: any
}

class Dashboard extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = CDashboardmodel();
        this.handleDropChange = this.handleDropChange.bind(this); 
        this.onhandleDateRange = this.onhandleDateRange.bind(this);   
        this.setOpenPicker = this.setOpenPicker.bind(this);   
        this.onhandleToggle = this.onhandleToggle.bind(this);
        this.redirectToReports = this.redirectToReports.bind(this);
    }
    componentDidMount(){  
        let is_superuser: any = null;
        let userId: any = "";
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null){
            is_superuser = userInfo["is_superuser"];
            userId = userInfo["userId"];
        }
        this.props.backtoDashboard(11);
        const resVals: any = this.state;
        if(is_superuser){
            resVals.aGWReportsDrop.OrganizerName.value = 0;
            this.loadOrgNameMasterdata(resVals);
        }
        else{
            resVals.aGWReportsDrop.OrganizerName.value = userId;
            const callJsonHead: any = { 
                FromDate: moment(resVals.aGWReportsDt.FdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
                ToDate: moment(resVals.aGWReportsDt.TdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
                organizerID: userId
            }
            this.loadNameMasterdata(resVals,callJsonHead);
        }
    }
    //load organizer details
    loadOrgNameMasterdata= (resVals: any) => {
        try{
            $("#divLoading").attr("style", "display:block")
            //mani 22/12
            //useAxios.get(config.Url("cmo_game","get"), config.authHeaderwithParam("OrgName~@#%&2"))
            apiHelper.getAPICall("cmo_game", "get",4,"OrgName~@#%&2")
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let OrganizerNamesArr: any = [];
                if(res.data !== null && res.data.length !== 0){
                    if(res.data["organizer_details"] !== null && res.data["organizer_details"].length !== 0){                  
                    for(var prop of res.data["organizer_details"]){
                        OrganizerNamesArr.push({value: prop["dl_organizerid"],label: prop["dl_organizername"],userId: prop["dl_userid"]})
                    }
                  }
                }         
                $("#divLoading").attr("style", "display:none") 
                //$("#templaTBlid").attr("style", "display:none") 
                //const resVals: any = this.state;
                resVals.aGWReportsDrop.OrganizerNames = OrganizerNamesArr;                
                const callJsonHead: any = { 
                    FromDate: moment(resVals.aGWReportsDt.FdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    ToDate: moment(resVals.aGWReportsDt.TdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    organizerID:  resVals.aGWReportsDrop.OrganizerName.value
                }
                this.loadNameMasterdata(resVals,callJsonHead);
                return
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none") 
                return false
            })
        }
        catch(ex) {
          alert("Load Master Data Failed..");
          return
        } 
    }
    /* load game names */
    loadNameMasterdata = (initialState: any,callJsonHead: any) => {
        try{
            $("#divLoading").attr("style", "display:block")
            //mani 22/12
            //useAxios.get(config.Url("ctm_game","get"), config.authHeaderwithParamRpt(callJsonHead))
            apiHelper.getAPICall("ctm_game", "get",5,callJsonHead)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let GameNamesArr: any = [];
                if(res.data !== null && res.data.length !== 0){
                    let getDtlPoint: any = "get_rptDetails";
                    if(res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0 && (res.data[getDtlPoint][0]["dl_gameid"] === 0  && res.data[getDtlPoint].length !== 1)){                  
                    for(var prop of res.data[getDtlPoint]){
                        GameNamesArr.push({value: prop["dl_gameid"] + "_" + prop["dl_organizerid"],label: prop["dl_gamename"],orgId: prop["dl_organizerid"],orgName: prop["dl_organizername"]})
                    }
                  }
                }         
                $("#divLoading").attr("style", "display:none")
                // initialState.aGWReportsDrop.gameDetId = CReportsmodel().aGWReportsDrop.gameDetId; 
                initialState.aGWReportsDrop.gameDet = GameNamesArr;
                initialState.aGWReportsDrop.gameDetId = {value: "0_0",display: false};
                this.loadDashboarDatas(initialState);
                this.setState(initialState);
                return
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none") 
                return false
            }) 
        }
        catch(ex) {
          alert("Load Master Data Failed..");
          return
        }
    }
    /* Drop down change Print preview */
    handleDropChange = (e: any,type: number) => {
    try{
        const resVals: any = this.state;
        resVals.aGWReportsCt.options= null;
        resVals.aGWReportsCt.series= null;
        resVals.aGWReportsCt.options1 = null;
        resVals.aGWReportsCt.series1 = null;
        resVals.aGWReportsCt.chartTitle = "";
        resVals.aGWReportsCt.chartTitle1 = "";
        resVals.aGWReportsTab.tabAllData = [];
        resVals.aGWReportsTab.tabNxtDrawData = [];
        resVals.succFlag = undefined;
        resVals.errMsg = "";
        if(type === 1){
            resVals.aGWReportsDrop.OrganizerName.value = e.value;
            resVals.aGWReportsDrop.OrganizerName.display = false;
            const callJsonHead: any = { 
                FromDate: moment(resVals.aGWReportsDt.FdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
                ToDate: moment(resVals.aGWReportsDt.TdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
                organizerID: resVals.aGWReportsDrop.OrganizerName.value
            }
            this.loadNameMasterdata(resVals,callJsonHead);
            //resVals.aGWReportsDrop.OrganizerName.userId = resVals.aGWReportsDrop.OrganizerNames.filter((option: any) => option.value.toString() === e.value.toString())[0].userId
        }
        else if(type === 2){
            resVals.aGWReportsDrop.gameDetId.value = e.value;
            resVals.aGWReportsDrop.gameDetId.display= false;
            this.loadDashboarDatas(resVals);
        }
     this.setState(resVals);
        return
    }
    catch(ex) {
        alert("On Dropdown Change Failed..");
        return
    }
    }
    onhandleDateRange = (value: any) => {
        const resVals: any = this.state;
        resVals.aGWReportsCt.options= null;
        resVals.aGWReportsCt.series= null;
        resVals.aGWReportsCt.options1 = null;
        resVals.aGWReportsCt.series1 = null;
        resVals.aGWReportsCt.chartTitle = "";
        resVals.aGWReportsCt.chartTitle1 = "";
        resVals.aGWReportsTab.tabAllData = [];
        resVals.aGWReportsTab.tabNxtDrawData = [];
        resVals.aGWReportsDt.openPicker = false;
        resVals.aGWReportsDt.drawDate = value;
        resVals.aGWReportsDt.FdrawDate.value = value.start.format("MMM DD,yyyy");
        resVals.aGWReportsDt.TdrawDate.value = value.end.format("MMM DD,yyyy");
        resVals.succFlag = undefined;
        resVals.errMsg = "";
        const callJsonHead: any = { 
            FromDate: moment(resVals.aGWReportsDt.FdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
            ToDate: moment(resVals.aGWReportsDt.TdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
            organizerID: resVals.aGWReportsDrop.OrganizerName.value
        }
        this.loadNameMasterdata(resVals,callJsonHead);
        this.setState(resVals);
        return;
    }
    //open-close date range picker
    setOpenPicker = () => {
        const resVals: any = this.state;
        resVals.aGWReportsDt.openPicker = resVals.aGWReportsDt.openPicker ? false : true;
        this.setState(resVals);
        return
    }
    //filter based load dashboard datas
    loadDashboarDatas = (resVals: any) => {
        try{
            $("#divLoading").attr("style", "display:block")
            let is_superuser: any = null;
            let userId: any = "";
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            if(userInfo !== null){
                is_superuser = userInfo["is_superuser"];
                userId = userInfo["userId"];
            }
            const callJsonHead: any = { 
                FromDate: moment(resVals.aGWReportsDt.FdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
                ToDate: moment(resVals.aGWReportsDt.TdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
                organizerID: is_superuser ? resVals.aGWReportsDrop.OrganizerName.value : userId,
                GameID: resVals.aGWReportsDrop.gameDetId.value.split('_')[0]
            }
            //mani 22/12
            //useAxios.get(config.Url("c_dashboard","get"), config.authHeaderwithParamRpt(callJsonHead))
            apiHelper.getAPICall("c_dashboard", "get",5,callJsonHead)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let loadLineChartArr: any = [];
                let loadDonutChartArr: any = [];
                if(res.data !== null && res.data.length !== 0){
                    const isEnableOverAll: boolean = is_superuser && resVals.aGWReportsDrop.OrganizerName.value === 0 ? true : false;                
                    if(res.data["revenue_data"] !== null && res.data["revenue_data"].length !== 0){                  
                        loadLineChartArr = this.loadLineChart(res.data["revenue_data"],isEnableOverAll); 
                        if(loadLineChartArr.length !== 0){
                            resVals.aGWReportsCt.options = loadLineChartArr[0];
                            resVals.aGWReportsCt.series = loadLineChartArr[1];
                            resVals.aGWReportsCt.chartTitle = loadLineChartArr[2];
                        }
                    }
                    if(res.data["betamt_data"] !== null && res.data["betamt_data"].length !== 0){                         
                        loadDonutChartArr = this.loadDonutChart(res.data["betamt_data"],isEnableOverAll);
                        if(loadDonutChartArr.length !== 0){
                            resVals.aGWReportsCt.options1 = loadDonutChartArr[0];
                            resVals.aGWReportsCt.series1 = loadDonutChartArr[1];
                            resVals.aGWReportsCt.chartTitle1 = loadDonutChartArr[2];
                        }
                    }
                    if(res.data["organizer_data"] !== null && res.data["organizer_data"].length !== 0){                  
                        const resData: any = res.data["organizer_data"];
                        let evenIndex: number = 0;
                        resData.forEach((elem: any,index: number) => {if((index + 2) % 2 === 0)evenIndex++; elem.in_sno = evenIndex })
                        resVals.aGWReportsTab.tabAllData = resData;
                    }
                    if(res.data["nextdraw_data"] !== null && res.data["nextdraw_data"].length !== 0){                  
                        resVals.aGWReportsTab.tabNxtDrawData = res.data["nextdraw_data"];
                    }
                }         
                $("#divLoading").attr("style", "display:none") 

                this.setState(resVals);
                return
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none") 
                return false
            })
        }
        catch(ex) {
          alert("Load Master Data Failed..");
          return
        }
    }
    //load line chart for first part
    loadLineChart = (lineData: any,isEnableOverAll: boolean) => {
        let chartOptions: any = null;
        let chartCat: any = [];
        let series: any = [];
        let chartTitle: any = isEnableOverAll ? "Overall " : "";
        CommonDashboard.getChartColumnTitle(true).forEach((elem: any) => {
            chartTitle += elem.value + " VS "; 
            series.push({name: elem.value,data: lineData.map((elems: any) => elems[elem.key]),type: "line"}); 
        }) 
        chartCat = lineData.map((elems: any) => elems.in_drawdate); 
        chartOptions = { 
            chart: { type: "line",
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
                }             
            },                              
            stroke: {
                show: true,
                curve: 'smooth',
                width: 5
            },
            markers: {
                size: 5
            },
            title: {text: ""},
            xaxis: { categories: chartCat }       
        }
        return [chartOptions, series, chartTitle.substring(0, chartTitle.length - 4)]
    }
     //load donut chart for first part
    loadDonutChart = (DonutData: any,isEnableOverAll: boolean) => {
        let chartOptions: any = null;
        let chartCat: any = [];
        let series: any = [];
        let chartTitle: any = isEnableOverAll ? "Overall " : "";
        CommonDashboard.getChartColumnTitle(chartTitle === "" ? true : false).forEach((elem: any) => {
            chartTitle += elem.value + " VS "; 
            if(!isEnableOverAll){
                series.push(DonutData[0][elem.key]); 
                chartCat.push(elem.value);
            }
        })
        if(isEnableOverAll){
            chartCat = DonutData.map((elems: any) => elems.in_organizername); 
            series = DonutData.map((elems: any) => elems.in_betamount); 
        }
        chartOptions = { 
            chart: {type: "donut",
            toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false
                    }
            }},  
            responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 260
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
            }],
            legend: {
                position: 'bottom'
            },
            plotOptions: {
                pie: {
                  startAngle: 0,
                  endAngle: 360,
                  expandOnClick: true,
                  offsetX: 0,
                  offsetY: 0,
                  customScale: 1,
                  dataLabels: {
                      offset: 0,
                      minAngleToShowLabel: 10
                  }, 
                  donut: {
                    size: '60%',
                    background: 'transparent',
                    labels: {
                      show: true,                      
                      total: {
                        show: true,
                        showAlways: false,
                        label: 'Total',
                        fontSize: '18px',
                        fontFamily: 'Poppins-Medium',
                        fontWeight: 'bold',
                        color: '#373d3f',
                        formatter: function (w: any) {
                          return w.globals.seriesTotals.reduce((a: any, b: any) => {
                            return a + b
                          }, 0)
                        }
                      }
                    }
                  },      
                }
              }, 
            title: {text: ""},
            labels: chartCat 
        } 
        return [chartOptions, series, chartTitle.substring(0, chartTitle.length - 4)]
    }
    // redirect to reports-dashboard
    redirectToReports = (flag: number,organsid: any) => {
        const resVal: any = this.state;
        resVal.pageIndex = flag;
        resVal.organsid = organsid;
        this.setState(resVal);
        return;
    }
    //load organizer box data
    loadOrgTabListData = (tabAllData: any,tabNxtDrawData: any,mainrowIndex: number) => {
        const orgDatas: any = [];
        tabAllData.map((elems: any,index: number) => {
        const nextDrawData: any = tabNxtDrawData.filter((elems1: any) => elems1.in_organsid === elems.in_organsid);
        return orgDatas.push(           
            <div key={"orgDatasSub_" + mainrowIndex + "_" + index} className={index % 2 === 0 ? "DashBetSecLft" : "DashBetSecRgt"} onClick={() => this.redirectToReports(1,elems.in_organsid)}>					
                <div className="DashbetTop">
                    <div className="Dashlogo">
                        <span><img alt="" src={nextDrawData.length > 0 && nextDrawData[0].dl_logoatchfileuri !== "" ? nextDrawData[0].dl_logoatchfileuri : ProImg} /></span>
                        <div>
                            <h3>{elems["in_organizername"]}</h3>
                            <small>{nextDrawData.length > 0 ? nextDrawData[0].in_address : ""}</small>
                        </div>
                    </div>
                    <div className="DashlogoRgt">
                        <span>{nextDrawData.length > 0 ? nextDrawData[0].in_drawtime : ""}</span>
                        <small>{nextDrawData.length > 0 && nextDrawData[0].in_drawdate !== "01-01-1900" ? moment(nextDrawData[0].in_drawdate,'DD-MM-yyyy').format('MMM DD,yyyy') : ""}</small>
                    </div>
                </div>
                <div className="DashbetBtm">
                    <ul>
                        {CommonDashboard.totolOrgDetails().map((elem: any,index1: number) => {
                            return (
                                <li key={"orgData_" + index + "_" + index1}>
                                    <img alt="" className="bg1"  src={elem.imgUrl} />
                                    <small>{elem.value}</small>
                                    <span>{elems[elem.key]}</span>
                                </li> 
                            )
                        })
                        }                                     
                    </ul>
                </div>
            </div>
        );
        })
        return orgDatas
    }
    // change day/month/week filter based set the From/To date
    onhandleToggle = (type: any) => {
        const resVals: any = this.state;
        resVals.aGWReportsCt.options= null;
        resVals.aGWReportsCt.series= null;
        resVals.aGWReportsCt.options1 = null;
        resVals.aGWReportsCt.series1 = null;
        resVals.aGWReportsCt.chartTitle = "";
        resVals.aGWReportsCt.chartTitle1 = "";
        resVals.aGWReportsTab.tabAllData = [];
        resVals.aGWReportsTab.tabNxtDrawData = [];
        resVals.aGWReportsDt.toggleVal = type;
        resVals.aGWReportsDt.drawDate = moment.range(moment().clone().subtract(1, type), moment().clone());
        resVals.aGWReportsDt.FdrawDate.value = moment().subtract(1, type).format('MMM DD,yyyy').toString();
        resVals.aGWReportsDt.TdrawDate.value = moment().format('MMM DD,yyyy').toString();
        resVals.succFlag = undefined;
        resVals.errMsg = "";
        const callJsonHead: any = { 
            FromDate: moment(resVals.aGWReportsDt.FdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
            ToDate: moment(resVals.aGWReportsDt.TdrawDate.value,'MMM DD,yyyy').format('yyyy-MM-DD') + " 00:00:00",
            organizerID: resVals.aGWReportsDrop.OrganizerName.value
        }
        this.loadNameMasterdata(resVals,callJsonHead);
        //this.setState(resVals);
        return;
    }
render() {
    const resVal: any = this.state;

    const DropdownIndicator = (props: any) => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <FontAwesomeIcon style={{fontSize: "20px"}} icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>
            </components.DropdownIndicator>
          )
        );
      };
    const Control = (props: any) => { 
        return (
        <components.Control {...props}>
          <i className="fa fa-user iconStyle" aria-hidden="true"></i> {props.children}
        </components.Control>); 
    };
    const Control1 = (props: any) => { 
        return (
        <components.Control {...props}>
          <i className="fas fa-gamepad iconStyle" aria-hidden="true"></i> {props.children}
        </components.Control>); 
    };
    const styles = {
        menu: (base: any) => ({
            ...base,
            marginTop: 0
        })
    };

    let is_superuser: any = null;
    const userInfoString: any = sessionStorage.getItem('userInfo');
    const userInfo: any = JSON.parse(userInfoString);
    if(userInfo !== null)
        is_superuser = userInfo["is_superuser"];

    const resGrpData: any =  resVal.aGWReportsTab.tabAllData.length !== 0 ?
    _.groupBy(resVal.aGWReportsTab.tabAllData, function(item) {
        return item["in_sno"];
    }) : null;
    let dashJsondata: any = null;
    if(resVal.pageIndex === 1){
        dashJsondata = {            
            FdrawDate: moment(resVal.aGWReportsDt.FdrawDate.value,'MMM DD,yyyy'),
            TdrawDate: moment(resVal.aGWReportsDt.TdrawDate.value,'MMM DD,yyyy'),
            FdrawDateVal: moment(resVal.aGWReportsDt.FdrawDate.value,'MMM DD,yyyy').format('DD-MM-yyyy'),
            TdrawDateVal: moment(resVal.aGWReportsDt.TdrawDate.value,'MMM DD,yyyy').format('DD-MM-yyyy'),
            organizerID: resVal.organsid
        }
    }
    return(
        resVal.pageIndex === 0 ?
        (<div className="ContainerChart scrollbar" style={{height: $(window).height() - 60 + "px"}}>      
        <div className="DashboardCont">
		<div className="DashboardContinr">
			<h3>{t('lblDeshboard')}</h3>
			<div className="DashRgt">
                {is_superuser !== null && is_superuser ? (
				<div className="DashcontInner1">					
                    <Select
                        value={resVal.aGWReportsDrop.OrganizerName.value === null ? null : resVal.aGWReportsDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.aGWReportsDrop.OrganizerName.value.toString())}
                        onChange={(e: any) => {this.handleDropChange(e, 1)}}
                        //isDisabled={this.props.rowData === null ? false : true}
                        placeholder={t('lblSelect')}
                        options={resVal.aGWReportsDrop.OrganizerNames}
                        // maxMenuHeight={110}
                        className="userlogSelect1"
                        components={{ Control: Control, DropdownIndicator: DropdownIndicator }}
                        styles={styles}
                    />
				</div>) : null}
				<div className="DashcontInner1">
					<Select
                        value={resVal.aGWReportsDrop.gameDetId.value === null ? null : resVal.aGWReportsDrop.gameDet.filter((option: any) => option.value.toString() === resVal.aGWReportsDrop.gameDetId.value.toString())}
                        onChange={(e: any) => {this.handleDropChange(e, 2)}}
                        placeholder={t('lblSelect')}
                        options={resVal.aGWReportsDrop.gameDet}
                        // maxMenuHeight={110}
                        className="userlogSelect1"
                        components={{ Control: Control1, DropdownIndicator: DropdownIndicator }}
                        styles={styles}
                    />
				</div>
				<div className="DashcontInner" id="DateRangePicker">
                    <i className="fas fa-calendar-alt" onClick={() => this.setOpenPicker()}></i>
					<label id="DateRange" onClick={() => this.setOpenPicker()}>{resVal.aGWReportsDt.FdrawDate.value} <b>to</b> {resVal.aGWReportsDt.TdrawDate.value}
					</label> 
                    <div key="tabPopupF_0" id="tabPopupFC_0" className="graphPop7 graphPop-1"
                    style={{display: resVal.aGWReportsDt.openPicker ? "block" : "none" }}>
                       {resVal.aGWReportsDt.openPicker && (
                        <DateRangePicker
                            value={resVal.aGWReportsDt.drawDate}
                            onSelect={this.onhandleDateRange}
                            singleDateRange={true}
                        />
                        )}
                    </div>                  
				</div>                
				<div className="DashcontDateCont">
					<label>{t('lblDrawType')}</label>
					<div className="DashcontDate">
						<ul>
							<li onClick={() => this.onhandleToggle("day")}><a href="#section" className={resVal.aGWReportsDt.toggleVal === "day" ? "Active" : ""}>{t('lblDay')}</a></li>
							<li onClick={() => this.onhandleToggle("week")}><a href="#section" className={resVal.aGWReportsDt.toggleVal === "week" ? "Active" : ""}>{t('lblWeek')}</a></li>
							<li onClick={() => this.onhandleToggle("months")}><a href="#section" className={resVal.aGWReportsDt.toggleVal === "months" ? "Active" : ""}>{t('lblMonth')}</a></li>
						</ul>
					</div>
				</div>
			</div>
			</div>
			{resVal.aGWReportsTab.tabAllData.length > 0 ? 
            (<div className="DashChartCont fadeInUp delay-250 fast go">
				<div className="ChartLft">
						<h4>{resVal.aGWReportsCt.chartTitle}</h4>
					<div className="ChartLftBtm">
                        <Chart
                        options={resVal.aGWReportsCt.options}
                        series={resVal.aGWReportsCt.series}
                        height="250"
                        />
					</div>
				</div>
				<div className="ChartRgt">
					<h4 style={{fontSize: resVal.aGWReportsCt.chartTitle1.includes("Overall") ? "16px" : "12px"}} >{resVal.aGWReportsCt.chartTitle1}</h4>
					<div className="ChartLftBtm">
                        <Chart
                        options={resVal.aGWReportsCt.options1}
                        series={resVal.aGWReportsCt.series1}
                        type={"donut"}
                        height="280"
                        />
					</div>
				</div>
			</div>) : null}
            {resVal.aGWReportsTab.tabAllData.length > 0 && resGrpData !== undefined && resGrpData !== null ? 
            (<div className="DashBetSecCont">
                {Object.values(resGrpData).map((elems: any,index: number) => {
                return <div key={"orgDatasMain_" + index} className="DashBetSec">
                {index === 0 ? (<h3 style={{paddingBottom: "5px"}}>{t('lblActiveOrg')}</h3>) : null}
                {this.loadOrgTabListData(elems,resVal.aGWReportsTab.tabNxtDrawData,index)}
                </div>})}
            </div>) : null}
		</div>
		</div>) : <AGWReports rootFlag={15} dashJsondata={dashJsondata} backClick={this.redirectToReports.bind(this)} />
    );
}
}

/* export default  withTranslation()(Dashboard); */
export default  Dashboard;