const getTableTitle =(header: string) => {
    switch(header){
        case "in_gamedesc":
            return "Game Type"
        case "in_gameid":
            return "Game Id"
        case "in_drawdate":
            return "Draw Date"
        case "in_drawtime":
            return "Draw Time" 
        case "in_gamename": 
            return "Game Name"
        case "in_startdate":
            return "Start Date" 
        case "in_enddate": 
            return "End Date"
        case "in_starttime": 
            return "Start Time"
        case "in_endtime": 
            return "End Time"
        case "in_approvestatusdesc":
            return "Approval Status"
        case "in_saledesc":
            return "Sale Status"
        case "in_username":
            return "Created by"
        case "in_organizername":
            return "Org. Name"
        case "in_mrpvalue":
            return "MRP"
        default:
            return header
    }
}
const getKeyByValue = (objRes: any, searchInput: string) =>{
    var propertyArr: any = ['in_gamedesc','in_gameid','in_drawdate','in_drawtime',
    'in_gamename','in_startdate','in_enddate','in_starttime','in_endtime','in_saledesc','in_approvestatusdesc',
    'in_username','in_organizername','in_mrpvalue'] //table header's filter allow columns
    for(var prop of propertyArr) {
        if (objRes.hasOwnProperty(prop)) {
            if(objRes[prop].toString().toLowerCase().includes(searchInput.toLowerCase()))
                return true;
        }
    }
}
const CommonGame = { getTableTitle: getTableTitle,getKeyByValue: getKeyByValue };

export default CommonGame;