//Export
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import exportFromJSON from 'export-from-json' 

//use for show the report header's
const getPageTitle =(rootFlag: number) => {
    switch(rootFlag){
        case 1:
            return "Purchase Input"
        case 2:
            return "Unsold Settlement" 
        case 3:
            return "Winners Input" 
        case 4:
            return "Ticket Claim"
        case 5:
            return "View Purchase" 
        case 6:
            return "Delete Purchase"
        default:
            return ""
    }
}
const getTableTitle =(header: string) => {
    switch(header){
        case "in_organizernmae":
        case "in_organizername":
            return "Org. Name"
        case "in_ticketno":
        case "tickenumber":
            return "Ticket No."
        case "in_mrp":
            return "MRP."
        case "in_gameid":
            return "Game Id"
        case "in_gamename":
            return "Game Name"
        case "in_drawdate":
            return "Draw Date"
        case "in_status":
            return "Status"
        case "in_sno":
            return "#"
        case "winamount":
            return "Winning No."
        case "claimamount":
            return "Claim Amount"
        case "in_salecount":
            return "Sale Count"
        case "dl_unsoldcount":
            return "Unsold Count"
        case "dl_winacount":
            return "Win Account"
        case "dl_claimcount":
            return "Claim Count"
        case "dl_blockcount":
            return "Block Count"
        case "in_transactionid": 
            return "TXN ID"
        default:
            return header
    }
}
// Table based- Filter Data (Search option)
const getHeaderKeyArr = (rootFlag: number,isHyperLink?: boolean) => {
    if(rootFlag === 5){
        return ['in_organizernmae','in_transactionid','in_ticketno','in_mrp','in_gameid','in_gamename','in_drawdate','in_status'] 
    }
    else if(rootFlag === 6){
        return ['in_organizername','in_gameid','in_gamename','in_drawdate','in_salecount','dl_unsoldcount',
        'dl_winacount','dl_claimcount','dl_blockcount'] 
    }
    else
        return ["tickenumber","winamount"]
}
// Table based- Filter Data (Search option)
const getKeyByValue = (objRes: any, searchInput: string,rootFlag: number,isHyperLink?: boolean) =>{
    for(var prop of getHeaderKeyArr(rootFlag,isHyperLink)) {
        if (objRes.hasOwnProperty(prop)) {
            if(objRes[prop].toString().toLowerCase().includes(searchInput.toLowerCase()))
                return true;
        }
    }
}
//use for PDF-1 and Print Preview (Header)
const getHeaderArr = (rootFlag: number,isHyperLink?: boolean) => {
    if(rootFlag === 5){
        return ["#","Org. Name","TXN ID","Ticket No.","MRP.","Game Id","Game Name","Draw Date","Status"]
    }
    else if(rootFlag === 6){
        return ["#","Org. Name","Game Id","Game Name","Draw Date","Sale Count", "Unsold Count", "Win Account",
        "Claim Account","Block Count"]
    }
    else
        return []
}
//use for PDF-2 (Field Datas)
const getTableData = (rootFlag: number,tabData: any,isHyperLink?: boolean) => {
    if(rootFlag === 5){
        return tabData.map((elt: any)=> {
            return [elt.in_sno === undefined ? "" : elt.in_sno,
            elt.in_organizernmae,elt.in_transactionid,elt.in_ticketno,elt.in_mrp,elt.in_gameid,elt.in_gamename,elt.in_drawdate,elt.in_status]
        });
    }
    else if(rootFlag === 6){
        return tabData.map((elt: any)=> {
            return [elt.in_sno === undefined ? "" : elt.in_sno,
            elt.in_organizername, elt.in_gameid,elt.in_gamename,elt.in_drawdate,elt.in_salecount,
            elt.dl_unsoldcount,elt.dl_winacount,elt.dl_claimcount,elt.dl_blockcount]
        });
    }
    else
        return tabData
}
/* Export PDF */
const exportPDF= (tabData: any,pageTitle: string,fileName: string,rootFlag: number,isHyperLink?: boolean) => {
    try{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = pageTitle;
        const headers = [getHeaderArr(rootFlag,isHyperLink)];    
        const data = getTableData(rootFlag,tabData,isHyperLink); //tabData.map((elt: any)=> [elt.name, elt.profession]);
    
        let content = {
          startY: 50,
          head: headers,
          body: data,
          didParseCell: function (table: any) {
            if (table.section === 'head') {
              table.cell.styles.fillColor = [73,80,87];
              table.cell.styles.textColor = 255;
              table.cell.styles.fontStyle = "bold";
              table.cell.styles.fontSize = 10;
            //   table.cell.styles.lineColor = 169;
            //   table.cell.styles.lineWidth = 1;
            }
            if(table.section === 'body') {
                if(table.row.cells[1].text[0] === "Sub Total"){
                    table.cell.styles.fillColor = 200;
                    table.cell.styles.textColor = 51;
                    table.cell.styles.fontStyle = "bold";
                    table.cell.styles.fontSize = 8;
                }
                else if(table.row.cells[1].text[0] === "Grand Total"){
                    table.cell.styles.fillColor = 136;
                    table.cell.styles.textColor = 255;
                    table.cell.styles.fontStyle = "bold";
                    table.cell.styles.fontSize = 10;
                }
                else{
                    table.cell.styles.fillColor = 245;
                    table.cell.styles.textColor = 51;
                    table.cell.styles.fontStyle = "normal";
                    table.cell.styles.fontSize = 8;
                }
                // table.cell.styles.lineColor = 204;
                // table.cell.styles.lineWidth = 1;
            }
         }
        };

        doc.text(title, marginLeft, 40);
        autoTable(doc, content)
        doc.save(fileName + ".pdf")
        }
        catch(ex) {
        alert("Export PDF Failed..");
        return
        }
}
/* Export Excel XLS/CSV */
const exportExcel = (Orgdata: any,fileName: any,fileType: any,rootFlag: number,isHyperLink?: boolean) =>{
    exportFromJSON({ data: Orgdata, fileName: fileName, exportType: fileType,
        beforeTableEncode: (tableRow: any) => {
            var element = tableRow[tableRow.length - 1];
            tableRow.splice(tableRow.length - 1, 1);
            tableRow.splice(0, 0, element);
            for(let indx = 0; indx < tableRow.length; indx++){
                tableRow[indx].fieldName = getTableTitle(tableRow[indx].fieldName);
            }
            return tableRow;
        }
    })
}
/* Export Print preview */
const exportPrint = (Orgdata: any,pageTitle: any,rootFlag: number,isHyperLink?: boolean) => {
    try{
        var mywindow: any = window.open('', 'MARES', 'height=400,width=600');
        mywindow.document.write('<html><head><title>MARES</title><style>table, th, td{border: 1px solid #ccc !important;border-collapse: collapse;}.header{font-weight: bold;}.header1{font-weight: bold;text-align: center;}</style>');
       // mywindow.document.write('<link rel="stylesheet" href=' + dataleadcss + ' type="text/css" />');
        mywindow.document.write('</head><body><div class="header1">' + pageTitle + '</div><br /><table>');
        let tagData: any = "";
        tagData += "<tr>"  
        getHeaderArr(rootFlag,isHyperLink).forEach((key: any) => {
            tagData += ("<th>" + key +"</th>")
        }) 
        tagData += "</tr>"
        Orgdata.map(function(obj: any) {
            tagData += "<tr>"                 
            getHeaderKeyArr(rootFlag,isHyperLink).forEach((key: any,index: number) => {
                if(index === 0)
                    tagData += ("<td>" + (obj.in_sno === undefined ? "" : obj.in_sno) +"</td>");   
                tagData += ("<td>" + (obj[key] === undefined ? "" : obj[key])  +"</td>")
            }) 
            tagData += "</tr>"
            return obj;              
        });         
        mywindow.document.write(tagData + '</table></body></html>');
        mywindow.print();
        mywindow.close();
        return
    }
    catch(ex) {
      alert("Export Print Failed..");
      return
    }
}
const CommonPapersLotto = { exportPDF: exportPDF,exportExcel: exportExcel,exportPrint: exportPrint, getPageTitle: getPageTitle,getTableTitle: getTableTitle,getKeyByValue: getKeyByValue };

export default CommonPapersLotto;