import React from "react";
import moment from "moment"
import apiHelper from "../../../utils/ApiHelper"
import useAxios from "axios";
import config from "../../../utils/config";
import { t } from 'i18next';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js'
import { Bar} from 'react-chartjs-2'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
)
var $ = require('jquery')
  export interface IProps {
    // pupUpOpenClick: any,
    // flog: number //to show date or hour based report
    startdate:any,
    enddate:any,
    gameID:any,
    organizername:any
 }
 export interface IState {
    //createOrganizer: any
     chartData: any,
     myTitle:any
 }
////5th line chart js
const options4: any = {
    plugins: {
        title: {
            display: true,
        },
    },
    interaction: {
        mode: 'nearest',
        intersect: false,
        axis: 'x'
        },
    responsive: true,
    scales: {
        x: {
            stacked: true,
            title: {
                display: true,
                text: 'Sale Date'
            }
        },
        y: {
            stacked: true,
             title: {
                 display: true,
                 text: 'Sale Amount'
             }
        },
    },
};
const gidata: any = {
    labels: ['21 Oct', '22 Oct', '23 Oct', '24 Oct', '25 Oct', '26 Oct','27 Oct'],
    datasets: [
        {
            label: 'WEB',
            data: [60, 20, 30, 40, 20, 50, 40],
            backgroundColor: 'rgb(75, 92, 192)',
        },
        {
            label: 'USSD',
            data: [60, 30, 40.90, 30, 80, 90, 70],
            backgroundColor: 'rgb(75, 192, 192)',
        },
        {
            label: 'POS',
            data: [20, 40, 60.90, 30, 46, 78, 40],
            backgroundColor: 'rgb(255, 99, 132)',
        },
     ],
};
class ChannelWiseChart extends React.Component<IProps,IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            chartData: { labels: [], datasets: [] } , 
            myTitle: "Provider Wise Sales By Day"
        };
        options4.onClick = this.handleClick;
    }
       initData = () => {
            let Gameid = typeof (this.props.gameID) === 'string' ? (this.props.gameID).split('_')[0] : 0;
            const callJsonHead: any = {
            organizerID: this.props.organizername,
            FromDate:moment(this.props.startdate).format('YYYY-MM-DD') + " 00:00:00",
            ToDate: moment(this.props.enddate).format('YYYY-MM-DD') + " 00:00:00",
            GameId: Gameid,
            Status:0   
        }
        //MANI 22/12
        //useAxios.get(config.Url("Channel-Wise-Sales", "get"), config.authHeaderwithParamRpt(callJsonHead))
        apiHelper.getAPICall("Channel-Wise-Sales", "get",5,callJsonHead)
            .then((res)=> {
                console.log("RESPONSE RECEIVED: Channel By Hour", res);
              if (res !== null && res.data !== null && res.data.result === undefined) {
                    let xAxis: any = [];
                    let ydatasets: any = [];
                    let tempArr: any = [];
                    let tempArr1: any = [];
                    let tempArr2: any = [];
                    let yname: number = 0;
                    let colDataArr: any = res.data["get_rptDetails"]; 
                    xAxis =  colDataArr.map((item:any) =>item.in_drawdate).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    const yAxis =  colDataArr.map((item:any) => item.in_channelname).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    yAxis.forEach(( fobj: any) => {
                        xAxis.forEach((fdate: any) => {
                            const objLst = colDataArr.filter((item: any) => { return item.in_channelname == fobj && item.in_drawdate == fdate });
                            if (objLst.length > 0)
                              {
                                  tempArr.push(objLst[0].in_betamount);
                               }
                        else{
                            tempArr.push(0);
                              }
                        });
                        var color = Math.floor(Math.random() * 16777216).toString(16);
                        var colour1= '#000000'.slice(0, -color.length) + color;
                        ydatasets.push(
                            {
                                label:fobj,//yAxis[yname],
                                fill: false,
                                backgroundColor:colour1,
                                borderColor:colour1,
                                data: tempArr,
                                tension: 0.2,
                                pointStyle: 'circle',
                                pointRadius: 4,
                            },
                            )
                            yname++;
                        tempArr = [];
                     }
                    )
                    this.setState({ chartData: { labels:xAxis, datasets: ydatasets } });
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                return  { labels:[], datasets:[] } ;
            })
    }
    componentDidMount(): void {
        this.initData();
        return;
        $("#myChart").click(function (this: any, e: any, i: any) {
            alert("er");
            // var firstPoint = this.getElementAtEvent(e)[0];
            //let inx: any = i[0].index;
            //let ds = dataaaa2.labels[inx];
            //alert(ds);
            //   var activePoints = myNewChart.getSegmentsAtEvent(evt);           
            //     /* do something */
        }
        );
    }
componentDidUpdate(prevProps:any) {
        if (prevProps.startdate !== this.props.startdate) {
            this.initData()
        }
      }
   handleClick = (e: any, i: any) => {
        debugger;
        let inx: any = i[0].index;
         let ds =  this.state.chartData.labels[inx];
         var obj = {
             Id: ds,
             fromDate: "",
             toDate: ""
         }
       //this.props.pupUpOpenClick(obj);
    }
 render(): React.ReactNode {
   return(
        <div>
          <h4>{t('lblChannelSales')}</h4>                             
          {/* <Bar options={options4} data={gidata} /> */}
          <Bar options={options4} data={this.state.chartData} />
          </div> 
    )
}
};
export default ChannelWiseChart;