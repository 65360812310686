import moment from "moment";
function CAuditmodel() {
    return  { 
        errMsg: "",
        succFlag: undefined,  
        rptGenDate: "",
        fileAttach: {name: "",fileResult: null,display: false},
        aGAuditsDt: {
                FdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false}          
                // TdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false}          
        },
        aGAuditsText: {
            selTxnValue: {name: "",display: false}   
        },
        aGAuditsDrop: {  //only dropdown values
            gameDet: [],
            gameDetId: {value: null,display: false},
            gameType: [],
            gameTypeId: {value: null,display: false}, 
            OrganizerNames: [],
            OrganizerName: {value: null,display: false} 
        },
        aGAuditsTab: {
            rowData: null,
            inputChanged: {},
            tabData: [],
            tabDataSub: [],
            tabDbData: [],
            colData: [],
            colDataSub: [],
            filteredData: [],
            searchInput: ""
        }
    } 
};

export default CAuditmodel;