import React from 'react';

import ListTable from "./listtable";
import CommonAudit from "../AuditTrail/Child/c_auditcommon";
var $ = require('jquery')

export default class ListSubTable extends React.Component {
    constructor(props) {
      super(props); 
      this.state = {
        filteredData: [],
        searchInput: []
      };
      this.globalSearch = this.globalSearch.bind(this);
    }
    subTabStateReset= () => {
        this.setState({filteredData: [],searchInput: []})
        return;
    }  
    // And in your global search
    globalSearch = (e,index) => {
    try{
        let initialState = this.state;
        $('.showPlusMinus1').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');  
        $('.graphPop6').css({display: "none"})   
        const searchInput = e.target.value;
        const skipGT = this.props.tabData[index];
        let filteredData = skipGT.filter((value) => {
        return CommonAudit.getKeyByValue(value, searchInput,this.props.rootFlag,true);
        });
        initialState.filteredData[index] = filteredData;
        initialState.searchInput[index] = searchInput;
        this.setState(initialState);
        return
    }
    catch(ex) {
        alert("Load Search filter Sub List Load Failed..");
        return
    }        
    };
    closePopup =() => {
        $('.overlay').fadeOut();
        $('#lightBox7').fadeOut();
    }
    render() {
      return(
        <div id="lightBox7" className="lightBox7" style={{marginLeft: '-'+ ($('#lightBox7').width() / 2) +'px',marginTop: '0px'}}>
        <div className="userLogpreview">
            <div className="PreviewHead">
                <h2>{CommonAudit.getPageTitle(this.props.rootFlag)}</h2>
                <a href="#section" className="CancelBtn" onClick={this.closePopup}><i className="fas fa-times"></i></a>
            </div>                
            <div className="ContainerChart1 scrollbar">
            <div className="contentclouds">
            {this.props.tabData !== undefined && this.props.tabData.length > 0 ? 
            this.props.tabData.map((elem,index) => {      
            return (<div className="UseformNew" key={"multiTable_" + index}>          
            <div className="ContainerTable" style={{padding: "10px 10px 0 10px"}}>
                <div className="tableSearchreportpop">
                        <div className="sportSrch">
                            <h3>{"Sheet " + (index + 1)}</h3>
                            <input type="text" placeholder="Search here..!" className="searchInput" style={{float: "right"}} value={this.state.searchInput[index] === undefined || this.state.searchInput[index] === null ? "" : this.state.searchInput[index]} onChange={(e) => this.globalSearch(e,index)} autoComplete="off" />
                        </div>
                </div>
            </div>
            <div className="TableReact">            
                 <ListTable
                    sorting={this.props.sorting}
                    columns={this.props.colData[index]}
                    key={"mutiTabless_" + index}
                    pageSize={elem !== undefined && elem.length > 50 ? 50 : elem.length > 25 ? 25 : 10}
                    data={this.state.filteredData[index] && (this.state.searchInput[index] === undefined || this.state.searchInput[index] === null ? "" : this.state.searchInput[index]) !== "" ? this.state.filteredData[index] : elem}           
                />
            </div>
            </div>)
            }) : null}  
            </div>   			
            </div>
        </div>
    </div>);
  }
}