import React from 'react'; 
import useAxios from "axios"; 
import COrganizermodel from "./Child/c_organizemodel";
import Lightbox from "../Shared/lightbox";
import config from "../../utils/config"
import { withTranslation  } from 'react-i18next';
import apiHelper from '../../utils/ApiHelper';
 var $ = require('jquery');
export interface IState {
    createOrganizer: any, 
}  
export interface IProps {
    rowData: any,
    rootFlag: number,
    backClick: any,
    t:any 
}   
class CPayment extends React.Component<IProps,IState>  {
    constructor(props: IProps) {
        super(props);  
        this.state = {
          createOrganizer: COrganizermodel()
          };
        this.onhandleTxtChange = this.onhandleTxtChange.bind(this);
        this.onSubmitData = this.onSubmitData.bind(this);
        //this.removeLogoUpload = this.removeLogoUpload.bind(this);//
      }
      componentDidMount(){
        $('#myfileExp').val('');
        $('#myfileExp1').val('');
        const rowData: any = this.props.rowData;
        if(rowData !== null){
            this.updateFields(rowData);
        }
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):
        const rowData: any = this.props.rowData;  
        if (this.props.rootFlag !== prevProps.rootFlag && rowData !== null) {
            //this.loadTypeMasterdata();
            if(rowData !== null){
                $('#myfileExp').val('');
                $('#myfileExp1').val('');
                this.updateFields(rowData); 
            } 
        }
    }
        updateFields= (rowData: any) =>{
        try{
        const resVal: any = this.state.createOrganizer;         
        resVal.organizerId = rowData.dl_organizerid;
        resVal.cOrganizerMand.organzierName.name = rowData.dl_organizername;
        resVal.cOrganizerMand.commission.name = rowData.dl_commission;
          this.setState({createOrganizer: resVal});
        return }
        catch(ex) {
        alert("Field Updation Failed..");
        return
        }       
    }
     onhandleTxtChange= (e: any,fieldFlag: number) => {
        try{
        let resVal: any = this.state.createOrganizer;
        const inputVal: any = e.target.value;
        if(fieldFlag === 0){
            resVal.cOrganizerMand.organzierName.name = inputVal;
            resVal.cOrganizerMand.organzierName.display = inputVal.trim() === "" ? true : false;
        }
          if(inputVal === "" || parseFloat(inputVal) <= 100){
          if(fieldFlag === 1){
            resVal.cOrganizerMand.commission.name = inputVal;
            resVal.cOrganizerMand.commission.display = inputVal.trim() === "" ? true : false;
             }
        }
        else{
            if(fieldFlag === 1){
                resVal.cOrganizerMand.commission.display = true;
                resVal.cOrganizerMand.commission.errDisp = "Sales Commission should not be greater than 100.00";           
            }
        }
        resVal.errMsg = "";
          resVal.succFlag = undefined;
        if(resVal.errMsg !== ""){
            $("#divLoading").attr("style", "display:none") 
            this.setState({createOrganizer: resVal});
            $('.overlay').fadeIn();
            $('#lightBox2').fadeIn(); 
            return 
        }else{
            this.setState({createOrganizer: resVal})
            return
        }
    }
    catch(ex) {
        alert("On handle text change Failed..");
        return
          }
      }
handleKeypress = (evt: any,type: string) => {
    if (type === "integer" && evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
    //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
        evt.preventDefault();
    }
    else if (evt.which !== 46 && evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
        //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
            evt.preventDefault();
    }
}
maxLengthCheck = (object: any) => {
     if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
}

onSubmitData = (e: any,Type: string) => {
    try{
    e.preventDefault();
    let resVal: any = this.state.createOrganizer;
    let isErrData: boolean = false;
    $("#divLoading").attr("style", "display:block")
        resVal.succFlag = undefined;
       //validation part-Start
        Object.entries(resVal.cOrganizerMand).forEach(([key, value],index: number) => {
        let val = resVal.cOrganizerMand[key].name;
          if(key !== 'organzierName' && val === ''){{
            resVal.cOrganizerMand[key].display = true
             isErrData = true
        }}
         if(key !== 'commission' && val === ''){{
            resVal.cOrganizerMand[key].display = true
            resVal.cOrganizerMand.commission.errDisp = "Sales Commission has to be provided";           
             isErrData = true
        }}
           })
     resVal.succFlag = undefined;
    //validation part-End
       if(isErrData){
        $("#divLoading").attr("style", "display:none") 
        //resVal.errMsg = "Invalid data..!"
        this.setState({createOrganizer: resVal});
        // $('.overlay').fadeIn();
        // $('#lightBox2').fadeIn(); 
        return 
    }
    else{
        if(Type === "C"){
                 const userInfoString: any = sessionStorage.getItem('userInfo');
                 const userInfo: any = JSON.parse(userInfoString);
                 const jsonData: any = {
                "pd_desc": resVal.cOrganizerMand.organzierName.name,
                "image_text": "Image",
                "commission": resVal.cOrganizerMand.commission.name, 
                 }
                //ykarthik 22.12               
                //useAxios.post(config.Url("push-payment","post"), jsonData, config.authHeader())
                apiHelper.postAPICall("push-payment","post",2,jsonData)
               .then ( (res) => {
                console.log("RESPONSE RECEIVED payment : ", res);
                console.log(res);
                $("#divLoading").attr("style", "display:none")
                if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
                $("#divLoading").attr("style", "display:none") 
                resVal.errMsg = res.data.result.toString().replace('successfully','');
                resVal.succFlag = undefined;
                resVal.cOrganizerMand= COrganizermodel().cOrganizerMand;
                this.setState({createOrganizer: resVal});
                  }
                else{
                resVal = COrganizermodel()
                resVal.errMsg = res.data.result.toString().replace('successfully','');
                resVal.succFlag = 0;
                this.setState({createOrganizer: resVal});
                }
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn();
                return
            })
                .catch((err:any) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none")
                if(err !== undefined && err.response !== undefined && err.response.statusText !== undefined && err.response.statusText === 'Unprocessable Entity'){
                resVal.errMsg = "Invalid data..!"
                this.setState({createOrganizer: resVal});
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                } 
                return
            })
             } 
           else{
            this.props.backClick(this.state.createOrganizer);
            return 
         }
      } 
      }
    catch(ex) {
    alert("Data Inserion/ Updation Failed..");
    return
  }
}
render(): React.ReactNode {
        const resVal: any = this.state.createOrganizer; 
        const { t } = this.props;
        return (
            <div className="ContainerChart scrollbar">
                  <Lightbox errMsg= {resVal.errMsg} succFlag= {resVal.succFlag} />            
                  <div className="contentclouds">
                    <div className="userLogdata1">
                        <div className="userLogHead">
                            <div className="userLogHeadinner">
                                <h2>{t('lblCreatePayment')}</h2>
                                <small>{t('lblPaymentDetails')}</small>
                                </div>
                            </div>
                         <div className="userLogform scrollbar">
                            <div className="userLogformLft">
                                <ul>
                                    <li>
                                    <div className="userLogCloud2">
                                       <div>
                                            <label>{t('lblOrganizerName')}*</label>
                                            <input className="newUserTxt" value={resVal.cOrganizerMand.organzierName.name} placeholder={t('lblEntername')} autoComplete="off" type="text" maxLength={100}  onChange={(e: any) => this.onhandleTxtChange(e,0)}/>
                                            <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cOrganizerMand.organzierName.display ? "block" : "none"}}>Organizer Name has to be provided</label>
                                            </div>
                                        </div>
                                        {/* <div className="userLogCloud2">
                                            <div>
                                                <label>{t('lblImgUpload')}</label>
                                                <input className="newUserTxt"   placeholder="Upload Image"  style={{ color: "transparent" }}  id="myfileExp" name="myfileExp"  />
                                            </div>
                                        </div> */}
                                        <div className="userLogCloud2">
                                            <div>
                                                <label>{t('lblCommission')}</label>
                                                  {/* <input className="newUserTxt" value={resVal.cOrganizerMand.commission.name} placeholder="Enter Commission"  onChange={(e: any) => this.onhandleTxtChange(e,1)}/>    */}
                                                  <input className="newUserTxt" id="colValL1" min="0" onKeyPress= {(e:any) => this.handleKeypress(e,"decimal")} value={resVal.cOrganizerMand.commission.name} onChange={(e: any) => this.onhandleTxtChange(e,1)} placeholder={t('lblEnterComm')} autoComplete="off" type="number" maxLength={5} onInput={this.maxLengthCheck} />
                                                  <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cOrganizerMand.commission.display ? "block" : "none"}}>{resVal.cOrganizerMand.commission.errDisp}</label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="userLogBtnCont3">
                              {/* <button className="GreyBg" id="loginBtn">Reset</button>  */}
                             <button className="GreyBg" id="loginBtn" onClick={() => {
                                this.setState({createOrganizer: COrganizermodel()})
                                if(this.props.rowData !== null)
                                this.props.backClick(null);
                                }}><i className={"fa fa-" + (this.props.rowData === null ? "sync" : "times")} style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t("lblReset") : "Cancel"}</button>	 
                            <button className="GreenBg" id="registerBtn" onClick={(e: any) => this.onSubmitData(e,this.props.rowData === null ? "C" : "E")  }><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t("lblSubmit"): "OK"}</button>		
                          </div>   
                      </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(CPayment );
