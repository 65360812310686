import React from 'react';
import useAxios from "axios";
import apiHelper from "../../utils/ApiHelper"
import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import Breadcrumb from "../Shared/breadcrumb";
import CAgentHmodel from "./Child/c_agentHmodel";
import CommonAgentH from './Child/c_agentHcommon';
import ListSubTable from "../Shared/listsubtableAgent";
import Tablistview from "../Shared/tablistview"

import excel from 'xlsx';
//responsive dropdown
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown,faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { withTranslation  } from 'react-i18next';

var $ = require('jquery')
library.add(faCaretDown);
library.add(faCaretUp);

export interface IProps {
    rowData: any,
    rootFlag: number,
    backClick: any,
    t:any
}
export interface IState {
    errMsg: string,
    succFlag: any,
    cAgentHMand: any,
    cAgentHOpt: any,
    cAgentHDrop: any,
    cAgentHRadio: number,
    aAgentH: any
}
  class CAgentH extends React.Component<IProps, IState> {
  // ------------------------------------------^
    private subTabStateReset: any = React.createRef;
    constructor(props: IProps) {
      super(props); 
      this.subTabStateReset = React.createRef(); 
      this.state = CAgentHmodel();
      this.onhandleTxtChange = this.onhandleTxtChange.bind(this);
      this.onSubmitData = this.onSubmitData.bind(this);
      this.handleToggleClick = this.handleToggleClick.bind(this);
      this.onViewTableData = this.onViewTableData.bind(this);
      this.onhandleFileChange = this.onhandleFileChange.bind(this);
      this.handleDropChange = this.handleDropChange.bind(this);
      this.removeAadharUpload = this.removeAadharUpload.bind(this);
    }
    componentDidMount(){
        $('#myfileExp').val('');
        $('#myfileExp1').val('');
        const rowData: any = this.props.rowData;
        let resVals: any = this.state;
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        const callJsonHead: any = { 
            organizerID: 0,
            flag_id: 1
        }
        if(is_superuser !== null && is_superuser){
            const _this: any = this;
            _this.loadOrgNameMasterdata(resVals).then((res: any) => {
                _this.loadNameMasterdata(res,callJsonHead,"load_state","CState",2).then((res1: any) => {
                    if(rowData !== null){                       
                        _this.updateFields(res1,rowData);
                        return
                    }
                    else{
                        _this.setState(res1);
                        return
                    }     
                })
            })
        }
        else{ 
            const _this: any = this;
            _this.loadNameMasterdata(resVals,callJsonHead,"load_state","CState",2).then((res: any) => {            
                if(rowData !== null){
                    _this.updateFields(res,rowData);
                    return
                }
                else{
                    if(_this.props.rootFlag === 3 || _this.props.rootFlag === 5){
                        const callJsonHead: any = { 
                            organizerID: userInfo["userId"],
                            flag_id: 1
                        }
                        _this.loadNameMasterdata(res,callJsonHead,"load_superstockist","CStockist",0).then((res1: any) => {
                            _this.setState(res1);
                            return 
                        })
                    }
                    else{
                        _this.setState(res);
                        return
                    }
                }                
            })          
        }        
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):        
        if (this.props.rootFlag !== prevProps.rootFlag) {
            this.resetValues();
        }
    }
    resetValues= (flag?: number) => {
        const rowData: any = this.props.rowData;  
        $('#myfileExp').val('');
        $('#myfileExp1').val('');
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        const resVals: any = this.state;
        const initialState: any = CAgentHmodel();
        initialState.cAgentHDrop.OrganizerNames = resVals.cAgentHDrop.OrganizerNames;
        initialState.cAgentHDrop.stateType = resVals.cAgentHDrop.stateType;
        if(rowData !== null){
            if(flag === 1){
                this.setState(initialState, () => {
                    this.props.backClick(null);
                    return
                });
            }
            else{
                this.updateFields(initialState,rowData);
                return
            }             
        }
        else{
            const _this: any = this;
            if(!userInfo["is_superuser"] && (_this.props.rootFlag === 3 || _this.props.rootFlag === 5)){
                const callJsonHead: any = { 
                    organizerID: userInfo["userId"],
                    flag_id: 1
                }
                _this.loadNameMasterdata(initialState,callJsonHead,"load_superstockist","CStockist",0).then((res1: any) => {
                    _this.setState(res1);
                    return 
                })
            }
            else{
                _this.setState(initialState);
                return
            }
        } 
    }
    loadOrgNameMasterdata= (resVals: any) => {
        try{
        $("#divLoading").attr("style", "display:block")
        this.setState(CAgentHmodel());
        return new Promise((resolve, reject) => {
            //mani 22/12
            //useAxios.get(config.Url("cmo_game","get"), config.authHeaderwithParam("OrgName~@#%&1"))
            apiHelper.getAPICall("cmo_game", "get",4,"OrgName~@#%&1")
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let OrganizerNamesArr: any = [];
                if(res.data !== null && res.data.length !== 0){
                    if(res.data["organizer_details"] !== undefined && res.data["organizer_details"] !== null && res.data["organizer_details"].length !== 0){                  
                    for(var prop of res.data["organizer_details"]){
                        OrganizerNamesArr.push({value: prop["dl_organizerid"],label: prop["dl_organizername"],userId: prop["dl_userid"]})
                    }
                }
                }         
                $("#divLoading").attr("style", "display:none") 
                //$("#templaTBlid").attr("style", "display:none") 
                resVals.cAgentHDrop.OrganizerNames = OrganizerNamesArr;
                resolve(resVals)
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none") 
                reject(err)
            })
        }); 
        }
        catch(ex) {
        alert("Master Data Load Failed..");
        return
        }
    }
    /* load game names */
    loadNameMasterdata = (initialState: any,callJsonHead: any,getDtlPoint: string,methodName: string,flag: number) => {
        try{
            $("#divLoading").attr("style", "display:block")
            return new Promise((resolve, reject) => {
                //mani 22/12
               // useAxios.get(config.Url(methodName,"get"), config.authHeaderwithParamRpt(callJsonHead))
               apiHelper.getAPICall(methodName, "get",5,callJsonHead)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    let fieldNamesArr: any = [];
                    if(res.data !== null && res.data.length !== 0){
                        if(res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0){                  
                        for(var prop of res.data[getDtlPoint]){
                            if(flag === 0)
                                fieldNamesArr.push({value: prop["in_sstockistid"] + "_" + prop["in_organizerid"],label: prop["in_sstockistname"],orgId: prop["in_organizerid"]})
                            else if(flag === 1)
                                fieldNamesArr.push({value: prop["in_stockistid"] + "_" + prop["in_organizerid"],label: prop["in_stockistname"],orgId: prop["in_organizerid"]})
                            else if(flag === 2)
                                fieldNamesArr.push({value: prop["in_stateid"],label: prop["in_statename"],orgId: prop["in_organizerid"]})
                        }
                    }
                    }         
                    $("#divLoading").attr("style", "display:none")
                    fieldNamesArr = fieldNamesArr.length === 1 && fieldNamesArr[0].value.split('_')[0].toString() === "0" ? [] : fieldNamesArr;
                    if(flag === 0)
                        initialState.cAgentHDrop.stockistType = fieldNamesArr;
                    else if(flag === 1)
                        initialState.cAgentHDrop.sstockistType = fieldNamesArr;
                    else if(flag === 2)
                        initialState.cAgentHDrop.stateType = fieldNamesArr;                   
                    //this.setState(initialState);
                    resolve(initialState);
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none") 
                    reject(null)
                }) 
            });
        }
        catch(ex) {
            alert("Load Master Data Failed..");
            return
        }
    }
    updateFields= (resVal: any,rowData: any) =>{
        try{       
            resVal.cAgentHDrop.OrganizerName.value = rowData.dl_organizerid;
            resVal.cAgentHDrop.stateTypeId.value = rowData.in_stateid;
            //if(this.props.rootFlag === 3 || this.props.rootFlag === 5 || this.props.rootFlag === 7){
                resVal.cAgentHDrop.stockistTypeId.value = rowData.in_ssid + "_" + rowData.dl_organizerid + "_" + rowData.in_sstockistname; //rowData.in_ssid + "_" + rowData.dl_organizerid;
                //if(this.props.rootFlag !== 3){
                    resVal.cAgentHOpt.contactPerId.name = rowData.in_terminalid;
                    resVal.cAgentHDrop.sstockistTypeId.value = rowData.in_sid + "_" + rowData.dl_organizerid + "_" + rowData.in_stockistname; //rowData.in_sid + "_" + rowData.dl_organizerid;
                //}
            //}
            resVal.cAgentHMand.contactPerName.name = this.props.rootFlag === 1 ? rowData.in_sstockistname : this.props.rootFlag === 3 ? rowData.in_stockistname : rowData.in_terminalname;
            resVal.cAgentHMand.contactPerNum.name = rowData.in_contactnumber;
            resVal.cAgentHMand.address.name = rowData.in_address;
            resVal.cAgentHMand.pinCode.name = rowData.in_pincode;
            resVal.cAgentHMand.location.name = rowData.in_location;
            //resVal.cAgentHMand.idProof.name = rowData.in_idproofnumber;
            resVal.cAgentHOpt.imgAttach.name = rowData.in_aadharupload;
            resVal.cAgentHOpt.imgAttach.uri = rowData.in_imgbasedata;
            resVal.cAgentHOpt.imgAttach.aName = rowData.in_aadharname;
            resVal.cAgentHOpt.imgAttach.aNo = rowData.in_aadharno;
            resVal.cAgentHOpt.imgAttach.dob = rowData.in_aadhardob;
            resVal.cAgentHOpt.imgAttach.gender = rowData.in_aadhargender;
            this.setState(resVal);
            return 
        }
        catch(ex) {
        alert("Field Updation Failed..");
        return
        }       
    }
     /* Text change on Agent create */
    onhandleTxtChange= (e: any,fieldFlag: number) => {
        try{
        let resVal: any = this.state;
        let fileErrCnt: number = 0;
        resVal.errMsg = "";
       switch(fieldFlag) {
        //mani 26/12
        //if(fieldFlag === 1)
        case 1:
        {
            resVal.cAgentHMand.contactPerName.name = e.target.value;
            resVal.cAgentHMand.contactPerName.display = e.target.value.trim() === "" ? true : false;
        }
        break;
       //else if(fieldFlag === 2)
        case 2:
        {
            resVal.cAgentHMand.contactPerNum.name = e.target.value;
            resVal.cAgentHMand.contactPerNum.display = e.target.value.trim() === "" ? true : false;
        }
        break;
        //else if(fieldFlag === 3)
        case 3:
        {
            let file:any = (e.target as HTMLInputElement).files;
            if(file.length !== 0){           
                let fileNam:string = file[0].name.toString();
                let sizeInBytes:any = file[0].size;
                let fileType: string = fileNam.split('.')[1];
                let fileName: string = fileNam.split('.')[0];    
                const sizeInMB = Math.round(sizeInBytes / (1024*1024));
                if(fileType === undefined){
                    resVal.errMsg = "Invalid file..!";
                    fileErrCnt++;
                }
                else if(sizeInMB <= 1){  
                    this.getBase64(file[0], (result: any) => {                      
                        this.onhandleGetAadharDet(this.state,file[0],(fileName + "." + fileType),result);
                        return
                    });                  
                }
                else{
                    resVal.errMsg = "Data size should be less than or equals 1 MB";
                    fileErrCnt++;
                }                  
            }
            else{
                resVal.errMsg = "No files selected..";
                fileErrCnt++;             
            }
        }
        break;
        //else if(fieldFlag === 4)
        case 4:
        {
            resVal.cAgentHMand.address.name = e.target.value;
            resVal.cAgentHMand.address.display = e.target.value.trim() === "" ? true : false;
        }
        break;
        //else if(fieldFlag === 5)
        case 5:
        {
            resVal.cAgentHMand.pinCode.name = e.target.value;
            resVal.cAgentHMand.pinCode.display = e.target.value.trim() === "" ? true : false;
        }
        break;
        //else if(fieldFlag === 6)
        case 6:
        {
            resVal.cAgentHMand.location.name = e.target.value;
            resVal.cAgentHMand.location.display = e.target.value.trim() === "" ? true : false;
        }
        break;
        //else if(fieldFlag === 7)
        case 7:
        {
            resVal.cAgentHOpt.contactPerId.name = e.target.value;
            resVal.cAgentHOpt.contactPerId.display = e.target.value === "" || e.target.value.includes(' ')  ? true : false;
        }
        break;
    }
        resVal.succFlag = undefined;
        if(resVal.errMsg !== ""){
            $("#divLoading").attr("style", "display:none") 
            if(fieldFlag === 3 && fileErrCnt !== 0){
                $('#myfileExp1').val('');
                const initialState: any = CAgentHmodel();
                resVal.cAgentHOpt.imgAttach = initialState.cAgentHOpt.imgAttach;
            }
            this.setState(resVal);
            $('.overlay').fadeIn();
            $('#lightBox2').fadeIn(); 
            return 
        }else{
            this.setState(resVal)
            return
        }
       }
        catch(ex) {
        alert("On handle text change Failed..");
        return
        }
    }
    
    //get an aadhar details
    onhandleGetAadharDet =(resVal: any,file: any, fileName: any,result: any) => {
        try{
            $("#divLoading").attr("style", "display:block")
            const baseImgStr: any = result.split('base64,');
            if(baseImgStr.length > 1){
                const jsonData: any = {
                    "kyc_data": {
                        "type":"adhar",
                        "image": baseImgStr.length > 1 ? baseImgStr[1] : ""
                    }
                }
                //mani 22/12
                //useAxios.post(config.Url("c_AgentHImg","post"), jsonData, config.authHeader())
                apiHelper.postAPICall("c_AgentHImg", "post",2,jsonData)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    $("#divLoading").attr("style", "display:none")
                    if(res.data !== null && res.data.result !== null && res.data.result.values !== null){
                        resVal.errMsg = "";
                        resVal.succFlag = undefined;
                        const resData: any =  res.data.result.values;
                        resVal.cAgentHOpt.imgAttach.fileResult = file;
                        resVal.cAgentHOpt.imgAttach.name = fileName;  
                        resVal.cAgentHOpt.imgAttach.uri = result;
                        resVal.cAgentHOpt.imgAttach.aName = resData.name[0];
                        resVal.cAgentHOpt.imgAttach.aNo = resData.aadhar_no[0];
                        resVal.cAgentHOpt.imgAttach.dob = resData.dob[0];
                        resVal.cAgentHOpt.imgAttach.gender = resData.gender[0];
                        this.setState(resVal);
                        return
                    }
                    else{
                        $("#divLoading").attr("style", "display:none") 
                        $('#myfileExp1').val('');
                        resVal.errMsg = "Please give valid image file..!"
                        const initialState: any = CAgentHmodel();
                        resVal.cAgentHOpt.imgAttach = initialState.cAgentHOpt.imgAttach;
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                        return        
                    }                    
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    $('#myfileExp1').val('');
                    resVal.errMsg = "Invalid image..!"
                    const initialState: any = CAgentHmodel();
                    resVal.cAgentHOpt.imgAttach = initialState.cAgentHOpt.imgAttach;
                    this.setState(resVal);
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn(); 
                    return         
                })
            }
            else{
                $("#divLoading").attr("style", "display:none") 
                $('#myfileExp1').val('');
                resVal.errMsg = "Invalid image..!"
                const initialState: any = CAgentHmodel();
                resVal.cAgentHOpt.imgAttach = initialState.cAgentHOpt.imgAttach;
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return             
            }
        }
        catch(ex) {
          alert("On Click on get the aadhar details..");
          return
        }
    }
    // img to base64 string convertion
    getBase64(file: any, cb: any) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error); 
        };
    }
    /* Drop down change on Agent create */
    handleDropChange = (e: any,type: number) => {
        try{
            const _this: any = this;
            const resVals: any = _this.state;
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            resVals.succFlag = undefined;
            resVals.errMsg = "";
            switch(type){
                //mani
           // if(type === 1)
           case 1:
            {
                resVals.cAgentHDrop.OrganizerName.value = e.value;
                resVals.cAgentHDrop.OrganizerName.display = false; 
                resVals.cAgentHDrop.stockistType = [];
                resVals.cAgentHDrop.stockistTypeId.value = null;
                resVals.cAgentHDrop.sstockistType= [];
                resVals.cAgentHDrop.sstockistTypeId.value = null;  
                if((_this.props.rootFlag === 3 || _this.props.rootFlag === 5) && this.props.rowData === null){
                    const callJsonHead: any = { 
                        organizerID: userInfo["is_superuser"] ? resVals.cAgentHDrop.OrganizerName.value : userInfo["userId"],
                        flag_id: 1
                    }
                    _this.loadNameMasterdata(resVals,callJsonHead,"load_superstockist","CStockist",0).then((res1: any) => {
                        _this.setState(res1);
                        return 
                    })
                }  
                else{
                    _this.setState(resVals);
                    return 
                } 
                break;            
            }
            //else if(type === 2)
            case 2:
                {
                resVals.cAgentHDrop.stateTypeId.value = e.value;
                resVals.cAgentHDrop.stateTypeId.display= false;
                _this.setState(resVals);
                return 
            }
            //else if(type === 3)
            case 3:
             {
                resVals.cAgentHDrop.stockistTypeId.value = e.value;
                resVals.cAgentHDrop.stockistTypeId.display= false;
                resVals.cAgentHDrop.sstockistType= [];
                resVals.cAgentHDrop.sstockistTypeId.value = null;  
                if(_this.props.rootFlag === 5){
                    const callJsonHead: any = { 
                        organizerID: userInfo["is_superuser"] ? resVals.cAgentHDrop.OrganizerName.value : userInfo["userId"],
                        super_stockistID: parseInt(resVals.cAgentHDrop.stockistTypeId.value.split('_')[0]),
                        flag_id: 1
                    }
                    _this.loadNameMasterdata(resVals,callJsonHead,"load_stockist","CSStockist",1).then((res1: any) => {
                        _this.setState(res1);
                        return 
                    })
                }
                else{
                    _this.setState(resVals);
                    return 
                }
                break;
            }
            //else if(type === 4)
            case 4:
            {
                resVals.cAgentHDrop.sstockistTypeId.value = e.value;
                resVals.cAgentHDrop.sstockistTypeId.display= false;
                _this.setState(resVals);
                return 
            }
        }
        }
        catch(ex) {
          alert("On Dropdown Change Failed..");
          return
        }
    }
    removeAadharUpload = (evt: any) => {
        evt.preventDefault();
        $('#myfileExp1').val('');
        const resVals: any = this.state;
        const initialState: any = CAgentHmodel();
        resVals.cAgentHOpt.imgAttach = initialState.cAgentHOpt.imgAttach;
        this.setState(resVals)
        return
    }
    onViewTableData= (e: any) => {
        try{
            e.preventDefault();
            $("#divLoading").attr("style", "display:block") 
            const resVal: any = this.state;
            if(resVal.cAgentHRadio === 1 && (resVal.cAgentHOpt.fileAttach.name === null || resVal.cAgentHOpt.fileAttach.name === "")){ //drop down validation
                $('#myfileExp').val('');
                resVal.cAgentHOpt.fileAttach.display = true;
                resVal.cAgentHDrop.OrganizerName.display = false;
                resVal.cAgentHDrop.stateTypeId.display = false;
                resVal.cAgentHDrop.stockistTypeId.display = false;
                resVal.cAgentHDrop.sstockistTypeId.display = false;
                this.setState(resVal);
                $("#divLoading").attr("style", "display:none") 
                return
            }
            else{
                $("#divLoading").attr("style", "display:none") 
                this.setState(resVal);
                this.subTabStateReset.current.subTabStateReset();
                $('#lightBox5').fadeIn();
                $('.overlay').fadeIn();
                return
            }
        }
        catch(ex) {
            alert("Data View Failed..");
            return
        }
    }
    onSubmitData= (e: any,Type: string) => {
        try{
        e.preventDefault();
        let resVal: any = this.state;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        let isErrData: boolean = false;
        $("#divLoading").attr("style", "display:block")
        //validation part-Start
        if(resVal.cAgentHRadio === 0){
            Object.entries(resVal.cAgentHMand).forEach(([key, value],index: number) => {
                let val = resVal.cAgentHMand[key].name;
                if(val === null || val === "" || resVal.cAgentHMand[key].display){
                    resVal.cAgentHMand[key].display = true
                    isErrData = true
                }
                else{ return }
            })
            if((this.props.rootFlag === 5 || this.props.rootFlag === 7) && (resVal.cAgentHOpt.contactPerId.name === "" || resVal.cAgentHOpt.contactPerId.name.includes(' '))){ //terminal/player id change
                resVal.cAgentHOpt.contactPerId.display = true
                isErrData = true
            } 
        }
        else if(resVal.cAgentHRadio === 1 && (resVal.cAgentHOpt.fileAttach.name === null || resVal.cAgentHOpt.fileAttach.name === "")){ //file change validation
            $('#myfileExp').val('');
            resVal.cAgentHOpt.fileAttach.display = true;
            isErrData = true
        }
        if(resVal.cAgentHDrop.stateTypeId.value === null){ //drop down validation
            resVal.cAgentHDrop.stateTypeId.display = true
            isErrData = true
        }
        if((this.props.rootFlag === 3 || this.props.rootFlag === 5) && resVal.cAgentHDrop.stockistTypeId.value === null){ //drop down validation
            resVal.cAgentHDrop.stockistTypeId.display = true
            isErrData = true
        }
        if((this.props.rootFlag === 5) && resVal.cAgentHDrop.sstockistTypeId.value === null){ //drop down validation
            resVal.cAgentHDrop.sstockistTypeId.display = true
            isErrData = true
        }      
        if(userInfo["is_superuser"] && resVal.cAgentHDrop.OrganizerName.value === null){ //drop down validation
            resVal.cAgentHDrop.OrganizerName.display = true
            isErrData = true
        }
        resVal.succFlag = undefined;
        //validation part-End
        if(isErrData){
            $("#divLoading").attr("style", "display:none") 
            //resVal.errMsg = "Invalid data..!"
            this.setState(resVal);
            // $('.overlay').fadeIn();
            // $('#lightBox2').fadeIn(); 
            return 
        }else{
            if(Type === "C"){
                const userInfoString: any = sessionStorage.getItem('userInfo');
                const userInfo: any = JSON.parse(userInfoString);
                const userIp: string = $("#userIp").text(); 
                const flagId: number = this.props.rootFlag === 1 ? 0 : this.props.rootFlag === 3 ? 1 : this.props.rootFlag === 5 ? 2 : 3;            
                const resArr: any = resVal.cAgentHRadio !== 0 ? resVal.aAgentH.tabDbData[0] : 
                this.props.rootFlag === 1 || this.props.rootFlag === 3 ?
                {
                    "Name":[resVal.cAgentHMand.contactPerName.name],
                    "ContactNumber":[resVal.cAgentHMand.contactPerNum.name],
                    "Address":[resVal.cAgentHMand.address.name],
                    "Pincode":[resVal.cAgentHMand.pinCode.name],
                    "Location":[resVal.cAgentHMand.location.name]                 
                } : 
                {
                    "Terminalid":[resVal.cAgentHOpt.contactPerId.name],
                    "Name":[resVal.cAgentHMand.contactPerName.name],
                    "ContactNumber":[resVal.cAgentHMand.contactPerNum.name],
                    "Address":[resVal.cAgentHMand.address.name],
                    "Pincode":[resVal.cAgentHMand.pinCode.name],
                    "Location":[resVal.cAgentHMand.location.name]
                };
                const kycDetailArr: any = JSON.stringify({
                    "aadharupload":[resVal.cAgentHOpt.imgAttach.name],
                    "aadharName":[resVal.cAgentHOpt.imgAttach.aName],
                    "aadharNo":[resVal.cAgentHOpt.imgAttach.aNo],
                    "aadhardob":[resVal.cAgentHOpt.imgAttach.dob],
                    "aadhargender":[resVal.cAgentHOpt.imgAttach.gender]
                });               
                const jsonData: any = {
                    "stateID": parseInt(resVal.cAgentHDrop.stateTypeId.value),
                    "super_stockistID": resVal.cAgentHDrop.stockistTypeId.value === null ? 0 : parseInt(resVal.cAgentHDrop.stockistTypeId.value.split('_')[0]),
                    "stockistID": resVal.cAgentHDrop.sstockistTypeId.value === null ? 0 : parseInt(resVal.cAgentHDrop.sstockistTypeId.value.split('_')[0]),
                    "reg_array": resArr,
                    "organizerID": userInfo["is_superuser"] ? resVal.cAgentHDrop.OrganizerName.value : userInfo["userId"],
                    "flag_id": flagId,
                    "kycDetail": kycDetailArr,
                    "imgbaseData": resVal.cAgentHOpt.imgAttach.uri,
                    "ipaddress": userIp
                }
                //mani 22/12
               // useAxios.post(config.Url("c_AgentH","post"), jsonData, config.authHeader())
               apiHelper.postAPICall("c_AgentH", "post",2,jsonData)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    $("#divLoading").attr("style", "display:none")
                    if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
                        $("#divLoading").attr("style", "display:none") 
                        resVal.errMsg = res.data.result.toString().replace('successfully','');
                        resVal.succFlag = undefined;
                        this.setState(resVal);                   
                    }
                    else{
                        resVal = CAgentHmodel()
                        resVal.cAgentHDrop.OrganizerNames = this.state.cAgentHDrop.OrganizerNames;
                        resVal.cAgentHDrop.stateType = this.state.cAgentHDrop.stateType;
                        resVal.errMsg = res.data.result.toString().replace('successfully','');
                        resVal.succFlag = 0;
                        this.setState(resVal);
                    }
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    if(err !== undefined && err.response !== undefined && err.response.statusText !== undefined && err.response.statusText === 'Unprocessable Entity'){
                        resVal.errMsg = "Invalid data..!"
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                    } 
                    return 
                })
            }
            else{
                this.props.backClick(this.state);
                return
            }
        } 
        }
        catch(ex) {
        alert("Data Inserion/ Updation Failed..");
        return
        }      
    }
    // event triggers on key press on input controls
    handleKeypress = (evt: any) => {
        if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
        //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
            evt.preventDefault();
        }
    }
    maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
    }
    handleToggleClick =(e: any,flag: number) => {
        try{
            const resVals: any = this.state;
            if(resVals.cAgentHRadio !== flag){
                const initialState: any = CAgentHmodel();
                $('#myfileExp').val('');
                $('#myfileExp1').val('');
                resVals.cAgentHOpt = initialState.cAgentHOpt;
                resVals.cAgentHMand = initialState.cAgentHMand;
                resVals.aAgentH = initialState.aAgentH;
                resVals.succFlag = undefined;
                resVals.errMsg = "";
                resVals.cAgentHRadio = flag;
                this.setState(resVals);
                return
            }
        }
        catch(ex) {
          alert("On Change Toggle button updation Failed..");
          return
        }
    }
    //on change the paper lott file upload data
    onhandleFileChange = (e: any) => {
        let file:any = (e.target as HTMLInputElement).files;
        $("#divLoading").attr("style", "display:block") 
        const resVal: any = this.state;
        $('.showPlusMinus1').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');  
        $('.graphPop6').css({display: "none"})   
        if(file.length !== 0){           
            let fileNam:string = file[0].name.toString();
            let sizeInBytes:any = file[0].size;
            let fileType: string = fileNam.split('.')[1];
            let fileName: string = fileNam.split('.')[0];    
            const sizeInMB = Math.round(sizeInBytes / (1024*1024));
            if(fileType === undefined){               
                $("#divLoading").attr("style", "display:none") 
                $('#myfileExp').val('');
                const initialState: any = CAgentHmodel();
                resVal.cAgentHOpt.fileAttach = initialState.cAgentHOpt.fileAttach; 
                resVal.errMsg = "Invalid file..!";
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return 
            }
            else if(sizeInMB <= 10 && (fileType.includes('xls') || fileType.includes('csv'))){   
                resVal.cAgentHOpt.fileAttach.fileResult = file[0];
                resVal.cAgentHOpt.fileAttach.name = fileName + "." + fileType;
                resVal.cAgentHOpt.fileAttach.display = false;
                return this.convertExcelCsvFiletoJson(resVal.cAgentHOpt.fileAttach.fileResult,this.props.rootFlag === 5 || this.props.rootFlag === 7 ? 2 : 1,(err:any, response:any) => {
                    if(err || response === undefined || response === null){
                        console.log(err); 
                        $('#myfileExp').val(''); 
                        $("#divLoading").attr("style", "display:none")
                        const initialState: any = CAgentHmodel();
                        resVal.cAgentHOpt.fileAttach = initialState.cAgentHOpt.fileAttach;  
                        resVal.errMsg = "Please give valid data..!";
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                        return  
                    }
                    else{
                        $("#divLoading").attr("style", "display:none") 
                        resVal.aAgentH.tabDataSub = response.data;
                        resVal.aAgentH.colDataSub = response.colData;
                        resVal.aAgentH.tabDbData = response.dataDb;
                        this.setState(resVal);
                        return
                    }
                });    
            }
            else if(sizeInMB <= 10){
                resVal.errMsg = "Invalid file..!";
                $('#myfileExp').val('');
                const initialState: any = CAgentHmodel();
                resVal.cAgentHOpt.fileAttach = initialState.cAgentHOpt.fileAttach; 
                $("#divLoading").attr("style", "display:none") 
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return 
            }
            else{
                resVal.errMsg = "Data size should be less than or equals 10 MB";
                $('#myfileExp').val('');
                const initialState: any = CAgentHmodel();
                resVal.cAgentHOpt.fileAttach = initialState.cAgentHOpt.fileAttach; 
                $("#divLoading").attr("style", "display:none") 
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return 
            }                             
        }
        else{
            resVal.errMsg = "No files selected..";
            $("#divLoading").attr("style", "display:none") 
            $('#myfileExp').val('');
            const initialState: any = CAgentHmodel();
            resVal.cAgentHOpt.fileAttach = initialState.cAgentHOpt.fileAttach; 
            this.setState(resVal);
        }
        return
    }
    // convert excel and csv file into json obj
    convertExcelCsvFiletoJson(file:any,type: number,callback:any) { 
        let _this: any = this;
        return new Promise<void>(function(resolve, reject) {
        try {
            var reader = new FileReader();
            var rABS = !!reader.readAsBinaryString;
            reader.onload = function(e:any) {
            try{
                /* Parse data */
                var bstr = e.target.result;
                var wb = excel.read(bstr, { type: rABS ? "binary" : "array",
                cellDates: true //, dateNF: 'dd/mm/yyyy;@' 
                });
                var datasVal: any= [];
                var datasDbVal: any= [];
                var datasDbVal1: any= [];
                var datasDbVal2: any= [];
                var datasDbVal3: any= [];
                var datasDbVal4: any= [];
                var datasDbVal5: any= [];
                var datasDbVal6: any= [];
                var errCount: number = 0;
                for(let i = 0;i < 1; i++){ //wb.SheetNames.length
                /* Get all worksheets */
                var wsname = wb.SheetNames[i];
                var ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const jsonArr: any = excel.utils.sheet_to_json(ws, {
                    header: 1,
                    raw: false,defval: '',blankrows: false
                });
                
                if(jsonArr.length > 0){                  
                    // const tabName: string = wsname.toString();
                    // sheetName.push(tabName); 
                    jsonArr.shift();
                    for(let i = 0; i < jsonArr.length; i++){
                        let datasObj: any = {}
                        if(type === 1){
                            if(jsonArr[i].length >= 5){
                                Object.assign(datasObj, {"in_sno": i + 1,"Name": jsonArr[i][0],"ContactNumber": jsonArr[i][1],"Address": jsonArr[i][2]
                                ,"Pincode": jsonArr[i][3],"Location": jsonArr[i][4]})
                                datasDbVal1.push(jsonArr[i][0]);
                                datasDbVal2.push(jsonArr[i][1]);
                                datasDbVal3.push(jsonArr[i][2]);
                                datasDbVal4.push(jsonArr[i][3]);
                                datasDbVal5.push(jsonArr[i][4]);
                            }
                            else{
                                errCount = 1;
                                break;
                            }
                        }
                        else if(type === 2){
                            if(jsonArr[i].length >= 6){
                                Object.assign(datasObj, {"in_sno": i + 1,"Terminalid": jsonArr[i][0],"Name": jsonArr[i][1],"ContactNumber": jsonArr[i][2],"Address": jsonArr[i][3]
                                ,"Pincode": jsonArr[i][4],"Location": jsonArr[i][5]})
                                datasDbVal1.push(jsonArr[i][0]);
                                datasDbVal2.push(jsonArr[i][1]);
                                datasDbVal3.push(jsonArr[i][2]);
                                datasDbVal4.push(jsonArr[i][3]);
                                datasDbVal5.push(jsonArr[i][4]);
                                datasDbVal6.push(jsonArr[i][5]);
                            }
                            else{
                                errCount = 1;
                                break;
                            }
                        }
                        datasVal.push(datasObj)
                    }
                    if(type === 1){
                        datasDbVal.push({"Name": datasDbVal1,"ContactNumber": datasDbVal2,"Address": datasDbVal3
                        ,"Pincode": datasDbVal4,"Location": datasDbVal5});
                    }
                    else if(type === 2){
                        datasDbVal.push({"Terminalid": datasDbVal1,"Name": datasDbVal2,"ContactNumber": datasDbVal3,"Address": datasDbVal4
                        ,"Pincode": datasDbVal5,"Location": datasDbVal6});
                    }
                }
                else{
                    errCount = 1;
                    break;
                }
                }
                const resData: any = errCount === 0 ? {
                    data: datasVal,
                    colData: _this.loadColDatas(datasVal[0], type),
                    dataDb: datasDbVal
                } : null;
                resolve(resData);
                return callback(null, resData);
            }
            catch(ex){
                return callback(null);
            }
            };
            if (file && rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        } catch(err){
            $("#divLoading").attr("style", "display:none")
            reject(err);
        }
        });
    } 
    // load view column heeder data
    loadColDatas = (resData: any, type: number) => {
        const colDataArr: any = [];
        let cntIndex: number = 0;
        const windowWidth: number = $(window).width();
        for(var prop of Object.keys(resData)){
            const headerTitle: any = prop === "Name" ? ((this.props.rootFlag === 1 ? "Stocki." : this.props.rootFlag === 3 ? "Sub Stocki." : 
            this.props.rootFlag === 5 ? "Terminal" : this.props.rootFlag === 7 ? "Player" : "") + " " + prop) : CommonAgentH.getTableTitle(prop === "Terminalid" && this.props.rootFlag === 7 ? (prop.toLowerCase() + "s") : prop.toLowerCase());                   
            const propValue: string = prop; 
            const isVisible: any = cntIndex === 0 ? false : true;
            if(propValue === "in_sno"){
                colDataArr.push({Visible: isVisible,width: 40,Header: headerTitle,accessor: propValue,
                    show: !isVisible,className: "wordwrap",headerClassName: "wordwrap"})
            } 
            else{                   
                colDataArr.push({Visible: isVisible,Header: headerTitle,accessor: propValue,
                    show: !isVisible,className: "wordwrap",headerClassName: "wordwrap"})
            }
            //responsive block
            if(windowWidth > 800 && propValue === "Location"){
                cntIndex++;
            }
            else if(windowWidth < 600 && propValue === (type === 2 ? "Terminalid" : "Name")){
                cntIndex++;
            }
            else if(windowWidth > 600 && windowWidth <= 800 && propValue === (type === 2 ? "Name" : this.props.rootFlag === 3 ? "ContactNumber" : "Address")){
                cntIndex++;
            }
            //responsive block
        }
        if(windowWidth > 800){}
        else{
            colDataArr.push({
                width: 50,
                Header: "View",
                accessor: "View",
                //resizable: false,
                // sortable: false,
                className: "wordwrap",
                Cell: (row: any) => (
                <div className="userLogBtnCont3" style={{padding: 0}}>	
                    <small>
                        <i  key={"tabPopupI0_" + row.index} id={"tabPopupIC0_" + row.index} className="fa showPlusMinus1 fa-plus GreenBgg" style={{borderRadius: "100px",padding: "5px"}} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e,row.index)}></i>
                    </small>          
                    <div key={"tabPopup0_" + row.index} id={"tabPopupC0_" + row.index} className="graphPop6 graphPop-1">
                    <Tablistview keys={"tabPopupV0_" + row.index} rowData={row.original} colData={this.state.aAgentH.colDataSub} />
                    </div>	                            
                </div>
                )
            });
        }
        return colDataArr;
    } 
    //show hide lightbox
    onShowLightBox= (e: any, index: any) => {
        e.preventDefault();         
        const currDisplay: any = index === null ? $('#tabPopupFC0_0').css('display') : $('#tabPopupC0_' + index).css('display');    
        // $('.fa.fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        // $('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
        $('.showPlusMinus1').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');  
        $('.graphPop6').css({display: "none"})   
        if(index !== null){  
        $('#tabPopupIC0_' + index).attr('class', (currDisplay === "none" ? "fa showPlusMinus1 fa-minus RedBgg" : "fa showPlusMinus1 fa-plus GreenBgg"));  
        $('#tabPopupC0_' + index).css({display: currDisplay === "none" ? "block" : "none"})
        }
        else{
        $('#tabPopupIFC0_0').attr('class', (currDisplay === "none" ? "fa showPlusMinus1 fa-minus RedBgg" : "fa showPlusMinus1 fa-plus GreenBgg"));      
        $('#tabPopupFC0_0').css({display: currDisplay === "none" ? "block" : "none"})
        }  
        return 
    }  
    render() {
    const { t } = this.props;
    const resVal: any = this.state;
        const DropdownIndicator = (props: any) => {
            return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                <FontAwesomeIcon style={{fontSize: "20px"}} icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>
                </components.DropdownIndicator>
            )
            );
        };
        const styles = {
            menu: (base: any) => ({
                ...base,
                marginTop: 0
            })
        };
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        const fieldName: any = this.props.rootFlag === 1 ? t('lblCreateStock') : this.props.rootFlag === 3 ? t('lblCreateSubStock') : 
            this.props.rootFlag === 5 ? t('lblCreateTerminal') : this.props.rootFlag === 7 ? t('lblCreatePlayer') : "";
      return(
        <div className="ContainerChart scrollbar" style={{height: $(window).height() - 60 + "px"}}>
             <ListSubTable
                tabData= {resVal.aAgentH.tabDataSub}
                colData= {resVal.aAgentH.colDataSub}
                rootFlag= {this.props.rootFlag}
                sorting={true}
                ref ={this.subTabStateReset}
            />
            <Lightbox errMsg= {resVal.errMsg} succFlag= {resVal.succFlag} />            
            <div className="contentclouds">
            <div className="userLogdata1">					
                <div className="userLogHead">
                <Breadcrumb Menu= "Agent Hierarchy" subMenu= {this.props.rowData === null ? "Create " + CommonAgentH.getPageTitle(this.props.rootFlag) : "View " + CommonAgentH.getPageTitle(this.props.rootFlag)} subMenu1= {this.props.rowData === null ? null : "Edit " + CommonAgentH.getPageTitle(this.props.rootFlag)} />
                <div className="userLogHeadinner">
				<h2>{(this.props.rowData === null ? "Create " : "Edit ") + CommonAgentH.getPageTitle(this.props.rootFlag)}</h2>
				<small>{t('lblHierarchyDetail')}</small>
			    </div>
                </div>
                <div className="userLogform scrollbar">
                    <div className="userLogformLft">
                        <ul>
                            <li>
                                {is_superuser !== null && is_superuser ? 
                                <div className={this.props.rootFlag === 5 ? "userLogCloud44" : "userLogCloud3"}>
                                    <div>
                                        <label>{t('lblOrganizerName')} *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.OrganizerName.value === null ? null : resVal.cAgentHDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.OrganizerName.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,1)}
                                        placeholder={t('lblSelectName')}
                                        options={resVal.cAgentHDrop.OrganizerNames}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.OrganizerName.display ? "block" : "none"}}>{t('lblOrgNameProvided')}</label>
                                    </div>
                                </div> : null}
                                {this.props.rootFlag === 3 || this.props.rootFlag === 5 ?
                                (<div className={is_superuser !== null && is_superuser && this.props.rootFlag === 5 ? "userLogCloud44" : "userLogCloud3"}>
                                    <div>
                                        <label>{t('lblStockistName')} *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.stockistTypeId.value === null ? null : resVal.cAgentHDrop.stockistType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.stockistTypeId.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,3)}
                                        placeholder={this.props.rowData === null || resVal.cAgentHDrop.stockistTypeId.value === null ? t('lblSelectStockName') : resVal.cAgentHDrop.stockistTypeId.value.split('_')[2]}
                                        options={resVal.cAgentHDrop.stockistType}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.stockistTypeId.display ? "block" : "none"}}>{t('lblStockistNameProvided')}</label>
                                    </div>
                                </div>) : null}
                                {this.props.rootFlag === 5 ?
                                (<div className={is_superuser !== null && is_superuser ? "userLogCloud44" : "userLogCloud3"}>
                                    <div>
                                        <label>{t('lblSubStockistName')} *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.sstockistTypeId.value === null ? null : resVal.cAgentHDrop.sstockistType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.sstockistTypeId.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,4)}
                                        placeholder={this.props.rowData === null || resVal.cAgentHDrop.sstockistTypeId.value === null ? t('lblSelectSubStock') : resVal.cAgentHDrop.sstockistTypeId.value.split('_')[2]}
                                        options={resVal.cAgentHDrop.sstockistType}
                                        isDisabled={this.props.rowData === null ? false : true}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.sstockistTypeId.display ? "block" : "none"}}>{t('lblSubStockistProvided')}</label>
                                    </div>
                                </div>) : null} 
                                <div className={is_superuser !== null && is_superuser && this.props.rootFlag === 5 ? "userLogCloud44" : "userLogCloud3"}>
                                    <div>
                                        <label>{t('lblStateName')} *</label>
                                        <Select
                                        value={resVal.cAgentHDrop.stateTypeId.value === null ? null : resVal.cAgentHDrop.stateType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.stateTypeId.value.toString())}
                                        onChange={(e: any) => this.handleDropChange(e,2)}
                                        placeholder={t('lblSelectState')}
                                        options={resVal.cAgentHDrop.stateType}
                                        // maxMenuHeight={110}
                                        className="userlogSelect"
                                        components={{ DropdownIndicator }}
                                        styles={styles}
                                        />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.stateTypeId.display ? "block" : "none"}}>{t('lblStateNameProvided')}</label>
                                    </div>
                                </div>                                    
                            </li> 
                            {this.props.rowData === null ?
                            (<li>
								<div className="userLogCloud2">
									<div className="radioBtn">
										<div className="radioBtn1">
										<label className="container">{t('lblIndividual')}
										  <input type="radio" style={{width: "auto"}} checked={resVal.cAgentHRadio === 0 ? true : false} onChange={(e: any) => this.handleToggleClick(e,0)} name="radio" />
										  <span className="checkmark"></span>
										</label>
										</div>
										<div className="radioBtn1">
										<label className="container">{t('lblBulkDataUpload')}
										  <input type="radio" style={{width: "auto"}} checked={resVal.cAgentHRadio === 1 ? true : false} onChange={(e: any) => this.handleToggleClick(e,1)} name="radio" />
										  <span className="checkmark"></span>
										</label>
										</div>
									</div>
								</div>
							</li>) : null}
                            {resVal.cAgentHRadio === 0 ? 
                            (<>                                
                            <li>
                                {this.props.rootFlag === 5 || this.props.rootFlag === 7 ?
                                (<div className="userLogCloud4">
                                    <div>
                                        <label>{fieldName} Id *</label>
                                        <input className="newUserTxt disableCtrl" value={resVal.cAgentHOpt.contactPerId.name} onChange={(e: any) => this.onhandleTxtChange(e,7)} placeholder={"Enter "+ fieldName +" Id"} autoComplete="off" type="text" maxLength={fieldName === "Player" ? 15 : 5} disabled={this.props.rowData === null ? false : true} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHOpt.contactPerId.display ? "block" : "none"}}>{fieldName} Id has to be provided or remove white spaces.</label>
                                    </div>
                                </div>) : null}
                                <div className={this.props.rootFlag === 5 || this.props.rootFlag === 7 ? "userLogCloud4" : "userLogCloud2"}>
                                    <div>
                                        <label>{fieldName} Name *</label>
                                        <input className="newUserTxt" value={resVal.cAgentHMand.contactPerName.name} onChange={(e: any) => this.onhandleTxtChange(e,1)} placeholder={"Enter "+ fieldName +" Name"} autoComplete="off" type="text" maxLength={50}/>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHMand.contactPerName.display ? "block" : "none"}}>{fieldName} Name has to be provided</label>
                                    </div>
                                </div>
                                <div className={this.props.rootFlag === 5 || this.props.rootFlag === 7 ? "userLogCloud4" : "userLogCloud2"}>
                                    <div>
                                        <label>{t('lblContactNo')} *</label>
                                        <input className="input_Number" id="colValL1" min="0" onKeyPress= {(e:any) => this.handleKeypress(e)} value={resVal.cAgentHMand.contactPerNum.name} onChange={(e: any) => this.onhandleTxtChange(e,2)} placeholder={t('lblEnterNo')} autoComplete="off" type="number" maxLength={15} onInput={this.maxLengthCheck} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHMand.contactPerNum.display ? "block" : "none"}}>{t('lblContactNoProvided')}</label>
                                    </div>
                                </div>
                                <div className={this.props.rootFlag === 5 || this.props.rootFlag === 7 ? "userLogCloud4" : "userLogCloud2"}>
                                    <div>
                                        <label>{t('lblLoc')} *</label>
                                        <input className="newUserTxt" value={resVal.cAgentHMand.location.name} onChange={(e: any) => this.onhandleTxtChange(e,6)} placeholder={t('lblEnterLoc')} autoComplete="off" type="text" maxLength={100}/>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHMand.location.display ? "block" : "none"}}>{t('lblLocProvided')}</label> 
                                    </div>
                                </div>
                            </li>                            
                            <li>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblAddress')} *</label>
                                        <textarea id="w3review" rows={5} cols={40} placeholder= {t('lblEnterAddress')} value={resVal.cAgentHMand.address.name} onChange={(e: any) => this.onhandleTxtChange(e,4)} autoComplete="off" maxLength={400} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHMand.address.display ? "block" : "none"}}>{t('lblAddressProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblPincode')} *</label>
                                        <input className="input_Number" id="colValL1" min="0" onKeyPress= {(e:any) => this.handleKeypress(e)} value={resVal.cAgentHMand.pinCode.name} onChange={(e: any) => this.onhandleTxtChange(e,5)} placeholder={t('lblEnterPin')} autoComplete="off" type="number" maxLength={10} onInput={this.maxLengthCheck} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHMand.pinCode.display ? "block" : "none"}}>{t('lblPincodeProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        {/* Aadhar Upload */}
                                        <label>{t('lblIdUpload')}</label>
                                        <input className="newUserTxt" style= {{color: "transparent"}} type="file" id="myfileExp1" name="myfileExp1" accept="image/*" onChange={(e: any) => this.onhandleTxtChange(e,3)} />
                                        {resVal.cAgentHOpt.imgAttach.uri === null || resVal.cAgentHOpt.imgAttach.uri === "" ? null : 
                                        (<div className="AdhaarDtls">
                                            <h4>{t('lblAadhar')}</h4>
                                            <span className="logoDiv2"><img src={resVal.cAgentHOpt.imgAttach.uri} alt="" /></span>
                                            <i className="fas fa-times" style={{float: "right"}} onClick={(e: any) => this.removeAadharUpload(e)}></i>
                                            <ul>
                                                <li><label style={{fontWeight: "bold",textAlign: "right"}}>{t('lblAadherName')}</label><span>{resVal.cAgentHOpt.imgAttach.aName}</span></li>
                                                <li><label style={{fontWeight: "bold",textAlign: "right"}}>{t('lblAadherNo')}</label><span>{resVal.cAgentHOpt.imgAttach.aNo}</span></li>
                                                <li><label style={{fontWeight: "bold",textAlign: "right"}}>{t('lblDOB')}</label><span>{resVal.cAgentHOpt.imgAttach.dob}</span></li>
                                                <li><label style={{fontWeight: "bold",textAlign: "right"}}>{t('lblGender')}</label><span>{resVal.cAgentHOpt.imgAttach.gender}</span></li>
                                                <li><label style={{fontWeight: "bold",textAlign: "right"}}>{t('lblFile')}</label><span>{resVal.cAgentHOpt.imgAttach.name}</span></li>
                                            </ul>
                                        </div>)}
                                    </div>
                                </div>
                            </li>  
                            </>) : 
                            (<li>
                                <div className="userLogCloud6">
                                    <div>
                                    <label>{t('lblExcelData')}</label>
                                        <input className="newUserTxt" style= {{color: "transparent"}} type="file" id="myfileExp" name="myfileExp" accept=".csv,.xlsb,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                        onChange={(e: any) => this.onhandleFileChange(e)} />
                                        {resVal.cAgentHOpt.fileAttach.name === null || resVal.cAgentHOpt.fileAttach.name === "" ? null : (<label style={{fontSize: "12px"}}><b>{resVal.cAgentHOpt.fileAttach.name}</b></label>)}
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHOpt.fileAttach.display ? "block" : "none"}}>{t('lblMustExcelData')}</label>
                                        <a title="Sample Excel Data" href={this.props.rootFlag === 1 ? '/excel/agent_1.xlsx' : this.props.rootFlag === 3 ? "/excel/agent_2.xlsx" : this.props.rootFlag === 5 ? "/excel/agent_3.xlsx" : "/excel/agent_4.xlsx"} target="_blank" rel="noreferrer" download={fieldName.replaceAll(' ','') + "_sample"}><i className="fa fa-download" style={{width:"auto",float:"left",color:"#000",margin:"0 2px"}} aria-hidden="true"></i> </a>
                                    </div>
                                </div>
                            </li>)}                                 
                        </ul>                                
                        <div className="userLogBtnCont3">
                            <button className="GreyBg" id="loginBtn" onClick={() => this.resetValues(1)}><i className={"fa fa-" + (this.props.rowData === null ? "sync" : "times")} style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t('lblReset') : t('lblCancel')}</button>							
                            {resVal.cAgentHRadio === 1 ? (
                            <button className="GreenBg" id="registerBtn" onClick={(e: any) => this.onViewTableData(e)}><i className="fas fa-eye" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblView')}</button>		
                            ) : null}
                            <button className="GreenBg" id="registerBtn" onClick={(e: any) => this.onSubmitData(e,this.props.rowData === null ? "C" : "E")}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t('lblCreate') : t('lblOk')}</button>		
                        </div>                 
                    </div>
                </div>
                </div>			
            </div>
	    </div>                   
    );
  }
}

export default withTranslation()(CAgentH);