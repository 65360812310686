import React from 'react';
import apiHelper from "../../utils/ApiHelper"
import useAxios from "axios";
import moment from "moment"
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown,faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import CAgentH from "./c_agentH"
import CCAgentH from "./cc_agentH"
import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import ListTable from "../Shared/listtable";
import Breadcrumb from "../Shared/breadcrumb";
import CommonAgentH from './Child/c_agentHcommon';
import Tablistview from "../Shared/tablistview";
import CAgentHmodel from "./Child/c_agentHmodel";

import { withTranslation  } from 'react-i18next';
import { t } from 'i18next';

// import TooltipData from "../../assets/img/icons/Arrow_new.png"
// import PlusIcon from "../../assets/img/icons/Plus_Icon.png"
// import MinusIcon from "../../assets/img/icons/Minus_Icon.png"

var $ = require('jquery')//
library.add(faCaretDown);
library.add(faCaretUp);

export interface IProps {
    rootFlag: number,
    t : any
}
export interface IState {
    errMsg: string,
    succFlag: any,
    cAgentHMand: any,
    cAgentHOpt: any,
    cAgentHDrop: any,
    cAgentHRadio: number,
    aAgentH: any,
    
}

  class AAgentH extends React.Component<IProps, IState> {
  // ------------------------------------------^
    constructor(props: IProps) {
      super(props);  
      this.state = CAgentHmodel()
      this.globalSearch = this.globalSearch.bind(this);
      this.onFilterListTableData = this.onFilterListTableData.bind(this);
      this.handleDropChange = this.handleDropChange.bind(this);
    }
    componentDidMount(){
        const initialState: any = CAgentHmodel()
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        const callJsonHead: any = { 
            flag_id: 0
        }
        const callJsonHead2: any = { 
            organizerID: 0,
            flag_id: 0
        }
        if(is_superuser !== null && is_superuser){
            const _this: any = this;
            _this.loadOrgNameMasterdata(initialState).then((res: any) => {
                _this.loadNameMasterdata(res,callJsonHead,"load_gameDetails","cm_game",2).then((res1: any) => {
                    _this.loadNameMasterdata(res1,callJsonHead2,"load_state","CState",4).then((res2: any) => {
                    _this.setState(res2, () => {
                        if(_this.props.rootFlag === 2 || _this.props.rootFlag === 8){
                            // _this.loadListTableData();
                            _this.setState(res2);
                            return
                        }
                        else{
                            const callJsonHead1: any = { 
                                organizerID: res1.cAgentHDrop.OrganizerName.value,
                                flag_id: 0
                            }
                            _this.loadNameMasterdata(res2,callJsonHead1,"load_superstockist","CStockist",0).then((res3: any) => {
                                _this.setState(res3);
                                return 
                            }) 
                        }
                    });
                    return
                    });   
                })
            })
        }
        else{
            const _this: any = this;
            _this.loadNameMasterdata(initialState,callJsonHead,"load_gameDetails","cm_game",2).then((res: any) => {            
                _this.loadNameMasterdata(res,callJsonHead2,"load_state","CState",4).then((res1: any) => {
                _this.setState(res1, () => {
                    if(_this.props.rootFlag === 2){
                        _this.loadListTableData();
                        return
                    }
                    else if(_this.props.rootFlag === 8){
                        _this.setState(res1);
                        return
                    }
                    else{
                        const callJsonHead1: any = { 
                            organizerID: userInfo["userId"],
                            flag_id: 0
                        }
                        _this.loadNameMasterdata(res1,callJsonHead1,"load_superstockist","CStockist",0).then((res2: any) => {
                            _this.setState(res2);
                            return 
                        })  
                    }
                });
                return
            });             
            })     
        }
    }
    loadOrgNameMasterdata= (resVals: any) => {
        try{
        $("#divLoading").attr("style", "display:block")
        return new Promise((resolve, reject) => {
            //mani 22/12
            //useAxios.get(config.Url("cmo_game","get"), config.authHeaderwithParam("OrgName~@#%&0"))
            apiHelper.getAPICall("cmo_game", "get",4,"OrgName~@#%&0")
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let OrganizerNamesArr: any = [];
                if(res.data !== null && res.data.length !== 0){
                    if(res.data["organizer_details"] !== undefined && res.data["organizer_details"] !== null && res.data["organizer_details"].length !== 0){                  
                    for(var prop of res.data["organizer_details"]){
                        OrganizerNamesArr.push({value: prop["dl_organizerid"],label: prop["dl_organizername"],userId: prop["dl_userid"]})
                    }
                }
                }         
                $("#divLoading").attr("style", "display:none") 
                //$("#templaTBlid").attr("style", "display:none") 
                resVals.cAgentHDrop.OrganizerNames = OrganizerNamesArr;
                resVals.cAgentHDrop.OrganizerName = {value: 0,display: false};
                resolve(resVals)
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none") 
                reject(err)
            })
        }); 
        }
        catch(ex) {
        alert("Master Data Load Failed..");
        return
        }
    }
    /* load game names */
    loadNameMasterdata = (initialState: any,callJsonHead: any,getDtlPoint: string,methodName: string,flag: number) => {
        try{
            $("#divLoading").attr("style", "display:block")
            return new Promise((resolve, reject) => {
                 //mani 22/12
              //  useAxios.get(config.Url(methodName,"get"), config.authHeaderwithParamRpt(callJsonHead))
               apiHelper.getAPICall(methodName, "get",5,callJsonHead)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    let fieldNamesArr: any = [];
                    if(res.data !== null && res.data.length !== 0){
                        if(res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0){                  
                        for(var prop of res.data[getDtlPoint]){
                            if(flag === 0)
                                fieldNamesArr.push({value: prop["in_sstockistid"] + "_" + prop["in_organizerid"],label: prop["in_sstockistname"],orgId: prop["in_organizerid"]})
                            else if(flag === 1)
                                fieldNamesArr.push({value: prop["in_stockistid"] + "_" + prop["in_organizerid"],label: prop["in_stockistname"],orgId: prop["in_organizerid"]})
                            else if(flag === 2)
                                fieldNamesArr.push({value: prop["in_gamegroupid"],label: prop["in_gamegroupdesc"]})                            
                            else if(flag === 4)
                                fieldNamesArr.push({value: prop["in_stateid"],label: prop["in_statename"],orgId: prop["in_organizerid"]})
                        }
                    }
                    }         
                    $("#divLoading").attr("style", "display:none")
                    fieldNamesArr = fieldNamesArr.length === 1 && fieldNamesArr[0].value.split('_')[0].toString() === "0" ? [] : fieldNamesArr;
                    if(flag === 0)
                        initialState.cAgentHDrop.stockistType = fieldNamesArr;
                    else if(flag === 1)
                        initialState.cAgentHDrop.sstockistType = fieldNamesArr;
                    else if(flag === 2){
                        initialState.cAgentHDrop.gameType = fieldNamesArr;
                        initialState.cAgentHDrop.gameTypeId = {value: 0,display: false};
                    }
                    else if(flag === 4){
                        initialState.cAgentHDrop.stateType = fieldNamesArr;
                        initialState.cAgentHDrop.stateTypeId = {value: 0,display: false};
                    }
                    //this.setState(initialState);
                    resolve(initialState);
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none") 
                    reject(null)
                }) 
            });
        }
        catch(ex) {
            alert("Load Master Data Failed..");
            return
        }
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):
        if (this.props.rootFlag !== prevProps.rootFlag) {            
            this.resetValues();
        }
    }
    resetValues =() => {
        const initialState: any = CAgentHmodel()
        const resVal: any = this.state;
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        initialState.cAgentHDrop.OrganizerNames = resVal.cAgentHDrop.OrganizerNames;
        initialState.cAgentHDrop.gameType = resVal.cAgentHDrop.gameType;
        initialState.cAgentHDrop.stateType = resVal.cAgentHDrop.stateType;
        initialState.cAgentHDrop.OrganizerName = {value: 0,display: false};
        initialState.cAgentHDrop.gameTypeId = {value: 0,display: false};
        initialState.cAgentHDrop.stateTypeId = {value: 0,display: false};
        this.setState(initialState, () => {
            if(this.props.rootFlag === 2){
                if(is_superuser)
                    this.setState(initialState);
                else
                    this.loadListTableData();
            }
            else if(this.props.rootFlag === 8){
                this.setState(initialState);
            }
            else{
                const _this: any = this;
                const callJsonHead1: any = { 
                    organizerID: is_superuser ? initialState.cAgentHDrop.OrganizerName.value : userInfo["userId"],
                    flag_id: 0
                }
                _this.loadNameMasterdata(initialState,callJsonHead1,"load_superstockist","CStockist",0).then((res2: any) => {
                    _this.setState(res2);
                    return 
                })  
            }
        });
        return
    }
    loadListTableData= (succText?: string) => {
    try{
        $("#divLoading").attr("style", "display:block")        
        const initialState: any = CAgentHmodel();
        initialState.cAgentHDrop = this.state.cAgentHDrop;
        initialState.cAgentHRadio = this.state.cAgentHRadio;
        this.setState(initialState,() => {
            this.loadListTableCallback(initialState,succText);
        });
        return       
    }
    catch(ex) {
      alert("Load List Failed..");
      return
    }
    } 
    loadListTableCallback= (resVal: any,succText: any) => {
        const windowWidth: number = $(window).width();
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        const callJsonData: any = this.props.rootFlag !== 10 ? {
            organizerID: userInfo["is_superuser"] !== null && userInfo["is_superuser"] ? resVal.cAgentHDrop.OrganizerName.value  : userInfo["userId"],
            super_stockistID: resVal.cAgentHDrop.stockistTypeId.value === null ? 0 : parseInt(resVal.cAgentHDrop.stockistTypeId.value.split('_')[0]),
            stockistID: resVal.cAgentHDrop.sstockistTypeId.value === null ? 0 : parseInt(resVal.cAgentHDrop.sstockistTypeId.value.split('_')[0]),
            stateID: resVal.cAgentHDrop.stateTypeId.value === null ? 0 : resVal.cAgentHDrop.stateTypeId.value,
            agent_status: this.props.rootFlag === 2 ? 0 : this.props.rootFlag === 4 ? 1 : this.props.rootFlag === 6 ? 2 : 3           
        } : {           
            organizerID: userInfo["is_superuser"] !== null && userInfo["is_superuser"] ? resVal.cAgentHDrop.OrganizerName.value  : userInfo["userId"],
            super_stockistID: resVal.cAgentHDrop.stockistTypeId.value === null ? 0 : parseInt(resVal.cAgentHDrop.stockistTypeId.value.split('_')[0]),
            stockistID: resVal.cAgentHDrop.sstockistTypeId.value === null ? 0 : parseInt(resVal.cAgentHDrop.sstockistTypeId.value.split('_')[0]),
            agent_type: 2, //resVal.cAgentHRadio,
            game_groupID: resVal.cAgentHDrop.gameTypeId.value                     
        };
        const callUrl: any = this.props.rootFlag === 10 ? "aa_AgentH" : "a_AgentH";
         //mani 22/12
        // useAxios.get(config.Url(callUrl,"get"), config.authHeaderwithParamRpt(callJsonData))
         apiHelper.getAPICall(callUrl, "get",5,callJsonData)
        .then((res) => {
            console.log("RESPONSE RECEIVED: ", res);
            let colDataArr: any = [];
            let rowDataArr: any = [];
            let errText: any = "";
            if(res !== null && res.data !== null && res.data.result === undefined){
                if(res.data.agent_info !== undefined && res.data.agent_info !== null && res.data.agent_info.length !== 0){
                    colDataArr.push({
                        Visible: false,
                        width: windowWidth > 800 ? 120 : 75,
                        Header: "Actions",
                        accessor: "Edit Actions",
                        //resizable: false,
                        sortable: false,
                        className: "wordwrap",
                        Cell: (row: any) => (
                        <div className="userLogBtnCont3" style={{padding: 0}}>							
                        {windowWidth > 800 ? <button className="BlueBg" id="registerBtn" style={{fontSize: "12px",padding: "7px"}} onClick={(e: any) => this.updateListTableRow(e, row.original)}><i className="fa fa-edit" style={{marginRight: "5px"}} aria-hidden="true"></i>Edit</button> :
                        <i className="fa fa-edit BlueC" style={{fontSize: "12px"}} aria-hidden="true" onClick={(e: any) => this.updateListTableRow(e, row.original)}></i>}	
                        </div> 
                        )
                    });
                    colDataArr.push({Visible: false,width: 40,Header: "#",accessor: "#",className: "wordwrap",Cell: (row: any) => { return <div style={{ textAlign: "right" }}>{row.index + 1}</div>; }});
                    let cntIndex: number = 0;
                    for(var prop of Object.keys(res.data["agent_info"][0])){
                        const headerTitle: string = CommonAgentH.getTableTitle((prop === "in_terminalid" || prop === "in_terminalname") && this.props.rootFlag === 8 ? (prop + "s") : prop);
                        const isVisible: boolean = prop === 'dl_organizerid' || prop === "in_ssid" || prop === "in_sid" || prop === "in_stateid" || prop ===  "in_imgbasedata" || prop ===  "dl_commissiontypeid" || prop ===  "dl_agenttypeid" || prop ===  "dl_gamegroupid"
                        || (this.props.rootFlag === 8 && (prop === "in_sstockistname" || prop === "in_stockistname"))
                        || (this.props.rootFlag === 2 && (prop === "in_stockistname" || prop === "in_terminalname" || prop === "in_terminalid"))
                        || (this.props.rootFlag === 10 && resVal.cAgentHRadio === 0 && (prop === "dl_terminalname" || prop === "dl_terminalid"))
                        || (this.props.rootFlag === 4 && (prop === "in_terminalname" || prop === "in_terminalid")) ? false : cntIndex === 0 ? true : false;
                        const isVisible1: boolean = prop === 'dl_organizerid' || prop === "in_ssid" || prop === "in_sid" || prop === "in_stateid" || prop ===  "in_imgbasedata" || prop ===  "dl_commissiontypeid" || prop ===  "dl_agenttypeid" || prop ===  "dl_gamegroupid"
                        || (this.props.rootFlag === 8 && (prop === "in_sstockistname" || prop === "in_stockistname"))
                        || (this.props.rootFlag === 2 && (prop === "in_stockistname" || prop === "in_terminalname" || prop === "in_terminalid"))
                        || (this.props.rootFlag === 10 && resVal.cAgentHRadio === 0 && (prop === "dl_terminalname" || prop === "dl_terminalid"))
                        || (this.props.rootFlag === 4 && (prop === "in_terminalname" || prop === "in_terminalid")) || cntIndex === 0 ? false : true;
                        
                        if((prop === "in_organizername" || prop === "dl_organizername" || prop === "in_pincode") && windowWidth > 600){
                            colDataArr.push({width: 100,Visible: isVisible1,Header: headerTitle,accessor: prop,
                                show: isVisible,className: "wordwrap",headerClassName: "wordwrap"})
                        }
                        else{
                            colDataArr.push({Visible: isVisible1,Header: headerTitle,accessor: prop,
                            show: isVisible,className: "wordwrap",headerClassName: "wordwrap"})
                        }

                        if(windowWidth > 800 && prop === (this.props.rootFlag === 2 ? "in_location" : this.props.rootFlag === 4 ? "in_address" : this.props.rootFlag === 8 ? "in_pincode" : this.props.rootFlag === 10 ? "dl_claimcommission" : "in_statename")){
                            cntIndex++;
                        }
                        else if(windowWidth < 600 && (prop === "in_organizername" || prop === "dl_organizername")){
                            cntIndex++;
                        }
                        else if(windowWidth > 600 && windowWidth <= 800 && prop === (this.props.rootFlag === 2 ? "in_contactnumber" : this.props.rootFlag === 8 ? "in_terminalname" : this.props.rootFlag === 10 ? "in_sstockistname" : "in_stockistname")){
                            cntIndex++;
                        }
                    }
                    colDataArr.push({
                        width: 50,
                        Header: "View",
                        accessor: "View",
                        //resizable: false,
                        sortable: false,
                        className: "wordwrap",
                        Cell: (row: any) => (
                        <div className="userLogBtnCont3" style={{padding: 0}}>	
                            <small>
                                {/* <img key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,row.index)}></img> */}
                                <i  key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="fa showPlusMinus fa-plus GreenBgg" style={{borderRadius: "100px",padding: "5px"}} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e,row.index)}></i>
                            </small>          
                            <div key={"tabPopup_" + row.index} id={"tabPopupC_" + row.index} className="graphPop4 graphPop-1">
                            <Tablistview keys={"tabPopupV_" + row.index} rowData={row.original} colData={this.state.aAgentH.colData} />
                            {/* <img alt="" src={TooltipData}></img>	 */}
                            </div>
                        </div>
                        )
                    });
                    rowDataArr = res.data["agent_info"];
                }
                else{
                    errText= "No Data Available";
                }
            }
            else{
                errText= res.data.result.toString();
            }        
            $("#divLoading").attr("style", "display:none") 
            //$("#templaTBlid").attr("style", "display:none") 
            let errMsg: string = "";
            let succFlag: any = undefined;
            if(succText !== undefined && succText !== ""){
                errMsg= succText;
                succFlag= 0;
            }
            else if(errText !== undefined && errText !== ""){
                errMsg= errText;
            }
            resVal.aAgentH.colData = colDataArr;
            resVal.aAgentH.tabData = rowDataArr;
            resVal.aAgentH.filteredData = [];
            resVal.aAgentH.searchInput = "";
            resVal.succFlag = succFlag;
            resVal.errMsg = errMsg;
            resVal.aAgentH.rowData = null;
            resVal.aAgentH.pageIndex = 1;
            this.setState(resVal);
            if((errText !== undefined && errMsg !== "") || (succText !== undefined && succText !== "")){
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn();
            }
            return
        })
        .catch((err) => {
            console.log("AXIOS ERROR: ", err.response);
            $("#divLoading").attr("style", "display:none") 
            this.setState({succFlag: undefined,errMsg: t('lblNodata')});
            $('.overlay').fadeIn();
            $('#lightBox2').fadeIn();
            return false
        }) 
    }
    updateListTable =(createGame: any) =>{
        if(createGame !== null){
            this.insertData(createGame);  
        }
        const resVal: any = this.state;
        resVal.aAgentH.pageIndex = 1;
        resVal.aAgentH.rowData = null
        this.setState(resVal);   
        return
    }
    updateListTableRow =(e: any,rowData: any) =>{
        e.preventDefault();  
        const resVal: any = this.state;
        resVal.aAgentH.pageIndex = 2;
        resVal.aAgentH.rowData = rowData
        this.setState(resVal); 
        return 
    }   
    onShowLightBox= (e: any, index: any) => {
        e.preventDefault();         
        const currDisplay: any = $('#tabPopupC_' + index).css('display');    
        // $('.fa.fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        // $('.fa-minus').addClass('fa-plus').removeClass('fa-minus');  
        $('.showPlusMinus').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        $('.graphPop4').css({display: "none"})   
        $('#tabPopupIC_' + index).attr('class', (currDisplay === "none" ? "fa showPlusMinus fa-minus RedBgg" : "fa showPlusMinus fa-plus GreenBgg"));      
        $('#tabPopupC_' + index).css({display: currDisplay === "none" ? "block" : "none"})  
        return 
    } 
    insertData= (rowData: any) => { 
    try{
        $("#divLoading").attr("style", "display:block")
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        const userIp: string = $("#userIp").text(); 
        const kycDetailArr: any = JSON.stringify({
            "aadharupload":[rowData.cAgentHOpt.imgAttach.name],
            "aadharName":[rowData.cAgentHOpt.imgAttach.aName],
            "aadharNo":[rowData.cAgentHOpt.imgAttach.aNo],
            "aadhardob":[rowData.cAgentHOpt.imgAttach.dob],
            "aadhargender":[rowData.cAgentHOpt.imgAttach.gender]
        });
        const jsonData: any = this.props.rootFlag === 10 ? {
            "organizerID": userInfo["is_superuser"] ? rowData.cAgentHDrop.OrganizerName.value : userInfo["userId"],
            "super_stockistID": rowData.cAgentHDrop.stockistTypeId.value === null ? 0 : parseInt(rowData.cAgentHDrop.stockistTypeId.value.split('_')[0]),
            "stockistID": rowData.cAgentHDrop.sstockistTypeId.value === null ? 0 : parseInt(rowData.cAgentHDrop.sstockistTypeId.value.split('_')[0]),
            "terminalID": rowData.cAgentHRadio === 0 || rowData.cAgentHDrop.terminalTypeId.value === null ? "" : rowData.cAgentHDrop.terminalTypeId.value.split('&$@')[0],
            "game_groupID": rowData.cAgentHDrop.gameTypeId.value,
            "sales_commission": parseFloat(rowData.ccAgentHMand.salesComm.name).toFixed(2),
            "claim_commission": parseFloat(rowData.ccAgentHMand.claimComm.name).toFixed(2),
            "effect_from" : moment(rowData.ccAgentHMand.startDate.name,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            "effect_to" : moment(rowData.ccAgentHMand.endDate.name,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00", 
            "created_by" : userInfo["userId"],
            "ipaddress" : userIp,
            "commission_type" : rowData.cAgentHDrop.commissionTypeId.value,
            "agent_type": 2 //rowData.cAgentHRadio,
        } : {
            "organizerID": userInfo["is_superuser"] ? rowData.cAgentHDrop.OrganizerName.value : userInfo["userId"],
            "terminalname": rowData.cAgentHMand.contactPerName.name,//used for all header's name field
            "terminalID": rowData.cAgentHOpt.contactPerId.name,
            "agent_status": this.props.rootFlag === 2 ? 0 : this.props.rootFlag === 4 ? 1 : this.props.rootFlag === 6 ? 2 : 3,
            "contact_number": rowData.cAgentHMand.contactPerNum.name,
            "address": rowData.cAgentHMand.address.name,
            "pincode": rowData.cAgentHMand.pinCode.name,
            "location": rowData.cAgentHMand.location.name,
            "kycDetail": kycDetailArr,
            "imgbaseData": rowData.cAgentHOpt.imgAttach.uri,
            "stateID": parseInt(rowData.cAgentHDrop.stateTypeId.value),
            "super_stockistID": rowData.cAgentHDrop.stockistTypeId.value === null ? 0 : parseInt(rowData.cAgentHDrop.stockistTypeId.value.split('_')[0]),
            "stockistID": rowData.cAgentHDrop.sstockistTypeId.value === null ? 0 : parseInt(rowData.cAgentHDrop.sstockistTypeId.value.split('_')[0]),
            "ipaddress": userIp
        };
        const callUrl: any = this.props.rootFlag === 10 ? "cc_AgentHPut" : "c_AgentHPut";     
          //mani 23/12
        //useAxios.put(config.Url(callUrl,"put"), jsonData, config.authHeader())
        apiHelper.putAPICall(callUrl, "put",2,jsonData)  
        .then((res) => {
            console.log("RESPONSE RECEIVED: ", res);
            if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
                $("#divLoading").attr("style", "display:none") 
                this.setState({errMsg: res.data.result.toString().replace('successfully',''),succFlag: undefined})
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn();
                return
            }
            else{
                this.loadListTableData(res.data.result.toString().replace('successfully',''));          
                return
            }
        })
        .catch((err) => {
            console.log("AXIOS ERROR: ", err.response);
            $("#divLoading").attr("style", "display:none")
            return 
        })   
    }
    catch(ex) {
      alert("Data Insertion Failed..");
      return
    }    
    }
    // And in your global search
    globalSearch = (e: any) => {
        try{
            const initialState: any = this.state;
            const searchInput: string = e.target.value;
            let filteredData = initialState.aAgentH.tabData.filter((value: any) => {
            return CommonAgentH.getKeyByValue(value, searchInput,this.props.rootFlag);
            });
            initialState.aAgentH.filteredData = filteredData;
            initialState.aAgentH.searchInput = searchInput;
            initialState.succFlag = undefined;
            initialState.errMsg = "";
            initialState.aAgentH.rowData = null;
            initialState.aAgentH.pageIndex = 1;
            this.setState(initialState);
            return
        }
            catch(ex) {
            alert("Search result Failed..");
            return
        }
    }; 
    // handleToggleClick =(e: any) => {
    //     try{
    //         e.preventDefault();
    //         const resVals: any = this.state;
    //         resVals.aAgentH.colData = [];
    //         resVals.aAgentH.tabData = [];
    //         resVals.aAgentH.filteredData = [];
    //         resVals.aAgentH.searchInput = "";
    //         resVals.succFlag = undefined;
    //         resVals.errMsg = "";
    //         resVals.aAgentH.rowData = null;
    //         resVals.aAgentH.pageIndex = 1;
    //         resVals.cAgentHRadio = resVals.cAgentHRadio === 1 ? 0 : 1;
    //         this.setState(resVals);
    //     }
    //     catch(ex) {
    //       alert("On Change Toggle button updation Failed..");
    //       return
    //     }
    // }
    /* Drop down change on Agent create */
    handleDropChange = (e: any,type: number) => {
        try{
            const _this: any = this;
            const resVals: any = _this.state;
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            resVals.succFlag = undefined;
            resVals.errMsg = "";
            resVals.aAgentH.colData = [];
            resVals.aAgentH.tabData = [];
            resVals.aAgentH.filteredData = [];
            resVals.aAgentH.searchInput = "";
            resVals.succFlag = undefined;
            resVals.errMsg = "";
            resVals.aAgentH.rowData = null;
            resVals.aAgentH.pageIndex = 1;
            switch(type){
                //mani 26/12
           // if(type === 1)
           case 1:
            {
                resVals.cAgentHDrop.OrganizerName.value = e.value;
                resVals.cAgentHDrop.OrganizerName.display = false;
                resVals.cAgentHDrop.stockistType = [];
                resVals.cAgentHDrop.stockistTypeId.value = null;
                resVals.cAgentHDrop.sstockistType= [];
                resVals.cAgentHDrop.sstockistTypeId.value = null;
                if(_this.props.rootFlag === 4 || _this.props.rootFlag === 6 || _this.props.rootFlag === 10){                 
                    const callJsonHead: any = { 
                        organizerID: resVals.cAgentHDrop.OrganizerName.value,
                        flag_id: 0
                    }
                    _this.loadNameMasterdata(resVals,callJsonHead,"load_superstockist","CStockist",0).then((res1: any) => {
                        _this.setState(res1);
                        return 
                    }) 
                }
                else{
                    _this.setState(resVals);
                    return
                } 
                break;           
            }
            //else if(type === 2)
            case 2:
            {
                 resVals.cAgentHDrop.gameTypeId.value = e.value;
                resVals.cAgentHDrop.gameTypeId.display= false;
                _this.setState(resVals);
                return
                 
            }   
            case 3:        
            //else if(type === 3)
             {
                resVals.cAgentHDrop.stockistTypeId.value = e.value;
                resVals.cAgentHDrop.stockistTypeId.display= false;
                resVals.cAgentHDrop.sstockistType= [];
                resVals.cAgentHDrop.sstockistType.value = null;    
                if(_this.props.rootFlag !== 6){
                    _this.setState(resVals);
                    return 
                }
                else{
                    const callJsonHead: any = { 
                        organizerID: userInfo["is_superuser"] ? resVals.cAgentHDrop.OrganizerName.value : userInfo["userId"],
                        super_stockistID: parseInt(resVals.cAgentHDrop.stockistTypeId.value.split('_')[0]),
                        flag_id: 0
                    }
                    _this.loadNameMasterdata(resVals,callJsonHead,"load_stockist","CSStockist",1).then((res1: any) => {
                        _this.setState(res1);
                        return 
                    })
                }
                break;
            }
            //else if(type === 4)
            case 4:
                {
                resVals.cAgentHDrop.sstockistTypeId.value = e.value;
                resVals.cAgentHDrop.sstockistTypeId.display= false;
                // if(resVals.cAgentHRadio === 1){
                //     const callJsonHead: any = { 
                //         organizerID: userInfo["is_superuser"] ? resVals.cAgentHDrop.OrganizerName.value : userInfo["userId"],
                //         super_stockistID: parseInt(resVals.cAgentHDrop.stockistTypeId.value.split('_')[0]),
                //         stockistID: parseInt(resVals.cAgentHDrop.sstockistTypeId.value.split('_')[0]),
                //         flag_id: 0
                //     }
                //     _this.loadNameMasterdata(resVals,callJsonHead,"load_terminal","CTerminal",3).then((res1: any) => {
                //         _this.setState(res1);
                //         return 
                //     })
                // }
                _this.setState(resVals);
                return 
            }
            //else if(type === 5)
            case 5:
            {
                resVals.cAgentHDrop.stateTypeId.value = e.value;
                resVals.cAgentHDrop.stateTypeId.display= false;
                _this.setState(resVals);
                return 
            }
        }
    }
        catch(ex) {
            alert("On Dropdown Change Failed..");
            return
        }
    } 
    onFilterListTableData = (e: any) => {
        try{
        e.preventDefault(); 
        const resVal: any = this.state;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        let isErrData: boolean = false;
        if(userInfo["is_superuser"] && resVal.cAgentHDrop.OrganizerName.value === null){
            resVal.cAgentHDrop.OrganizerName.display = true
            isErrData = true
        }
        if(this.props.rootFlag !== 2){
            if(this.props.rootFlag === 10 && resVal.cAgentHDrop.gameTypeId.value === null){ //drop down validation
                resVal.cAgentHDrop.gameTypeId.display = true
                isErrData = true
            }
            if((this.props.rootFlag === 4 || this.props.rootFlag === 6 || this.props.rootFlag === 10) && resVal.cAgentHDrop.stockistTypeId.value === null){ //drop down validation
                resVal.cAgentHDrop.stockistTypeId.display = true
                isErrData = true
            }
            if(this.props.rootFlag === 8 && resVal.cAgentHDrop.stateTypeId.value === null){ //drop down validation
                resVal.cAgentHDrop.stateTypeId.display = true
                isErrData = true
            }
            if(this.props.rootFlag === 6 && resVal.cAgentHDrop.sstockistTypeId.value === null){ //drop down validation
                resVal.cAgentHDrop.sstockistTypeId.display = true
                isErrData = true
            }  
        }  
        if(isErrData){
            $("#divLoading").attr("style", "display:none") 
            //resVal.errMsg = "Invalid data..!"
            this.setState(resVal);
            // $('.overlay').fadeIn();
            // $('#lightBox2').fadeIn(); 
            return 
        }else{
            if(resVal.aAgentH.tabData !== undefined && resVal.aAgentH.tabData.length > 0){
                $("#divLoading").attr("style", "display:none") 
                resVal.errMsg = "Page already rendered..!"
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn();
                return;
            }
            else{
                this.loadListTableData();    
                return
            }
        }
        }
        catch(ex) {
        alert("Load List Failed..");
        return
        }
    }
    render() {
    const { t } = this.props;
    const resVal: any = this.state;
    const pageSize: number = resVal.aAgentH.tabData !== undefined && resVal.aAgentH.tabData.length > 50 ? 50 : resVal.aAgentH.tabData.length > 25 ? 25 : 10;
    const DropdownIndicator = (props: any) => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <FontAwesomeIcon style={{fontSize: "20px"}} icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>
            </components.DropdownIndicator>
          )
        );
      };
    const styles = {
        menu: (base: any) => ({
            ...base,
            marginTop: 0
        })
    };
    let is_superuser: any = null;
    const userInfoString: any = sessionStorage.getItem('userInfo');
    const userInfo: any = JSON.parse(userInfoString);
    if(userInfo !== null)
        is_superuser = userInfo["is_superuser"];
    const headerTitle: any = CommonAgentH.getPageTitle(this.props.rootFlag);
      return(
        resVal.aAgentH.pageIndex === 1 ?
        (<div className="ContainerChart scrollbar" style={{height: $(window).height() - 60 + "px"}} onClick={(e: any) => config.onBodyClick(e)}>        
            <Lightbox errMsg= {resVal.errMsg} succFlag= {resVal.succFlag} />            
            <div className="contentclouds">					
            <div className="userLogHead">                
                <div className="userLogHeadinner">
				<h2>{headerTitle}</h2>
				<small>{t('lblHierarchyDetail')}</small>
			    </div>
                <Breadcrumb Menu= "Agent Hierarchy" subMenu= {headerTitle} />       					
            </div>
            <div className="UseformNew">
             {this.props.rootFlag !== 2 ?
             (<div className="Dateform">	
             {/* {this.props.rootFlag === 10 ? 
                (<div className="toogleSwitch">
                    <label className="toggleSwitch nolabel" onClick={(e: any) => this.handleToggleClick(e)}>
                        <input type="checkbox" id="#toggleIds" checked={resVal.cAgentHRadio === 0} onChange={(e: any) => this.handleToggleClick(e)} />
                        <span>
                            <span>Terminal</span> 
                            <span>Agent</span>                                   
                        </span>
                        <a href="#section"> </a>
                    </label>
                </div>) : null}										 */}
             <div className="userLogformTop">
                 <ul>  
                    {is_superuser !== null && is_superuser ? (
                    <li style={{width: "33.33333%"}}>                        
                        <div className="userLogCloudtop">
                            <Select
                            value={resVal.cAgentHDrop.OrganizerName.value === null ? null : resVal.cAgentHDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.OrganizerName.value.toString())}
                            onChange={(e: any) => {this.handleDropChange(e, 1)}}
                            //isDisabled={this.props.rowData === null ? false : true}
                            placeholder='Select Organizer Name'
                            options={resVal.cAgentHDrop.OrganizerNames}
                            // maxMenuHeight={110}
                            className="userlogSelect"
                            components={{ DropdownIndicator }}
                            styles={styles}
                            />
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.cAgentHDrop.OrganizerName.display ? "block" : "none"}}>{t('lblOrgNameProvided')}</label>
                        </div>
                    </li>) : null}  
                    {this.props.rootFlag === 10 ?                   
                    (<li style={{width: "33.33333%"}}>
                         <div className="userLogCloudtop">
                        <Select
                        value={resVal.cAgentHDrop.gameTypeId.value === null ? null : resVal.cAgentHDrop.gameType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.gameTypeId.value.toString())}
                        onChange={(e: any) => this.handleDropChange(e,2)}
                        placeholder='Select Game Type'
                        options={resVal.cAgentHDrop.gameType}
                        // maxMenuHeight={110}
                        className="userlogSelect"
                        components={{ DropdownIndicator }}
                        styles={styles}
                        />
                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.gameTypeId.display ? "block" : "none"}}>{t('lblGameProvided')}</label>
                         </div>
                     </li>) : null}
                    {this.props.rootFlag !== 8 ?             
                    (<li style={{width: "33.33333%"}}>
                        <div className="userLogCloudtop">
                        <Select
                        value={resVal.cAgentHDrop.stockistTypeId.value === null ? null : resVal.cAgentHDrop.stockistType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.stockistTypeId.value.toString())}
                        onChange={(e: any) => this.handleDropChange(e,3)}
                        placeholder='Select Stockist Name'
                        options={resVal.cAgentHDrop.stockistType}
                        // maxMenuHeight={110}
                        className="userlogSelect"
                        components={{ DropdownIndicator }}
                        styles={styles}
                        />
                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.stockistTypeId.display ? "block" : "none"}}>{t('lblStockistNameProvided')}</label>
                        </div>
                    </li>) :
                    (<li style={{width: "33.33333%"}}>
                        <div className="userLogCloudtop">
                        <Select
                        value={resVal.cAgentHDrop.stateTypeId.value === null ? null : resVal.cAgentHDrop.stateType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.stateTypeId.value.toString())}
                        onChange={(e: any) => this.handleDropChange(e,5)}
                        placeholder='Select State Name'
                        options={resVal.cAgentHDrop.stateType}
                        // maxMenuHeight={110}
                        className="userlogSelect"
                        components={{ DropdownIndicator }}
                        styles={styles}
                        />
                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.stateTypeId.display ? "block" : "none"}}>{t('lblStateNameProvided')}</label>
                        </div>
                    </li>)}
                    {this.props.rootFlag === 6 ?                   
                    (<li style={{width: "33.33333%"}}>
                         <div className="userLogCloudtop">
                         <Select
                            value={resVal.cAgentHDrop.sstockistTypeId.value === null ? null : resVal.cAgentHDrop.sstockistType.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.sstockistTypeId.value.toString())}
                            onChange={(e: any) => this.handleDropChange(e,4)}
                            placeholder='Select Sub Stockist Name'
                            options={resVal.cAgentHDrop.sstockistType}
                            // maxMenuHeight={110}
                            className="userlogSelect"
                            components={{ DropdownIndicator }}
                            styles={styles}
                            />
                            <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cAgentHDrop.sstockistTypeId.display ? "block" : "none"}}>{t('lblSubStockistProvided')}</label>
                         </div>
                     </li>) : null}                    
                 </ul>
             </div>            
             <div className="userLogBtnTop">							
                 <button id="registerBtn" onClick={(e: any) => this.onFilterListTableData(e)}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblSubmit')}</button>		
                 <button id="registerBtn1" style={{float: "right"}} onClick={this.resetValues.bind(this)}><i className="fa fa-sync" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblReset')}</button>		
             </div>            
             </div>) : is_superuser !== null && is_superuser ?
            (<div className="Dateform">										
            <div className="userLogformTop">
                <ul>  
                    <li style={{width: "33.33333%"}}>                        
                        <div className="userLogCloudtop">
                            <Select
                            value={resVal.cAgentHDrop.OrganizerName.value === null ? null : resVal.cAgentHDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.cAgentHDrop.OrganizerName.value.toString())}
                            onChange={(e: any) => {this.handleDropChange(e, 1)}}
                            //isDisabled={this.props.rowData === null ? false : true}
                            placeholder='Select Organizer Name'
                            options={resVal.cAgentHDrop.OrganizerNames}
                            // maxMenuHeight={110}
                            className="userlogSelect"
                            components={{ DropdownIndicator }}
                            styles={styles}
                            />
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.cAgentHDrop.OrganizerName.display ? "block" : "none"}}>{t('lblOrgNameProvided')}</label>
                        </div>
                    </li>
                </ul>	
            </div>
            <div className="userLogBtnTop">							
                 <button id="registerBtn" onClick={(e: any) => this.onFilterListTableData(e)}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblSubmit')}</button>		
                 <button id="registerBtn1" style={{float: "right"}} onClick={this.resetValues.bind(this)}><i className="fa fa-sync" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblReset')}</button>		
            </div> 
            </div>) : null}
            {resVal.aAgentH.tabData !== undefined && resVal.aAgentH.tabData.length > 0 ? 
            (<div className="ContainerTable">
				{/* <div className="searckLink">
						<div className="">   
							<span id="succText"></span>							
						</div>
                </div> */}
                <div className="tableSearch">
						<div className="sportSrch">
							<input type="text" placeholder="Search here..!" id="myInput" value={resVal.aAgentH.searchInput} onChange={(e: any) => this.globalSearch(e)} autoComplete="off" />
						</div>
                </div>
			</div>) : null}	
			{resVal.aAgentH.tabData !== undefined && resVal.aAgentH.tabData.length > 0 ? 
            (<div className="TableReact">            
                <ListTable
                sorting={true}
                columns={resVal.aAgentH.colData}
                pageSize={pageSize}
                data={resVal.aAgentH.filteredData && resVal.aAgentH.searchInput !== "" ? resVal.aAgentH.filteredData : resVal.aAgentH.tabData}           
                />
            </div>) : this.props.rootFlag === 2 && is_superuser !== null && !is_superuser ? 
            (<div className="TableReact"><span className="noDataCenter">No Data Available</span></div>): null} 
            </div>
            </div>
        </div>) : this.props.rootFlag === 10 ?
        (<CCAgentH backClick={this.updateListTable.bind(this)} rootFlag={9} rowData={resVal.aAgentH.rowData} />) :
        (<CAgentH backClick={this.updateListTable.bind(this)} rootFlag={this.props.rootFlag === 2 ? 1 : this.props.rootFlag === 4 ? 3 : this.props.rootFlag === 6 ? 5 : 7} rowData={resVal.aAgentH.rowData} />)                    
    );
  }
}

export default withTranslation()(AAgentH)