//Export
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import exportFromJSON from 'export-from-json' 
import { t } from "i18next";

//use for show the report header's
const getPageTitle =(rootFlag: number) => {
    switch(rootFlag){
        case 1:
            return t('lblSchemeInput')
        case 2:
            return t('lblViewScheme')
        case 3:
            return t('lblResultInput')
        case 4:
            return t('lblWinnerProcess')
        case 5:
            return t('lblCompareWinner') 
        case 6:
            return t('lblTransValidate')
        case 7:
            return t('lblWinningValidate')
        default:
            return ""
    }
}
const getTableTitle =(header: string) => {
    switch(header){
        case "rank":
        case "dl_rank":
            return "Rank"
        case "noofwinner":
        case "dl_noofwinner":
            return "No of Winners"
        case "totprize":
        case "dl_totprize":
            return "Total Price Amt."
        case "winningno":
            return "Winning No"
        case "dl_organizername":
            return "Org. Name"
        case "dl_gametypename":
            return "Game Type"
        case "dl_ticketno":
        case "tickenumber":
            return "Ticket No."
        case "dl_gameid":
            return "Game Id"
        case "dl_gamename":
            return "Game Name"
        case "dl_drawdate":
            return "Draw Date"
        case "dl_drawtime":
            return "Draw Time"
        case "dl_winnumber":
            return "Winning No's"
        case "dl_machno":
            return "Machine No's"
        case "dl_status":
        case "status":
            return "Status"
        case "in_sno":
            return "#"
        case "dl_schemeid":
            return "Scheme Id"
        case "selectedno":
        case "dl_selectedno":
            return "Selected No."
        case "matchno":
        case "dl_matchno":
            return "Match No."
        case "prizeamount":
        case "dl_prizeamount":
            return "Price Amt."
        case "dl_schemename":
            return "Scheme Type"
        case "dl_transactionid": 
            return "TXN ID"
        case "dl_auditwinamount":
            return "Auditor Winning Amt."
        case "dl_winamount":
            return "Winner list Winning Amt."
        case "dl_transamount": 
            return "Trans Amount"
        case "dl_transdatetime": 
            return "Trans DateTime"
        case "dl_claimdatetime": 
            return "Claimed DateTime"
        case "dl_info": 
            return "Ticket Info"
        default:
            return header
    }
}
// Table based- Filter Data (Search option)
const getHeaderKeyArr = (rootFlag: number,headerFlag?: number) => {
      switch(rootFlag){
        //mani 26/12
    //if(rootFlag === 2)
    case 2:
        {
        return headerFlag === 1 || headerFlag === 2 ? ['dl_schemeid','dl_selectedno','dl_matchno','dl_prizeamount','dl_schemename'] :
        ['dl_schemeid','dl_rank','dl_noofwinner','dl_prizeamount','dl_totprize'];
    }
     //else if(rootFlag === 4)
    case 4:
        {
        return headerFlag === 1 ? ['dl_gamename','dl_drawdate','dl_drawtime','dl_winnumber','dl_machno'] :
        headerFlag === 2 ? ['dl_gamename','dl_drawdate','dl_drawtime','dl_winnumber'] :
        ['dl_gamename','dl_drawdate','dl_drawtime','dl_rank','dl_winnumber'];
    }
    //else if(rootFlag === 5)
    case 5: {
        return ['dl_transactionid','dl_auditwinamount','dl_winamount','status'] 
       }
   // else if(rootFlag === 6)
   case 6:{
        return ['dl_transactionid','dl_info','dl_transamount','dl_transdatetime'] 
    }
    //else if(rootFlag === 7)
    case 7:{
        return ['dl_transactionid','dl_info','dl_winamount','dl_claimdatetime'] 
    }
    //else if(rootFlag === 1)
    case 1:{
        return ["selectedno","matchno","prizeamount","rank","noofwinner","totprize"]
    }
    //else if(rootFlag === 3)
    case 3:{
        return ["rank","winningno"]
    }
     default :{
        return []
    }
      }
}
// Table based- Filter Data (Search option)
const getKeyByValue = (objRes: any, searchInput: string,rootFlag: number,headerFlag?: number) =>{
    for(var prop of getHeaderKeyArr(rootFlag,headerFlag)) {
        if (objRes.hasOwnProperty(prop)) {
            if(objRes[prop].toString().toLowerCase().includes(searchInput.toLowerCase()))
                return true;
        }
    }
}
//use for PDF-1 and Print Preview (Header)
const getHeaderArr = (rootFlag: number,headerFlag?: number) => {
    switch(rootFlag){
         //mani 26/12
    //if(rootFlag === 2)
     case 2:{
        return headerFlag === 1 || headerFlag === 2 ? ["#","Scheme Id","Selected No.","Match No.","Price Amt.","Scheme Type"] :
        ["#","Scheme Id","Rank","No of Winners","Price Amt.","Total Price Amt."];  
    }
    //else if(rootFlag === 4)
    case 4:{
        return headerFlag === 1 ? ["#","Game Name","Draw Date","Draw Time","Winning No's","Machine No's"] :
        headerFlag === 2 ? ["#","Game Name","Draw Date","Draw Time","Winning No's"] :
        ["#","Game Name","Draw Date","Draw Time","Rank","Winning No's"]; 
    }
    //else if(rootFlag === 5)
    case 5:{
        return ["#","TXN ID","Auditor Winning Amt.","Winner list Winning Amt.","Status"]
    }
    //else if(rootFlag === 6)
    case 6:{
        return ["#","TXN ID","Ticket Info","Trans Amount","Trans DateTime"]
    }
    //else if(rootFlag === 7){
    case 7:{
        return ["#","TXN ID","Ticket Info","Winner list Winning Amt.","Claimed DateTime"]
    }
    default:{
        return []
    }
}
}
//use for PDF-2 (Field Datas)
const getTableData = (rootFlag: number,tabData: any,headerFlag?: number) => {
    switch(rootFlag){
    //if(rootFlag === 2)
    //mani 26/12
    case 2:{
        return tabData.map((elt: any)=> {
            return headerFlag === 1 || headerFlag === 2 ? [elt.in_sno === undefined ? "" : elt.in_sno,
                elt.dl_schemeid,elt.dl_selectedno,elt.dl_matchno,elt.dl_prizeamount,elt.dl_schemename] :
                [elt.in_sno === undefined ? "" : elt.in_sno,
                elt.dl_schemeid,elt.dl_rank,elt.dl_noofwinner,elt.dl_prizeamount,elt.dl_totprize];
        });
    }
    //else if(rootFlag === 4){
        case 4:{
        return tabData.map((elt: any)=> {
            return headerFlag === 1 ? [elt.in_sno === undefined ? "" : elt.in_sno,
                elt.dl_gamename,elt.dl_drawdate,elt.dl_drawtime,elt.dl_winnumber,elt.dl_machno] :
                headerFlag === 2 ?  [elt.in_sno === undefined ? "" : elt.in_sno,
                elt.dl_gamename,elt.dl_drawdate,elt.dl_drawtime,elt.dl_winnumber] :
                [elt.in_sno === undefined ? "" : elt.in_sno,
                elt.dl_gamename,elt.dl_drawdate,elt.dl_drawtime,elt.dl_rank,elt.dl_winnumber];
        });
    }
    //else if(rootFlag === 5){
        case 5:{
        return tabData.map((elt: any)=> {
            return [elt.in_sno === undefined ? "" : elt.in_sno,
            elt.dl_transactionid,elt.dl_auditwinamount,elt.dl_winamount,elt.status]
        });
    }
    //else if(rootFlag === 6){
        case 6:{
        return tabData.map((elt: any)=> {
            return [elt.in_sno === undefined ? "" : elt.in_sno,
            elt.dl_transactionid,elt.dl_info,elt.dl_transamount,elt.dl_transdatetime]
        });
    }
    //else if(rootFlag === 7){
        case 7:{
        return tabData.map((elt: any)=> {
            return [elt.in_sno === undefined ? "" : elt.in_sno,
            elt.dl_transactionid,elt.dl_info,elt.dl_winamount,elt.dl_claimdatetime]
        });
    }
    default:{
        return tabData}
}
}
/* Export PDF */
const exportPDF= (tabData: any,pageTitle: string,fileName: string,rootFlag: number,headerFlag?: number) => {
    try{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = pageTitle;
        const headers = [getHeaderArr(rootFlag,headerFlag)];    
        const data = getTableData(rootFlag,tabData,headerFlag); //tabData.map((elt: any)=> [elt.name, elt.profession]);
    
        let content = {
          startY: 50,
          head: headers,
          body: data,
          didParseCell: function (table: any) {
            if (table.section === 'head') {
              table.cell.styles.fillColor = [73,80,87];
              table.cell.styles.textColor = 255;
              table.cell.styles.fontStyle = "bold";
              table.cell.styles.fontSize = 10;
            //   table.cell.styles.lineColor = 169;
            //   table.cell.styles.lineWidth = 1;
            }
            if(table.section === 'body') {
                if(table.row.cells[1].text[0] === "Sub Total"){
                    table.cell.styles.fillColor = 200;
                    table.cell.styles.textColor = 51;
                    table.cell.styles.fontStyle = "bold";
                    table.cell.styles.fontSize = 8;
                }
                else if(table.row.cells[1].text[0] === "Grand Total"){
                    table.cell.styles.fillColor = 136;
                    table.cell.styles.textColor = 255;
                    table.cell.styles.fontStyle = "bold";
                    table.cell.styles.fontSize = 10;
                }
                else{
                    table.cell.styles.fillColor = 245;
                    table.cell.styles.textColor = 51;
                    table.cell.styles.fontStyle = "normal";
                    table.cell.styles.fontSize = 8;
                }
                // table.cell.styles.lineColor = 204;
                // table.cell.styles.lineWidth = 1;
            }
         }
        };

        doc.text(title, marginLeft, 40);
        autoTable(doc, content)
        doc.save(fileName + ".pdf")
        }
        catch(ex) {
        alert("Export PDF Failed..");
        return
        }
}
/* Export Excel XLS/CSV */
const exportExcel = (Orgdata: any,fileName: any,fileType: any,rootFlag: number,headerFlag?: number) =>{
    exportFromJSON({ data: Orgdata, fileName: fileName, exportType: fileType,
        beforeTableEncode: (tableRow: any) => {
            var element = tableRow[tableRow.length - 1];
            tableRow.splice(tableRow.length - 1, 1);
            tableRow.splice(0, 0, element);
            for(let indx = 0; indx < tableRow.length; indx++){
                if(tableRow[indx].fieldName === 'dl_flagid' || tableRow[indx].fieldName === 'dl_gametypeid' || tableRow[indx].fieldName === 'dl_organizerid' || tableRow[indx].fieldName === "dl_gametypename" || tableRow[indx].fieldName === "dl_organizername"
                || (rootFlag === 4 && headerFlag !== 3 && tableRow[indx].fieldName === "dl_rank")
                || (rootFlag === 4 && (headerFlag === 2 || headerFlag === 3) && tableRow[indx].fieldName === "dl_machno")
                || (rootFlag === 2 && ((headerFlag === 3 && (tableRow[indx].fieldName === "dl_selectedno" || tableRow[indx].fieldName === "dl_matchno" || tableRow[indx].fieldName === "dl_schemename")) 
                || (headerFlag !== 3 && (tableRow[indx].fieldName === "dl_rank" || tableRow[indx].fieldName === "dl_noofwinner" || tableRow[indx].fieldName === "dl_totprize"))))
                ){
                    tableRow.splice(indx, 1);
                    indx = indx - 1;
                }
                else
                    tableRow[indx].fieldName = getTableTitle(tableRow[indx].fieldName);
            }
            return tableRow;
        }
    })
}
/* Export Print preview */
const exportPrint = (Orgdata: any,pageTitle: any,rootFlag: number,headerFlag?: number) => {
    try{
        var mywindow: any = window.open('', 'GIMARES', 'height=400,width=600');
        mywindow.document.write('<html><head><title>GIMARES</title><style>table, th, td{border: 1px solid #ccc !important;border-collapse: collapse;}.header{font-weight: bold;}.header1{font-weight: bold;text-align: center;}</style>');
       // mywindow.document.write('<link rel="stylesheet" href=' + dataleadcss + ' type="text/css" />');
        mywindow.document.write('</head><body><div class="header1">' + pageTitle + '</div><br /><table>');
        let tagData: any = "";
        tagData += "<tr>"  
        getHeaderArr(rootFlag,headerFlag).forEach((key: any) => {
            tagData += ("<th>" + key +"</th>")
        }) 
        tagData += "</tr>"
        Orgdata.map(function(obj: any) {
            tagData += "<tr>"                 
            getHeaderKeyArr(rootFlag,headerFlag).forEach((key: any,index: number) => {
                if(index === 0)
                    tagData += ("<td>" + (obj.in_sno === undefined ? "" : obj.in_sno) +"</td>");   
                tagData += ("<td>" + (obj[key] === undefined ? "" : obj[key])  +"</td>")
            }) 
            tagData += "</tr>"
            return obj;              
        });         
        mywindow.document.write(tagData + '</table></body></html>');
        mywindow.print();
        mywindow.close();
        return
    }
    catch(ex) {
      alert("Export Print Failed..");
      return
    }
}
const CommonAuditTrail = { exportPDF: exportPDF,exportExcel: exportExcel,exportPrint: exportPrint, getPageTitle: getPageTitle,getTableTitle: getTableTitle,getKeyByValue: getKeyByValue };

export default CommonAuditTrail;