import React from "react";
import apiHelper from "../../../utils/ApiHelper"
import { Line } from "react-chartjs-2";
import useAxios from "axios";
import axios from 'axios';
import moment from "moment"
import config from "../../../utils/config";
import { t } from 'i18next';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
var $ = require('jquery')

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

export interface IProps {
    // pupUpOpenClick: any,
    // flog: number //to show date or hour based report
     startdate:any,
     enddate:any,
     gameID:any,
     organizername:any

}
 export interface IState {
    //createOrganizer: any
    chartData: any,
    myTitle:any
}


 const data:any ={
labels:["10AM","11AM","12PM","1PM","2PM","3PM","4PM"],
datasets:[{
    label:"WEB",
    data:[0,80,50,70,0,60,75],
    backgroundColor: '#e778fd',//purple
    borderColor:"#9b6d9b",
    tension: 0.2,
    pointStyle: 'circle',
    pointRadius: 4,
},{
    label:"USSD",
    data:[10,20,30,50,60,80,90],
    backgroundColor: ' #f91919',//orange
    borderColor:" #a55433",
    tension: 0.2,
    pointStyle: 'circle',
    pointRadius: 4,

},{
    label:"POS",
    data:[60,20,60,50,10,60,50],
    backgroundColor: '#faa200',//yellow
    borderColor:"#bd964e",
    tension: 0.2,
    pointStyle: 'circle',
    pointRadius: 4,

}]  

}


const options: any = {
    responsive: true,
    hoverRadius: 8,
    hoverBackgroundColor: '#ae359f',
    interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x'
    },
   plugins:{
     legend: {           
            position: 'top',
            // labels: {
            //     color:"#000000" ,
            // }
           
        },
        //   title: {
        //     display: true,
        //     text: 'ChannelWiseSale'
        //     }
    },
    
    scales:{
        x:{    
               title: {
                display: true,
                text:  "Time",
            }
        },
        Y:{
           
            title: {
                display: true,
                text:  "Amount",
                  
            }
        }
    }

};
//Channel-Wise-Sale
class LineChannelWiseSale extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            chartData: { labels: [], datasets: [] } ,            
            myTitle: "Provider Wise Sales By Day"
        };
        options.onClick = this.handleClick;
    }

     initData = () => {
        const Gameid = typeof (this.props.gameID) === 'string' ? (this.props.gameID).split('_')[0] : 0;
        //_fg=> 0-datebased|1-houre based
        const callJsonHead: any = {
            organizerID: 0,
            FromDate: moment(this.props.startdate).format('YYYY-MM-DD') + " 00:00:00",
            ToDate:moment(this.props.enddate).format('YYYY-MM-DD') + " 00:00:00",            
            GameId: Gameid,
            Status:0  //0-daywise|1-week wise
        }
        //mani 22/12
        //useAxios.get(config.Url("Channel-Wise-Sale", "get"), config.authHeaderwithParamRpt(callJsonHead))
        apiHelper.getAPICall("Channel-Wise-Sale", "get",5,callJsonHead)
             .then((res)=> {
                console.log("RESPONSE RECEIVED: Channel By Hour", res);
              if (res !== null && res.data !== null && res.data.result === undefined) {
                    let xAxis: any = [];
                    let ydatasets: any = [];
                    let tempArr: any = [];
                    let yname: number = 0;
                    let colDataArr: any = res.data["get_rptDetails"]; 
                   
                    //get all x-axis unique vaule 
                  //  xAxis = [...colDataArr.map((item: any) => item.in_drawdate)];
                      xAxis =  colDataArr.map((item:any) =>item.in_hour).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    //get all y-axis unique vaule 
                    //const yAxis = [...new Map(colDataArr.map((item: any) => item.in_channelname))];
                    const yAxis =  colDataArr.map((item:any) => item.in_channelname).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    yAxis.forEach(( fobj: any) => {
                        xAxis.forEach((fdate: any) => {
                            const objLst = colDataArr.filter((item: any) => { return item.in_channelname == fobj && item.in_hour == fdate });
                            if (objLst.length > 0)
                                tempArr.push(objLst[0].in_betamount);
                            else
                                tempArr.push(0);
                        });

                        var color = Math.floor(Math.random() * 16777216).toString(16);
                        var colour1= '#000000'.slice(0, -color.length) + color; 
                        ydatasets.push(
                            {
                                label: fobj,//yAxis[yname],
                                fill: false,
                                backgroundColor: colour1,
                                borderColor: colour1,   
                                data: tempArr,
                                tension: 0.2,
                                pointStyle: 'circle',
                                pointRadius: 4,
                            })
                            yname++;
                        tempArr = [];
                    })
                    this.setState({ chartData: { labels: xAxis, datasets: ydatasets } });
                     return  { labels: xAxis, datasets: ydatasets } ;
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                return  { labels:[], datasets:[] } ;
            })
    }

    componentDidMount(): void {
        this.initData();
        return;
        $("#myChart").click(function (this: any, e: any, i: any) {
            alert("er");
            // var firstPoint = this.getElementAtEvent(e)[0];
            //let inx: any = i[0].index;
            //let ds = dataaaa2.labels[inx];
            //alert(ds);
            //   var activePoints = myNewChart.getSegmentsAtEvent(evt);           
            //     /* do something */
        }
        );
    }
    componentDidUpdate(prevProps:any) {
        if (prevProps.startdate !== this.props.startdate) {
            this.initData()
        }
      }
    handleClick = (e: any, i: any) => {
        debugger;
        let inx: any = i[0].index;
         let ds =  this.state.chartData.labels[inx];
         var obj = {
             Id: ds,
             fromDate: "",
             toDate: ""
         }
       // this.props.pupUpOpenClick(obj);
    }
    render(): React.ReactNode {
     return(
        <div>
            <h4>{t('lblChannelSaleHour')}</h4>
        {/* <Line options={options}  data={data} /> */}
        <Line options={options}  data={this.state.chartData} /> 
         </div>
    ) 
   }
 };
 export default LineChannelWiseSale;