import React from 'react';
import useAxios from "axios";
import moment from "moment"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// import { green } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';

import COrganizer from "./c_organizer"
import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import ListTable from "../Shared/listtable";
import Breadcrumb from "../Shared/breadcrumb";
import CommonOrganizer from './Child/c_organizecommon';
import Tablistview from "../Shared/tablistview";
import COrganizermodel from "./Child/c_organizemodel";
// import TooltipData from "../../assets/img/icons/Arrow_new.png"
// import PlusIcon from "../../assets/img/icons/Plus_Icon.png"
// import MinusIcon from "../../assets/img/icons/Minus_Icon.png"
import { withTranslation  } from 'react-i18next';
import { t } from 'i18next';
import apiHelper from '../../utils/ApiHelper';
import CommonReports from '../Reports/Child/c_reportscommon';

var $ = require('jquery')

export interface IProps {
    rootFlag: number,
    t:any
}
export interface IState {
    tabData: any,
    colData: any,
    filteredData: any,
    searchInput: string,
    succFlag: any,
    errMsg: string,
    pageIndex: number,
    rowData: any,
    drawDate: any
}

  class AOrganizer extends React.Component<IProps, IState> {
  // ------------------------------------------^
    constructor(props: IProps) {
      super(props);  
      this.state = COrganizermodel().aOrganizer;
      this.onSubmitData = this.onSubmitData.bind(this);
      this.globalSearch = this.globalSearch.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);
      this.onFilterListTableData = this.onFilterListTableData.bind(this);
    }
    componentDidMount(){
        // if(this.props.rootFlag !== 1){
        //     this.loadListTableData(this.state.drawDate);
        // }
        // else{
        //     const initialState: any = COrganizermodel().aOrganizer
        //     this.setState(initialState);       
        // } 
        const initialState: any = COrganizermodel().aOrganizer
        this.setState(initialState,() => { 
            if(this.props.rootFlag !== 1)
                this.loadListTableData(this.state.drawDate); 
        });
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):
        if (this.props.rootFlag !== prevProps.rootFlag) {            
            // if(this.props.rootFlag !== 1){
            //     this.loadListTableData(this.state.drawDate);
            // }
            // else{
            //     const initialState: any = COrganizermodel().aOrganizer
            //     this.setState(initialState);       
            // }  
            this.resetValues();
        }
    }
    resetValues =() => {
        const initialState: any = COrganizermodel().aOrganizer
        this.setState(initialState,() => { 
            if(this.props.rootFlag !== 1)
                this.loadListTableData(this.state.drawDate); 
        });
    }
    loadListTableData= (drawDate: any,succText?: string) => {
    try{
        $("#divLoading").attr("style", "display:block")
        //this.setState(initialState);
        const windowWidth: number = $(window).width();
        const initialState: any = COrganizermodel().aOrganizer;
        initialState.drawDate = drawDate;
        this.setState(initialState);
        const drawDateVal: any = initialState.drawDate; 
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        //MANI 23/12
        //useAxios.get(config.Url(this.props.rootFlag === 1 ? "a_organizer" : "gc_organizer","get"), config.authHeaderwithParam("Organizer~@#%&" + this.props.rootFlag + "~@#%&" + (moment(drawDateVal.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') || moment().format('yyyy-MM-DD')) + " 00:00:00~@#%&" + (moment(drawDateVal.TdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') || moment().format('yyyy-MM-DD')) + " 00:00:00~@#%&" + userInfo["userId"]))
        apiHelper.getAPICall(this.props.rootFlag === 1 ? "a_organizer" : "gc_organizer","get",4,"Organizer~@#%&" + this.props.rootFlag + "~@#%&" + (moment(drawDateVal.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') || moment().format('yyyy-MM-DD')) + " 00:00:00~@#%&" + (moment(drawDateVal.TdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') || moment().format('yyyy-MM-DD')) + " 00:00:00~@#%&" + userInfo["userId"])
        .then((res) => {
            console.log("RESPONSE RECEIVED: ", res);
            let colDataArr: any = [];
            let rowDataArr: any = [];
            let errText: any = "";
            if(res !== null && res.data !== null && res.data.result === undefined){
                if(res.data.organizer_datum !== undefined && res.data.organizer_datum !== null && res.data.organizer_datum.length !== 0){
                    if(this.props.rootFlag === 1){
                    const singleWidthCnt: number = res.data["organizer_datum"].filter((elem: any) => elem.dl_approvestatus === 0).length;
                    colDataArr.push({
                        Visible: false,
                        width: windowWidth > 800 && singleWidthCnt > 0 ? 240 : windowWidth > 800 ? 120 : 75,
                        Header: "Actions",
                        accessor: "A/R Actions",
                        //resizable: false,
                        sortable: false,
                        className: "wordwrap",
                        Cell: (row: any) => (
                            windowWidth > 800 ?
                            (row.original.dl_approvestatus === 0 ?
                            (<div className="userLogBtnCont3" style={{padding: 0}}>							
                            <button className="GreenBg" style={{fontSize: "12px",padding: "7px"}} onClick={(e: any) => this.onSubmitData(e, row.original, 1)}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>Approve</button>		
                            <button className="RedBg" style={{fontSize: "12px",padding: "7px"}} onClick={(e: any) => this.onSubmitData(e, row.original, 2)}><i className="fa fa-times" style={{marginRight: "5px"}} aria-hidden="true"></i>Reject</button>
                            </div>) : 
                            row.original.dl_approvestatus === 1 ?
                            (<div className="userLogBtnCont3" style={{padding: 0}}>							
                            <button className="GreenBg" style={{fontSize: "12px",pointerEvents: "none",padding: "7px"}}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>Approved</button>		
                            </div>) :
                            (<div className="userLogBtnCont3" style={{padding: 0}}>							
                            <button className="RedBg" style={{fontSize: "12px",pointerEvents: "none",padding: "7px"}}><i className="fa fa-times" style={{marginRight: "5px"}} aria-hidden="true"></i>Rejected</button>		
                            </div>)) : 
                            (row.original.dl_approvestatus === 0 ?
                            (<div className="userLogBtnCont3" style={{padding: 0}}>							
                            <i className="fa fa-check GreenC" style={{fontSize: "12px"}} aria-hidden="true" onClick={(e: any) => this.onSubmitData(e, row.original, 1)}></i>
						    <i className="fas fa-times RedC" style={{fontSize: "12px",margin: "0 10px"}} aria-hidden="true" onClick={(e: any) => this.onSubmitData(e, row.original, 2)}></i>
                            </div>) : 
                            row.original.dl_approvestatus === 1 ?
                            (<div className="userLogBtnCont3" style={{padding: 0}}>	
                            <i className="fa fa-check GreenC" style={{fontSize: "12px",pointerEvents: "none"}} aria-hidden="true"></i>	
                            </div>) :
                            (<div className="userLogBtnCont3" style={{padding: 0}}>	
                            <i className="fa fa-times RedC" style={{fontSize: "12px",pointerEvents: "none"}} aria-hidden="true"></i>		
                            </div>))
                        )
                      });
                    }
                    else if(this.props.rootFlag === 2){
                        colDataArr.push({
                            Visible: false,
                            width: windowWidth > 800 ? 120 : 75,
                            Header: "Actions",
                            accessor: "Generate Actions",
                            //resizable: false,
                            sortable: false,
                            className: "wordwrap",
                            Cell: (row: any) => (
                            <div className="userLogBtnCont3" style={{padding: 0}}>							
                            {windowWidth > 800 ? <button className="BlueBg" id="registerBtn" style={{fontSize: "12px",padding: "7px"}} onClick={(e: any) => this.onSubmitData(e, row.original)}><i className="fa fa-edit" style={{marginRight: "5px"}} aria-hidden="true"></i>Generate</button> :
                            <i className="fa fa-edit BlueC" style={{fontSize: "12px"}} aria-hidden="true" onClick={(e: any) => this.onSubmitData(e, row.original)}></i>}	
                            </div> 
                            )
                          });
                    }
                    else if(this.props.rootFlag === 4){
                        colDataArr.push({
                            Visible: false,
                            width: windowWidth > 800 ? 120 : 75,
                            Header: "Actions",
                            accessor: "Edit Actions",
                            //resizable: false,
                            sortable: false,
                            className: "wordwrap",
                            Cell: (row: any) => (
                            <div className="userLogBtnCont3" style={{padding: 0}}>							
                            {windowWidth > 800 ? <button className="BlueBg" id="registerBtn" style={{fontSize: "12px",padding: "7px"}} onClick={(e: any) => this.updateListTableRow(e, row.original)}><i className="fa fa-edit" style={{marginRight: "5px"}} aria-hidden="true"></i>Edit</button> :
                            <i className="fa fa-edit BlueC" style={{fontSize: "12px"}} aria-hidden="true" onClick={(e: any) => this.updateListTableRow(e, row.original)}></i>}	
                            </div> 
                            )
                          });
                    }
                    else{
                        colDataArr.push({
                            Visible: false,
                            width: windowWidth > 800 ? 140 : 75,
                            Header: "Actions",
                            accessor: "Regenerate Actions",
                            //resizable: false,
                            sortable: false,
                            className: "wordwrap",
                            Cell: (row: any) => (
                            <div className="userLogBtnCont3" style={{padding: 0}}>							
                            {windowWidth > 800 ? <button className="BlueBg" id="loginBtn" style={{fontSize: "12px",padding: "7px"}} onClick={(e: any) => this.onSubmitData(e, row.original)}><i className="fa fa-edit" style={{marginRight: "5px"}} aria-hidden="true"></i>Regenerate</button> :
                            <i className="fa fa-edit BlueC" style={{fontSize: "12px"}} aria-hidden="true" onClick={(e: any) => this.onSubmitData(e, row.original)}></i>}	
                            </div>
                            )
                          });
                    }
                    colDataArr.push({Visible: false,width: 40,Header: "#",accessor: "#",className: "wordwrap",Cell: (row: any) => { return <div style={{ textAlign: "right" }}>{row.index + 1}</div>; }});
                    let cntIndex: number = 0;
                    for(var prop of Object.keys(res.data["organizer_datum"][0])){
                        const headerTitle: string = CommonOrganizer.getTableTitle(prop);
                        const isVisible: boolean = prop === 'dl_logoatchfileuri' || prop === 'dl_organizerid' || prop === 'dl_approvestatus' || (this.props.rootFlag === 1 && prop === 'dl_approvestatusdesc') ? false : cntIndex === 0 ? true : false;
                        const isVisible1: boolean = prop === 'dl_logoatchfileuri' || prop === 'dl_organizerid' || prop === 'dl_approvestatus' || (this.props.rootFlag === 1 && prop === 'dl_approvestatusdesc') || cntIndex === 0 ? false : true;
                        colDataArr.push({Visible: isVisible1,Header: headerTitle,accessor: prop,
                        show: isVisible,className: "wordwrap",headerClassName: "wordwrap"})
                        if(windowWidth > 800 && prop === "dl_emailidl1"){
                            cntIndex++;
                        }
                        else if(windowWidth < 600 && prop === "dl_organizername"){
                            cntIndex++;
                        }
                        else if(windowWidth > 600 && windowWidth <= 800 && prop === "dl_contactpersonnamel1"){
                            cntIndex++;
                        }
                    }
                    colDataArr.push({
                        width: 50,
                        Header: "View",
                        accessor: "View",
                        //resizable: false,
                        sortable: false,
                        className: "wordwrap",
                        Cell: (row: any) => (
                        <div className="userLogBtnCont3" style={{padding: 0}}>	
                            <small>
                                {/* <img key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,row.index)}></img> */}
                                <i  key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="fa showPlusMinus fa-plus GreenBgg" style={{borderRadius: "100px",padding: "5px"}} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e,row.index)}></i>
                            </small>          
                            <div key={"tabPopup_" + row.index} id={"tabPopupC_" + row.index} className="graphPop4 graphPop-1">
                            <Tablistview keys={"tabPopupV_" + row.index} rowData={row.original} colData={this.state.colData} />
                            {/* <img alt="" src={TooltipData}></img>	 */}
                            </div>
                        </div>
                        )
                    });
                    rowDataArr = res.data["organizer_datum"];
                }
                else{
                    errText= t('lblNodata');
                }
            }
            else{
                errText= res.data.result.toString();
            }        
            $("#divLoading").attr("style", "display:none") 
            //$("#templaTBlid").attr("style", "display:none") 
            let errMsg: string = "";
            let succFlag: any = undefined;
            if(succText !== undefined && succText !== ""){
                errMsg= succText;
                succFlag= 0;
            }
            else if(errText !== undefined && errText !== ""){
                errMsg= errText;
            }
            this.setState({colData: colDataArr,tabData: rowDataArr,filteredData: [],searchInput: "",succFlag: succFlag,errMsg: errMsg,rowData: null,pageIndex: 1 });
            if((errText !== undefined && errMsg !== "") || (succText !== undefined && succText !== "")){
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn();
            }
            return
        })
        .catch((err) => {
            console.log("AXIOS ERROR: ", err.response);
            $("#divLoading").attr("style", "display:none") 
            this.setState({succFlag: undefined,errMsg: t('lblNodata')});
            $('.overlay').fadeIn();
            $('#lightBox2').fadeIn();
            return false
        }) 
    }
    catch(ex) {
      alert("Load List Failed..");
      return
    }
    } 
    updateListTable =(createGame: any) =>{
        if(createGame !== null)
            this.insertData(createGame);  
        this.setState({pageIndex: 1,rowData: null});   
        return
    }
    updateListTableRow =(e: any,rowData: any) =>{
        e.preventDefault();  
        // this.insertData(rowData);  
        this.setState({pageIndex: 2,rowData: rowData});  
        return 
    }  
    onSubmitData= (e: any, rowData: any,actionFlag?: number) => {
        e.preventDefault();   
        this.insertData(rowData,actionFlag);
        return
    } 
    onShowLightBox= (e: any, index: any) => {
        e.preventDefault();         
        const currDisplay: any = $('#tabPopupC_' + index).css('display');    
        // $('.fa.fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        // $('.fa-minus').addClass('fa-plus').removeClass('fa-minus');  
        $('.showPlusMinus').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        $('.graphPop4').css({display: "none"})   
        $('#tabPopupIC_' + index).attr('class', (currDisplay === "none" ? "fa showPlusMinus fa-minus RedBgg" : "fa showPlusMinus fa-plus GreenBgg"));      
        $('#tabPopupC_' + index).css({display: currDisplay === "none" ? "block" : "none"})  
        return 
    } 
    insertData= (rowData: any,actionFlag?: number) => { 
    try{
        $("#divLoading").attr("style", "display:block")
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        const userIp: string = $("#userIp").text();       
        const jsonData: any = this.props.rootFlag === 1 ? {
            "organizer_id": rowData.dl_organizerid,
            "approve_status": actionFlag,
            "username": userInfo["userId"],
            "ipaddress": userIp
        } : this.props.rootFlag === 4 ? {
            "organizerID": rowData.organizerId,
            "organizername": rowData.cOrganizerMand.organzierName.name,
            "contactpersonnamel1": rowData.cOrganizerMand.contactPerLOne.name,
            "contactnumberl1": rowData.cOrganizerMand.contactNumLOne.name ? parseInt(rowData.cOrganizerMand.contactNumLOne.name) : null,
            "emailidl1": rowData.cOrganizerMand.emailIdLOne.name,
            "contactpersonnamel2": rowData.cOrganizerOpt.contactPerLTwo,
            "contactnumberl2": rowData.cOrganizerOpt.contactNumLTwo ? parseInt(rowData.cOrganizerOpt.contactNumLTwo) : null,
            "emailidl2": rowData.cOrganizerOpt.emailIdLTwo.name,
            "address": rowData.cOrganizerMand.address.name,
            "approvalnumber": rowData.cOrganizerOpt.approvalNum,
            "approvalcpyatch": rowData.cOrganizerOpt.approvalAtt.name === "" ? "" : rowData.cOrganizerOpt.approvalAtt.name,
            "logoatch": rowData.cOrganizerOpt.logoAtt.name === "" ? "" : (rowData.cOrganizerOpt.logoAtt.name + "&!@%$#" + rowData.cOrganizerOpt.logoAtt.uri),
            "username": userInfo["userId"],
            "ipaddress": userIp
        } : {
            "organizer_id": rowData.dl_organizerid,
            "username": userInfo["userId"],
            "ipaddress": userIp
        };
        // if(this.props.rootFlag !== 4){
            let callApiUrl:any =this.props.rootFlag === 1 ? "a_organizer" : "gc_organizer";
       // useAxios[this.props.rootFlag !== 4 ? "post" : "put"](config.Url(this.props.rootFlag === 1 ? "a_organizer" : "gc_organizer",this.props.rootFlag !== 4 ? "post" : "put"), jsonData, config.authHeader())
       (this.props.rootFlag !== 4 ? apiHelper.postAPICall(callApiUrl,"post",2,jsonData):apiHelper.putAPICall(callApiUrl,"put",2,jsonData))
        .then((res: any) => {
            console.log("RESPONSE RECEIVED: ", res);
            // $(".ContainerChart").scrollTop(0);                     
            // $(".overlay").delay(3000).fadeOut(500);
            // $("#lightBox2").delay(3000).fadeOut(500);
            if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
                $("#divLoading").attr("style", "display:none") 
                this.setState({errMsg: res.data.result.toString().replace('successfully',''),succFlag: undefined})
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn();
                return
            }
            else{
                //const succText: any = this.props.rootFlag === 1 ? (actionFlag === 1 ? "Organizer Approved" : "Organizer Rejected") : this.props.rootFlag === 2 ? "Organizer Certificates Generated" : "Organizer Certificates Regenerated";
                this.loadListTableData(this.state.drawDate,res.data.result.toString().replace('successfully','')); 
                return
            }
        })
        .catch((err) => {
            console.log("AXIOS ERROR: ", err.response);
            $("#divLoading").attr("style", "display:none")
            return 
        })
        // }
        // else{
        //     useAxios.put(config.Url("c_organizer","put"), jsonData, config.authHeader())
        //     .then((res) => {
        //         console.log("RESPONSE RECEIVED: ", res);
        //         if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
        //             $("#divLoading").attr("style", "display:none") 
        //             this.setState({errMsg: res.data.result.toString().replace('successfully',''),succFlag: undefined})
        //             $('.overlay').fadeIn();
        //             $('#lightBox2').fadeIn();
        //             return
        //         }
        //         else{
        //             //const succText: any = "Organizer Edited";
        //             this.loadListTableData(this.state.drawDate,res.data.result.toString().replace('successfully',''));          
        //             return
        //         }
        //     })
        //     .catch((err) => {
        //         console.log("AXIOS ERROR: ", err.response);
        //         $("#divLoading").attr("style", "display:none")
        //         return 
        //     })  
        // } 
    }
    catch(ex) {
      alert("Data Insertion Failed..");
      return
    }    
    }
    // And in your global search
    globalSearch = (e: any) => {
        try{
            let { tabData } = this.state;
            const searchInput: string = e.target.value;
            let filteredData = tabData.filter((value: any) => {
            return CommonOrganizer.getKeyByValue(value, searchInput);
            });
            this.setState({filteredData: filteredData,searchInput: searchInput,succFlag: undefined,errMsg: "",rowData: null,pageIndex: 1 });
            return
        }
            catch(ex) {
            alert("Search result Failed..");
            return
        }
    };  
    handleDateChange = (date: any,key: string,format: string) => {
        try{
        const resVal: any = this.state.drawDate;
        resVal[key].defValue = moment(date._d);
        resVal[key].value = moment(date._d).format(format);
        resVal.FdrawDate.display = resVal.FdrawDate.value === null ? true : resVal.TdrawDate.value === null ? false : (resVal.TdrawDate.value !== null && moment(resVal.FdrawDate.value,format) > moment(resVal.TdrawDate.value,format))   
        resVal.TdrawDate.display = resVal.TdrawDate.value !== null ? false : true;  
        this.setState({drawDate: resVal,tabData: [],filteredData: [],searchInput: "",succFlag: undefined,errMsg: "",rowData: null,pageIndex: 1 });
        return
        }
        catch(ex) {
        alert("On handle Date change Failed..");
        return
        }
    };
    onFilterListTableData = (e: any) => {
        try{
        e.preventDefault(); 
        const resVal: any = this.state;
        let isErrData: boolean = false;
        if(this.props.rootFlag === 1){
            Object.entries(resVal.drawDate).forEach(([key, value]) => {
                let val = resVal.drawDate[key].value;
                if(val === null || val === "" || resVal.drawDate[key].display){
                    resVal.drawDate[key].display = true
                    isErrData = true
                }else{ return }
            })
        }
        if(isErrData){
            $("#divLoading").attr("style", "display:none") 
            //resVal.errMsg = "Invalid data..!"
            this.setState(resVal);
            // $('.overlay').fadeIn();
            // $('#lightBox2').fadeIn(); 
            return 
        }else{
            if(resVal.tabData !== undefined && resVal.tabData.length > 0 && this.props.rootFlag === 1){
                $("#divLoading").attr("style", "display:none") 
                resVal.errMsg = "Page already rendered..!"
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn();
                return;
            }
            else{
                this.loadListTableData(this.state.drawDate);    
                return
            }
        }
        }
        catch(ex) {
        alert("Load List Failed..");
        return
        }
    }
    render() {
    const { t } = this.props;
    const resVal: any = this.state;
    const themeClr = CommonReports.ThemeColour()
    const pageSize: number = resVal.tabData !== undefined && resVal.tabData.length > 50 ? 50 : resVal.tabData.length > 25 ? 25 : 10;
    const defaultMaterialTheme = createMuiTheme({
        palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: themeClr,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
            }
        },
    });
      return(
        this.state.pageIndex === 1 ?
        (<div className="ContainerChart scrollbar" style={{height: $(window).height() - 60 + "px"}} onClick={(e: any) => config.onBodyClick(e)}>        
            <Lightbox errMsg= {resVal.errMsg} succFlag= {resVal.succFlag} />            
            <div className="contentclouds">					
            <div className="userLogHead">                
                <div className="userLogHeadinner">
				<h2>{this.props.rootFlag === 1 ? t('lblApproveOrg') : this.props.rootFlag === 2 ? t('lblPushOrg') : this.props.rootFlag === 4 ? t('lblViewOrg') : t('lblRegenerateoOrg')}</h2>
				<small>{t('lblViewandEdit')}</small>
			    </div>
                <Breadcrumb Menu= {t('lblOrganizer')} subMenu= {this.props.rootFlag === 1 ? t('lblApproveOrg') : this.props.rootFlag === 2 ? t('lblPushOrg') : t('lblRegenerateoOrg')} />       					
            </div>
            <div className="UseformNew">
            {this.props.rootFlag === 1 ? (
             <div className="Dateform">										
             <div className="userLogformTop">
                 <MuiPickersUtilsProvider utils={MomentUtils}>
                 <ul>                    
                     <li style={{width: "50%"}}>
                         <div className="userLogCloudtop">
                         <ThemeProvider theme={defaultMaterialTheme}>                                        
                         <DatePicker
                             placeholder={t('lblPickFrom')}
                             className="rptdatePicker"
                             format="DD-MM-yyyy"
                             value={resVal.drawDate.FdrawDate.defValue}
                             onChange={(date: any) => this.handleDateChange(date,"FdrawDate","DD-MM-yyyy")}
                         />                                        
                         </ThemeProvider>
                         <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.drawDate.FdrawDate.display ? "block" : "none"}}>{resVal.drawDate.FdrawDate.value === null ? t('lblFromDateProvided') : t('lblGraterthanTodate')}</label>
                         </div>
                     </li>                    
                     <li style={{width: "50%"}}>
                         <div className="userLogCloudtop">
                            <ThemeProvider theme={defaultMaterialTheme}>
                             <DatePicker
                                 placeholder={t('lblPickTo')}
                                 className="rptdatePicker"
                                 format="DD-MM-yyyy"
                                 value={resVal.drawDate.TdrawDate.defValue}
                                 onChange={(date: any) => this.handleDateChange(date,"TdrawDate","DD-MM-yyyy")}
                             />
                             </ThemeProvider>
                             <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.drawDate.TdrawDate.display ? "block" : "none"}}>{('lblToDateProvided')}</label>
                         </div>
                     </li>
                 </ul>
                 </MuiPickersUtilsProvider>
             </div>            
             <div className="userLogBtnTop">							
                 <button id="registerBtn" onClick={(e: any) => this.onFilterListTableData(e)}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblSubmit')}</button>		
                 <button id="registerBtn1" style={{float: "right"}} onClick={this.resetValues.bind(this)}><i className="fa fa-sync" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblReset')}</button>		
             </div>            
             </div>) : null}		           
            {resVal.tabData !== undefined && resVal.tabData.length > 0 ? 
            (<div className="ContainerTable">
				{/* <div className="searckLink">
						<div className="">   
							<span id="succText"></span>							
						</div>
                </div> */}
                <div className="tableSearch">
						<div className="sportSrch">
							<input type="text" placeholder="Search here..!" id="myInput" value={this.state.searchInput} onChange={(e: any) => this.globalSearch(e)} autoComplete="off" />
						</div>
                </div>
			</div>) : null}	
			{resVal.tabData !== undefined && resVal.tabData.length > 0 ? 
            (<div className="TableReact">            
                <ListTable
                sorting={true}
                columns={resVal.colData}
                pageSize={pageSize}
                data={resVal.filteredData && this.state.searchInput !== "" ? resVal.filteredData : resVal.tabData}           
                />
            </div>) : this.props.rootFlag !== 1 ? 
            (<div className="TableReact"><span className="noDataCenter">{t('lblNodata')}</span></div>): null} 
            </div>
            </div>
        </div>) : <COrganizer backClick={this.updateListTable.bind(this)} rootFlag={5} rowData={this.state.rowData} />                    
    );
  }
}

export default withTranslation()(AOrganizer);