import { useState ,useEffect} from 'react';
import React from 'react';
//import '../assets/css/style.css';
//import '../assets/css/font-awesome.min.css';
//import 'semantic-ui-css/semantic.min.css';
// import _ from "lodash";
import PropTypes from 'prop-types';
//import useAxios from "axios";
import config from "../utils/config"
import apiHelper from "../utils/ApiHelper"
import sonallogo from "../assets/img/sonal-logo.jpg";
import Lightbox from "./Shared/lightbox";
import LangSelector from './LangSelector';
import { withTranslation  } from 'react-i18next';
import { t } from 'i18next';


var $ = require('jquery');
var Crypto = require("cryptr");
const cryptr = new Crypto(config.secretKey());

async function loginUser(credentials: any) {
    //return useAxios.post(config.Url("login", "post"), credentials, config.authWOHeader())
    return apiHelper.postAPICall("login", "post",1,credentials)
        .then((res) => res.data)
        .catch((err) => {
            console.log("AXIOS ERROR: ", err.response);
            $("#divLoading").attr("style", "display:none")
        })
}
// async function userModalData(token: any) {
//     return useAxios.get(config.Url("userModal","get"), config.authHeader(token))
//     .then((res) => res.data)
//     .catch((err) => {
//         console.log("AXIOS ERROR: ", err.response);
//         $("#divLoading").attr("style", "display:none")
//     })
// }
//event triggers on overlay div click
function closePopup() {
    var $ = require('jquery')
    $('.overlay').fadeOut();
    $('#lightBox2').fadeOut();
}
function onSubmitData(e: any) {
    if (e.keyCode === 13) {
        e.preventDefault();
        $('#loginBtn').click();
    }
}
function setCookie(key: any, value: any, expiry: any) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}
function getCookie(key: any) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : "";
}
function eraseCookie(key: any) {
    var keyValue = getCookie(key);
    setCookie(key, keyValue, '-1');
}
 function Login({ setToken, remMeUser, remMePass, isChecked }: any) {    
    const [username, setUserName] = useState(remMeUser);
    const [password, setPassword] = useState(remMePass);
    const [errMsg, setErrMsg] = useState("");
    const [eyeIcon, setEyeIcon] = useState("eyeClose");
    
    //event triggers on click the Submit button
    const handleSubmit = async (e: any) => {
        try {
            e.preventDefault();
            $("#divLoading").attr("style", "display:block")
            let errMsgs = ((username === "" || username === undefined) && (password === "" || password === undefined)) ? t('lblEnterBoth'): (username === "" || username === undefined) ? t('lblEnterUsername') : (password === "" || password === undefined) ? t('lblEnterPassword') : "";
            if (errMsgs !== "") {
                $("#divLoading").attr("style", "display:none")
                setToken("-1");
                setErrMsg(errMsgs);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn();
                //alert(errMsg);
                return
            }
            else {
                const token = await loginUser({
                    username,
                    password
                });
                //$("#divLoading").attr("style", "display:none") 
                //if(token){
                //const userInfos: any = await userModalData(token);
                // const userInfos: any = {username: username,email: "kamaraj.p@gitechgames.com"};
                let errMsg: number = 1;
                if (token && token.result !== null && token.result.length > 0 && token.result[0] !== null) {
                    const succMsg: string = token.result[0][0];
                    if (succMsg.includes('succesfully logined')) {
                        const getUserDet: any = succMsg.split('~');
                        if (getUserDet !== null && getUserDet.length > 0) {
                            const userInfos: any = { username: getUserDet[1], is_superuser: getUserDet[2] === '1' ? true : false, userId: getUserDet[3], email: getUserDet[4] };
                            sessionStorage.setItem('userInfo', JSON.stringify(userInfos));
                            errMsg = 0;
                            //Remember Me- start
                            if ($('#rememberChkBox').is(":checked")) {
                                const loginInfo: any = {
                                    uId: username,
                                    pW: password
                                };
                                const encData: any = cryptr.encrypt(JSON.stringify(loginInfo));
                                setCookie('loginInfo', encData, '1'); //'1' indicates day count
                            }
                            else {
                                eraseCookie('loginInfo');
                            }
                            //Remember Me- End                
                            $("#divLoading").attr("style", "display:none")
                            const token: any = { token: getUserDet[5] }
                            setToken(token);
                            return
                        }
                    }
                }
                if (errMsg === 1) {
                    $("#divLoading").attr("style", "display:none")
                    setToken("-1");
                    setErrMsg("Invalid User Credentials");
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return
                }
                // }
                // else{ 
                //     setToken(token);
                //     return
                // }
            }
        }
        catch (ex) {
            $("#divLoading").attr("style", "display:none")
            setToken("-1");
            setErrMsg("101 Server Connection refused");
            $('.overlay').fadeIn();
            $('#lightBox2').fadeIn();
            return
        }
    } 
   

        return (
            <div className="mainContainer1" onKeyDown={(e: any) => onSubmitData(e)} tabIndex={0}>
                <div className="overlay" onClick={(e: any) => closePopup()}></div>
                <div className="lds-roller" id="divLoading" style={{ display: "none" }}>
                    <div id="centerVal">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <Lightbox errMsg={errMsg} />
                <div className="loginRgt">
                    <div className="loginRgtInner">
                        <div className="imgSec">
                            <img alt="" src={sonallogo} />
                        </div>
                        {/*	<h2>Welcome</h2> */}
                        {/* <p>Don't have an account?</p>
                <div className="signBtn">
                    <a href="#section">SIGNUP</a>
                </div> */}
                    </div>
                </div>
                <div className="loginLft">
                    <div className="loginContentFrm">
                        <h3>{t('lblSigninAdmin')}</h3>
                        <p>{t('lblGameExperience')}</p>
                        <div className="userLogRgt">
                            <div className="enqFormdrb">
                                <div className="userLogContdrb">
                                    <div className="bounceIn delay-500 fast go">
                                        <input className="newUserTxt" id="in_username" placeholder="Enter Username" autoComplete="off" type="text" value={username} onChange={(e: any) => setUserName(e.target.value)} />
                                    </div>
                                </div>
                                <div className="userLogContdrb">
                                    <div className="bounceIn delay-500 fast go">
                                        <input className="newUserTxt" id="in_password" placeholder="Enter password"  autoComplete="off" type={eyeIcon === "eyeClose" ? "password" : "text"} value={password} onChange={(e: any) => setPassword(e.target.value)} />
                                        <span id="eyeIcon" className={"eyeIcon " + eyeIcon} onClick={(e: any) => setEyeIcon(eyeIcon === "eyeOpen" ? "eyeClose" : "eyeOpen")}></span>
                                    </div>
                                    <span className="ErrMsg">{t('lblErrMsg')}</span>
                                </div>
                            </div>
                        </div>  
                        {/* Comment this manikandan.kc 6/11/2023 */}
                        {/* <LangSelector/>                   */}
                        <div className="userLogPsw">
                            <label className=""><input type="checkbox" id="rememberChkBox" defaultChecked={isChecked} /><span className="caption">{t('lblRememberme')}</span></label>
                            <span className=""><a href="#section" className="forgot-pass">{t('lblForgotPassword')}</a></span>
                        </div>
                        <div className="userLogContdrbbtn">
                            <button type="submit" className="button buttonBlue" id="loginBtn" onClick={handleSubmit}>{t('lblSignin')}</button>
                        </div>
                        {/* <div className="loginChoice">
                  <span className="">&mdash; or login with &mdash;</span>
                </div>
                <div className="social-login">
                    <a href="#section" className="facebook"><span><i className="fa fa-facebook"></i></span></a>
                    <a href="#section" className="twitter"><span><i className="fa fa-twitter" ></i></span></a>
                    <a href="#section" className="google"><span><i className="fa fa-google" aria-hidden="true"></i></span> 
                    </a>
                </div> */}
                    </div>
                </div>
            </div>
        );
   
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
    remMeUser: PropTypes.string.isRequired,
    remMePass: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired
};
export default withTranslation()(Login );