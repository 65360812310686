import React,{useState,useRef,} from 'react';
import apiHelper from "../../../utils/ApiHelper"
import {
    CCard,
    CCardBody,
    CCardHeader,
 
} from '@coreui/react'
import{
    Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   PointElement,
    LineElement,
   Title,
   Tooltip,
   Legend,
   ArcElement,
  }from 'chart.js'
  import {Pie} from 'react-chartjs-2'
import config from '../../../utils/config';
import axios from 'axios';
import  { extendMoment } from "moment-range";
import { t } from 'i18next';

//import Chartdata from './chartmodel/chartcommon';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,

  )
   
  ChartJS.register(ArcElement, Tooltip, Legend);


  const Moment = require('moment');
  const moment = extendMoment(Moment);


  interface IProps {
    startdate:any,
    enddate:any,
   // rootChart:number;
    gameID:any,
    orgID:any
  }
       const PieChart: React.FC<IProps> = props => {
        const [provider, setData] = React.useState([]);        
        const [money, setMoney] = React.useState([]);
        const [_color, setData2] = React.useState([]);
        
        
         React.useEffect(()=>{ 
         getchartdata() 
          },[props.startdate,props.startdate])
        
        async function getchartdata(){  
         let callMethod: string = "";
         let callJsonHead:any = null;
         let getDtlPoint: any = "get_rptDetails";
         let getDtlDtPoint: any = "get_rptGenTime";
 
           callMethod = "cash-paymethod";
         callJsonHead ={ 
           organizerID:props.orgID,
           FromDate:moment(props.startdate).format('yyyy-MM-DD') + " 00:00:00",
           ToDate:moment(props.enddate).format('yyyy-MM-DD') + " 00:00:00",
           GameId:(props.gameID!=null)? props.gameID.split('_')[0]:"0"
    
        }
          //mani 22/12
       //await axios.get(config.Url(callMethod,'get'),config.authHeaderwithParamRpt(callJsonHead))
       apiHelper.getAPICall(callMethod, "get",5,callJsonHead)
          .then((res)=>{ 
             console.log('respiechart',res)

          let rptGenDt: any = "";
          let chartDataArr:any= []; 
          let arr:any =[];
          if (res !== null && res.data !== null && res.data.result === undefined) {
            if (res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0) {
                chartDataArr = res.data[getDtlPoint];
                rptGenDt = res.data[getDtlDtPoint].toString();

  const chartlabel = chartDataArr.map((elems: any,i:any) => elems.in_paymode); 
  const chartdata= chartDataArr.map((elems: any) => elems.in_addmoney)         
  let loadColorData: any = chartDataArr.map((item: any) => "#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase());
          setData(chartlabel)
          setMoney(chartdata)  
          setData2(loadColorData);
        return {chartlabel:[],chartdata:[]}
           
            }
            } 
        }
        ).catch((error) => {console.log('err',error);

//setIsLoaded(false)
        
      });

 }




let customLabels = provider.map((label,index) =>`${label}: [${money[index]}]`)



   
  const options :any ={
    plugins: {
      tooltip: {     

              callbacks: {
                label: function(context:any) {
                    //let labels = context.parsed
                    let label =context.label
                    return label;
                  }         
            }
          }

      },
  }

  
     
            const PeiChartData: any = {   
            labels: customLabels,
            datasets: [
                {
                    label:"$",
                    data:money ,
                    backgroundColor: _color,
                    borderColor: _color,
                    borderWidth: 1
                  
                }
               
            ]
  
            }  
            
            
          
    
        return(
               <div>
                  <CCard className="mb-4">
                    
                 <CCardHeader><h4>{t('lblCashInPaymethod')}</h4></CCardHeader>
                 <CCardBody>

                 {/* isLoaded ? <p>...loading</p>: */}

                 <Pie data={PeiChartData}  options={options}   />
                 

         
                 </CCardBody>
                 </CCard>
                 <div>
                  {/* <p>{props.startdate} / {props.enddate}</p>  */}
              
                </div>
                 </div>
   )
  };
export default PieChart;
