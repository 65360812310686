import React from "react";
import apiHelper from "../../../utils/ApiHelper"
import { extendMoment } from "moment-range";

import { Chart as ChartJS, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import axios from "axios";
import config from "../../../utils/config";
import { t } from "i18next";
var $ = require("jquery");
ChartJS.register(
    BarElement,
    Title,
    Tooltip,
    Legend
)
 
const Moment = require('moment');
const moment = extendMoment(Moment);
interface IProps{
    startdate:any,
    enddate:any,
    gameID:any,
    organizername:any

}
interface IState{
   options:any
    chartData:any
}

class PaymentBarChart extends React.Component<IProps,IState>{
    constructor(props:IProps) {
        super(props);
        this.state = {
            chartData: { labels: [], datasets: [],stacked: true } ,
            options :{}

    }}

    componentDidMount() {
        this.initData()
     }
     
     
     componentDidUpdate(prevProps:any) {
        if (prevProps.startdate !== this.props.startdate) {
            this.initData()
        }
      }
       
      initData = () => {
             
             let callMethod: string = "";
             let callJsonHead:any = null;
             let getDtlPoint: any = "get_rptDetails";
             let getDtlDtPoint: any = "get_rptGenTime";
             let Gameid = typeof (this.props.gameID) === 'string' ? (this.props.gameID).split('_')[0] : 0;
             callMethod = "add-withdraw-money";
     
             callJsonHead = {
                 organizerID:this.props.organizername,
                 FromDate:moment(this.props.startdate).format('yyyy-MM-DD') + " 00:00:00",
                 ToDate:moment(this.props.enddate).format('yyyy-MM-DD') + " 00:00:00",
                // GameId:Gameid,
                 
             }
            //mani 22/12
             //axios.get(config.Url(callMethod, "get"), config.authHeaderwithParamRpt(callJsonHead))
             apiHelper.getAPICall(callMethod, "get",5,callJsonHead)
                 .then((res)=> {
                    console.log('addmoney', res)
           if (res !== null && res.data !== null && res.data.result === undefined) {
             let xAxis: any = [];
             let ydatasets: any = [];
             let tempArr: any = [];
             let tempArr1: any = [];
            // let yname: number = 0;
             let dateAxis:any =[];
             let yAxis:any=[];
             let amtAxis:any=[];                        
             let colDataArr: any = res.data["get_rptDetails"];          
             //get all x-axis unique  date value
             xAxis =  colDataArr.map((item:any) => item.in_drawdate).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
           
             //get all y-axis unique vaule   organiser show label
              yAxis =  colDataArr.map((item:any) => item.in_paymode).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
     
            //org
             yAxis.forEach((fobj: any,) => {
                                   
                 //xaxis date
                 xAxis.forEach((fdate: any,i:any) => {
                                                                                         //     objlst org === yaxis org obj xaxis date ==  obj lst date                                                
                     const objLst = colDataArr.filter((item: any,i:any) => { return item.in_paymode == fobj  && item.in_drawdate == fdate});
                        
                     if (objLst.length > 0  )
                     {
                         tempArr.push(objLst[0].in_addmoney); //amount
                         tempArr1.push(objLst[0].in_withdraw); //amount
                     }
                     else
                     {
                         tempArr.push(0);
                         tempArr1.push(0);
                     }
                 });

                 var color = Math.floor(Math.random() * 16777216).toString(16);
                 var color1 = Math.floor(Math.random() * 16777216).toString(16);

                 var colour= '#000000'.slice(0, -color.length) + color;
                 var colour2= '#000000'.slice(0, -color1.length) + color1;

                 ydatasets.push(
                     {
                         label: fobj+" [In]",
                         fill: false,
                         backgroundColor: colour,
                         borderColor: colour,
                         data: tempArr,
                         stack: "Cash In" 
                     })
                     ydatasets.push( {
                        label: fobj+" [Out]",
                        fill: false,
                        backgroundColor: colour2,
                        borderColor: colour2,
                        data: tempArr1,
                        stack: "Cash Out"                         
                    })                
                 tempArr = [];
                 tempArr1 = [];

             })
             this.setState({ chartData: 
                 { labels: xAxis, 
                     datasets: ydatasets,
                     stacked: true
                  } 
                 });
             //return  { labels: xAxis, datasets: ydatasets } ;
         }
     })
     .catch((err) => {
         console.log("AXIOS ERROR: ", err.response);
         return  { labels:[], datasets:[] } ;
     })
     }
     

  
    render() {
       // const gidata = this.state.chartData


       const gidata: any = {
                    //x-axis date
        labels: ['21 Oct', '22 Oct', '23 Oct', '24 Oct', '25 Oct', '26 Oct','27 Oct'],
        datasets: [
            
            {
                    //org
                label: 'ORANGE MONEY',
                        // yaxis amount  
                data: [60, 30, 40, 60, 50, 80, 30],
                backgroundColor: 'rgb(75, 92, 192,0.8)',
                borderRadius: 1,
                //borderColor:'rgb(175, 52, 112,0.9)',
                borderWidth: 3,
                stack: "AddMoney"
            },
            {
                label: 'AFFITECH',
                data: [60, 30, 40, 30, 80, 90, 25],
                backgroundColor: 'rgb(75, 92, 172,0.8)',
                borderRadius: 1,
               //borderColor:'rgb(175, 52, 112,0.9)',
                borderWidth: 3,
                stack: "AddMoney"
            },
            {
                label: 'MTN',
                data: [20, 40, 50, 90, 50, 46, 78,],
                backgroundColor: 'rgb(75, 92, 112,0.8)',
                borderRadius: 10,
                //borderColor:'rgb(175, 52, 112,0.9)',
                borderWidth: 3,
                stack: "AddMoney"
            },
            {
                label: 'ORANGE MONEY',
                data: [40, 40, 20, 90, 30, 46, 78, 40],
                backgroundColor: 'rgb(21,206,3,0.9)',
                borderRadius: 10,
                //borderColor:'rgb(211,156,3,0.9)',
                borderWidth: 3,
                stack: "Withdraw"
            },
            {
                label: 'AFFITECH',
                data: [20, 40, 60, 60, 50, 46, 78, 40],
                backgroundColor: 'rgb(21,156,3,0.9)',
                borderRadius: 10,
                //borderColor:'rgb(211,156,3,0.9)',
                borderWidth: 3,
                stack: "Withdraw"
            },
    
        ],
        stacked: true,
    };
    
 
    const footers: any = (tooltipItems: any) => {
            let sum = 0;            
            tooltipItems.forEach(function (tooltipItem: any) {
                //sum += tooltipItem.parsed.y;
                sum = tooltipItem.dataset.stack;
                console.log('sum', sum)
                return;
            });
            //return sum +" "+tooltipItems[0].dataset.stack;
            return sum;
        };       

        const options4: any = {
            type: "bar",
            data: gidata,
            // legend: {
            //     display: true,
            //     labels: {
            //         color:'rgb(255, 99, 132)'            
            //     }
            // },
             plugins: {
                tooltip: {
                    callbacks: {
                        footer: footers,        
                    }
        
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Date',
                      
                    },
        
                },
           
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Amount',
                  
                },
            } }
        }


        return (
            <div>
                <h4>{t('lblCashIn/Out')}</h4>
                <Bar options={options4} data={
                    //gidata
                    this.state.chartData
                    } />
            </div>
        )
    }
};

export default PaymentBarChart;