import moment from "moment";
function CPaperssmodel() {
    return  { 
        errMsg: "",
        succFlag: undefined,  
        rptGenDate: "",
        fileAttach: {name: "",fileResult: null,display: false},
        aGWReportsDt: {
                FdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false},           
                TdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false}          
        },
        aGWReportsText: {
            selTxnValue: {name: "",display: false}   
        },
        aGWReportsDrop: {  //only dropdown values
            gameDet: [],
            gameDetId: {value: null,gamename: null,drawDate: null,orgId: null,display: false},
            OrganizerNames: [],
            OrganizerName: {value: null,display: false} 
        },
        aGWReportsTab: {
            tabData: [],
            tabDataSub: [],
            tabDbData: [],
            colData: [],
            colDataSub: [],
            filteredData: [],
            searchInput: "",
            selected: {},
            selectAll: 0
        }
    } 
};

export default CPaperssmodel;