import { extendMoment } from "moment-range";
const Moment = require('moment');
const moment = extendMoment(Moment);
function CDashboardmodel() {
    return  { 
        errMsg: "",
        succFlag: undefined,  
        rptGenDate: "",
        pageIndex: 0,
        organsid: null,
        aGWReportsDt: {
                openPicker: false,
                toggleVal: "months",
                drawDate: moment.range(moment().clone().subtract(1, "months"), moment().clone()),
                FdrawDate: {value: moment().subtract(1, 'months').format('MMM DD,yyyy').toString(),display: false},           
                TdrawDate: {value: moment().format('MMM DD,yyyy').toString(),display: false}          
        },
        aGWReportsDrop: {  //only dropdown values
            gameDet: [],
            gameDetId: {value: null,display: false},
            OrganizerNames: [],
            OrganizerName: {value: 0,display: false} 
        },
        aGWReportsCt: {
            options: null,
            series: null,
            options1: null,
            series1: null,
            chartTitle: "",
            chartTitle1: ""
        },
        aGWReportsTab: {
            tabAllData: [],
            tabNxtDrawData: []
        },
        popUpFilter: {
            Id: "",
            fromDate: "",
            toDate:""
        }
    } 
};

export default CDashboardmodel;