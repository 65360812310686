import Terminal from "../../../assets/img/icons/terminals.png";
import Player from "../../../assets/img/icons/players.png";
import Bet from "../../../assets/img/icons/betamounts.png";
import Win from "../../../assets/img/icons/winamounts.png";
import Payout from "../../../assets/img/icons/payouts.png";
import { t } from "i18next";

//rename the column names based on Db returned keys
const getChartColumnTitle =(isShowAllFields: boolean) => {
    return isShowAllFields ? [
        {key: "in_betamount",value: t('lblBetAmt')},{key: "in_winamount",value: t('lblWinAmt')},{key: "in_netamount",value: t('lblNetAmt')}
    ] : [
        {key: "in_betamount",value: t('lblBetAmt')}
    ];
}

//rename the column names based on Db returned keys
const totolOrgDetails= () => {
    return [
        {key: "in_noofterminals",value:t('lblNoOfTerminals'),imgUrl: Terminal,class: "bg1"},
        {key: "in_noofplayer",value: t('lblNoOfPlayers'),imgUrl: Player,class: "bg2"},
        {key: "in_betamount",value: t('lblBetAmt'),imgUrl: Bet,class: "bg3"},
        {key: "in_winamount",value: t('lblWinAmt'),imgUrl: Win,class: "bg4"},
        {key: "in_payout",value: t('lblPayOut'),imgUrl: Payout,class: "bg5"}
    ]
}
const CommonDashboard = {getChartColumnTitle: getChartColumnTitle,totolOrgDetails: totolOrgDetails };

export default CommonDashboard;