//Export
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import exportFromJSON from 'export-from-json'
import { t } from "i18next";

//use for show the report header's
const getPageTitle = (rootFlag: number) => {
    switch (rootFlag) {
        case 1:
            return t('lblGameWiseSaleVsPayout')
        case 2:
            return t('lblGameProfitVsLoss')
        case 3:
            return t('lblGameFilter')
        case 4:
            return t('lblGameTypeProfitVsLoss')
        case 5:
            return t('lblGameTypeFilter')
        case 6:
            return t('lblGameLastTrans')
        case 7:
            return t('lblViewTransDetails')
        case 8:
            return t('lblTerminalProfilVsLoss')
        case 9:
            return t('lblTerminalGameTypeProfitVsLoss')
        case 10:
            return t('lblClaimReport')
        case 11:
            return t('lblUnClaimReport')
        case 12:
            return t('lblOpSalesVsPayout')
        case 13:
            return t('lblChannelSalesVsPayout')
        case 14:
            return t('lblModeSlaeVaPayout')
        case 15:
            return t('lblOrgProfitVsLoss')
        case 16:
            return t('lblPlayerReport')
        case 17:
            return t('lblClaimVsUnclaimWin')
        case 18:
            return t('lblOverallSale')
        case 19:
            return t('lblRevenueForGovt')
        case 20:
            return t('lblAddMoney')
        case 21:
            return t('lblWithdraw')
        case 22:
            return t('lblGovtReport')
        case 23:
            return t('lblPgRep')
        case 24:
            return t('lblPgRepSummary')
//ykarthik 17.12
        case 25:
            return "Last Push Data"
        default:
            return ""
    }
}
//rename the column names based on Db returned keys
const getTableTitle = (header: string) => {
    switch (header) {
        case "in_sno":
            return "#"
        case "in_organizername":
            return "Org. Name"
        case "in_gamename":
            return "Game Name"
        case "in_gametype":
        case "in_gamegroupname":
        case "in_gametyename":
            return "Game Type"
        case "in_drawdate":
            return "Draw Date"
        case "in_drawtime":
            return "Draw Time"
        case "in_transcount":
            return "Trans Count"
        case "in_betamount":
            return "Bet Amt."
        case "in_winamount":
        case "in_winamounts":
            return "Winning Amt."
        case "in_payout":
            return "Payout (%)"
        case "in_status":
            return "Status"
        case "in_netamount":
            return "Net Amt."
        case "in_transactionid":
            return "TXN ID"
        case "in_transamount":
            return "Trans Amount"
        case "in_transdatetime":
            return "Trans DateTime"
        case "in_receiveddatetime":
            return "Recei. DateTime"
        case "in_recordid":
            return "Record Id"
        case "in_subrecordid":
            return "SubRecord Id"
        case "in_terminalid":
            return "Terminal Id"
        case "in_playerid":
            return "Player Id"
        case "in_claimedamount":
        case "in_paymentamount":
        case "in_claimamount":
            return "Claimed Amt."
        case "in_unclaimedamount":
        case "in_unclaimamount":
            return "UnClaimed Amt."
        case "in_modename":
            return "Mode Name"
        case "in_channelname":
            return "Channel Name"
        case "in_claimdatetime":
            return "Claimed DateTime"
        case "in_claimterminalid":
            return "Claim. Terminal Id"
        case "in_ticketinfo":
            return "Ticket Info"
        case "in_betinfo":
            return "Bet Info"
        case "in_claimstatus":
            return "Claimed Status"
        case "in_sstockistname":
            return "Stock. Name"
        case "in_stockistname":
            return "Sub Stock. Name"
        case "in_salecommission":
            return "Sales Comm."
        case "in_claimcommission":
            return "Claim Comm."
        case "in_netsale":
            return "Net Sale"
        case "in_revenueamount":
            return "Revenue Amt."
        case "in_commissiontype":
            return "Commission Type"
        case "in_tax":
            return "Tax Amt."
// ykarthik 16.12
            case "winningamount": //old
            return "Winning Amt."
     case "status":        //old
            return "Status"
     case "transactionid": //old
            return "TXN ID"
     case "netamount": //old
                return "Net Amt." 
    case 'transactiondatetime':  //old
        return "Trans DateTime"
    case "salescomm":  //old
           return "Sales Comm."
        case "tax":        //old
        return "Tax Amt."
    case "paymode":// kk new
         return "Paymode"        
    case "amount":// kk new
         return "Amount"
    case "salesamount":// kk new
         return 'Sales Amt.'
    case "date":// kk new
         return "Date"
//y.karthik 17.12
         case "addmoney":  // new
            return "Add Money"  
          case "claim":   // old
                return "Claim"
         case "sales":  //new
                 return "Sales"
         case "winning":  //old
                 return "Winning"
          case "withdraw":   //new
                 return "With Draw"
   


        default:
            return header
    }
}
//use for show the chart on particular fields..,
const getChartEnableFieldsArr = (rootFlag: number) => {

//ykarthik 26.12
switch(rootFlag){

    case 1: case 12: case 13: case 14: case 16 :
        return ['in_betamount', 'in_winamount']
        break;

    
    case 2: case 3: case 4: case 5: case 8: case 9: case 15: case 18 :
       return['in_betamount', 'in_winamount', 'in_netamount']
       break;

    case 19:
        return ['in_revenueamount', 'in_betamount', 'in_winamount', 'in_netamount']
        break;
        
    case 10:
        return  ['in_betamount', 'in_winamount', 'in_claimedamount']
        break;

    case 11:
        return ['in_betamount', 'in_winamount', 'in_unclaimedamount']
        break;

    case 17:
        return ['in_betamount', 'in_claimedamount', 'in_unclaimamount']
        break;
        //ykarthik
    case 22:
        return ['netamount','winningamount','salesamount']
        break;


        
    default:
     return [];

}


//     if (rootFlag === 1 || rootFlag === 12 || rootFlag === 13 || rootFlag === 14 || rootFlag === 16) {
//         return ['in_betamount', 'in_winamount']
//     }
//     else if (rootFlag === 2 || rootFlag === 3 || rootFlag === 4 || rootFlag === 5 ||
//         rootFlag === 8 || rootFlag === 9 || rootFlag === 15 || rootFlag === 18) {
//         return ['in_betamount', 'in_winamount', 'in_netamount']
//     }
//     else if (rootFlag === 19) {
//         return ['in_revenueamount', 'in_betamount', 'in_winamount', 'in_netamount']
//     }
//     else if (rootFlag === 10) {
//         return ['in_betamount', 'in_winamount', 'in_claimedamount']
//     }
//     else if (rootFlag === 11) {
//         return ['in_betamount', 'in_winamount', 'in_unclaimedamount']
//     }
//     else if (rootFlag === 17) {
//         return ['in_betamount', 'in_claimedamount', 'in_unclaimamount']
//     }

// ///////ykarthik
//     else if (rootFlag === 22){
//         return['netamount','winningamount','salesamount']
//     }
//     else
//         return []
}
// Table based- Filter Data (Search option)
const getHeaderKeyArr = (rootFlag: number, isHyperLink?: boolean, isSelSwitch?: number) => {

//ykarhtik 26.12
switch(rootFlag) {


    case 1:{
        return ['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
            'in_transcount', 'in_betamount', 'in_winamount', 'in_payout', 'in_status']}
        break;
    case   2: case  3:
        return ['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
             'in_transcount', 'in_betamount', 'in_winamount', 'in_payout', 'in_netamount', 'in_status']
        break;
    case 4: case 5:
        return ['in_organizername', 'in_gamegroupname', 'in_betamount', 'in_winamount', 'in_payout', 'in_netamount']
        break;
    case  6:
        return ['in_transactionid', 'in_transcount', 'in_transamount', 'in_transdatetime', 'in_receiveddatetime',
                    'in_gamename', 'in_recordid', 'in_status']
        break;
    case 7: 
        return ['in_transactionid', 'in_transcount', 'in_transamount', 'in_transdatetime', 'in_receiveddatetime',
                 'in_gamename', 'in_betinfo', 'in_recordid', 'in_subrecordid', 'in_status']
                 break;
    case  8:
        return ['in_organizername', 'in_terminalid', 'in_drawdate',
                   'in_transcount', 'in_betamount', 'in_winamount', 'in_payout', 'in_netamount']
                   break;
    case 9:
        return ['in_organizername', 'in_gametype', 'in_terminalid', 'in_drawdate',
                'in_transcount', 'in_betamount', 'in_winamount', 'in_payout', 'in_netamount']
                break;

    case  10:
         return isHyperLink ?
                 (['in_transactionid', 'in_transdatetime', 'in_claimamount', 'in_claimdatetime',
                    'in_terminalid', 'in_claimterminalid']) :
                 (['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
                    'in_betamount', 'in_winamount', 'in_claimedamount'])
                    break;
     case 11:
         return isHyperLink ?
                (['in_transactionid', 'in_transdatetime', 'in_unclaimamount', 'in_terminalid']) :
                (['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
                 'in_betamount', 'in_winamount', 'in_unclaimedamount'])
                break;
    case  12:{
            return isHyperLink ?
                (['in_transactionid', 'in_transdatetime', 'in_winamount', 'in_ticketinfo', 'in_claimstatus']) :
                (['in_organizername', 'in_gamename', 'in_terminalid', 'in_drawdate', 'in_drawtime',
                'in_betamount', 'in_winamount', 'in_payout'])}
                break;
    case  13:
            return ['in_organizername', 'in_channelname', 'in_drawdate', 'in_drawtime',
                   'in_betamount', 'in_winamount', 'in_payout']
                break;
     case 14:
            return ['in_organizername', 'in_modename', 'in_drawdate', 'in_drawtime',
                    'in_betamount', 'in_winamount', 'in_payout']
                break;
    case 15:
            return ['in_organizername', 'in_drawdate', 'in_transcount',
                    'in_betamount', 'in_winamount', 'in_payout', 'in_netamount', 'in_status']
                break;
    case  16:
            return ['in_organizername', 'in_gamename', 'in_playerid', 'in_drawdate', 'in_drawtime',
                'in_betamount', 'in_winamount', 'in_payout']
                 break;
    case  17:
            return ['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
                'in_betamount', 'in_claimedamount', 'in_unclaimamount']
                break;
    case  18:
            return isHyperLink ?
                (['in_organizername', 'in_betamount', 'in_winamount', 'in_netamount']) :
                (['in_betamount', 'in_winamount', 'in_netamount'])
                break;
    case 19:
            return isHyperLink ? (['in_sstockistname', 'in_gametyename', 'in_commissiontype', 'in_betamount', 'in_paymentamount', 'in_salecommission', 'in_claimcommission', 'in_netsale']) :
            (['in_organizername', 'in_revenueamount', 'in_betamount', 'in_winamount', 'in_netamount'])
              // return isHyperLink && isSelSwitch === 1 ? 
             // (['in_sstockistname','in_stockistname','in_betamount','in_paymentamount','in_salecommission','in_claimcommission','in_netsale']) : 
             // isHyperLink ? (['in_sstockistname','in_stockistname','in_terminalid','in_betamount','in_paymentamount','in_salecommission','in_claimcommission','in_netsale']) : 
             // (['in_organizername','in_betamount','in_winamount','in_netamount']) 
            break;
            //ykarthik
    case 20:
            return ['paymode','amount',"status","transactiondatetime","transactionid"]
            break;

    case 21:
            return ['paymode','amount',"status","transactiondatetime","transactionid"]
            break;

    case 22:
            return["in_organizername","date","salesamount","salescomm","winningamount","tax","netamount"]
            break;

    case 25:
            return["in_organizername","addmoney","claim","sales","winning","withdraw"]
            break;
       default:
       return []
}


//     if (rootFlag === 1) {
//         return ['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
//             'in_transcount', 'in_betamount', 'in_winamount', 'in_payout', 'in_status']
//     }
//     else if (rootFlag === 2 || rootFlag === 3) {
//         return ['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
//             'in_transcount', 'in_betamount', 'in_winamount', 'in_payout', 'in_netamount', 'in_status']
//     }
//     else if (rootFlag === 4 || rootFlag === 5) {
//         return ['in_organizername', 'in_gamegroupname', 'in_betamount', 'in_winamount', 'in_payout', 'in_netamount']
//     }
//     else if (rootFlag === 6) {
//         return ['in_transactionid', 'in_transcount', 'in_transamount', 'in_transdatetime', 'in_receiveddatetime',
//             'in_gamename', 'in_recordid', 'in_status']
//     }
//     else if (rootFlag === 7) {
//         return ['in_transactionid', 'in_transcount', 'in_transamount', 'in_transdatetime', 'in_receiveddatetime',
//             'in_gamename', 'in_betinfo', 'in_recordid', 'in_subrecordid', 'in_status']
//     }
//     else if (rootFlag === 8) {
//         return ['in_organizername', 'in_terminalid', 'in_drawdate',
//             'in_transcount', 'in_betamount', 'in_winamount', 'in_payout', 'in_netamount']
//     }
//     else if (rootFlag === 9) {
//         return ['in_organizername', 'in_gametype', 'in_terminalid', 'in_drawdate',
//             'in_transcount', 'in_betamount', 'in_winamount', 'in_payout', 'in_netamount']
//     }
//     else if (rootFlag === 10) {
//         return isHyperLink ?
//             (['in_transactionid', 'in_transdatetime', 'in_claimamount', 'in_claimdatetime',
//                 'in_terminalid', 'in_claimterminalid']) :
//             (['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
//                 'in_betamount', 'in_winamount', 'in_claimedamount'])
//     }
//     else if (rootFlag === 11) {
//         return isHyperLink ?
//             (['in_transactionid', 'in_transdatetime', 'in_unclaimamount', 'in_terminalid']) :
//             (['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
//                 'in_betamount', 'in_winamount', 'in_unclaimedamount'])
//     }
//     else if (rootFlag === 12) {
//         return isHyperLink ?
//             (['in_transactionid', 'in_transdatetime', 'in_winamount', 'in_ticketinfo', 'in_claimstatus']) :
//             (['in_organizername', 'in_gamename', 'in_terminalid', 'in_drawdate', 'in_drawtime',
//                 'in_betamount', 'in_winamount', 'in_payout'])
//     }
//     else if (rootFlag === 13) {
//         return ['in_organizername', 'in_channelname', 'in_drawdate', 'in_drawtime',
//             'in_betamount', 'in_winamount', 'in_payout']
//     }
//     else if (rootFlag === 14) {
//         return ['in_organizername', 'in_modename', 'in_drawdate', 'in_drawtime',
//             'in_betamount', 'in_winamount', 'in_payout']
//     }
//     else if (rootFlag === 15) {
//         return ['in_organizername', 'in_drawdate', 'in_transcount',
//             'in_betamount', 'in_winamount', 'in_payout', 'in_netamount', 'in_status']
//     }
//     else if (rootFlag === 16) {
//         return ['in_organizername', 'in_gamename', 'in_playerid', 'in_drawdate', 'in_drawtime',
//             'in_betamount', 'in_winamount', 'in_payout']
//     }
//     else if (rootFlag === 17) {
//         return ['in_organizername', 'in_gamename', 'in_drawdate', 'in_drawtime',
//             'in_betamount', 'in_claimedamount', 'in_unclaimamount']
//     }
//     else if (rootFlag === 18) {
//         return isHyperLink ?
//             (['in_organizername', 'in_betamount', 'in_winamount', 'in_netamount']) :
//             (['in_betamount', 'in_winamount', 'in_netamount'])
//     }
//     else if (rootFlag === 19) {
//         return isHyperLink ? (['in_sstockistname', 'in_gametyename', 'in_commissiontype', 'in_betamount', 'in_paymentamount', 'in_salecommission', 'in_claimcommission', 'in_netsale']) :
//             (['in_organizername', 'in_revenueamount', 'in_betamount', 'in_winamount', 'in_netamount'])
//         // return isHyperLink && isSelSwitch === 1 ? 
//         // (['in_sstockistname','in_stockistname','in_betamount','in_paymentamount','in_salecommission','in_claimcommission','in_netsale']) : 
//         // isHyperLink ? (['in_sstockistname','in_stockistname','in_terminalid','in_betamount','in_paymentamount','in_salecommission','in_claimcommission','in_netsale']) : 
//         // (['in_organizername','in_betamount','in_winamount','in_netamount']) 
//     }


// ///////ykarthik
//     else if (rootFlag === 20) {
//         return ['paymode','amount',"status","transactiondatetime","transactionid"] 
//     }
//     else if (rootFlag === 21) {
//         return ['paymode','amount',"status","transactiondatetime","transactionid"] }
//     else if (rootFlag === 22) {
//         return["in_organizername","date","salesamount","salescomm","winningamount","tax","netamount"]

//     }
//     else if (rootFlag === 25) {
//         return["in_organizername","addmoney","claim","sales","winning","withdraw"]

//     }
//     else
//         return []
}
// Table based- Filter Data (Search option)
const getKeyByValue = (objRes: any, searchInput: string, rootFlag: number, isHyperLink?: boolean, isSelSwitch?: number) => {
    for (var prop of getHeaderKeyArr(rootFlag, isHyperLink, isSelSwitch)) {
        if (objRes.hasOwnProperty(prop)) {
            if (objRes[prop].toString().toLowerCase().includes(searchInput.toLowerCase()))
                return true;
        }
    }
}
//use for PDF-1 and Print Preview (Header)
const getHeaderArr = (rootFlag: number, isHyperLink?: boolean, isSelSwitch?: number) => {

//ykarhtik 26.12
switch(true) {
    case rootFlag === 1:
        return ["#", "Org. Name", "Game Name", "Draw Date", "Draw Time", "Trans Count",
            "Bet Amt.", "Winning Amt.", "Payout (%)", "Status"]
        break;
    case  rootFlag === 2: case  rootFlag === 3:
        return ["#", "Org. Name", "Game Name", "Draw Date", "Draw Time", "Trans Count",
        "Bet Amt.", "Winning Amt.", "Payout (%)", "Net Amt.", "Status"]
        break;
    case  rootFlag === 4: case  rootFlag === 5:
        return ["#", "Org. Name", "Game Type", "Bet Amt.", "Winning Amt.", "Payout (%)", "Net Amt."]
        break;
    case  rootFlag === 6:
        return ['#', 'TXN ID', 'Trans Count', 'Trans Amount', 'Trans DateTime', 'Recei. DateTime',
        'Game Name', 'Record Id', 'Status']
        break;
    case  rootFlag === 7: 
    return ['#', 'TXN ID', 'Trans Count', 'Trans Amount', 'Trans DateTime', 'Recei. DateTime',
    'Game Name', 'Bet Info', 'Record Id', 'SubRecord Id', 'Status']
        break;
    case  rootFlag === 8:
        return ['#', 'Org. Name', 'Terminal Id', 'Draw Time',
        'Trans Count', 'Bet Amt.', 'Winning Amt.', 'Payout (%)', 'Net Amt.']
        break;
    case  rootFlag === 9:
        return ['#', 'Org. Name', 'Game Type', 'Terminal Id', 'Draw Time',
        'Trans Count', 'Bet Amt.', 'Winning Amt.', 'Payout (%)', 'Net Amt.']
        break;

    case  rootFlag === 10:
        return isHyperLink ? ['#', 'TXN ID', 'Trans DateTime', 'Claimed Amt.', 'Claimed DateTime',
        'Terminal Id', 'Claim. Terminal Id'] :
        ['#', 'Org. Name', 'Game Name', 'Draw Date', 'Draw Time',
            'Bet Amt.', 'Winning Amt.', 'Claimed Amt.']
        break;
     case  rootFlag === 11:
         return isHyperLink ? ['#', 'TXN ID', 'Trans DateTime', 'UnClaimed Amt.', 'Terminal Id'] :
         ['#', 'Org. Name', 'Game Name', 'Draw Date', 'Draw Time',
             'Bet Amt.', 'Winning Amt.', 'UnClaimed Amt.']
        break;
    case  rootFlag === 12:
            return isHyperLink ? ['#', 'TXN ID', 'Trans DateTime', 'Winning Amt.', 'Ticket Info', 'Claimed Status'] :
            ['#', 'Org. Name', 'Game Name', 'Terminal Id', 'Draw Date', 'Draw Time',
                'Bet Amt.', 'Winning Amt.', 'Payout (%)']
        break;
    case  rootFlag === 13:
        return ['#', 'Org. Name', 'Channel Name', 'Draw Date', 'Draw Time',
            'Bet Amt.', 'Winning Amt.', 'Payout (%)']
        break;
     case  rootFlag === 14:
        return ['#', 'Org. Name', 'Mode Name', 'Draw Date', 'Draw Time',
        'Bet Amt.', 'Winning Amt.', 'Payout (%)']
        break;
    case  rootFlag === 15:
        return ['#', 'Org. Name', 'Draw Date', 'Trans Count',
        'Bet Amt.', 'Winning Amt.', 'Payout (%)', 'Net Amt.', 'Status']
         break;
    case  rootFlag === 16:
        return ['#', 'Org. Name', 'Game Name', 'Player Id', 'Draw Date', 'Draw Time',
        'Bet Amt.', 'Winning Amt.', 'Payout (%)']
         break;
    case  rootFlag === 17:
        return ['#', 'Org. Name', 'Game Name', 'Draw Date', 'Draw Time',
        'Bet Amt.', 'Claimed Amt.', 'UnClaimed Amt.']
        break;
    case  rootFlag === 18:
        return isHyperLink ? ['#', 'Org. Name', 'Bet Amt.', 'Winning Amt.', 'Net Amt.'] :
        ['#', 'Bet Amt.', 'Winning Amt.', 'Net Amt.']
        break;
    case  rootFlag === 19:
        return isHyperLink ? ['#', 'Stock. Name', 'Game Type', 'Commission Type',
        'Bet Amt.', 'Claimed Amt.', 'Sales Comm.', 'Claim Comm.', 'Net Sale'] :
        ['#', 'Org. Name', 'Revenue Amt.', 'Bet Amt.', 'Winning Amt.', 'Net Amt.']
    // return isHyperLink && isSelSwitch === 1 ? ['#','Stock. Name','Sub Stock. Name',
    // 'Bet Amt.','Claimed Amt.','Sales Comm.','Claim Comm.','Net Sale'] : 
    // isHyperLink ? ['#','Stock. Name','Sub Stock. Name','Terminal Id',
    // 'Bet Amt.','Claimed Amt.','Sales Comm.','Claim Comm.','Net Sale'] : 
    // ['#','Org. Name','Bet Amt.','Winning Amt.','Net Amt.'] 
        break;
            //ykarthik
    case rootFlag === 20:
        return['#',"TXN ID","Status","Paymode","Claimed Amt.","Trans DateTime"]
        break;
    case  rootFlag === 21:
        return['#',"TXN ID","Status","Paymode","Claimed Amt.","Trans DateTime"]
        break;
    case  rootFlag === 22:
        return['#',"Org. Name","Date","Sales Amt.","Sales Comm.","Winning Amt.","Tax Amt.","Net Amt."]    
         break;
    case  rootFlag === 25:
        return['#',"Org. Name","Sales","Winning","Claim","Add Money","With Draw"]      
        break;

    default:
       return []
}




//     if (rootFlag === 1) {
//         return ["#", "Org. Name", "Game Name", "Draw Date", "Draw Time", "Trans Count",
//             "Bet Amt.", "Winning Amt.", "Payout (%)", "Status"]
//     }
//     else if (rootFlag === 2 || rootFlag === 3) {
//         return ["#", "Org. Name", "Game Name", "Draw Date", "Draw Time", "Trans Count",
//             "Bet Amt.", "Winning Amt.", "Payout (%)", "Net Amt.", "Status"]
//     }
//     else if (rootFlag === 4 || rootFlag === 5) {
//         return ["#", "Org. Name", "Game Type", "Bet Amt.", "Winning Amt.", "Payout (%)", "Net Amt."]
//     }
//     else if (rootFlag === 6) {
//         return ['#', 'TXN ID', 'Trans Count', 'Trans Amount', 'Trans DateTime', 'Recei. DateTime',
//             'Game Name', 'Record Id', 'Status']
//     }
//     else if (rootFlag === 7) {
//         return ['#', 'TXN ID', 'Trans Count', 'Trans Amount', 'Trans DateTime', 'Recei. DateTime',
//             'Game Name', 'Bet Info', 'Record Id', 'SubRecord Id', 'Status']
//     }
//     else if (rootFlag === 8) {
//         return ['#', 'Org. Name', 'Terminal Id', 'Draw Time',
//             'Trans Count', 'Bet Amt.', 'Winning Amt.', 'Payout (%)', 'Net Amt.']
//     }
//     else if (rootFlag === 9) {
//         return ['#', 'Org. Name', 'Game Type', 'Terminal Id', 'Draw Time',
//             'Trans Count', 'Bet Amt.', 'Winning Amt.', 'Payout (%)', 'Net Amt.']
//     }
//     else if (rootFlag === 10) {
//         return isHyperLink ? ['#', 'TXN ID', 'Trans DateTime', 'Claimed Amt.', 'Claimed DateTime',
//             'Terminal Id', 'Claim. Terminal Id'] :
//             ['#', 'Org. Name', 'Game Name', 'Draw Date', 'Draw Time',
//                 'Bet Amt.', 'Winning Amt.', 'Claimed Amt.']
//     }
//     else if (rootFlag === 11) {
//         return isHyperLink ? ['#', 'TXN ID', 'Trans DateTime', 'UnClaimed Amt.', 'Terminal Id'] :
//             ['#', 'Org. Name', 'Game Name', 'Draw Date', 'Draw Time',
//                 'Bet Amt.', 'Winning Amt.', 'UnClaimed Amt.']
//     }
//     else if (rootFlag === 12) {
//         return isHyperLink ? ['#', 'TXN ID', 'Trans DateTime', 'Winning Amt.', 'Ticket Info', 'Claimed Status'] :
//             ['#', 'Org. Name', 'Game Name', 'Terminal Id', 'Draw Date', 'Draw Time',
//                 'Bet Amt.', 'Winning Amt.', 'Payout (%)']
//     }
//     else if (rootFlag === 13) {
//         return ['#', 'Org. Name', 'Channel Name', 'Draw Date', 'Draw Time',
//             'Bet Amt.', 'Winning Amt.', 'Payout (%)']
//     }
//     else if (rootFlag === 14) {
//         return ['#', 'Org. Name', 'Mode Name', 'Draw Date', 'Draw Time',
//             'Bet Amt.', 'Winning Amt.', 'Payout (%)']
//     }
//     else if (rootFlag === 15) {
//         return ['#', 'Org. Name', 'Draw Date', 'Trans Count',
//             'Bet Amt.', 'Winning Amt.', 'Payout (%)', 'Net Amt.', 'Status']
//     }
//     else if (rootFlag === 16) {
//         return ['#', 'Org. Name', 'Game Name', 'Player Id', 'Draw Date', 'Draw Time',
//             'Bet Amt.', 'Winning Amt.', 'Payout (%)']
//     }
//     else if (rootFlag === 17) {
//         return ['#', 'Org. Name', 'Game Name', 'Draw Date', 'Draw Time',
//             'Bet Amt.', 'Claimed Amt.', 'UnClaimed Amt.']
//     }
//     else if (rootFlag === 18) {
//         return isHyperLink ? ['#', 'Org. Name', 'Bet Amt.', 'Winning Amt.', 'Net Amt.'] :
//             ['#', 'Bet Amt.', 'Winning Amt.', 'Net Amt.']
//     }
//     else if (rootFlag === 19) {
//         return isHyperLink ? ['#', 'Stock. Name', 'Game Type', 'Commission Type',
//             'Bet Amt.', 'Claimed Amt.', 'Sales Comm.', 'Claim Comm.', 'Net Sale'] :
//             ['#', 'Org. Name', 'Revenue Amt.', 'Bet Amt.', 'Winning Amt.', 'Net Amt.']
//         // return isHyperLink && isSelSwitch === 1 ? ['#','Stock. Name','Sub Stock. Name',
//         // 'Bet Amt.','Claimed Amt.','Sales Comm.','Claim Comm.','Net Sale'] : 
//         // isHyperLink ? ['#','Stock. Name','Sub Stock. Name','Terminal Id',
//         // 'Bet Amt.','Claimed Amt.','Sales Comm.','Claim Comm.','Net Sale'] : 
//         // ['#','Org. Name','Bet Amt.','Winning Amt.','Net Amt.'] 
//     }
// //ykarthik///////
//     else if (rootFlag===20){
//         return['#',"TXN ID","Status","Paymode","Claimed Amt.","Trans DateTime"]

//     }
//     else if(rootFlag===21){
//         return['#',"TXN ID","Status","Paymode","Claimed Amt.","Trans DateTime"]

//     }
//     else if(rootFlag===22){
//         return['#',"Org. Name","Date","Sales Amt.","Sales Comm.","Winning Amt.","Tax Amt.","Net Amt."]
        
//     }
//     else if(rootFlag===25){
//         return['#',"Org. Name","Sales","Winning","Claim","Add Money","With Draw"]
        
//     }
//     else
//         return []
}
//use for PDF-2 (Field Datas)
const getTableData = (rootFlag: number, tabData: any, isHyperLink?: boolean, isSelSwitch?: number) => {
   
   
      //ykarthik 26.12
      switch(rootFlag ){

        case 1:
            return tabData.map((elt: any) => {
                return [elt.in_sno === undefined ? "" : elt.in_sno,
                elt.in_organizername, elt.in_gamename, elt.in_drawdate,
                elt.in_drawtime, elt.in_transcount, elt.in_betamount,
                elt.in_winamount, elt.in_payout, elt.in_status]
            });
            break;

        case 2: case 3:
            return tabData.map((elt: any) => {
                return [elt.in_sno === undefined ? "" : elt.in_sno,
                elt.in_organizername, elt.in_gamename, elt.in_drawdate,
                elt.in_drawtime, elt.in_transcount, elt.in_betamount,
                elt.in_winamount, elt.in_payout, elt.in_netamount, elt.in_status]
            });
            break;
        case 4: case 5:
            return tabData.map((elt: any) => {
                return [elt.in_sno === undefined ? "" : elt.in_sno,
                elt.in_organizername, elt.in_gamegroupname, elt.in_betamount,
                elt.in_winamount, elt.in_payout, elt.in_netamount]
            });
            break;
        case 6:
             return tabData.map((elt: any) => {
                    return [elt.in_sno === undefined ? "" : elt.in_sno,
                    elt.in_transactionid, elt.in_transcount, elt.in_transamount,
                    elt.in_transdatetime, elt.in_receiveddatetime,
                    elt.in_gamename, elt.in_recordid, elt.in_status]
                });
             break;

        case 7:
            return tabData.map((elt: any) => {
                        return [elt.in_sno === undefined ? "" : elt.in_sno,
                        elt.in_transactionid, elt.in_transcount, elt.in_transamount,
                        elt.in_transdatetime, elt.in_receiveddatetime,
                        elt.in_gamename, elt.in_betinfo, elt.in_recordid, elt.in_subrecordid, elt.in_status]
                    }); 
            break;
        case 8:
            return tabData.map((elt: any) => {
             return [elt.in_sno === undefined ? "" : elt.in_sno,
                        elt.in_organizername, elt.in_terminalid, elt.in_drawdate,
                        elt.in_transcount, elt.in_betamount, elt.in_winamount, elt.in_payout, elt.in_netamount]
                    });
            break;
        case 9:
            return tabData.map((elt: any) => {
            return [elt.in_sno === undefined ? "" : elt.in_sno,
                            elt.in_organizername, elt.in_gametype, elt.in_terminalid, elt.in_drawdate,
                            elt.in_transcount, elt.in_betamount, elt.in_winamount, elt.in_payout, elt.in_netamount]
                        });
            break;            

        case 10:
            return tabData.map((elt: any) => {
            return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
                         elt.in_transactionid, elt.in_transdatetime, elt.in_claimamount, elt.in_claimdatetime,
                          elt.in_terminalid, elt.in_claimterminalid] :
                             [elt.in_sno === undefined ? "" : elt.in_sno,
                             elt.in_organizername, elt.in_gamename, elt.in_drawdate, elt.in_drawtime,
                             elt.in_betamount, elt.in_winamount, elt.in_claimedamount]
                     });
            break;
                 
        case 11:
            return tabData.map((elt: any) => {
             return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
                            elt.in_transactionid, elt.in_transdatetime, elt.in_unclaimamount, elt.in_terminalid] :
                                [elt.in_sno === undefined ? "" : elt.in_sno,
                                elt.in_organizername, elt.in_gamename, elt.in_drawdate, elt.in_drawtime,
                                elt.in_betamount, elt.in_winamount, elt.in_unclaimedamount]
                        });
                        break;
        case 12:
            return tabData.map((elt: any) => {
                            return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
                            elt.in_transactionid, elt.in_transdatetime, elt.in_winamount, elt.in_ticketinfo,
                            elt.in_claimstatus] :
                                [elt.in_sno === undefined ? "" : elt.in_sno,
                                elt.in_organizername, elt.in_gamename, elt.in_terminalid, elt.in_drawdate, elt.in_drawtime,
                                elt.in_betamount, elt.in_winamount, elt.in_payout]
                        });
            break;
        case 13:
            return tabData.map((elt: any) => {
                                return [elt.in_sno === undefined ? "" : elt.in_sno,
                                elt.in_organizername, elt.in_channelname, elt.in_drawdate, elt.in_drawtime,
                                elt.in_betamount, elt.in_winamount, elt.in_payout]
                            });
                            break;
        case 14:
            return tabData.map((elt: any) => {
                                    return [elt.in_sno === undefined ? "" : elt.in_sno,
                                    elt.in_organizername, elt.in_modename, elt.in_drawdate, elt.in_drawtime,
                                    elt.in_betamount, elt.in_winamount, elt.in_payout]
                                });
             break;

        case 15:
            return tabData.map((elt: any) => {
                                        return [elt.in_sno === undefined ? "" : elt.in_sno,
                                        elt.in_organizername, elt.in_drawdate, elt.in_transcount,
                                        elt.in_betamount, elt.in_winamount, elt.in_payout, elt.in_netamount, elt.in_status]
                                    });
            break;

        case 16:
            return tabData.map((elt: any) => {
                                            return [elt.in_sno === undefined ? "" : elt.in_sno,
                                            elt.in_organizername, elt.in_gamename, elt.in_playerid,
                                            elt.in_drawdate, elt.in_drawtime, elt.in_betamount, elt.in_winamount, elt.in_payout]
                                        });
            break;
        case 17:
            return tabData.map((elt: any) => {
                                                return [elt.in_sno === undefined ? "" : elt.in_sno,
                                                elt.in_organizername, elt.in_gamename,
                                                elt.in_drawdate, elt.in_drawtime, elt.in_betamount, elt.in_claimedamount, elt.in_unclaimamount]
                                            });
            break;
        case 18:
            return tabData.map((elt: any) => {
                             return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
                                                    elt.in_organizername, elt.in_betamount, elt.in_winamount, elt.in_netamount] :
                                                        [elt.in_sno === undefined ? "" : elt.in_sno,
                                                        elt.in_betamount, elt.in_winamount, elt.in_netamount]
                                                });
            break;

        case 19:
            return tabData.map((elt: any) => {
                                return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
                                                        elt.in_sstockistname, elt.in_gametyename, elt.in_commissiontype, elt.in_betamount,
                                                        elt.in_paymentamount, elt.in_salecommission, elt.in_claimcommission, elt.in_netsale] :
                                                            [elt.in_sno === undefined ? "" : elt.in_sno,
                                                            elt.in_organizername, elt.in_revenueamount, elt.in_betamount, elt.in_winamount, elt.in_netamount]
                                                        // return  isHyperLink && isSelSwitch === 1 ? [elt.in_sno === undefined ? "" : elt.in_sno,
                                                        // elt.in_sstockistname,elt.in_stockistname,elt.in_betamount,
                                                        // elt.in_paymentamount,elt.in_salecommission, elt.in_claimcommission,elt.in_netsale] :
                                                        // isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
                                                        // elt.in_sstockistname,elt.in_stockistname,elt.in_terminalid,elt.in_betamount,
                                                        // elt.in_paymentamount,elt.in_salecommission, elt.in_claimcommission,elt.in_netsale] :
                                                        // [elt.in_sno === undefined ? "" : elt.in_sno,
                                                        // elt.in_organizername,elt.in_betamount, elt.in_winamount,elt.in_netamount]
                                                    });
             break;
        case 20:{
             return tabData.map((elt: any) => {
                             return [elt.in_sno === undefined ? "" : elt.in_sno,
                      elt.transactionid, elt.status, elt.paymode, elt.amount,elt.transactiondatetime
                                     ] });
                         }
            break;
        case 21:
            return tabData.map((elt: any) => {
                    return [elt.in_sno === undefined ? "" : elt.in_sno,
                     elt.transactionid, elt.status, elt.paymode, elt.amount,elt.transactiondatetime
                  ]});
                                                    
            break;

         case 22:{
            return tabData.map((elt: any) => {
                 return [elt.in_sno === undefined ? "" : elt.in_sno,elt.in_organizername,
                 elt.date,elt.salesamount,elt.salescomm,elt.winningamount,
                 elt.tax,elt.netamount ] }); }
                 break;

        case 25:
            return tabData.map((elt: any) => {
            return [elt.in_sno === undefined ? "" : elt.in_sno,elt.in_organizername,
             elt.sales,elt.winning,elt.claim,elt.addmoney,elt.withdraw ] });

            break;

        default:

        return tabData; 
                                                
                                                  
    }
  
   
   
   
//     if (rootFlag === 1) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_gamename, elt.in_drawdate,
//             elt.in_drawtime, elt.in_transcount, elt.in_betamount,
//             elt.in_winamount, elt.in_payout, elt.in_status]
//         });
//     }
//     else if (rootFlag === 2 || rootFlag === 3) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_gamename, elt.in_drawdate,
//             elt.in_drawtime, elt.in_transcount, elt.in_betamount,
//             elt.in_winamount, elt.in_payout, elt.in_netamount, elt.in_status]
//         });
//     }
//     else if (rootFlag === 4 || rootFlag === 5) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_gamegroupname, elt.in_betamount,
//             elt.in_winamount, elt.in_payout, elt.in_netamount]
//         });
//     }
//     else if (rootFlag === 6) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_transactionid, elt.in_transcount, elt.in_transamount,
//             elt.in_transdatetime, elt.in_receiveddatetime,
//             elt.in_gamename, elt.in_recordid, elt.in_status]
//         });
//     }
//     else if (rootFlag === 7) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_transactionid, elt.in_transcount, elt.in_transamount,
//             elt.in_transdatetime, elt.in_receiveddatetime,
//             elt.in_gamename, elt.in_betinfo, elt.in_recordid, elt.in_subrecordid, elt.in_status]
//         });
//     }
//     else if (rootFlag === 8) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_terminalid, elt.in_drawdate,
//             elt.in_transcount, elt.in_betamount, elt.in_winamount, elt.in_payout, elt.in_netamount]
//         });
//     }
//     else if (rootFlag === 9) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_gametype, elt.in_terminalid, elt.in_drawdate,
//             elt.in_transcount, elt.in_betamount, elt.in_winamount, elt.in_payout, elt.in_netamount]
//         });
//     }
//     else if (rootFlag === 10) {
//         return tabData.map((elt: any) => {
//             return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_transactionid, elt.in_transdatetime, elt.in_claimamount, elt.in_claimdatetime,
//             elt.in_terminalid, elt.in_claimterminalid] :
//                 [elt.in_sno === undefined ? "" : elt.in_sno,
//                 elt.in_organizername, elt.in_gamename, elt.in_drawdate, elt.in_drawtime,
//                 elt.in_betamount, elt.in_winamount, elt.in_claimedamount]
//         });
//     }
//     else if (rootFlag === 11) {
//         return tabData.map((elt: any) => {
//             return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_transactionid, elt.in_transdatetime, elt.in_unclaimamount, elt.in_terminalid] :
//                 [elt.in_sno === undefined ? "" : elt.in_sno,
//                 elt.in_organizername, elt.in_gamename, elt.in_drawdate, elt.in_drawtime,
//                 elt.in_betamount, elt.in_winamount, elt.in_unclaimedamount]
//         });
//     }
//     else if (rootFlag === 12) {
//         return tabData.map((elt: any) => {
//             return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_transactionid, elt.in_transdatetime, elt.in_winamount, elt.in_ticketinfo,
//             elt.in_claimstatus] :
//                 [elt.in_sno === undefined ? "" : elt.in_sno,
//                 elt.in_organizername, elt.in_gamename, elt.in_terminalid, elt.in_drawdate, elt.in_drawtime,
//                 elt.in_betamount, elt.in_winamount, elt.in_payout]
//         });
//     }
//     else if (rootFlag === 13) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_channelname, elt.in_drawdate, elt.in_drawtime,
//             elt.in_betamount, elt.in_winamount, elt.in_payout]
//         });
//     }
//     else if (rootFlag === 14) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_modename, elt.in_drawdate, elt.in_drawtime,
//             elt.in_betamount, elt.in_winamount, elt.in_payout]
//         });
//     }
//     else if (rootFlag === 15) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_drawdate, elt.in_transcount,
//             elt.in_betamount, elt.in_winamount, elt.in_payout, elt.in_netamount, elt.in_status]
//         });
//     }
//     else if (rootFlag === 16) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_gamename, elt.in_playerid,
//             elt.in_drawdate, elt.in_drawtime, elt.in_betamount, elt.in_winamount, elt.in_payout]
//         });
//     }
//     else if (rootFlag === 17) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_gamename,
//             elt.in_drawdate, elt.in_drawtime, elt.in_betamount, elt.in_claimedamount, elt.in_unclaimamount]
//         });
//     }
//     else if (rootFlag === 18) {
//         return tabData.map((elt: any) => {
//             return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_organizername, elt.in_betamount, elt.in_winamount, elt.in_netamount] :
//                 [elt.in_sno === undefined ? "" : elt.in_sno,
//                 elt.in_betamount, elt.in_winamount, elt.in_netamount]
//         });
//     }
//     else if (rootFlag === 19) {
//         return tabData.map((elt: any) => {
//             return isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.in_sstockistname, elt.in_gametyename, elt.in_commissiontype, elt.in_betamount,
//             elt.in_paymentamount, elt.in_salecommission, elt.in_claimcommission, elt.in_netsale] :
//                 [elt.in_sno === undefined ? "" : elt.in_sno,
//                 elt.in_organizername, elt.in_revenueamount, elt.in_betamount, elt.in_winamount, elt.in_netamount]
//             // return  isHyperLink && isSelSwitch === 1 ? [elt.in_sno === undefined ? "" : elt.in_sno,
//             // elt.in_sstockistname,elt.in_stockistname,elt.in_betamount,
//             // elt.in_paymentamount,elt.in_salecommission, elt.in_claimcommission,elt.in_netsale] :
//             // isHyperLink ? [elt.in_sno === undefined ? "" : elt.in_sno,
//             // elt.in_sstockistname,elt.in_stockistname,elt.in_terminalid,elt.in_betamount,
//             // elt.in_paymentamount,elt.in_salecommission, elt.in_claimcommission,elt.in_netsale] :
//             // [elt.in_sno === undefined ? "" : elt.in_sno,
//             // elt.in_organizername,elt.in_betamount, elt.in_winamount,elt.in_netamount]
//         });
//     }

// //ykarthik///////////////////////

//     else if (rootFlag === 20) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.transactionid, elt.status, elt.paymode, elt.amount,elt.transactiondatetime
//             ]
 

//         });
//     }
//     else if (rootFlag === 21) {
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,
//             elt.transactionid, elt.status, elt.paymode, elt.amount,elt.transactiondatetime
//             ]


//         });
//     }
//     else if (rootFlag === 22) {  
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,elt.in_organizername,
//             elt.date,elt.salesamount,elt.salescomm,elt.winningamount,
//             elt.tax,elt.netamount
//             ]


//         });
//     }
//     else if (rootFlag === 25) {  
//         return tabData.map((elt: any) => {
//             return [elt.in_sno === undefined ? "" : elt.in_sno,elt.in_organizername,
//             elt.sales,elt.winning,elt.claim,elt.addmoney,elt.withdraw
//             ]


//         });
//     }
//     /////////////////////////////
//     else
//         return tabData
}
/* Export PDF */
const exportPDF = (tabData: any, pageTitle: string, fileName: string, rootFlag: number, genTime: string, isHyperLink?: boolean, isSelSwitch?: number) => {
    try {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = pageTitle;
        const headers = [getHeaderArr(rootFlag, isHyperLink, isSelSwitch)];
        const data = getTableData(rootFlag, tabData, isHyperLink, isSelSwitch); //tabData.map((elt: any)=> [elt.name, elt.profession]);

        let content = {
            startY: 50,
            head: headers,
            body: data,
            didParseCell: function (table: any) {
                if (table.section === 'head') {
                    table.cell.styles.fillColor = [73, 80, 87];
                    table.cell.styles.textColor = 255;
                    table.cell.styles.fontStyle = "bold";
                    table.cell.styles.fontSize = 10;
                    //   table.cell.styles.lineColor = 169;
                    //   table.cell.styles.lineWidth = 1;
                }
                if (table.section === 'body') {
                    if (table.row.cells[1].text[0] === "Sub Total") {
                        table.cell.styles.fillColor = 200;
                        table.cell.styles.textColor = 51;
                        table.cell.styles.fontStyle = "bold";
                        table.cell.styles.fontSize = 8;
                    }
                    else if (table.row.cells[1].text[0] === "Grand Total") {
                        table.cell.styles.fillColor = 136;
                        table.cell.styles.textColor = 255;
                        table.cell.styles.fontStyle = "bold";
                        table.cell.styles.fontSize = 10;
                    }
                    else {
                        table.cell.styles.fillColor = 245;
                        table.cell.styles.textColor = 51;
                        table.cell.styles.fontStyle = "normal";
                        table.cell.styles.fontSize = 8;
                    }
                    // table.cell.styles.lineColor = 204;
                    // table.cell.styles.lineWidth = 1;
                }
            }
        };

        doc.text(title + "                                               Report Generated On: " + genTime, marginLeft, 40);
        autoTable(doc, content)
        doc.save(fileName + ".pdf")
    }
    catch (ex) {
        alert("Export PDF Failed..");
        return
    }
}
/* Export Excel XLS/CSV */
const exportExcel = (Orgdata: any, fileName: any, fileType: any, rootFlag: number, genTime: string, isHyperLink?: boolean, isSelSwitch?: number) => {
    exportFromJSON({
        data: Orgdata, fileName: fileName, exportType: fileType,
        beforeTableEncode: (tableRow: any) => {
            var element = tableRow[tableRow.length - 1];
            tableRow.splice(tableRow.length - 1, 1);
            tableRow.splice(0, 0, element);
            for (let indx = 0; indx < tableRow.length; indx++) {
                if (tableRow[indx].fieldName === "in_organsid" || tableRow[indx].fieldName === "in_orgsid" ||
                    tableRow[indx].fieldName === "in_gameid" || tableRow[indx].fieldName === "in_gamegroupid" ||
                    (rootFlag === 19 && isSelSwitch === 1 && tableRow[indx].fieldName === "in_terminalid") ||
                    (rootFlag === 18 && tableRow[indx].fieldName === "in_organizername")) {
                    tableRow.splice(indx, 1);
                    indx = indx - 1;
                }
                else {
                    tableRow[indx].fieldName = getTableTitle(tableRow[indx].fieldName);
                }
                //Add Report generated On field as Export Excel
                if (indx === 1)
                    tableRow[indx].fieldValues.push("Report Generated On");
                else if (indx === 2)
                    tableRow[indx].fieldValues.push(genTime);
                else
                    tableRow[indx].fieldValues.push("");
            }
            return tableRow;
        }
    })
}
/* Export Print preview */
const exportPrint = (Orgdata: any, pageTitle: any, rootFlag: number, genTime: string, isHyperLink?: boolean, isSelSwitch?: number) => {
    try {
        var mywindow: any = window.open('', 'MARES', 'height=400,width=600');
        mywindow.document.write('<html><head><title>MARES</title><style>table, th, td{border: 1px solid #ccc !important;border-collapse: collapse;}.header{font-weight: bold;}.header1{font-weight: bold;text-align: center;}</style>');
        // mywindow.document.write('<link rel="stylesheet" href=' + dataleadcss + ' type="text/css" />');
        mywindow.document.write('</head><body><div class="header">Report Generated On: ' + genTime + '</div><br /><div class="header1">' + pageTitle + '</div><br /><table>');
        let tagData: any = "";
        tagData += "<tr>"
        getHeaderArr(rootFlag, isHyperLink, isSelSwitch).forEach((key: any) => {
            tagData += ("<th>" + key + "</th>")
        })
        tagData += "</tr>"
        Orgdata.map(function (obj: any) {
            tagData += "<tr>"
            getHeaderKeyArr(rootFlag, isHyperLink, isSelSwitch).forEach((key: any, index: number) => {
                if (index === 0)
                    tagData += ("<td>" + (obj.in_sno === undefined ? "" : obj.in_sno) + "</td>");
                tagData += ("<td>" + (obj[key] === undefined ? "" : obj[key]) + "</td>")
            })
            tagData += "</tr>"
            return obj;
        });
        mywindow.document.write(tagData + '</table></body></html>');
        mywindow.print();
        mywindow.close();
        return
    }
    catch (ex) {
        alert("Export Print Failed..");
        return
    }
}
//use for group by Subcount- (Fields)
const getGrpbySubcountFields = () => {
    return ['in_transcount', 'in_betamount', 'in_winamount', 'in_payout', 'in_netamount', 'in_transamount',
        'in_claimedamount', 'in_claimamount', 'in_unclaimedamount', 'in_unclaimamount', 'in_paymentamount',
        'in_revenueamount', 'in_salecommission', 'in_claimcommission', 'in_netsale',
      
  ///////ykarthik/////////////////
        'netamount','salesamount','salescomm','winningamount','tax'

    ]
}

const comTaxList = [
    { "in_organizername": "GI", 'Date': "29 Oct 2022", "in_betamount": 45645, "in_winamount": 777, "in_netamount": 1555, "in_salecommission": 500, "in_claimedamount": 200, "in_tax": 655 },
    { "in_organizername": "GI", 'Date': "23 Oct 2022", "in_betamount": 245121, "in_winamount": 5001, "in_netamount": 5005, "in_salecommission": 650, "in_claimedamount": 500, "in_tax": 55 },
    { "in_organizername": "PMC", 'Date': "30 Oct 2022", "in_betamount": 31456, "in_winamount": 5000, "in_netamount": 50000, "in_salecommission": 400, "in_claimedamount": 250, "in_tax": 400 },
    { "in_organizername": "EDITEC", 'Date': "31 Oct 2022", "in_betamount": 1255, "in_winamount": 8000, "in_netamount": 5000, "in_salecommission": 300, "in_claimedamount": 400, "in_tax": 600 },
];
  //  TableViewRemove
  const TableViewRemove =(rootFlag:number) =>{
    switch(rootFlag){
        case 1:
            return true;
        case 2:
            return true;
        case 3:
           return true;
        case 8:
            return true;
        case 9:
           return true;
        case 13:
            return true;
        case 14:
           return true;
        case 15:
            return true;
        case 17:
           return true;
           //
        case 25:
            return true;
        case 20:
            return true;
        case 21:
            return true;
        default :
           return false
     }
} 
//mani 29/12/2022 RemoveChartIcon
const ChartIcon =(rootFlag:number) =>{
switch(rootFlag){
case 6:
    return false;
case 7:
    return false;
case 20:
   return false;
case 21:
    return false;
case 25:
   return false;
default :
    return true
 }
} 

//if else Simplyfy 
const gwcallprops =(  propValue:any,num:any,rootFlag:any  )=>{
switch(num){
  //propValue === "in_sno"
case 1:
  return propValue === "in_sno"
  break;
 //DataBase Object Keys
case 2:
        return (propValue === "amount" && (rootFlag === 20 ||rootFlag === 21))
        || propValue === "in_transcount" || propValue === "in_betamount" 
        || propValue === "in_winamount" || propValue === "in_payout" 
        || propValue === "in_netamount" || propValue === "in_transamount" 
        || propValue === "in_claimedamount" || propValue === "in_unclaimamount"
        || propValue === "netamount" || propValue === "tax" 
        ||propValue === "winningamount" ||propValue === "salescomm" ||propValue === "salesamount" 
            break;
    //Propvalue === transactionid (Or) in_transactionid
case 3:
        return ( propValue === "transactionid"
            || propValue === "in_transactionid"
        )
        break;
    //propvalue === in_organizername, Add Grand Total For Footer 
case 4:
        return (propValue === "in_organizername" && 
        rootFlag !== 6 && rootFlag !== 7 && rootFlag !== 25   ) 
        || (propValue === "addmoney" && rootFlag !== 25)
                         
case 5:
        return  (
            ((  rootFlag === 1 ||   rootFlag === 2 ||  rootFlag === 3 ||  rootFlag === 9) && propValue === "in_winamount") ||
            (( rootFlag === 6 || rootFlag === 7) && propValue === "in_gamename")
        )
case 6:
        return (
                (( rootFlag === 1) && propValue === "in_gamename") ||
                (( rootFlag === 15) && propValue === "in_betamount") ||
                (( rootFlag === 9) && propValue === "in_drawdate") ||
                (( rootFlag === 8) && propValue === "in_transcount") ||
                (( rootFlag === 2 ||  rootFlag === 3 ||  rootFlag === 13 ||
                rootFlag === 14 ||  rootFlag === 17) && propValue === "in_drawtime") ||
                (( rootFlag === 6 ||  rootFlag === 7) && propValue === "in_transamount")
            )
        // propvalue === in_drawdate" (or ) "in_drawtime"  Adding Space In Footer
case 7 :
         return( (rootFlag === 1 || rootFlag === 2 
          ||  rootFlag === 3 ||  rootFlag === 8 ||  rootFlag === 9)
         && (propValue === "in_drawdate" || propValue === "in_drawtime"))
        //GrandTotalRootFlag 
case 8:
        return ( rootFlag === 8 ||  rootFlag === 9 ||
        rootFlag === 1 || rootFlag === 2 ||  rootFlag === 3 ||
        rootFlag === 17 ||  rootFlag === 15 || rootFlag === 13 ||
        rootFlag === 14)
}

}

const ThemeColour = () =>{
     return "#0A7CEE";
    //  Old Themecolour
}
const CommonReports = { exportPDF: exportPDF, exportExcel: exportExcel, exportPrint: exportPrint, getGrpbySubcountFields: getGrpbySubcountFields, getChartEnableFieldsArr: getChartEnableFieldsArr, getTableData: getTableData, getHeaderArr: getHeaderArr, getPageTitle: getPageTitle, getTableTitle: getTableTitle, getKeyByValue: getKeyByValue, tempData: { "comTaxList": comTaxList } ,
ChartIcon:ChartIcon,TableViewRemove:TableViewRemove,gwcallprops:gwcallprops ,ThemeColour:ThemeColour};

export default CommonReports;