function Breadcrumb(viaData: any) { 
    return(
    <div className="userLogHeadinnerRgt">
    {/* <ul className="headBtomMenu">
        <li><a href="#section">{viaData.Menu}</a><span>/</span></li>        
        {viaData.subMenu1 === undefined || viaData.subMenu1 === null || viaData.subMenu1 === "" ? 
        <li><a href="#section">{viaData.subMenu}</a></li> : 
        (<><li><a href="#section">{viaData.subMenu}</a><span>/</span></li>
        <li><a href="#section">{viaData.subMenu1}</a></li></>)}
    </ul> */}
    </div>
    ); 
}

export default Breadcrumb;
