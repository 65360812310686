import React from "react";
import useAxios from "axios";
import axios from 'axios';
import config from "../../../utils/config"
import moment from "moment"
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler
} from 'chart.js';
import { t } from "i18next";
import apiHelper from "../../../utils/ApiHelper";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
var $ = require('jquery')
export interface IProps {
    // pupUpOpenClick: any,
    // flog: number //to show date or hour based report
    startdate: any,
    enddate: any,    
    gameID:any,
    organizername:any
}
export interface IState {
    //createOrganizer: any
    chartData: any,
    myTitle: any
}

class PushData extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            chartData: { labels: [], datasets: [] },
            myTitle: "Push Data By Provider Wise"
        };
        // options.onClick = this.handleClick;
    }
    initData = () => {
        let Gameid = typeof (this.props.gameID) === 'string' ? (this.props.gameID).split('_')[0] : 0;
        //_fg=> 0-datebased|1-houre based
        const callJsonHead: any = {
            organizerID: this.props.organizername,
            FromDate:moment(this.props.startdate).format('YYYY-MM-DD')+ " 00:00:00",
            ToDate:moment(this.props.enddate).format('YYYY-MM-DD')+ " 00:00:00",
            GameId:Gameid,
       }
       //mani 22/12
        //useAxios.get(config.Url("provider_count", "get"), config.authHeaderwithParamRpt(callJsonHead))
        apiHelper.getAPICall("provider_count", "get",5,callJsonHead)
             .then((res) => {
                console.log(" RESPONSE RECEIVED: provider_count  ", res);
                if (res !== null && res.data !== null && res.data.result === undefined) {
                    let xAxis: any = [];
                    let tempArr: any = [];
                    let tempArr2: any = [];
                    let tempArr3: any = [];
                    let tempArr4: any = [];
                    let tempArr5: any = [];
                    let ydatasets: any = [];
                    let colDataArr: any = res.data["get_rptDetails"];
                     const yAxis = colDataArr.map((item: any) => item.in_organizername).filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                    yAxis.forEach((fobj: any) => {
                        const objLst = colDataArr.filter((item: any) => { return item.in_organizername == fobj });
                        if (objLst.length > 0) {
                            tempArr.push(objLst[0].in_betamount);
                            tempArr2.push(objLst[0].in_addmoney);
                            tempArr3.push(objLst[0].in_winamount);
                            tempArr4.push(objLst[0].in_claimedamount);
                            tempArr5.push(objLst[0].in_withdraw);
                           }
                        else {
                            tempArr.push(0);
                            tempArr2.push(0);
                            tempArr3.push(0);
                            tempArr4.push(0);
                            tempArr5.push(0);
                        }
                    })
                    ydatasets.push(
                        {
                            label: "Sale",
                            fill: true,
                            backgroundColor: '#FF9999',
                            borderColor: '#6050FF',
                            data: tempArr
                        });
                     ydatasets.push({
                        label: "Claimed",
                        fill: false,
                        backgroundColor:'#99CCFF',
                        borderColor:'#005600',
                        data: tempArr4
                         })
                        ydatasets.push({
                        label: "Winning",
                        fill: false,
                        backgroundColor:'#005aa2',
                        borderColor:'#41B883',
                        data: tempArr3
                      })
                     ydatasets.push({
                        label: "AddMoney",
                        fill: false,
                        backgroundColor:'#737373',
                        borderColor:'#737373',
                         data: tempArr2
                     })
                     ydatasets.push({
                        label: "WithDraw",
                        fill: false,
                        backgroundColor:'#A52A2A',
                        borderColor:'#A52A2A',
                        data: tempArr5
                     })
                    this.setState({ chartData: { labels: yAxis, datasets: ydatasets } });
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                return { labels: [], datasets: [] };
            })
    }
     componentDidMount(): void {
        this.initData();
        return;
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.startdate !== this.props.startdate) {
            this.initData()
        }
    }
    

     render(): React.ReactNode {


      
        const options: any = {
            type: "bar",
             responsive: true,
             data:this.state.chartData,
              plugins: {
                tooltip: {
                    callbacks: {
                      label: function(context:any) {
                        const labelIndex = context.dataset.label 
        
                        const  value =context.formattedValue.replaceAll(',', '') 
                        
                        return context.dataset.label+ ': ' + value;
                      }
                    }
                  }
            },
            interaction: {
                mode: 'index',
                intersect: true,
             },
            scales: {
                   x: {
                     display: true,
                    title: {
                        display: true,
                        text: 'Provider',
                    }
                },
                y: {
                     display: true,
                    title: {
                        display: true,
                        text: 'Push Count',
                    }
                }
            }
        };
        
       
        
        return (
            <div>
                <h4>{t('lblPushData')}</h4>
                <Bar options={options}  data={this.state.chartData} />
                {/* <Bar   options={options} data={data} />  */}
            </div>
        )
    }
};
export default PushData;