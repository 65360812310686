import React from 'react';
import useAxios from "axios";
import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import Breadcrumb from "../Shared/breadcrumb";
import COrganizermodel from "./Child/c_organizemodel";
import * as isValEmail from 'email-validator';
import Resizer from "react-image-file-resizer";
import { withTranslation  } from 'react-i18next';
import apiHelper from '../../utils/ApiHelper';
var $ = require('jquery')

export interface IProps {
    rowData: any,
    rootFlag: number,
    backClick: any,
    t:any
}
export interface IState {
    createOrganizer: any
}
  class COrganizer extends React.Component<IProps, IState> {
  // ------------------------------------------^
    constructor(props: IProps) {
      super(props);  
      this.state = {
        createOrganizer: COrganizermodel()
        };
      this.onhandleTxtChange = this.onhandleTxtChange.bind(this);
      this.onSubmitData = this.onSubmitData.bind(this);
      this.removeLogoUpload = this.removeLogoUpload.bind(this);
    }
    componentDidMount(){
        $('#myfileExp').val('');
        $('#myfileExp1').val('');
        const rowData: any = this.props.rowData;
        if(rowData !== null){
            this.updateFields(rowData);
        }
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):
        const rowData: any = this.props.rowData;  
        if (this.props.rootFlag !== prevProps.rootFlag && rowData !== null) {
            //this.loadTypeMasterdata();
            if(rowData !== null){
                $('#myfileExp').val('');
                $('#myfileExp1').val('');
                this.updateFields(rowData); 
            } 
        }
    }
    updateFields= (rowData: any) =>{
        try{
        const resVal: any = this.state.createOrganizer;         
        resVal.organizerId = rowData.dl_organizerid;
        resVal.cOrganizerMand.organzierName.name = rowData.dl_organizername;
        resVal.cOrganizerMand.contactPerLOne.name = rowData.dl_contactpersonnamel1;
        resVal.cOrganizerMand.contactNumLOne.name = rowData.dl_contactnumberl1;
        resVal.cOrganizerMand.emailIdLOne.name = rowData.dl_emailidl1;
        resVal.cOrganizerMand.address.name = rowData.dl_address;
        resVal.cOrganizerOpt.contactPerLTwo = rowData.dl_contactpersonnamel2;
        resVal.cOrganizerOpt.contactNumLTwo = rowData.dl_contactnumberl2;
        resVal.cOrganizerOpt.emailIdLTwo.name = rowData.dl_emailidl2;
        resVal.cOrganizerOpt.approvalNum = rowData.dl_approvalnumber;
        resVal.cOrganizerOpt.approvalAtt.name = rowData.dl_approveatchfile;
        //const logoAttach: any = rowData.dl_logoatchfile.split('&!@%$#');
        resVal.cOrganizerOpt.logoAtt.name = rowData.dl_logoatchfile;
        resVal.cOrganizerOpt.logoAtt.uri = rowData.dl_logoatchfileuri;
        this.setState({createOrganizer: resVal});
        return }
        catch(ex) {
        alert("Field Updation Failed..");
        return
        }       
    }
    onhandleTxtChange= (e: any,fieldFlag: number) => {
        try{
        let resVal: any = this.state.createOrganizer;
        resVal.errMsg = "";
        switch(fieldFlag){
        //if(fieldFlag === 0){
       case 0:{
            resVal.cOrganizerMand.organzierName.name = e.target.value;
            resVal.cOrganizerMand.organzierName.display = e.target.value.trim() === "" ? true : false;
        }
       break;
        //else if(fieldFlag === 1){
        case 1:{
            resVal.cOrganizerMand.contactPerLOne.name = e.target.value;
            resVal.cOrganizerMand.contactPerLOne.display = e.target.value.trim() === "" ? true : false;
         break;
        }
       
         //else if(fieldFlag === 2){
        case 2:{
            resVal.cOrganizerMand.contactNumLOne.name = e.target.value;
            resVal.cOrganizerMand.contactNumLOne.display = e.target.value.trim() === "" ? true : false;
          break;
        }
      
        // else if(fieldFlag === 3){
          case 3:{
            resVal.cOrganizerMand.emailIdLOne.name = e.target.value;
            resVal.cOrganizerMand.emailIdLOne.display = e.target.value.trim() === "" ? "1" : "0";
           break;
        }
          //else if(fieldFlag === 4){
             case 4:{
            resVal.cOrganizerMand.address.name = e.target.value;
            resVal.cOrganizerMand.address.display = e.target.value.trim() === "" ? true : false;
            break;
        }
        //else if(fieldFlag === 5){
            case 5:{
            resVal.cOrganizerOpt.contactPerLTwo = e.target.value;
             break;
        }
        // else if(fieldFlag === 6){
            case 6:{
            resVal.cOrganizerOpt.contactNumLTwo = e.target.value;
            break;
        }
         // else if(fieldFlag === 7){
           case 7:{
            resVal.cOrganizerOpt.emailIdLTwo.name = e.target.value;
            resVal.cOrganizerOpt.emailIdLTwo.display = "0";
           break;
        }
        //else if(fieldFlag === 10){
            case 10:{
            let file:any = (e.target as HTMLInputElement).files;
            if(file.length !== 0){           
                let fileNam:string = file[0].name.toString();
                let sizeInBytes:any = file[0].size;
                let fileType: string = fileNam.split('.')[1];
                let fileName: string = fileNam.split('.')[0];    
                const sizeInMB = Math.round(sizeInBytes / (1024*1024));
                if(fileType === undefined){
                    resVal.errMsg = "Invalid file..!";
                    $('#myfileExp').val('');
                    resVal.cOrganizerOpt.logoAtt.fileResult = null;
                    resVal.cOrganizerOpt.logoAtt.name = "";   
                    resVal.cOrganizerOpt.logoAtt.uri = "";
                }
                else if(sizeInMB <= 1){                      
                    Resizer.imageFileResizer(
                        file[0],
                        50,
                        50,
                        "PNG",
                        100,
                        0,
                        (uri) => {
                          let resVals: any = this.state.createOrganizer;
                          resVals.cOrganizerOpt.logoAtt.fileResult = file[0];
                          resVals.cOrganizerOpt.logoAtt.name = fileName + "." + fileType;  
                          resVals.cOrganizerOpt.logoAtt.uri = uri;
                          this.setState({createOrganizer: resVal})
                        },
                        "base64"
                      );   
                }
                else{
                    $('#myfileExp').val('');
                    resVal.errMsg = "Data size should be less than or equals 1 MB";
                    resVal.cOrganizerOpt.logoAtt.fileResult = null;
                    resVal.cOrganizerOpt.logoAtt.name = "";   
                    resVal.cOrganizerOpt.logoAtt.uri = "";
                }                  
            }
            else{
                $('#myfileExp').val('');
                resVal.errMsg = "No files selected..";
                resVal.cOrganizerOpt.logoAtt.fileResult = null;
                resVal.cOrganizerOpt.logoAtt.name = "";   
                resVal.cOrganizerOpt.logoAtt.uri = "";   
            }
           break;
        }
         // else if(fieldFlag === 8){
           case 8:{
            resVal.cOrganizerOpt.approvalNum = e.target.value;
          break;
            }
          //else if(fieldFlag === 9){
           case 9:{
            let file:any = (e.target as HTMLInputElement).files;
            if(file.length !== 0){           
                let fileNam:string = file[0].name.toString();
                let sizeInBytes:any = file[0].size;
                let fileType: string = fileNam.split('.')[1];
                let fileName: string = fileNam.split('.')[0];    
                const sizeInMB = Math.round(sizeInBytes / (1024*1024));
                if(fileType === undefined){
                    $('#myfileExp1').val('');
                    resVal.cOrganizerOpt.approvalAtt.fileResult = null;
                    resVal.cOrganizerOpt.approvalAtt.name = "";   
                    resVal.errMsg = "Invalid file..!";
                }
                else if(sizeInMB <= 10){   
                    resVal.cOrganizerOpt.approvalAtt.fileResult = file[0];
                    resVal.cOrganizerOpt.approvalAtt.name = fileName + "." + fileType;     
                }
                else{
                    $('#myfileExp1').val('');
                resVal.cOrganizerOpt.approvalAtt.fileResult = null;
                resVal.cOrganizerOpt.approvalAtt.name = "";   
                resVal.errMsg = "Data size should be less than or equals 10 MB";
                }                  
            }
            else{
                $('#myfileExp1').val('');
                resVal.errMsg = "No files selected..";
                resVal.cOrganizerOpt.approvalAtt.fileResult = null;
                resVal.cOrganizerOpt.approvalAtt.name = "";   
            }
         }
         break;
        }
         resVal.succFlag = undefined;
        if(resVal.errMsg !== ""){
            $("#divLoading").attr("style", "display:none") 
            this.setState({createOrganizer: resVal});
            $('.overlay').fadeIn();
            $('#lightBox2').fadeIn(); 
            return 
        }else{
            this.setState({createOrganizer: resVal})
            return
        }
      }
        catch(ex) {
        alert("On handle text change Failed..");
        return
        }
    }
    onSubmitData= (e: any,Type: string) => {
        try{
        e.preventDefault();
        let resVal: any = this.state.createOrganizer;
        let isErrData: boolean = false;
        $("#divLoading").attr("style", "display:block")
        //validation part-Start
        Object.entries(resVal.cOrganizerMand).forEach(([key, value],index: number) => {
            let val = resVal.cOrganizerMand[key].name;
            if(key !== 'emailIdLOne' && val === ''){
                resVal.cOrganizerMand[key].display = true
                isErrData = true
            }
            else if(key === 'emailIdLOne' && val === ''){
                resVal.cOrganizerMand[key].display = "1"
                isErrData = true
            }
            else if(key === 'emailIdLOne' && !isValEmail.validate(val)){
                resVal.cOrganizerMand[key].display = "2"
                isErrData = true
            }
            else{
                resVal.cOrganizerMand[key].display = key === 'emailIdLOne' ? "0" : false;
            }
        })
        if(resVal.cOrganizerOpt.emailIdLTwo.name !== ''){
            if(!isValEmail.validate(resVal.cOrganizerOpt.emailIdLTwo.name)){
                resVal.cOrganizerOpt.emailIdLTwo.display = "2"
                isErrData = true
            }
        }
        resVal.succFlag = undefined;
        //validation part-End
        if(isErrData){
            $("#divLoading").attr("style", "display:none") 
            //resVal.errMsg = "Invalid data..!"
            this.setState({createOrganizer: resVal});
            // $('.overlay').fadeIn();
            // $('#lightBox2').fadeIn(); 
            return 
        }else{
            if(Type === "C"){
                const userInfoString: any = sessionStorage.getItem('userInfo');
                const userInfo: any = JSON.parse(userInfoString);
                const userIp: string = $("#userIp").text();
                const fileData: any = resVal.cOrganizerOpt.approvalAtt.name === null || resVal.cOrganizerOpt.approvalAtt.name === "" ? "" : resVal.cOrganizerOpt.approvalAtt.name;
                let fileLogoData: any = resVal.cOrganizerOpt.logoAtt.name === null || resVal.cOrganizerOpt.logoAtt.name === "" ? "" : resVal.cOrganizerOpt.logoAtt.name;
                fileLogoData = fileLogoData !== "" ? (fileLogoData + "&!@%$#" + resVal.cOrganizerOpt.logoAtt.uri) : fileLogoData;
                const jsonData: any = {
                    "organizername": resVal.cOrganizerMand.organzierName.name,
                    "contactpersonnamel1": resVal.cOrganizerMand.contactPerLOne.name,
                    "contactnumberl1": resVal.cOrganizerMand.contactNumLOne.name, 
                    "emailidl1": resVal.cOrganizerMand.emailIdLOne.name,
                    "contactpersonnamel2": resVal.cOrganizerOpt.contactPerLTwo,
                    "contactnumberl2": resVal.cOrganizerOpt.contactNumLTwo,
                    "emailidl2": resVal.cOrganizerOpt.emailIdLTwo.name,
                    "address": resVal.cOrganizerMand.address.name,
                    "approvalnumber": resVal.cOrganizerOpt.approvalNum,
                    "approvalcpyatch": fileData,
                    "logoatch": fileLogoData,
                    "username": userInfo["userId"], //current login user
                    "ipaddress": userIp
                }
                //MANI 23/12
                //useAxios.post(config.Url("c_organizer","post"), jsonData, config.authHeader())
                apiHelper.postAPICall("c_organizer", "post",2,jsonData)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    $("#divLoading").attr("style", "display:none")
                    if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
                        $("#divLoading").attr("style", "display:none") 
                        resVal.errMsg = res.data.result.toString().replace('successfully','');
                        resVal.succFlag = undefined;
                        this.setState({createOrganizer: resVal});                   
                    }
                    else{
                        resVal = COrganizermodel()
                        resVal.errMsg = res.data.result.toString().replace('successfully','');
                        resVal.succFlag = 0;
                        this.setState({createOrganizer: resVal});
                    }
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    if(err !== undefined && err.response !== undefined && err.response.statusText !== undefined && err.response.statusText === 'Unprocessable Entity'){
                        resVal.errMsg = "Invalid data..!"
                        this.setState({createOrganizer: resVal});
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                    } 
                    return 
                })
            }
            else{
                this.props.backClick(this.state.createOrganizer);
                return
            }
        } 
        }
        catch(ex) {
        alert("Data Inserion/ Updation Failed..");
        return
        }      
    }
    // event triggers on key press on input controls
    handleKeypress = (evt: any) => {
        if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
        //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
            evt.preventDefault();
        }
    }
    maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
    }
    removeLogoUpload = (evt: any) => {
        evt.preventDefault();
        $('#myfileExp').val('');
        const resVals: any = this.state.createOrganizer;
        resVals.cOrganizerOpt.logoAtt.fileResult = null;
        resVals.cOrganizerOpt.logoAtt.name = "";  
        resVals.cOrganizerOpt.logoAtt.uri = "";
        this.setState({createOrganizer: resVals})
        return
    }
    render() {
    const { t } = this.props;
    const resVal: any = this.state.createOrganizer;
      return(
        <div className="ContainerChart scrollbar" style={{height: $(window).height() - 60 + "px"}}>
            <Lightbox errMsg= {resVal.errMsg} succFlag= {resVal.succFlag} />            
            <div className="contentclouds">
            <div className="userLogdata1">					
                <div className="userLogHead">
                <Breadcrumb Menu= "Organizer" subMenu= {this.props.rowData === null ? "Create Organizer" : "View Organizer"} subMenu1= {this.props.rowData === null ? null : "Edit Organizer"} />
                <div className="userLogHeadinner">
				<h2>{this.props.rowData === null ? t('lblCreate') : t('lblEdit')} {t('lblOrganizer')}</h2>
				<small>{t('lblOrgDetails')}</small>
			    </div>
                </div>
                <div className="userLogform scrollbar">
                    <div className="userLogformLft">
                        <ul>
                            <li>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblOrganizerName')} *</label>
                                        <input className="newUserTxt" value={resVal.cOrganizerMand.organzierName.name} onChange={(e: any) => this.onhandleTxtChange(e,0)} placeholder={t('lblEntername')} autoComplete="off" type="text" maxLength={100}/>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cOrganizerMand.organzierName.display ? "block" : "none"}}>{t('lblOrgNameProvided')}</label>
                                    </div>
                                </div>  
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblLogoUpload')}</label>
                                        <input className="newUserTxt" style= {{color: "transparent"}} type="file" id="myfileExp" name="myfileExp" accept="image/*" onChange={(e: any) => this.onhandleTxtChange(e,10)} />
                                        {resVal.cOrganizerOpt.logoAtt.name === null || resVal.cOrganizerOpt.logoAtt.name === "" ? null : (<label style={{marginBottom: "5px",fontSize: "12px"}}>{t('lblSelectFile')} <b>{resVal.cOrganizerOpt.logoAtt.name}</b></label>)}
                                    </div>
                                </div> 
                                {resVal.cOrganizerOpt.logoAtt.uri !== "" ? 
                                (<div className="userLogCloud2">
                                    <div>  
                                        <span>                                     
                                        <img src={resVal.cOrganizerOpt.logoAtt.uri} alt="" />
                                        <small><i className="fas fa-times" onClick={(e: any) => this.removeLogoUpload(e)}></i></small>
                                        </span>
                                    </div>
                                </div>) : null}                                       
                            </li>                                 
                            <li>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblContactPersonName')} L1 *</label>
                                        <input className="newUserTxt" value={resVal.cOrganizerMand.contactPerLOne.name} onChange={(e: any) => this.onhandleTxtChange(e,1)} placeholder={t('lblEnterContactName')} autoComplete="off" type="text" maxLength={100}/>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cOrganizerMand.contactPerLOne.display ? "block" : "none"}}>{t('lblContactProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblContactNo')} L1 *</label>
                                        <input className="input_Number" id="colValL1" min="0" onKeyPress= {(e:any) => this.handleKeypress(e)} value={resVal.cOrganizerMand.contactNumLOne.name} onChange={(e: any) => this.onhandleTxtChange(e,2)} placeholder={t('lblEnterNo')} autoComplete="off" type="number" maxLength={15} onInput={this.maxLengthCheck} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cOrganizerMand.contactNumLOne.display ? "block" : "none"}}>{t('lblContactNoProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblEmailId')} L1 *</label>
                                        <input className="newUserTxt" value={resVal.cOrganizerMand.emailIdLOne.name} onChange={(e: any) => this.onhandleTxtChange(e,3)} placeholder={t('lblEnterEmail')} autoComplete="off" type="text" maxLength={50}/>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cOrganizerMand.emailIdLOne.display === "0" ? "none" : "block"}}>{resVal.cOrganizerMand.emailIdLOne.display === "1" ? t('lblEmailidProvided') : t('lblInvalidEmail')}</label>
                                    </div>
                                </div>
                            </li>
                            
                            <li>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblContactPersonName')} L2</label>
                                        <input className="newUserTxt" value={resVal.cOrganizerOpt.contactPerLTwo} onChange={(e: any) => this.onhandleTxtChange(e,5)} placeholder={t('lblEnterContactName')} autoComplete="off" type="text" maxLength={100}/>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblContactNo')} L2</label>
                                        <input className="input_Number" id="colValL2" min="0" onKeyPress= {(e:any) => this.handleKeypress(e)} value={resVal.cOrganizerOpt.contactNumLTwo} onChange={(e: any) => this.onhandleTxtChange(e,6)} placeholder={t('lblEnterNo')} autoComplete="off" type="number" maxLength={15} onInput={this.maxLengthCheck} />
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblEmailId')} L2</label>
                                        <input className="newUserTxt" value={resVal.cOrganizerOpt.emailIdLTwo.name} onChange={(e: any) => this.onhandleTxtChange(e,7)} placeholder={t('lblEnterEmail')} autoComplete="off" type="text" maxLength={50}/>
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cOrganizerOpt.emailIdLTwo.display === "0" ? "none" : "block"}}>{t('lblInvalidEmailL2')}</label>
                                    </div>
                                </div>
                            </li>
                            
                            
                            <li>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblAddress')} *</label>
                                        <textarea id="w3review" rows={5} cols={40} placeholder= {t('lblEnterAddress')} value={resVal.cOrganizerMand.address.name} onChange={(e: any) => this.onhandleTxtChange(e,4)} autoComplete="off" maxLength={400} />
                                        <label style={{color: "#ff0000",fontSize: "12px",display: resVal.cOrganizerMand.address.display ? "block" : "none"}}>{('lblAddressProvided')}</label>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblApprovalno')}</label>
                                        <input className="newUserTxt" value={resVal.cOrganizerOpt.approvalNum} onChange={(e: any) => this.onhandleTxtChange(e,8)} placeholder={t('lblEnterApprovalNo')} autoComplete="off" type="text" maxLength={15}/>
                                    </div>
                                </div>
                                <div className="userLogCloud2">
                                    <div>
                                        <label>{t('lblApprovalCopy')}</label>
                                        <input className="newUserTxt" style= {{color: "transparent"}} type="file" id="myfileExp1" name="myfileExp1" onChange={(e: any) => this.onhandleTxtChange(e,9)} disabled={this.props.rowData === null ? false: true} />
                                        {resVal.cOrganizerOpt.approvalAtt.name === null || resVal.cOrganizerOpt.approvalAtt.name === "" ? null : (<label style={{marginBottom: "5px",fontSize: "12px"}}>{t('lblSelectFile')}<b>{resVal.cOrganizerOpt.approvalAtt.name}</b></label>)}
                                    </div>
                                </div>
                            </li>                                    
                        </ul>                                
                        <div className="userLogBtnCont3">
                            <button className="GreyBg" id="loginBtn" onClick={() => {
                                this.setState({createOrganizer: COrganizermodel()})
                                if(this.props.rowData !== null)
                                    this.props.backClick(null);
                                }}><i className={"fa fa-" + (this.props.rowData === null ? "sync" : "times")} style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t('lblReset') : t('lblCancel')}</button>							
                            <button className="GreenBg" id="registerBtn" onClick={(e: any) => this.onSubmitData(e,this.props.rowData === null ? "C" : "E")}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>{this.props.rowData === null ? t('lblCreate') : t('lblOk')}</button>		
                        </div>                 
                    </div>
                </div>
                </div>			
            </div>
	    </div>                   
    );
  }
}

export default withTranslation()(COrganizer);