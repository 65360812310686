import { t } from "i18next"

//use for show the report header's
const getPageTitle =(rootFlag: number,rowData?: any) => {
    switch(rootFlag){
        case 1:
            return t('lblCreateStock')
        case 2:
            return t('lblViewStockist')
        case 3:
            return t('lblCreateSubStock')
        case 4:
            return t('lblViewSubStockist')
        case 5:
            return t('lblCreateTerminal') 
        case 6:
            return t('lblViewTerminal')
        case 7:
            return t('lblCreatePlayer')
        case 8:
            return t('lblViewPlayer')
        case 9:
            return t('lblCreateComm') 
        case 10:
            return t('lblViewComm')
        default:
            return ""
    }
}

const getTableTitle =(header: string) => {
    switch(header){
        case "in_sno":
            return "#"
        case "in_organizername":
        case "dl_organizername":
            return "Org. Name"
        case "dl_gametypename":
            return "Game Type"
        case "in_sstockistname":
            return "Stock. Name"
        case "in_stockistname":
            return "Sub Stock. Name"
        case "in_terminalid":
        case "dl_terminalid":
        case "terminalid":
            return "Terminal Id"
        case "in_terminalids":
        case "terminalids":
            return "Player Id"
        case "in_terminalname":
        case "dl_terminalname":
            return "Terminal Name"
        case "in_terminalnames":
            return "Player Name"
        case "in_contactnumber":
        case "contactnumber":
            return "Contact No."
        case "in_address":
        case "address":
            return "Address"
        case "in_pincode":
        case "pincode":
            return "Pincode"
        case "in_location":
        case "location":
            return "Location"
        case "in_statename":
        case "state":
            return "State Name"
        case "in_aadharupload":
            return "Aadhar Upload"
        case "in_aadharname":
            return "Aadhar Name"
        case "in_aadharno":
            return "Aadhar No."
        case "in_aadhardob":
            return "DOB (Aadhar)"
        case "in_aadhargender":
            return "Gender (Aadhar)"
        case "dl_salescommission":
            return "Sales Commission"
        case "dl_claimcommission":
            return "Claim Commission"
        case "dl_effectivefrom":
            return "Effective From"
        case "dl_effectiveto":
            return "Effective To"
        case "dl_commissiontype":
            return "Commission Type"
        case "dl_agenttype":
            return "Agent/Terminal"
        default:
            return header
    }
}
// Table based- Filter Data (Search option)
const getHeaderKeyArr = (rootFlag: number,flag?: number) => {
    switch(rootFlag){
        //mani 26/12
   // if(rootFlag === 2)
   case 2:
    {
        return ['in_organizername','in_statename','in_sstockistname',
        'in_contactnumber','in_address','in_pincode','in_location',
        'in_aadharupload','in_aadharname','in_aadharno','in_aadhardob','in_aadhargender']  //table header's filter allow columns
   }
   
    //else if(rootFlag === 4)
    case 4:
    {
        return ['in_organizername','in_statename','in_sstockistname','in_stockistname',
        'in_contactnumber','in_address','in_pincode','in_location',
        'in_aadharupload','in_aadharname','in_aadharno','in_aadhardob','in_aadhargender']   
    }
    
    //else if(rootFlag === 6)
    case 6:
    {
        return ['in_organizername','in_statename','in_sstockistname','in_stockistname','in_terminalid','in_terminalname',
        'in_contactnumber','in_address','in_pincode','in_location',
        'in_aadharupload','in_aadharname','in_aadharno','in_aadhardob','in_aadhargender'] 
    }
    case 8:
    //else if(rootFlag === 8)
    {
        return ['in_organizername','in_statename','in_terminalid','in_terminalname',
        'in_contactnumber','in_address','in_pincode','in_location',
        'in_aadharupload','in_aadharname','in_aadharno','in_aadhardob','in_aadhargender']   
    }
    //else if(rootFlag === 10)
    case 10:
    {
        return ['dl_organizername','dl_gametypename','in_sstockistname',
        'dl_salescommission','dl_claimcommission','dl_effectivefrom','dl_effectiveto','dl_commissiontype'];
        // ['dl_organizername','dl_gametypename','in_sstockistname','in_stockistname','dl_terminalid','dl_terminalname',
        // 'dl_salescommission','dl_claimcommission','dl_effectivefrom','dl_effectiveto','dl_commissiontype','dl_agenttype'] 
    }
    //else if(rootFlag === 1 || rootFlag === 3)
    case 1:case 3:
    {
        return ['Name','ContactNumber','Address',
        'Pincode','in_address','Location']  
    }
    //else if(rootFlag === 5 || rootFlag === 7)
    case 5:case 7:
    {
        return ['Terminalid','Name','ContactNumber','Address',
        'Pincode','in_address','Location']  
    }
    default : {
        return []
    }
}
}
const getKeyByValue = (objRes: any, searchInput: string,rootFlag: number) =>{
    for(var prop of getHeaderKeyArr(rootFlag)) {
        if (objRes.hasOwnProperty(prop)) {
            if(objRes[prop].toString().toLowerCase().includes(searchInput.toLowerCase()))
                return true;
        }
    }
}
const CommonOrganizer = {getPageTitle: getPageTitle, getTableTitle: getTableTitle,getKeyByValue: getKeyByValue };

export default CommonOrganizer;