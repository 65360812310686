const getTableTitle =(header: string) => {
    switch(header){
        case "dl_organizername":
            return "Org. Name"
        case "dl_contactpersonnamel1":
            return "Contact Per. Name L1"
        case "dl_contactnumberl1":
            return "Contact Number L1" 
        case "dl_emailidl1": 
            return "eMail ID L1"
        case "dl_contactpersonnamel2":
            return "Contact Per. Name L2" 
        case "dl_contactnumberl2": 
            return "Contact Number L2"
        case "dl_emailidl2": 
            return "eMail ID L2"
        case "dl_address": 
            return "Address"
        case "dl_approvalnumber": 
            return "Approval Number"
        case "dl_approveatchfile": 
            return "Approval copy attachment"
        case "dl_approvestatusdesc":
            return "Approval Status"
        case "dl_username":
            return "Created by"
        case "dl_logoatchfile":
            return "Logo Upload"
        default:
            return header
    }
}
const getKeyByValue = (objRes: any, searchInput: string) =>{
    var propertyArr: any = ['dl_organizername','dl_contactpersonnamel1','dl_contactnumberl1','dl_emailidl1',
    'dl_contactpersonnamel2','dl_contactnumberl2','dl_emailidl2','dl_address','dl_approvalnumber','dl_approveatchfile','dl_approvestatusdesc',
    'dl_username','dl_logoatchfile'] //table header's filter allow columns
    for(var prop of propertyArr) {
        if (objRes.hasOwnProperty(prop)) {
            if(objRes[prop].toString().toLowerCase().includes(searchInput.toLowerCase()))
                return true;
        }
    }
}
const CommonOrganizer = { getTableTitle: getTableTitle,getKeyByValue: getKeyByValue };

export default CommonOrganizer;