import React from 'react'
import apiHelper from "../../../utils/ApiHelper"
import useAxios from "axios";
import { t } from 'i18next';
import config from "../../../utils/config"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import moment from "moment"
var $ = require('jquery')
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
)

const options2: any = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
        },
    },
    interaction: {
        mode: 'index',
        intersect: false
    },
    scales: {
        x: {
            display: true,
            title: {
                display: true,
                text: 'Sale Date'
            }
        },
        y: {
            display: true,
            title: {
                display: true,
                text: 'Sale Amount'
            }
        }
    },
    //onClick: handleClick
    onClick: null
}
const dataaaa2: any = {
    labels: ['21 Oct', '22 Oct', '23 Oct', '24 Oct', '25 Oct', '26 Oct', '27 Oct'],
    datasets: [
        {
            label: 'GI',
            fill: false,
            backgroundColor: ('#0000FF'),
            borderColor: ('#0000FF'),
            data: [10, 60, 25, 50, 70, 16, 32],
        }, {
            label: 'PMC',
            fill: false,
            backgroundColor: '#90EE90',
            borderColor: '#90EE90',
            //borderDash: [5, 5],
            data: [20, 50, 40, 30, 56, 32, 40],
        }, {
            label: 'EDITEC',
            backgroundColor: '#41B883',
            borderColor: '#41B883',
            data: [30, 40, 20, 10, 44, 48, 60],
            fill: true,
        }
    ]
}
// function handleClick(e: any, i: any) {
//     let inx: any = i[0].index;
//     let ds = this.state.dataaaa2.labels[inx];
//     //alert(ds);
//     $('#lightBox41').fadeIn();
//     $('.overlay').fadeIn();    
// }


export interface IProps {
   // pupUpOpenClick: any,
   // flog: number //to show date or hour based report
   startdate:any,
   enddate:any,
   gameID:any,
   organizername:any
}
export interface IState {
    //createOrganizer: any
    chartData: any,
    myTitle:any,
    
}
class ProviderWiseChart extends React.Component<IProps, IState>{
     constructor(props: IProps) {
        super(props);
        this.state = {
            chartData: { labels: [], datasets: []} ,            
            myTitle: t('lblProvideSaleByDay'),
             
           };
        options2.onClick = this.handleClick;
    }
    

     initData = () => {
        let Gameid = typeof (this.props.gameID) === 'string' ? (this.props.gameID).split('_')[0] : 0 ;
        //_fg=> 0-datebased|1-houre based
            const callJsonHead: any = {
            organizerID: this.props.organizername,
            FromDate: moment(this.props.startdate).format('YYYY-MM-DD')+ " 00:00:00",
            ToDate: moment(this.props.enddate).format('YYYY-MM-DD')+ " 00:00:00",       
            GameId: Gameid,
            Status:0  //0-daywise|1-week wise
        }
        // mani 22/12
        //useAxios.get(config.Url("chat-addmoneybyMode", "get"), config.authHeaderwithParamRpt(callJsonHead))
        apiHelper.getAPICall("chat-addmoneybyMode", "get",5,callJsonHead)
            .then((res)=> {
                console.log("RESPONSE RECEIVED: ", res);
              if (res !== null && res.data !== null && res.data.result === undefined) {
                    let xAxis: any = [];
                    let ydatasets: any = [];
                    let tempArr: any = [];
                    let yname: number = 0;
                    let colDataArr: any = res.data["get_rptDetails"]; 
                    
                     xAxis =  colDataArr.map((item:any) =>item.in_drawdate).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                     const yAxis =  colDataArr.map((item:any) => item.in_channelname).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                     yAxis.forEach(( fobj: any) => {
                     xAxis.forEach((fdate: any) => {
                            const objLst = colDataArr.filter((item: any) => { return item.in_channelname == fobj && item.in_drawdate == fdate });
                            if (objLst.length > 0)
                                tempArr.push(objLst[0].in_betamount);
                            else
                                tempArr.push(0);
                        });
                        
                        var color = Math.floor(Math.random() * 16777216).toString(16);
                        var colour1= '#000000'.slice(0, -color.length) + color;
                        
                        ydatasets.push(
                            {
                                label: fobj, //yAxis[yname],
                                fill: false,
                                backgroundColor: colour1,
                                borderColor: colour1,
                                data: tempArr
                            }) 
                            
                            yname++;
                        tempArr = [];
                    })
                    this.setState({ chartData: { labels: xAxis, datasets: ydatasets }});
                    //return  { labels: xAxis, datasets: ydatasets } ;
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                return  { labels:[], datasets:[] } ;
            })
    }

 componentDidMount(): void {
    this.initData();
         return;
       
    }
    
    componentDidUpdate(prevProps:any) {
        if (prevProps.startdate !== this.props.startdate) {
            this.initData()
        }
      }
    handleClick = (e: any, i: any) => {
        debugger;
        let inx: any = i[0].index;
         let ds =  this.state.chartData.labels[inx];
         var obj = {
             Id: ds,
             fromDate: "",
             toDate: ""
         }
       // this.props.pupUpOpenClick(obj);
    }
    
  render(): React.ReactNode {
    
    console.log(this.props) 
         return (
            <div>
                <h4>{this.state.myTitle}</h4>
                {/* <Bar id='myChart' options={options2} data={this.state.chartData} /> */}
                <Bar id='myChart' options={options2} data={this.state.chartData} />
                {/* <h1>{moment(this.props.startdate).format('YYYY-MM-DD')+ " 00:00:00"}</h1>
                <h1>{moment(this.props.enddate).format('YYYY-MM-DD')+ " 00:00:00"}</h1> */}

            </div>
        )
    }
};
export default ProviderWiseChart;