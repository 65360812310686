import React from 'react';
import useAxios from "axios";
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown,faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import moment from "moment"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// import { green } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';
import excel from 'xlsx';
// import _ from "lodash"

import ListTable from "../Shared/listtable";
import Tablistview from "../Shared/tablistview";
import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import ListSubTable from "../Shared/listsubtablePaper";
import Breadcrumb from "../Shared/breadcrumb";
import CPaperssmodel from "./Child/c_paperlottomodel";
import CPapersscommon from "./Child/c_paperlottocommon";
import apiHelper from '../../utils/ApiHelper';
// import TooltipData from "../../assets/img/icons/Arrow_new.png"
// import PlusIcon from "../../assets/img/icons/Plus_Icon.png"
// import MinusIcon from "../../assets/img/icons/Minus_Icon.png"

var $ = require('jquery')
library.add(faCaretDown);
library.add(faCaretUp);

export interface IProps {
    rootFlag: number
}
export interface IState {
    succFlag: any,
    errMsg: string,
    aGWReportsDt: any,
    aGWReportsDrop: any,
    aGWReportsTab: any,
    fileAttach: any,
    aGWReportsText: any
}
  class AGWPapers extends React.Component<IProps, IState> {
    private subTabStateReset: any = React.createRef;
  // ------------------------------------------^
    constructor(props: IProps) {
      super(props);  
      this.subTabStateReset = React.createRef();
      this.state = CPaperssmodel();
      this.resetValues = this.resetValues.bind(this);
      this.globalSearch = this.globalSearch.bind(this);
      this.onhandleTxtChange = this.onhandleTxtChange.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);
      this.handleDropChange = this.handleDropChange.bind(this);
      this.onFilterListTableData = this.onFilterListTableData.bind(this);
      this.onBlkUnBlkListData = this.onBlkUnBlkListData.bind(this);
      this.onhandleFileChange = this.onhandleFileChange.bind(this);
      this.exportXLPdfPrintdownload = this.exportXLPdfPrintdownload.bind(this);
      this.continuePopup = this.continuePopup.bind(this);
      this.toggleRow = this.toggleRow.bind(this);
      this.toggleSelectAll = this.toggleSelectAll.bind(this);
    }
    //on page load
    componentDidMount(){  
        $('#myfileExp').val('');
        let is_superuser: any = null;
        let userId: any = "";
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null){
            is_superuser = userInfo["is_superuser"];
            userId = userInfo["userId"];
        }
        const resVals: any = this.state;
        const callJsonHead: any = { 
            FromDate: moment(resVals.aGWReportsDt.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            ToDate: moment((this.props.rootFlag === 6 ? resVals.aGWReportsDt.FdrawDate.value : resVals.aGWReportsDt.TdrawDate.value),'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            organizerID: is_superuser ? 0 : userId
        }
        this.loadNameMasterdata(resVals,callJsonHead);
        return
    }
    /* load game names */
    loadNameMasterdata = (initialState: any,callJsonHead: any,succMsg?: string) => {
        try{
            $("#divLoading").attr("style", "display:block")
            //MANI 23/12
            //useAxios.get(config.Url("ctmpl_game","get"), config.authHeaderwithParamRpt(callJsonHead))
            apiHelper.getAPICall("ctmpl_game", "get",5,callJsonHead)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let GameNamesArr: any = [];
                if(res.data !== null && res.data.length !== 0){
                    let getDtlPoint: any = "get_rptDetails";
                    if(res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0){                  
                    for(var prop of res.data[getDtlPoint]){
                        GameNamesArr.push({value: prop["dl_gameid"] + "_" + prop["dl_organizerid"],label: prop["dl_gamename"],orgId: prop["dl_organizerid"],orgName: prop["dl_organizername"],drawDate: prop["dl_drawdate"]})
                    }
                  }
                }         
                $("#divLoading").attr("style", "display:none")
                initialState.aGWReportsDrop.gameDet = GameNamesArr;
                initialState.aGWReportsDrop.gameDetId.value = null;
                if(succMsg !== undefined && succMsg !== ""){
                    initialState.succFlag = 0; 
                    initialState.errMsg = succMsg; 
                    this.setState(initialState);
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                }
                else{
                    initialState.succFlag = undefined; 
                    initialState.errMsg = ""; 
                    this.setState(initialState);
                }
                return
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none") 
                return false
            }) 
        }
        catch(ex) {
          alert("Load Master Data Failed..");
          return
        }
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):
        if (this.props.rootFlag !== prevProps.rootFlag) {
            $('#myfileExp').val('');
            this.resetValues(); 
        }
    }
    //reset model values
    resetValues= (succMsg?: string) => {
        let is_superuser: any = null;
        let userId: any = "";
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null){
            is_superuser = userInfo["is_superuser"];
            userId = userInfo["userId"];
        }
        const initialState: any = CPaperssmodel();
        const callJsonHead: any = { 
            FromDate: moment(initialState.aGWReportsDt.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            ToDate: moment((this.props.rootFlag === 6 ? initialState.aGWReportsDt.FdrawDate.value : initialState.aGWReportsDt.TdrawDate.value),'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            organizerID: is_superuser ? 0 : userId
        }
        this.loadNameMasterdata(initialState,callJsonHead,succMsg);
        return
    }
    /* event triggers on click the Block/Unblock button in view purchase screen */
    onBlkUnBlkListData = (e: any, flag: number) => {
    try{
        e.preventDefault(); 
        const resVal: any = this.state;
        if(Object.values(resVal.aGWReportsTab.selected).includes(true)){
            const formattedStr: string = Object.keys(resVal.aGWReportsTab.selected).filter((elem: any) => elem).toString() + "@" + flag;
            //console.log(formattedStr);
            this.createPaperLottData(formattedStr);
            return
        }
        else{
            $("#divLoading").attr("style", "display:none") 
            resVal.errMsg = "Select atleast one ticket number..!"
            this.setState(resVal);
            $('.overlay').fadeIn();
            $('#lightBox2').fadeIn(); 
            return;
        }
    }
    catch(ex) {
      alert("Load List Failed..");
      return
    }
    }
    /* event triggers on click the submit button */
    onFilterListTableData = (e: any, type: string) => {
    try{
        e.preventDefault(); 
        const resVal : any = this.state;
        let isErrData: boolean = false;
        resVal.fileAttach.display = false
        resVal.aGWReportsText.selTxnValue.display = false;
        resVal.aGWReportsDrop.gameDetId.display = false;
        resVal.aGWReportsDrop.OrganizerName.display = false;
        resVal.aGWReportsDt.FdrawDate.display = false;
        resVal.aGWReportsDt.TdrawDate.display = false;
        if(type !== "View" || (this.props.rootFlag === 6 && type === "View")){
        Object.entries(resVal.aGWReportsDt).forEach(([key, value]) => { //date field validation
            let val = resVal.aGWReportsDt[key].value;
            if(val === null || val === "" || resVal.aGWReportsDt[key].display){
                if(this.props.rootFlag !== 6 || key !== "TdrawDate"){
                    resVal.aGWReportsDt[key].display = true
                    isErrData = true
                }
            }else{ return }
        })
        }
        if((this.props.rootFlag === 1 || this.props.rootFlag === 2 || this.props.rootFlag === 3) && (resVal.fileAttach.name === null || resVal.fileAttach.name === "")){ 
            $('#myfileExp').val('');
            resVal.fileAttach.display = true
            isErrData = true
        }
        if(this.props.rootFlag === 4 && resVal.aGWReportsText.selTxnValue.name === ""){ //text box validation
            resVal.aGWReportsText.selTxnValue.display = true
            isErrData = true
        }
        if(resVal.aGWReportsDrop.gameDetId.value === null && (type !== "View" || (this.props.rootFlag === 6 && type === "View"))){ //drop down validation
            resVal.aGWReportsDrop.gameDetId.display = true
            isErrData = true
        }
        // if(userInfo["is_superuser"] && resVal.aGWReportsDrop.OrganizerName.value === null){ //drop down validation
        //     resVal.aGWReportsDrop.OrganizerName.display = true
        //     isErrData = true
        // }
        if(isErrData){
            $("#divLoading").attr("style", "display:none") 
            //resVal.errMsg = "Invalid data..!"
            this.setState(resVal);
            // $('.overlay').fadeIn();
            // $('#lightBox2').fadeIn(); 
            return 
        }else{            
            if(type === "View"){
                if(this.props.rootFlag === 6){
                    if(resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0){
                        $("#divLoading").attr("style", "display:none") 
                        resVal.errMsg = "Page already rendered..!"
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                        return;
                    }
                    else{
                        this.loadListTableData();
                        return
                    } 
                }
                else{
                    this.setState(resVal);
                    this.subTabStateReset.current.subTabStateReset();
                    $('#lightBox7').fadeIn();
                    $('.overlay').fadeIn();
                    return
                }
            }
            else{
                if(this.props.rootFlag !== 5){
                    if(this.props.rootFlag === 2){
                        if(resVal.fileAttach.name !== "" && resVal.fileAttach.fileResult !== null){
                            this.createPaperLottData(0); 
                            return
                        }
                        else{
                            this.setState(resVal);
                            $('#lightBoxHead').html("Unsold Ticket Not available.");
                            $('#okBtn').html('OK'); 
                            $('.overlay').fadeIn();
                            $('#lightBox6').fadeIn(); 
                            return
                        }
                    }
                    else if(this.props.rootFlag === 6){
                        $('#lightBoxHead').html("Delete the " + resVal.aGWReportsDrop.gameDetId.gamename + " Game.");
                        this.setState(resVal);
                        $('#okBtn').html('OK'); 
                        $('.overlay').fadeIn();
                        $('#lightBox6').fadeIn(); 
                        return
                    }
                    else{
                        this.createPaperLottData(); 
                        return
                    }
                }
                else{
                    if(resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0){
                        $("#divLoading").attr("style", "display:none") 
                        resVal.errMsg = "Page already rendered..!"
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                        return;
                    }
                    else{
                        this.loadListTableData();
                        return
                    } 
                }
            }            
        }
    }
    catch(ex) {
      alert("Load List Failed..");
      return
    }
    }
    continuePopup = (falg: number) => {
        try{
            if(falg === 1){
                $('.overlay').fadeOut();
                $('#lightBox6').fadeOut(); 
                return;
            }
            else{
                this.createPaperLottData(this.props.rootFlag === 4 ? 1 : 2);
                return;
            }
        }
        catch(ex) {
            alert("Load Popup Data Failed..");
            return
        } 
    }
    //block/un block check box individual change event
    toggleRow(in_ticketno: any) {
        const resVal: any = this.state;
        const newSelected = Object.assign({}, resVal.aGWReportsTab.selected);
        newSelected[in_ticketno] = !resVal.aGWReportsTab.selected[in_ticketno];
        resVal.aGWReportsTab.selected = newSelected;
        resVal.aGWReportsTab.selectAll = ((resVal.aGWReportsTab.tabData.length === Object.values(resVal.aGWReportsTab.selected).length && Object.values(resVal.aGWReportsTab.selected).includes(false))
        || resVal.aGWReportsTab.tabData.length !== Object.values(resVal.aGWReportsTab.selected).length) ? 0 : 1;
        this.setState(resVal);
        return
    }
    //block/un block check box overall change event
    toggleSelectAll() {
        let newSelected: any = {};
        const resVal: any = this.state;
        resVal.aGWReportsTab.tabData.forEach((x: any) => {
            newSelected[x.in_ticketno] = resVal.aGWReportsTab.selectAll === 0 ? true : false;
        });
        resVal.aGWReportsTab.selected = newSelected;
        resVal.aGWReportsTab.selectAll = resVal.aGWReportsTab.selectAll === 0 ? 1 : 0;
        this.setState(resVal);
        return
    }
    //load list details ~ Paper lott data Get
    loadListTableData= (succMsg?: any) => {
        try{
            $("#divLoading").attr("style", "display:block")
            //this.setState(initialState);
            const windowWidth: number = $(window).width();
            const initialState: any = this.state;
            const drawDateVal: any = initialState.aGWReportsDt; 
            let callMethod: string = "";
            let callJsonHead: any = null;
            let getDtlPoint: any = "get_rptDetails";
            const FdrawDate: any = moment(drawDateVal.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00";
            const TdrawDate: any = moment(drawDateVal.TdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00";
            if(this.props.rootFlag === 5){
                callMethod = "GVP_Papers";
                callJsonHead = {
                    organizerID: initialState.aGWReportsDrop.gameDetId.orgId,
                    FromDate: FdrawDate,
                    ToDate: TdrawDate,
                    GameID: parseInt(initialState.aGWReportsDrop.gameDetId.value.split('_')[0])
                };
            } 
            else if(this.props.rootFlag === 6){
                const userIp: string = $("#userIp").text();  
                callMethod = "GDPV_Papers";
                callJsonHead = {
                    organizerID: initialState.aGWReportsDrop.gameDetId.orgId,
                    DrawDate: FdrawDate,
                    GameID: parseInt(initialState.aGWReportsDrop.gameDetId.value.split('_')[0]),
                    GameName: initialState.aGWReportsDrop.gameDetId.gamename,
                    ipaddress: userIp
                };
            }             
            else{
            }
            //MANI 23/12
            //useAxios.get(config.Url(callMethod,"get"), config.authHeaderwithParamRpt(callJsonHead))
            apiHelper.getAPICall(callMethod, "get",5,callJsonHead)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let colDataArr: any = [];
                let rowDataArr: any = [];
                let errText: string = "";
                if(res !== null && res.data !== null && res.data.result === undefined){
                  if(res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0){
                        rowDataArr = res.data[getDtlPoint];
                        rowDataArr.forEach((elem: any, index: number) => { elem.in_sno = index + 1; });  
                        if(this.props.rootFlag === 5){
                            colDataArr.push({
                                id: "checkbox",
                                accessor: "",
                                Cell: (rowInfo: any) => {
                                    return (
                                        <input
                                            type="checkbox"
                                            className="chkBoxToggle"
                                            checked={this.state.aGWReportsTab.selected[rowInfo.original.in_ticketno] === true}
                                            onChange={() => this.toggleRow(rowInfo.original.in_ticketno)}
                                        />
                                    );
                                },
                                Header: () => {
                                    return (
                                        <input
                                            type="checkbox"
                                            className="chkBoxToggle"
                                            checked={this.state.aGWReportsTab.selectAll === 1}
                                            onChange={() => this.toggleSelectAll()}
                                        />
                                    );
                                },
                                sortable: false,
                                className: "wordwrap chkBoxToggle",Visible: false,width: 40
                            })
                        }
                        colDataArr.push({className: "wordwrap",Visible: false,width: 40,Header: "#",accessor: "#",Cell: (row: any) => {                       
                            return <div style={{ textAlign: "right" }}>{row.original.in_sno}</div>;
                        }});
                        let cntIndex: number = 0;
                        for(var prop of Object.keys(res.data[getDtlPoint][0])){
                            const headerTitle: string = CPapersscommon.getTableTitle(prop);                     
                            const propValue: string = prop;                     
                            const isVisible: any = cntIndex === 0 ? false : true;
                            if(propValue === "in_sno"){                            
                            }
                            else if(propValue === "in_status"){   
                                colDataArr.push({Visible: isVisible,Header: headerTitle,accessor: propValue,
                                    show: !isVisible,className: "wordwrap",headerClassName: "wordwrap",Cell: (row: any) => {                       
                                        return <div>
                                        {row.original.in_status.split('(')[0]}
                                        {row.original.in_status.split('(').length > 1 ? 
                                        <><br /><span style={{fontSize: "10px",fontWeight: "bold"}}>{"(" + row.original.in_status.split('(')[1]}</span></> : null}
                                        </div>;
                                    }})                         
                            }
                            else{
                                colDataArr.push({Visible: isVisible,Header: headerTitle,accessor: propValue,
                                show: !isVisible,className: "wordwrap",headerClassName: "wordwrap"})
                            }
                            //responsive block
                            if(windowWidth > 800 && propValue === "dl_unsoldcount" && this.props.rootFlag === 6){
                                cntIndex++;
                            }
                            else if(windowWidth < 600 && (propValue === "in_organizernmae" || propValue === "in_organizername")){
                                cntIndex++;
                            }
                            else if(windowWidth > 600 && windowWidth <= 800 && propValue === (this.props.rootFlag === 6 ? "in_drawdate" : this.props.rootFlag === 5 ? "in_mrp" : "in_gameid")){
                                cntIndex++;
                            }
                            //responsive block
                        }
                        if(windowWidth > 800 && this.props.rootFlag !== 6){ 
                        }
                        else{                 
                        colDataArr.push({
                        width: 50,
                        Header: "View",
                        accessor: "View",
                        //resizable: false,
                        sortable: false,
                        className: "wordwrap",
                        Cell: (row: any) => (
                        <div className="userLogBtnCont3" style={{padding: 0}}>	
                            <small>
                                {/* <img key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,row.index)}></img> */}
                                <i  key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="fa showPlusMinus fa-plus GreenBgg" style={{borderRadius: "100px",padding: "5px"}} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e,row.index)}></i>
                            </small>          
                            <div key={"tabPopup_" + row.index} id={"tabPopupC_" + row.index} className="graphPop4 graphPop-1">
                            <Tablistview keys={"tabPopupV_" + row.index} rowData={row.original} colData={this.state.aGWReportsTab.colData} pageTitle="PaperLottery" />
                            {/* <img alt="" src={TooltipData}></img>	 */}
                            </div>
                        </div>
                        )
                        });
                    }
                  }
                  else{
                    errText = "No Data Available"
                  }  
                }
                else{
                    errText= res.data.result.toString();
                }          
                //$("#templaTBlid").attr("style", "display:none") 
                if(errText === undefined || errText === ""){
                    initialState.aGWReportsTab.colData = colDataArr;               
                    initialState.aGWReportsTab.tabData = rowDataArr; 
                    initialState.aGWReportsTab.selected = {}; 
                    initialState.aGWReportsTab.selectAll = 0; 
                }
                initialState.aGWReportsTab.filteredData = [];
                initialState.aGWReportsTab.searchInput = "";
                initialState.succFlag = undefined;
                initialState.errMsg = errText;
                if((errText === undefined || errText === "") && succMsg !== undefined && succMsg !== ""){
                    errText = succMsg;
                    initialState.succFlag = 0;
                    initialState.errMsg = errText;
                }
                $("#divLoading").attr("style", "display:none") 
                this.setState(initialState);
                if(errText !== undefined && errText !== ""){
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                }
                return
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none")
                this.setState({succFlag: undefined,errMsg: "No Data Available"});
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return false
            })
        }
        catch(ex) {
          alert("Load List Data Failed..");
          return
        } 
    }
    // Paper lott data Insert/Update/Delete
    createPaperLottData = (unsoldClaimFlag?: any) => {
        const resVal: any = this.state;
        const gameId: number = parseInt(resVal.aGWReportsDrop.gameDetId.value.split('_')[0]);
        const drawDateFormat: any = moment(resVal.aGWReportsDrop.gameDetId.drawDate,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00";
        try{
            $("#divLoading").attr("style", "display:block")
            const userIp: string = $("#userIp").text();    
            const jsonData: any = this.props.rootFlag === 1 ? {
                "GameID": gameId,
                "GameName": resVal.aGWReportsDrop.gameDetId.gamename,
                "DrawDate": drawDateFormat,
                "purchase_array": resVal.aGWReportsTab.tabDbData,
                "OrganizerID": resVal.aGWReportsDrop.gameDetId.orgId,
                "ipaddress": userIp
            } : this.props.rootFlag === 2 ? {
                "GameID": gameId,
                "GameName": resVal.aGWReportsDrop.gameDetId.gamename,
                "DrawDate": drawDateFormat,
                "unsold_array": unsoldClaimFlag === 2 ? [{"tickenumber":""}] : resVal.aGWReportsTab.tabDbData,
                "OrganizerID": resVal.aGWReportsDrop.gameDetId.orgId,
                "ipaddress": userIp,
                "sold_status" : unsoldClaimFlag
            } : this.props.rootFlag === 3 ? {
                "GameID": gameId,
                "GameName": resVal.aGWReportsDrop.gameDetId.gamename,
                "DrawDate": drawDateFormat,
                "winners_array": resVal.aGWReportsTab.tabDbData,
                "OrganizerID": resVal.aGWReportsDrop.gameDetId.orgId,
                "ipaddress": userIp
            } : this.props.rootFlag === 4 ? {
                "GameID": gameId,
                "GameName": resVal.aGWReportsDrop.gameDetId.gamename,
                "DrawDate": drawDateFormat,
                "TicketNumber": resVal.aGWReportsText.selTxnValue.name,
                "OrganizerID": resVal.aGWReportsDrop.gameDetId.orgId,
                "ipaddress": userIp,
                "claim_status" : unsoldClaimFlag === undefined || unsoldClaimFlag === null ? 0 : unsoldClaimFlag
            } : this.props.rootFlag === 5 ? {
                "GameID": gameId,
                "GameName": resVal.aGWReportsDrop.gameDetId.gamename,
                "DrawDate": drawDateFormat,
                "TicketNumber": unsoldClaimFlag.split('@')[0],
                "OrganizerID": resVal.aGWReportsDrop.gameDetId.orgId,
                "ipaddress": userIp,
                "purchase_status" : parseInt(unsoldClaimFlag.split('@')[1])
            } : {
                "GameID": gameId,
                "DrawDate": drawDateFormat,
                "OrganizerID": resVal.aGWReportsDrop.gameDetId.orgId,
                "ipaddress": userIp
            };
            const callApiUrl: string = this.props.rootFlag === 1 ? "GPI_Papers" : this.props.rootFlag === 2 ? "GUS_Papers" : this.props.rootFlag === 3 ? "GWI_Papers" :
            this.props.rootFlag === 4 ? "GTC_Papers" : this.props.rootFlag === 5 ? "GVPBU_Papers" : "GDP_Papers";
            if(this.props.rootFlag !== 6){
                //MANI 23/12
            //useAxios.post(config.Url(callApiUrl,"post"), jsonData, config.authHeader())
            apiHelper.postAPICall(callApiUrl,"post",2,jsonData)
            .then((res: any) => {
                console.log("RESPONSE RECEIVED: ", res);               
                //error message based stop the update process- shows an error pop
                if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
                    $("#divLoading").attr("style", "display:none") 
                    const errMsg: string = res.data.result.toString();     
                    this.setState({errMsg: errMsg,succFlag: undefined})
                    $('.overlay').fadeIn();
                    if(unsoldClaimFlag === 2 || unsoldClaimFlag === 1){
                        $('#lightBox6').fadeOut(); 
                    }
                    if(errMsg.includes('SuccessWA')){
                        $('#lightBoxHead').html(errMsg.split('~')[0]); 
                        $('#okBtn').html('CLAIM'); 
                        $('#lightBox6').fadeIn(); 
                    }
                    else{
                        $('#lightBox2').fadeIn();
                    }  
                    return
                }
                else{  
                    if(unsoldClaimFlag === 2 || unsoldClaimFlag === 1){
                        $('.overlay').fadeOut();
                        $('#lightBox6').fadeOut(); 
                    }
                    if(this.props.rootFlag === 5) //GET AFTER BLOCK/UNBLOCK
                        this.loadListTableData(res.data.result.toString().replace('successfully',''));
                    else                 
                        this.resetValues(res.data.result.toString().replace('successfully',''));            
                    return
                }
            })
            .catch((err) => {
                if(unsoldClaimFlag === 2 || unsoldClaimFlag === 1){
                    $('.overlay').fadeOut();
                    $('#lightBox6').fadeOut(); 
                }
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none")
                return 
            }) 
            }
            else{
                //mani 23/12
                //useAxios.delete(config.Url(callApiUrl,"delete"), config.authHeaderDelete(jsonData))
                apiHelper.deleteAPICall(callApiUrl,"delete",3,jsonData)
                .then((res: any) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    //error message based stop the update process- shows an error pop
                    if(res.data !== null && res.data.result !== null && !res.data.result.toString().includes('successfully')){
                        $("#divLoading").attr("style", "display:none") 
                        const errMsg: string = res.data.result.toString();                    
                        this.setState({errMsg: errMsg,succFlag: undefined})
                        $('.overlay').fadeIn();
                        if(unsoldClaimFlag === 2){
                            $('#lightBox6').fadeOut(); 
                        }
                        $('#lightBox2').fadeIn();
                        return
                    }
                    else{  
                        if(unsoldClaimFlag === 2){
                            $('.overlay').fadeOut();
                            $('#lightBox6').fadeOut(); 
                        }                  
                        this.resetValues(res.data.result.toString().replace('successfully',''));            
                        return
                    }
                })
                .catch((err) => {
                    if(unsoldClaimFlag === 2){
                        $('.overlay').fadeOut();
                        $('#lightBox6').fadeOut(); 
                    }
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    return 
                })
            }
            }
            catch(ex) {
            alert("Data Insertion Failed..");
            return
            }  
    }
    // plus minus expand/collapse functioanlity
    onShowLightBox= (e: any, index: any) => {
        e.preventDefault();         
        const currDisplay: any = $('#tabPopupC_' + index).css('display');    
        // $('.fa.fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        // $('.fa-minus').addClass('fa-plus').removeClass('fa-minus');  
        $('.showPlusMinus').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        $('.graphPop4').css({display: "none"})   
        $('#tabPopupIC_' + index).attr('class', (currDisplay === "none" ? "fa showPlusMinus fa-minus RedBgg" : "fa showPlusMinus fa-plus GreenBgg"));      
        $('#tabPopupC_' + index).css({display: currDisplay === "none" ? "block" : "none"})  
        return 
    } 
    //export datas xls..,csv..,print
    exportXLPdfPrintdownload = (e: any,fileType: any) => {
        try{
        e.preventDefault();
        if(fileType === "chart"){
            $('.overlay').fadeIn();
            $('#lightBox4').fadeIn();
            return
        }
        else{
            const rootFlag: number = this.props.rootFlag;
            const pageTitle: any = CPapersscommon.getPageTitle(rootFlag);
            const fileName: string = pageTitle.replaceAll(' ','');  
            const Orgdata: any = this.state.aGWReportsTab.tabData;
            if(fileType === "pdf"){
                CPapersscommon.exportPDF(Orgdata,pageTitle,fileName,this.props.rootFlag);
            }
            else if(fileType === "csv" || fileType === "xls"){  
                CPapersscommon.exportExcel(Orgdata,fileName,fileType,this.props.rootFlag);   
            }
            else{
                CPapersscommon.exportPrint(Orgdata,pageTitle,this.props.rootFlag);
            }
        }
        return
        }
        catch(ex) {
        alert("Export Failed..");
        return
        }
    }
    // And in your global search
    globalSearch = (e: any) => {
    try{
        let initialState : any = this.state;
        $('.showPlusMinus').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');  
        $('.graphPop4').css({display: "none"})   
        const searchInput: string = e.target.value;
        const skipGT: any = initialState.aGWReportsTab.tabData;
        let filteredData = skipGT.filter((value: any) => {
        return CPapersscommon.getKeyByValue(value, searchInput,this.props.rootFlag);
        });
        initialState.aGWReportsTab.filteredData = filteredData;
        initialState.aGWReportsTab.searchInput = searchInput;
        initialState.succFlag = undefined;
        initialState.errMsg = "";
        this.setState(initialState);
        return
    }
    catch(ex) {
        alert("Load Search filter List Load Failed..");
        return
    }        
    }; 
    /* From/To Date change event */
    handleDateChange = (date: any,key: string,format: string) => {
    try{
        let initialState : any = this.state;
        const resVal: any = initialState.aGWReportsDt;
        resVal[key].defValue = moment(date._d);
        resVal[key].value = moment(date._d).format(format);
        if(this.props.rootFlag !== 6){
            resVal.FdrawDate.display = resVal.FdrawDate.value === null ? true : resVal.TdrawDate.value === null ? false : (resVal.TdrawDate.value !== null && moment(resVal.FdrawDate.value,format) > moment(resVal.TdrawDate.value,format))   
            resVal.TdrawDate.display = resVal.TdrawDate.value !== null ? false : true;
        }
        else{
            resVal.FdrawDate.display = false;
        }
        initialState.aGWReportsDt = resVal;
        initialState.aGWReportsTab.filteredData = [];
        initialState.aGWReportsTab.searchInput = "";
        if(this.props.rootFlag === 4 || this.props.rootFlag === 5 || this.props.rootFlag === 6){
            initialState.aGWReportsTab.tabData = [];
            initialState.aGWReportsTab.selected = {};
            initialState.aGWReportsTab.selectAll = 0;
            initialState.aGWReportsTab.tabDataSub = [];
            initialState.aGWReportsTab.tabDbData = [];
        }
        initialState.aGWReportsDrop.gameDetId = {value: null,gamename: null,drawDate: null,orgId: null,display: false};
        initialState.succFlag = undefined;
        initialState.errMsg = "";
        let is_superuser: any = null;
        let userId: any = "";
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null){
            userId = userInfo["userId"];
            is_superuser = userInfo["is_superuser"];
        }
        if(!resVal.FdrawDate.display && !resVal.TdrawDate.display){
            const callJsonHead: any = { 
                FromDate: moment(resVal.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                ToDate: moment((this.props.rootFlag === 6 ? resVal.FdrawDate.value : resVal.TdrawDate.value),'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                organizerID: is_superuser ? 0 : userId
            }
            this.loadNameMasterdata(initialState,callJsonHead);
            return
        }
        else{
            this.setState(initialState);
        }
    }
    catch(ex) {
      alert("On Date Change Failed..");
      return
    }        
    };  
    //ticket no chnage for winners list
    onhandleTxtChange= (e: any,fieldFlag: number) => {
        try{
            const resVal: any = this.state;
            const resVals: any = this.state;
            resVal.aGWReportsTab.filteredData = [];
            resVal.aGWReportsTab.searchInput = "";
            resVal.aGWReportsTab.tabData = [];
            resVal.aGWReportsTab.selected = {};
            resVal.aGWReportsTab.selectAll = 0;
            resVals.aGWReportsTab.tabDataSub = [];
            resVal.aGWReportsTab.tabDbData = [];
            resVal.succFlag = undefined;
            resVal.errMsg = "";
            if(fieldFlag === 0){
                resVal.aGWReportsText.selTxnValue.name = e.target.value;
                resVal.aGWReportsText.selTxnValue.display = e.target.value.trim() === "" ? true : false;
            }
            this.setState(resVal);
            return
        }
        catch(ex) {
          alert("On Ticket Number Change Failed..");
          return
        }
    } 
    /* Game Drop down change */
    handleDropChange = (e: any,type: number) => {
    try{
        const resVals: any = this.state;
        resVals.aGWReportsTab.filteredData = [];
        resVals.aGWReportsTab.searchInput = "";
        if(this.props.rootFlag === 4 || this.props.rootFlag === 5 || this.props.rootFlag === 6){
            resVals.aGWReportsTab.tabData = [];
            resVals.aGWReportsTab.selected = {};
            resVals.aGWReportsTab.selectAll = 0;
            resVals.aGWReportsTab.tabDataSub = [];
            resVals.aGWReportsTab.tabDbData = [];
        }
        resVals.succFlag = undefined;
        resVals.errMsg = "";
        if(type === 2){
            resVals.aGWReportsDrop.gameDetId.value = e.value;
            resVals.aGWReportsDrop.gameDetId.gamename = e.label;
            resVals.aGWReportsDrop.gameDetId.drawDate = e.drawDate;
            resVals.aGWReportsDrop.gameDetId.orgId = e.orgId;
            resVals.aGWReportsDrop.gameDetId.display= false;
        }
        this.setState(resVals);
        return
    }
    catch(ex) {
      alert("On Dropdown Change Failed..");
      return
    }
    }
    //on change the paper lott file upload data
    onhandleFileChange = (e: any) => {
        let file:any = (e.target as HTMLInputElement).files;
        $("#divLoading").attr("style", "display:block") 
        const resVal: any = this.state;
        if(file.length !== 0){           
            let fileNam:string = file[0].name.toString();
            let sizeInBytes:any = file[0].size;
            let fileType: string = fileNam.split('.')[1];
            let fileName: string = fileNam.split('.')[0];    
            const sizeInMB = Math.round(sizeInBytes / (1024*1024));
            if(fileType === undefined){     
                $('#myfileExp').val('');           
                $("#divLoading").attr("style", "display:none") 
                resVal.errMsg = "Invalid file..!";
                resVal.fileAttach.fileResult = null;
                resVal.fileAttach.name = "";
                resVal.fileAttach.display = false; 
                resVal.aGWReportsTab.tabData = [];
                resVal.aGWReportsTab.selected = {};
                resVal.aGWReportsTab.selectAll = 0;
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return 
            }
            else if(sizeInMB <= 10 && (fileType.includes('xls') || fileType.includes('csv'))){   
                resVal.fileAttach.fileResult = file[0];
                resVal.fileAttach.name = fileName + "." + fileType;
                resVal.fileAttach.display = false;
                resVal.aGWReportsTab.tabData = [];
                resVal.aGWReportsTab.selected = {};
                resVal.aGWReportsTab.selectAll = 0;
                return this.convertExcelCsvFiletoJson(resVal.fileAttach.fileResult,this.props.rootFlag === 3 ? 2 : 1, (err:any, response:any) => {
                    if(err || response === undefined || response === null){
                        console.log(err); 
                        $('#myfileExp').val(''); 
                        $("#divLoading").attr("style", "display:none")  
                        resVal.fileAttach.fileResult = null;
                        resVal.fileAttach.name = "";
                        resVal.fileAttach.display = false; 
                        resVal.errMsg = "Invalid data..!";
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                        return  
                    }
                    else{
                      $("#divLoading").attr("style", "display:none")                        
                        resVal.aGWReportsTab.tabDataSub = response.data;
                        resVal.aGWReportsTab.colDataSub = response.colData;
                        resVal.aGWReportsTab.tabDbData = response.dataDb;
                        this.setState(resVal);
                        return
                    }
                });    
            }
            else if(sizeInMB <= 10){
                resVal.errMsg = "Invalid file..!";
                resVal.fileAttach.fileResult = null;
                resVal.fileAttach.name = "";
                resVal.fileAttach.display = false; 
                resVal.aGWReportsTab.tabData = [];
                resVal.aGWReportsTab.selected = {};
                resVal.aGWReportsTab.selectAll = 0;
                $('#myfileExp').val('');
                $("#divLoading").attr("style", "display:none") 
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return 
            }
            else{
                resVal.errMsg = "Data size should be less than or equals 10 MB";
                resVal.fileAttach.fileResult = null;
                resVal.fileAttach.name = "";
                resVal.fileAttach.display = false; 
                resVal.aGWReportsTab.tabData = [];
                resVal.aGWReportsTab.selected = {};
                resVal.aGWReportsTab.selectAll = 0;
                $('#myfileExp').val('');
                $("#divLoading").attr("style", "display:none") 
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return 
            }                             
        }
        else{
            resVal.fileAttach.fileResult = null;
            resVal.fileAttach.name = "";
            resVal.fileAttach.display = false;
            resVal.aGWReportsTab.tabData = [];
            resVal.aGWReportsTab.selected = {};
            resVal.aGWReportsTab.selectAll = 0;
            resVal.errMsg = "No files selected..";
            $('#myfileExp').val('');
            $("#divLoading").attr("style", "display:none") 
            this.setState(resVal);
        }
        return
    }
    // convert excel and csv file into json obj
    convertExcelCsvFiletoJson(file:any,type: number,callback:any) { 
        let _this: any = this;
        return new Promise<void>(function(resolve, reject) {
        try {
            var reader = new FileReader();
            var rABS = !!reader.readAsBinaryString;
            reader.onload = function(e:any) {
            try{
                /* Parse data */
                var bstr = e.target.result;
                var wb = excel.read(bstr, { type: rABS ? "binary" : "array",
                cellDates: true //, dateNF: 'dd/mm/yyyy;@' 
                });
                var datasVal: any= [];
                var datasDbVal: any= [];
                var datasDbVal1: any= [];
                var datasDbVal2: any= [];
                var errCount: number = 0;
                for(let i = 0;i < 1; i++){ //wb.SheetNames.length
                /* Get all worksheets */
                var wsname = wb.SheetNames[i];
                var ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const jsonArr: any = excel.utils.sheet_to_json(ws, {
                    header: 1,
                    raw: false,defval: '',blankrows: false
                });
                
                if(jsonArr.length > 0){                  
                    // const tabName: string = wsname.toString();
                    // sheetName.push(tabName); 
                    jsonArr.shift();
                    for(let i = 0; i < jsonArr.length; i++){
                        let datasObj: any = {}
                        if(type === 1){
                            if(jsonArr[i].length >= 1){
                                Object.assign(datasObj, {"in_sno": i + 1,"tickenumber": jsonArr[i][0]})
                                datasDbVal1.push(jsonArr[i][0]);
                            }
                            else{
                                errCount = 1;
                                break;
                            }
                        }
                        else if(type === 2){
                            if(jsonArr[i].length >= 2){
                            Object.assign(datasObj, {"in_sno": i + 1,"tickenumber": jsonArr[i][0],"winamount": jsonArr[i][1]})
                            datasDbVal1.push(jsonArr[i][0]);
                            datasDbVal2.push(jsonArr[i][1]);
                            }
                            else{
                                errCount = 1;
                                break;
                            }
                        }
                        // else if(type === 3){
                        //     if(jsonArr[i].length >= 2){
                        //     Object.assign(datasObj, {"in_sno": i + 1,"tickenumber": jsonArr[i][0],"claimamount": jsonArr[i][1]})
                        //     datasDbVal1.push(jsonArr[i][0]);
                        //     datasDbVal2.push(jsonArr[i][1]);
                        //     }
                        //     else{
                        //         errCount = 1;
                        //         break;
                        //     }
                        // }
                        datasVal.push(datasObj)
                    }
                    //datas.push(datasVal);
                    if(type === 1){
                        datasDbVal.push({"tickenumber": datasDbVal1});
                    }
                    else if(type === 2){
                        datasDbVal.push({"tickenumber": datasDbVal1,"winamount": datasDbVal2});
                    }
                    // else if(type === 3){
                    //     datasDbVal.push({"tickenumber": datasDbVal1,"claimamount": datasDbVal2});
                    // }
                }
                else{
                    errCount = 1;
                    break;
                }
                }
                const resData: any = errCount === 0 ?
                {
                    data: datasVal,
                    colData: _this.loadColDatas(datasVal[0]),
                    dataDb: datasDbVal
                } : null;
                resolve(resData);
                return callback(null, resData);
            }
            catch(ex){
                return callback(null);
            }
            };
            if (file && rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        } catch(err){
            $("#divLoading").attr("style", "display:none")
            reject(err);
        }
        });
    } 
    // load view column heeder data
    loadColDatas = (resData: any) => {
        const colDataArr: any = [];
        for(var prop of Object.keys(resData)){
            const headerTitle: string = CPapersscommon.getTableTitle(prop);                     
            const propValue: string = prop; 
            if(propValue === "in_sno"){
                colDataArr.push({width: 40,Header: headerTitle,accessor: propValue,
                    className: "wordwrap",headerClassName: "wordwrap"})
            } 
            else{                   
                colDataArr.push({Header: headerTitle,accessor: propValue,
                    className: "wordwrap",headerClassName: "wordwrap"})
            }
        }
        return colDataArr;
    }    
    render() {
    const resVal: any = this.state;    
    const defaultMaterialTheme = createMuiTheme({
        palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#009269',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
          }
        },
    });
    const DropdownIndicator = (props: any) => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <FontAwesomeIcon style={{fontSize: "20px"}} icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>
            </components.DropdownIndicator>
          )
        );
      };
    const styles = {
        menu: (base: any) => ({
            ...base,
            marginTop: 0
        })
    };
    const pageSize: number = resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 50 ? 50 : resVal.aGWReportsTab.tabData.length > 25 ? 25 : 10;
    const headerTitle: any = CPapersscommon.getPageTitle(this.props.rootFlag);
      return(
        <div className="ContainerChart scrollbar" style={{height: $(window).height() - 60 + "px"}}>  
            <ListSubTable
                tabData= {resVal.aGWReportsTab.tabDataSub}
                colData= {resVal.aGWReportsTab.colDataSub}
                rootFlag= {this.props.rootFlag}
                sorting={true}
                ref ={this.subTabStateReset}
            />
            <div id="lightBox6" className="lightBox6" style={{marginLeft: '-'+ ($('#lightBox6').width() / 2) +'px',marginTop: '-'+ ($('#lightBox6').height() / 2) +'px'}}>	
                <div className="userLog2">					
                    <h2 id="lightBoxHead">&nbsp;</h2>
                    <p>Do you want to continue?</p>                        
                    <div className="Btninfo">	
                        <button id="cancelBtn" className="GreyBg" onClick={() => this.continuePopup(1)}>CANCEL</button>					
                        <button id="okBtn" onClick={() => this.continuePopup(0)}>&nbsp;</button>				
                    </div>
                </div>
            </div>          					          
            <Lightbox errMsg= {resVal.errMsg} succFlag= {resVal.succFlag} />   
            <div className="contentclouds">         
            <div className="userLogHead">                
                <div className="userLogHeadinner">
				<h2>{headerTitle}</h2>
				<small>Give the Paper lottery details. Then it would be monitoring in  MARES SYSTEM</small>
			    </div>
                <Breadcrumb Menu= "Paper Lottery" subMenu= {headerTitle} /> 
            </div>
            <div className="UseformNew">
            <div className="Dateform">											
            <div className="userLogformTop">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <ul>
                    {/* {is_superuser !== null && is_superuser ? (
                    <li style={{width: this.props.rootFlag === 5 ? "25%" : this.props.rootFlag === 6 ? "33.3333%" : "20%"}}>                        
                        <div className="userLogCloudtop">
                            <Select
                            value={resVal.aGWReportsDrop.OrganizerName.value === null ? null : resVal.aGWReportsDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.aGWReportsDrop.OrganizerName.value.toString())}
                            onChange={(e: any) => {this.handleDropChange(e, 1)}}
                            //isDisabled={this.props.rowData === null ? false : true}
                            placeholder='Select Organizer Name'
                            options={resVal.aGWReportsDrop.OrganizerNames}
                            // maxMenuHeight={110}
                            className="userlogSelect"
                            components={{ DropdownIndicator }}
                            styles={styles}
                            />
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGWReportsDrop.OrganizerName.display ? "block" : "none"}}>Organizer Name has to be provided</label>
                        </div>
                    </li>) : null} */}
                    <li style={{width: (this.props.rootFlag === 5 || this.props.rootFlag === 6 ? "33.3333%" : "25%")}}>
                        <div className="userLogCloudtop">
                        <ThemeProvider theme={defaultMaterialTheme}>                                        
                            <DatePicker
                                placeholder="Pick From Date"
                                className="rptdatePicker"
                                format="DD-MM-yyyy"
                                value={resVal.aGWReportsDt.FdrawDate.defValue}
                                onChange={(date: any) => this.handleDateChange(date,"FdrawDate","DD-MM-yyyy")}
                            />                                        
                            </ThemeProvider>
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGWReportsDt.FdrawDate.display ? "block" : "none"}}>{this.props.rootFlag !== 6 && this.props.rootFlag !== 7 && resVal.aGWReportsDt.FdrawDate.value === null ? "From Date has to be provided" : resVal.aGWReportsDt.FdrawDate.value === null ? "Draw Date has to be provided" : "From Date should not be greater than To Date"}</label>
                        </div>
                    </li>                    
                    {this.props.rootFlag !== 6 ?
                    (<li style={{width: (this.props.rootFlag === 5 ? "33.3333%" : "25%")}}>                        
                        <div className="userLogCloudtop">
                            <ThemeProvider theme={defaultMaterialTheme}>
                            <DatePicker
                                placeholder="Pick To Date"
                                className="rptdatePicker"
                                format="DD-MM-yyyy"
                                value={resVal.aGWReportsDt.TdrawDate.defValue}
                                onChange={(date: any) => this.handleDateChange(date,"TdrawDate","DD-MM-yyyy")}
                            />
                            </ThemeProvider>
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGWReportsDt.TdrawDate.display ? "block" : "none"}}>To Date has to be provided</label>
                        </div> 
                    </li>) : null}
                    <li style={{width: (this.props.rootFlag === 5 || this.props.rootFlag === 6 ? "33.3333%" : "25%")}}>                        
                        <div className="userLogCloudtop">
                            <Select
                            value={resVal.aGWReportsDrop.gameDetId.value === null ? null : resVal.aGWReportsDrop.gameDet.filter((option: any) => option.value.toString() === resVal.aGWReportsDrop.gameDetId.value.toString())}
                            onChange={(e: any) => {this.handleDropChange(e, 2)}}
                            placeholder='Select Game Name'
                            options={this.props.rootFlag === 6 ? resVal.aGWReportsDrop.gameDet.filter((elem: any) => elem.value.toString() !== "0") : resVal.aGWReportsDrop.gameDet}
                            // maxMenuHeight={110}
                            className="userlogSelect"
                            components={{ DropdownIndicator }}
                            styles={styles}
                            />
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGWReportsDrop.gameDetId.display ? "block" : "none"}}>Game Name has to be provided</label>
                        </div>
                    </li>
                    {this.props.rootFlag !== 4 && this.props.rootFlag !== 5 && this.props.rootFlag !== 6 ?
                    (<li style={{width: "25%"}}>                        
                        <div className="userLogCloudtop">
                            <div className="userLogBtnTop3">
                            <input className="newUserTxt" style= {{color: "transparent"}} type="file" id="myfileExp" name="myfile1" accept=".csv,.xlsb,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                             onChange={(e: any) => this.onhandleFileChange(e)} hidden />
                            <label htmlFor="myfileExp" id="myfileExport"><i className="fas fa-file-import" style={{marginRight: "5px"}} aria-hidden="true"></i>Choose file</label>
                            <a title="Sample Excel Data" href={this.props.rootFlag === 1 ? '/excel/paper_1.xlsx' : this.props.rootFlag === 2 ? "/excel/paper_2.xlsx" : "/excel/paper_3.xlsx"} target="_blank" rel="noreferrer" download={headerTitle.replaceAll(' ','') + "_sample"}><i className="fa fa-download" style={{color:"#fff"}} aria-hidden="true"></i> </a>
                            </div>
                            {resVal.fileAttach.name === null || resVal.fileAttach.name === "" ? null : (<label style={{fontSize: "12px",color: "#fff"}}><b>{resVal.fileAttach.name}</b></label>)}
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.fileAttach.display ? "block" : "none"}}>Must import CSV or Excel data</label>
                        </div>
                    </li>) : this.props.rootFlag === 4 ?
                    (<li style={{width: "25%"}}>                        
                        <div className="userLogCloudtop">
                            <input className="newUserTxt" value={resVal.aGWReportsText.selTxnValue.name} onChange={(e: any) => this.onhandleTxtChange(e,0)} placeholder="Enter Ticket Number" autoComplete="off" type="text" maxLength={50}/>
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGWReportsText.selTxnValue.display ? "block" : "none"}}>Ticket Number has to be provided</label>
                        </div>
                    </li>) : null}
                </ul>
                </MuiPickersUtilsProvider>
            </div>            
            <div className="userLogBtnTop">							
                {this.props.rootFlag !== 4 && this.props.rootFlag !== 5 ?
                (<button id="registerBtn" onClick={(e: any) => this.onFilterListTableData(e,"View")}><i className="fas fa-eye" style={{marginRight: "5px"}} aria-hidden="true"></i>View</button>) : null}		
                <button id="registerBtn" onClick={(e: any) => this.onFilterListTableData(e,"Create")}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>Submit</button>		
                <button id="registerBtn1" style={{float: "right"}} onClick={() => this.resetValues()}><i className="fa fa-sync" style={{marginRight: "5px"}} aria-hidden="true"></i>Reset</button>		
            </div>            
            </div> 
            {/* {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ?
            (<div className="TblError">
				<span>Report Generated On: <b>{resVal.rptGenDate}</b></span>
			</div>) : null} */}
            {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ? 
            (<div className="ContainerTable">
                <div className="tableSearchreport">
                    <div className="sportSrch">
                        <input type="text" placeholder="Search here..!" id="myInput" value={resVal.aGWReportsTab.searchInput} onChange={(e: any) => this.globalSearch(e)} autoComplete="off" />
                        {this.props.rootFlag === 5 ?
                        (<><button className="ButtonDocBU" type="button" onClick={(e: any) => this.onBlkUnBlkListData(e, 5)}>
                            <a href="#section"><i className="fas fa-times" style={{marginRight: "5px",fontSize: "16px"}}></i>Block</a>
                        </button>
                        <button className="ButtonDocBU" type="button" onClick={(e: any) => this.onBlkUnBlkListData(e, 0)}>
                            <a href="#section"><i className="fas fa-check" style={{marginRight: "5px",fontSize: "16px"}}></i>Unblock</a>
                        </button></>) : null}
                    </div>
                </div>
                <div className="ButtonArea">  
					<button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'pdf')}>
						<a href="#section"><i className="fas fa-file-export" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>PDF</a>
					</button> 
					{/* <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'csv')}>
						<a href="#section"><i className="fas fa-file-excel" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>CSV</a>
					</button> */}
                    <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'xls')}>
						<a href="#section"><i className="fas fa-file-excel" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>Excel</a>
					</button>
					<button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'print')}>
						<a href="#section"><i className="fas fa-print" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>Print</a>
					</button>
				</div>
			</div>) : null}	
            {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ? (
			<div className="TableReact">            
                <ListTable
                sorting={true}
                columns={resVal.aGWReportsTab.colData}
                pageSize={pageSize}
                data={resVal.aGWReportsTab.filteredData && resVal.aGWReportsTab.searchInput !== "" ? resVal.aGWReportsTab.filteredData : resVal.aGWReportsTab.tabData}           
                />
            </div>) : null}   
            </div>
            </div>
        </div>
    );
  }
}

export default AGWPapers;