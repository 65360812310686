import React from 'react';
//import useAxios from "axios";
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import moment from "moment"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// import { green } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';
import _ from "lodash"

import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import ListTable from "../Shared/listtable";
import ListChart from "../Shared/listchart";
import Breadcrumb from "../Shared/breadcrumb";
import CommonReports from './Child/c_reportscommon';
import Tablistview from "../Shared/tablistview";
import CReportsmodel from "./Child/c_reportsmodel";
// import TooltipData from "../../assets/img/icons/Arrow_new.png"
// import PlusIcon from "../../assets/img/icons/Plus_Icon.png"
// import MinusIcon from "../../assets/img/icons/Minus_Icon.png"
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import apiHelper from '../../utils/ApiHelper';

var $ = require('jquery')
library.add(faCaretDown);
library.add(faCaretUp);

export interface IProps {
    rootFlag: number
    dashJsondata?: any
    backClick?: any,
    t: any
}
export interface IState {
    succFlag: any,
    errMsg: string,
    aGWReportsDt: any,
    aGWReportsDrop: any,
    aGWReportsCt: any,
    aGWReportsTab: any,
    rptGenDate: any
}
// 1- draw date 0- sale date
// RootFlag Note:
// 1: Game wise Sales Vs Payout
// 2: Game wise Profit Vs Loss
// 3: Game wise Profit Vs Loss(Filter)
// 6: Game wise Last Transaction
// 7: View Transaction Details
// 8: Terminal(Player) wise Profit Vs Loss
// 9: Terminal(Player) Game Type wise Profit Vs Loss
// 13. Channel wise Sales Vs Payout
// 14. Mode wise Sales Vs Payout
// 15. Organizer wise Profit Vs Loss
// 17. Claimed Vs Unclaimed Winning per draw
class AGWReports extends React.Component<IProps, IState> {
    // ------------------------------------------^
    constructor(props: IProps) {
        super(props);
        this.state = CReportsmodel();
        this.globalSearch = this.globalSearch.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.onhandleTxtChange = this.onhandleTxtChange.bind(this);
        this.handleDropChange = this.handleDropChange.bind(this);
        this.onFilterListTableData = this.onFilterListTableData.bind(this);
        this.exportXLPdfPrintdownload = this.exportXLPdfPrintdownload.bind(this);
        this.handleToggleClick = this.handleToggleClick.bind(this);
        this.redirectPage = this.redirectPage.bind(this);
    }
    componentDidMount() {
        let is_superuser: any = null;
        let userId: any = "";
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if (userInfo !== null) {
            is_superuser = userInfo["is_superuser"];
            userId = userInfo["userId"];
        }
        this.loadTypeMasterdata(is_superuser, userId);
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps: any) {
        // Typical usage (don't forget to compare props):
        if (this.props.rootFlag !== prevProps.rootFlag) {
            this.resetValues();
        }
    }
    resetValues = () => {
        const resVals: any = this.state;
        let is_superuser: any = null;
        let userId: any = "";
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if (userInfo !== null) {
            is_superuser = userInfo["is_superuser"];
            userId = userInfo["userId"];
        }
        const initialState: any = CReportsmodel();
        initialState.aGWReportsDrop.gameType = resVals.aGWReportsDrop.gameType;
        if (this.props.rootFlag === 9)
            initialState.aGWReportsDrop.gameTypeId = { value: 0, display: false };
        else
            initialState.aGWReportsDrop.gameTypeId = { value: null, display: false };
        initialState.aGWReportsDrop.OrganizerNames = resVals.aGWReportsDrop.OrganizerNames;
        if (is_superuser && this.props.rootFlag !== 6 && this.props.rootFlag !== 7)
            initialState.aGWReportsDrop.OrganizerName = { value: 0, display: false };
        else
            initialState.aGWReportsDrop.OrganizerName = { value: null, display: false };
        if (this.props.rootFlag === 3 || (!is_superuser && this.props.rootFlag === 6)) {
            const callJsonHead: any = {
                FromDate: moment(initialState.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                ToDate: (this.props.rootFlag === 6 ? moment(initialState.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') : moment(initialState.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD')) + " 00:00:00",
                organizerID: is_superuser ? initialState.aGWReportsDrop.OrganizerName.value : userId
            }
            this.loadNameMasterdata(initialState, callJsonHead);
            return
        }
        else {
            this.setState(initialState);
            return;
        }
    }
    //load game type details
    loadTypeMasterdata = (is_superuser: any, userId: any) => {
        try {
            $("#divLoading").attr("style", "display:block")
            this.setState(CReportsmodel());
    //return apiHelper.postAPICall("login", "post",1,credentials)
    //ykarthik 22.12
    apiHelper.getAPICall("cm_game","get",4,"GameType~@#%&0")
           // useAxios.get(config.Url("cm_game", "get"), config.authHeaderwithParam("GameType~@#%&0"))
                .then((res) => {
                    console.log("RESPONSE RECEIVED game: ", res);
                    let gameTypeArr: any = [];
                    if (res.data !== null && res.data.length !== 0) {
                        if (res.data["load_gameDetails"] !== undefined && res.data["load_gameDetails"] !== null && res.data["load_gameDetails"].length !== 0) {
                            for (var prop of res.data["load_gameDetails"]) {
                                gameTypeArr.push({ value: prop["in_gamegroupid"], label: prop["in_gamegroupdesc"] })
                            }
                        }
                    }
                    $("#divLoading").attr("style", "display:none")
                    if (is_superuser) {
                        this.loadOrgNameMasterdata(gameTypeArr);
                    } else {
                        const resVals: any = this.state;
                        resVals.aGWReportsDrop.gameType = gameTypeArr;
                        if (this.props.rootFlag === 9)
                            resVals.aGWReportsDrop.gameTypeId = { value: 0, display: false };
                        else
                            resVals.aGWReportsDrop.gameTypeId = { value: null, display: false };
                        if (this.props.rootFlag === 3 || this.props.rootFlag === 6) {
                            const callJsonHead: any = {
                                FromDate: moment(resVals.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                                ToDate: (this.props.rootFlag === 6 ? moment(resVals.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') : moment(resVals.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD')) + " 00:00:00",
                                organizerID: userId
                            }
                            this.loadNameMasterdata(resVals, callJsonHead);
                            return
                        }
                        else {
                            if (this.props.dashJsondata !== undefined && this.props.dashJsondata !== null) {
                                resVals.aGWReportsDt.FdrawDate.defValue = this.props.dashJsondata.FdrawDate;
                                resVals.aGWReportsDt.FdrawDate.value = this.props.dashJsondata.FdrawDateVal;
                                resVals.aGWReportsDt.FdrawDate.display = false;
                                resVals.aGWReportsDt.TdrawDate.defValue = this.props.dashJsondata.TdrawDate;
                                resVals.aGWReportsDt.TdrawDate.value = this.props.dashJsondata.TdrawDateVal;
                                resVals.aGWReportsDt.TdrawDate.display = false;
                                this.setState(resVals, () => {
                                    this.loadListTableData();
                                });
                                return
                            }
                            else {
                                this.setState(resVals);
                                return;
                            }
                        }
                    }
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    return false
                })
        }
        catch (ex) {
            alert("Load Master Data Failed..");
            return
        }
    }
    //load organizer details
    loadOrgNameMasterdata = (gameTypes: any) => {
        try {
            $("#divLoading").attr("style", "display:block")
            //ykarthik 22.12
            apiHelper.getAPICall("cmo_game","get",4,"OrgName~@#%&0")
           // useAxios.get(config.Url("cmo_game", "get"), config.authHeaderwithParam("OrgName~@#%&0"))
                .then((res) => {
                    console.log("RESPONSE RECEIVED org: ", res);
                    let OrganizerNamesArr: any = [];
                    if (res.data !== null && res.data.length !== 0) {
                        if (res.data["organizer_details"] !== undefined && res.data["organizer_details"] !== null && res.data["organizer_details"].length !== 0) {
                            for (var prop of res.data["organizer_details"]) {
                                OrganizerNamesArr.push({ value: prop["dl_organizerid"], label: prop["dl_organizername"], userId: prop["dl_userid"] })
                            }
                        }
                    }
                    $("#divLoading").attr("style", "display:none")
                    //$("#templaTBlid").attr("style", "display:none") 
                    const resVals: any = this.state;
                    resVals.aGWReportsDrop.gameType = gameTypes;
                    resVals.aGWReportsDrop.OrganizerNames = OrganizerNamesArr;
                    if (this.props.rootFlag === 9)
                        resVals.aGWReportsDrop.gameTypeId = { value: 0, display: false };
                    else
                        resVals.aGWReportsDrop.gameTypeId = { value: null, display: false };
                    if (this.props.rootFlag !== 6 && this.props.rootFlag !== 7)
                        resVals.aGWReportsDrop.OrganizerName = { value: 0, display: false };
                    else
                        resVals.aGWReportsDrop.OrganizerName = { value: null, display: false };
                    if (this.props.rootFlag === 3) {
                        const callJsonHead: any = {
                            FromDate: moment(resVals.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                            ToDate: moment(resVals.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                            organizerID: resVals.aGWReportsDrop.OrganizerName.value
                        }
                        this.loadNameMasterdata(resVals, callJsonHead);
                        return
                    }
                    else {
                        if (this.props.dashJsondata !== undefined && this.props.dashJsondata !== null) {
                            resVals.aGWReportsDt.FdrawDate.defValue = this.props.dashJsondata.FdrawDate;
                            resVals.aGWReportsDt.FdrawDate.value = this.props.dashJsondata.FdrawDateVal;
                            resVals.aGWReportsDt.FdrawDate.display = false;
                            resVals.aGWReportsDt.TdrawDate.defValue = this.props.dashJsondata.TdrawDate;
                            resVals.aGWReportsDt.TdrawDate.value = this.props.dashJsondata.TdrawDateVal;
                            resVals.aGWReportsDt.TdrawDate.display = false;
                            resVals.aGWReportsDrop.OrganizerName.value = this.props.dashJsondata.organizerID;
                            this.setState(resVals, () => {
                                this.loadListTableData();
                            });
                            return
                        }
                        else {
                            this.setState(resVals);
                            return
                        }
                    }
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    return false
                })
        }
        catch (ex) {
            alert("Load Master Data Failed..");
            return
        }
    }
    /* load game names */
    loadNameMasterdata = (initialState: any, callJsonHead: any) => {
        try {
            $("#divLoading").attr("style", "display:block")
            //ykarthik 22.12
            apiHelper.getAPICall("ctm_game","get",5,callJsonHead)
            //useAxios.get(config.Url("ctm_game", "get"), config.authHeaderwithParamRpt(callJsonHead))
                .then((res) => {
                    console.log("RESPONSE RECEIVED dashnewGames: ", res);
                    let GameNamesArr: any = [];
                    if (res.data !== null && res.data.length !== 0) {
                        let getDtlPoint: any = "get_rptDetails";
                        if (res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0 && (res.data[getDtlPoint][0]["dl_gameid"] === 0 && res.data[getDtlPoint].length !== 1)) {
                            for (var prop of res.data[getDtlPoint]) {
                                GameNamesArr.push({ value: prop["dl_gameid"] + "_" + prop["dl_organizerid"], label: prop["dl_gamename"], orgId: prop["dl_organizerid"], orgName: prop["dl_organizerid"] })
                            }
                        }
                    }
                    $("#divLoading").attr("style", "display:none")
                    // initialState.aGWReportsDrop.gameDetId = CReportsmodel().aGWReportsDrop.gameDetId; 
                    initialState.aGWReportsDrop.gameDet = GameNamesArr;
                    initialState.aGWReportsDrop.gameDetId.value = null;
                    this.setState(initialState);
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    return false
                })
        }
        catch (ex) {
            alert("Load Master Data Failed..");
            return
        }
    }
    /* event triggers on click the submit button */
    onFilterListTableData = (e: any) => {
        try {
            e.preventDefault();
            const resVal: any = this.state;
            let isErrData: boolean = false;
            Object.entries(resVal.aGWReportsDt).forEach(([key, value]) => { //date field validation
                let val = resVal.aGWReportsDt[key].value;
                if (val === null || val === "" || resVal.aGWReportsDt[key].display) {
                    if ((this.props.rootFlag !== 6 && this.props.rootFlag !== 7) || key !== "TdrawDate") {
                        resVal.aGWReportsDt[key].display = true
                        isErrData = true
                    }
                } else { return }
            })
            if ((this.props.rootFlag === 3 || this.props.rootFlag === 6) && resVal.aGWReportsDrop.gameDetId.value === null) { //drop down validation
                resVal.aGWReportsDrop.gameDetId.display = true
                isErrData = true
            }
            else if ((this.props.rootFlag === 9) && resVal.aGWReportsDrop.gameTypeId.value === null) { //drop down validation
                resVal.aGWReportsDrop.gameTypeId.display = true
                isErrData = true
            }
            else if (this.props.rootFlag === 7 && resVal.aGWReportsText.selTxnValue.name === "") { //text box validation
                resVal.aGWReportsText.selTxnValue.display = true
                isErrData = true
            }
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            if (userInfo["is_superuser"] && resVal.aGWReportsDrop.OrganizerName.value === null) { //drop down validation
                resVal.aGWReportsDrop.OrganizerName.display = true
                isErrData = true
            }
            if (isErrData) {
                $("#divLoading").attr("style", "display:none")
                //resVal.errMsg = "Invalid data..!"
                this.setState(resVal);
                // $('.overlay').fadeIn();
                // $('#lightBox2').fadeIn(); 
                return
            } else {
                if (resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0) {
                    $("#divLoading").attr("style", "display:none")
                    resVal.errMsg = "Page already rendered..!"
                    this.setState(resVal);
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return;
                }
                else {
                    this.loadListTableData();
                    return
                }
            }
        }
        catch (ex) {
            alert("Load List Failed..");
            return
        }
    }
    //load list details
    loadListTableData = () => {
        try {
            $("#divLoading").attr("style", "display:block")
            //this.setState(initialState);
            const windowWidth: number = $(window).width();
            const initialState: any = this.state;
            const drawDateVal: any = initialState.aGWReportsDt;
            let userId: any = "";
            let is_superuser: any = null;
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            if (userInfo !== null) {
                userId = userInfo["userId"];
                is_superuser = userInfo["is_superuser"];
            }
            let callMethod: string = "";
            let callJsonHead: any = null;
            let getDtlPoint: any = "get_rptDetails";
            let getDtlDtPoint: any = "get_rptGenTime";
            const OrgUserId: any = is_superuser ? initialState.aGWReportsDrop.OrganizerName.value : userId;
            const FdrawDate: any = moment(drawDateVal.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00";
            const TdrawDate: any = moment(drawDateVal.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00";
            //thamil for change if to switch case
            switch (this.props.rootFlag)
            //if (this.props.rootFlag === 13 || this.props.rootFlag === 14 || this.props.rootFlag === 17){
            {
                case 13:
                case 14:
                case 17: {
                    callMethod = this.props.rootFlag === 13 ? "GCW_Reports" : this.props.rootFlag === 14 ? "GMW_Reports" : "GCU_Reports";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate
                    };
                }
                    break;
                //else if (this.props.rootFlag === 15 || this.props.rootFlag === 1 || this.props.rootFlag === 2 || this.props.rootFlag === 8) {
                case 15: case 1: case 2: case 8: {
                    callMethod = this.props.rootFlag === 1 ? "GWSP_Reports" : this.props.rootFlag === 2 ? "GWPL_Reports" :
                        this.props.rootFlag === 8 ? "GPWPL_Reports" : this.props.rootFlag === 15 ? "GOW_Reports" : "";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate,
                        FlagID: initialState.aGWReportsRadio.isSelSwitch
                    };
                }
                    break;
                //else if (this.props.rootFlag === 9 || this.props.rootFlag === 3) {
                case 9: case 3: {
                    callMethod = this.props.rootFlag === 3 ? "GWPLF_Reports" : "GPWPLF_Reports";
                    const paramKey: any = this.props.rootFlag === 9 ? "GameTypeID" : "GameID";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate,
                        FlagID: initialState.aGWReportsRadio.isSelSwitch,
                       [paramKey]: this.props.rootFlag === 3 ? initialState.aGWReportsDrop.gameDetId.value.split('_')[0] : initialState.aGWReportsDrop.gameTypeId.value
                    };
                }
                    break;
                //else if (this.props.rootFlag === 6 || this.props.rootFlag === 7) {
                case 6: case 7: {
                    callMethod = this.props.rootFlag === 6 ? "GWLT_Reports" : "GVT_Reports";
                    const paramKey: any = this.props.rootFlag === 7 ? "TransactionID" : "GameID";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        FlagID: initialState.aGWReportsRadio.isSelSwitch,
                        [paramKey]: this.props.rootFlag === 7 ? initialState.aGWReportsText.selTxnValue.name : initialState.aGWReportsDrop.gameDetId.value.split('_')[0]
                    };
                }
                    break;
                case 20: {
                    callMethod = "add_money";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate,
                        Status: 0
                    };
                }
                    break;
                case 21: {
                    callMethod = "withdraw_money";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate,
                        Status: 1
                    };
                }
                    break;
                //else if (this.props.rootFlag === 20 || this.props.rootFlag === 21 || this.props.rootFlag === 22) {
                case 22: {
                    callMethod = "tax-govt-rpt";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate
                    };
                 }
                     break;
                    //kk 17.12  
                 case 25:{
                    callMethod = "push-data";
                    callJsonHead = {
                        organizerID:OrgUserId,
                        FromDate:FdrawDate,
                        ToDate:TdrawDate,
                        GameId:0
                       // FlagID: initialState.aGWReportsRadio.isSelSwitch
                    };
                } 
                break;        
                //else {  }
                default: { }
            }
            //ykarthik 22.12
           apiHelper.getAPICall(callMethod,"get",5,callJsonHead)
           // useAxios.get(config.Url(callMethod, "get"), config.authHeaderwithParamRpt(callJsonHead))
                .then((res) => {
                    //if (this.props.rootFlag == 22)
                    //res.data = { get_rptGenTime: "2022-11-23 12:43:51", get_rptDetails: CommonReports.tempData.comTaxList };
                    console.log("All Report RESPONSE RECEIVED  : ", res);
                    let colDataArr: any = [];
                    let rowDataArr: any = [];
                    let rptGenDt: any = "";
                    let errText: string = "";
                    if (res !== null && res.data !== null && res.data.result === undefined) {
                        if (res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0) {
                            rowDataArr = res.data[getDtlPoint];
                            rptGenDt = res.data[getDtlDtPoint].toString();
                            colDataArr.push({
                                className: "wordwrap", Visible: false, width: 40, Header: "#", accessor: "#", Cell: (row: any) => {
                                    // return <div style={{ textAlign: "right" }}>{row.original[Object.keys(row.original)[0]] !== "Sub Total" ? row.index + 1 : ""}</div>;
                                    return <div style={{ textAlign: "right" }}>{row.original.in_sno}</div>;
                                }
                            });
                            let cntIndex: number = 0;
                            for (var prop of Object.keys(res.data[getDtlPoint][0])) {
                                const headerTitle: string = CommonReports.getTableTitle(prop);
                                const propValue: string = prop;
                                const isVisible: any = cntIndex === 0 || (windowWidth > 800 && (this.props.rootFlag === 8 || this.props.rootFlag === 17 || this.props.rootFlag === 15 || this.props.rootFlag === 13 || this.props.rootFlag === 14)) ? false : true;
                                 //propValue === "in_sno" mani 31/12/2022 
                                 if (CommonReports.gwcallprops(propValue,1,this.props.rootFlag)) {
                                }
                                 //Total amount sum for table thru props   
                                   //DataBase Object Keys    mani 31/12/2022 
                                else if (CommonReports.gwcallprops(propValue,2,this.props.rootFlag)) 
                                {
                                    const totValue: number = propValue === "in_netamount" ? (_.sum(_.map(rowDataArr, (data: any) => data["in_betamount"])) - _.sum(_.map(rowDataArr, (data: any) => data["in_winamount"]))) :
                                        propValue === "in_payout" ? (_.sum(_.map(rowDataArr, (data: any) => data["in_winamount"])) / _.sum(_.map(rowDataArr, (data: any) => data["in_betamount"])) * 100) :
                                            _.sum(_.map(rowDataArr, (data: any) => data[propValue]));
                                             //mani 31/12/2022
                                             //GrandTotalRootFlag 
                                    if (windowWidth > 800 &&  (CommonReports.gwcallprops(propValue,8,this.props.rootFlag))) {
                                        colDataArr.push({
                                            width: propValue === "in_unclaimamount" ? 140 : 120, Visible: isVisible, Header: headerTitle, accessor: propValue,
                                            show: !isVisible, className: "wordwrap alignRight", headerClassName: "wordwrap",
                                            Footer: <span className="graphPop4Table">{propValue === "in_transcount" ? totValue : totValue.toFixed(2)}</span>
                                        })
                                    }
                                    else {
                                        colDataArr.push({
                                            Visible: isVisible, Header: headerTitle, accessor: propValue,
                                            show: !isVisible, className: "wordwrap alignRight", headerClassName: "wordwrap",
                                            Footer: <span className="graphPop4Table">{propValue === "in_transcount" ? totValue : totValue.toFixed(2)}</span>
                                        })
                                    }
                                }
                              //grandtotal  
                               //Propvalue === transactionid (Or) in_transactionid                        
                                else if (CommonReports.gwcallprops(propValue,3,this.props.rootFlag)) {
                                    colDataArr.push({
                                        Visible: isVisible, Header: headerTitle, accessor: propValue,
                                        show: !isVisible, className: "wordwrap", headerClassName: "wordwrap",
                                        Footer: <span className="graphPop4Table">Grand Total</span>
                                    })
                                }
                                  // //propvalue === in_organizername, Add Grand Total For Footer 
                                else if (CommonReports.gwcallprops(propValue,4,this.props.rootFlag)) {
                                    if (windowWidth > 800 && (this.props.rootFlag === 1 || this.props.rootFlag === 2 || this.props.rootFlag === 3 || this.props.rootFlag === 8 || this.props.rootFlag === 9)) {
                                        colDataArr.push({
                                            width: 170, Visible: isVisible, Header: headerTitle, accessor: propValue,
                                            show: !isVisible, className: "wordwrap", headerClassName: "wordwrap",
                                            Footer: <span className="graphPop4Table">Grand Total</span>
                                        })
                                    }
                                     //propvalue === in_organizername Add Grand Total For Footer 
                                    else {
                                        colDataArr.push({
                                            Visible: isVisible, Header: headerTitle, accessor: propValue,
                                            show: !isVisible, className: "wordwrap", headerClassName: "wordwrap",
                                            Footer: <span className="graphPop4Table">Grand Total</span>
                                        })
                                    }
                                }
                                 // propvalue === in_drawdate" (or ) "in_drawtime"  Adding Space In Footer
                                else {
                                    if (windowWidth > 800 &&CommonReports.gwcallprops(propValue,7,this.props.rootFlag) ) {
                                        colDataArr.push({
                                            width: 115, Visible: isVisible, Header: headerTitle, accessor: propValue,
                                            show: !isVisible, className: "wordwrap", headerClassName: "wordwrap"
                                        })
                                    }
                                   else {
                                        colDataArr.push({
                                            Visible: isVisible, Header: headerTitle, accessor: propValue,
                                            show: !isVisible, className: "wordwrap", headerClassName: "wordwrap"
                                        })
                                    }
                                }
                                //responsive block
                                  //mobile size responsive block
                                if (windowWidth > 800 && CommonReports.gwcallprops(propValue,5,this.props.rootFlag)) {
                                    cntIndex++;
                                }
                                //mobile size responsive block view button click showing data         //mani 02/01/2023
                                else if (windowWidth < 600 && (propValue === "in_organizername" || propValue === "transactionid")) {
                                    cntIndex++;
                                }
                                else if (windowWidth > 600 && windowWidth <= 800 && CommonReports.gwcallprops(propValue,6,this.props.rootFlag)) {
                                    cntIndex++;
                                }
                                //responsive block
                            }
                             //mani 31/12/2022 RemoveTableView
                            const TableViewRemove=CommonReports.TableViewRemove(this.props.rootFlag)
                            if (windowWidth > 800 && TableViewRemove === true) {
                            }
                            else {
                                colDataArr.push({
                                    width: 50,
                                    Header: "View",
                                    accessor: "View",
                                    //resizable: false,
                                    // sortable: false,
                                    className: "wordwrap",
                                    Footer:
                                        ((this.props.rootFlag === 6 || this.props.rootFlag === 7) && windowWidth > 600) ? null :
                                            (<div className="userLogBtnCont3" style={{ padding: 0 }}>
                                                <small>
                                                    {/* <img key="tabPopupIF_0" id="tabPopupIFC_0" className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,null)}></img> */}
                                                    <i key="tabPopupIF_0" id="tabPopupIFC_0" className="fa showPlusMinus fa-plus GreenBgg" style={{ borderRadius: "100px", padding: "5px", background: "#fff" }} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e, null)}></i>
                                                </small>
                                                <div key="tabPopupF_0" id="tabPopupFC_0" className="graphPop4 graphPop-1">
                                                    <table key="tabPopupVF_0">
                                                        <tbody>
                                                            {
                                                                colDataArr.filter((elem: any) => elem.Visible).map((elem: any, index: number) => {
                                                                    return (elem.Footer ? (<tr key={"tabIVIndex_" + index}>
                                                                        <td style={{ fontWeight: "bold" }} className="graphPop4Table">{elem.Header}</td>
                                                                        <td className="graphPop4Table">{elem.Footer}</td>
                                                                    </tr>) : null)
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    {/* <img alt="" src={TooltipData}></img>	 */}
                                                </div>
                                            </div>),
                                    Cell: (row: any) => (
                                        <div className="userLogBtnCont3" style={{ padding: 0 }}>
                                            <small>
                                                {/* <img key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,row.index)}></img> */}
                                                <i key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="fa showPlusMinus fa-plus GreenBgg" style={{ borderRadius: "100px", padding: "5px" }} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e, row.index)}></i>
                                            </small>
                                            <div key={"tabPopup_" + row.index} id={"tabPopupC_" + row.index} className="graphPop4 graphPop-1">
                         {/* view popuptable */}
                                                <Tablistview keys={"tabPopupV_" + row.index} rowData={row.original} colData={this.state.aGWReportsTab.colData} rootFlag={this.props.rootFlag} />
                                                {/* <img alt="" src={TooltipData}></img>	 */}
                                            </div>
                                        </div>
                                    )
                                });
                            }

                        }
                        else {
                            errText = "No Data Available"
                        }
                    }
                    else {
                        errText = res.data.result.toString();
                    }
                    //$("#templaTBlid").attr("style", "display:none") 
                    if (errText === undefined || errText === "") {
                        initialState.aGWReportsTab.colData = colDataArr;
                        initialState.rptGenDate = rptGenDt;
                        let resChartData: any = null;
                        if (this.props.rootFlag !== 6 && this.props.rootFlag !== 7) {
                            resChartData = this.loadChartData(rowDataArr, colDataArr,
                                this.state.aGWReportsDrop.OrganizerName.value === 0 || (this.state.aGWReportsDrop.gameDetId.value !== null && this.state.aGWReportsDrop.gameDetId.value.split('_')[0].toString() === "0") || this.state.aGWReportsDrop.gameTypeId.value === 0
                                    || this.props.rootFlag === 2 || this.props.rootFlag === 8
                                    || this.props.rootFlag === 13 || this.props.rootFlag === 14 ? "column" : "pie");
                        }
                        if (resChartData !== null && resChartData.length > 0) {
                            initialState.aGWReportsCt.options = resChartData[0];
                            initialState.aGWReportsCt.series = resChartData[1];
                            if (resChartData[2] !== null) {
                                rowDataArr = resChartData[2];
                            }
                            initialState.aGWReportsCt.options1 = resChartData[3];
                            initialState.aGWReportsCt.series1 = resChartData[4];
                            initialState.aGWReportsCt.chartTitle = resChartData[5];
                            initialState.aGWReportsCt.chartType = resChartData[6];
                        }
                        else {
                            rowDataArr.forEach((elem: any, index: number) => { elem.in_sno = index + 1; });
                        }
                        //Grand Total- Start
                        if (rowDataArr !== null && rowDataArr.length > 0) {
                            let newObj: any = {};
                            colDataArr.forEach((elem: any) => {
                                if (elem.accessor !== "View" && elem.accessor !== "#") {
                                    if (elem.Footer)
                                        Object.assign(newObj, { [elem.accessor]: elem.Footer.props.children });
                                    else
                                        Object.assign(newObj, { [elem.accessor]: "" });
                                }
                                return elem;
                            });
                            rowDataArr.push(newObj);
                        }
                        //Grand Total- End
                        initialState.aGWReportsTab.tabData = rowDataArr;
                    }
                    initialState.aGWReportsTab.filteredData = [];
                    initialState.aGWReportsTab.searchInput = "";
                    initialState.succFlag = undefined;
                    initialState.errMsg = errText;
                    $("#divLoading").attr("style", "display:none")
                    this.setState(initialState);
                    if (errText !== undefined && errText !== "") {
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn();
                    }
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    this.setState({ succFlag: undefined, errMsg: "No Data Available" });
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return false
                })
        }
        catch (ex) {
            alert("Load List Data Failed..");
            return
        }
    }

    //load chart details
    loadChartData = (rowDataArr: any, colDataArr: any, chartType: any) => {
        try {
            let series: any = []; //column
            let series1: any = []; //pie
            let chartTitle: any = "";
            let chartHeadArr: any = [];
            let resChartDet: any = [];
            let chartOptions: any = null; //column
            let chartOptions1: any = null; //pie
            const chartEnableFields: any = CommonReports.getChartEnableFieldsArr(this.props.rootFlag);
            colDataArr.filter((elem: any) => chartEnableFields.includes(elem.accessor)).map((elem: any) => {
                if (elem.Footer) {
                    const chartTit: any = CommonReports.getTableTitle(elem.accessor);
                    //if(chartType === "pie"){
                    series1.push(parseFloat(elem.Footer.props.children));
                    chartHeadArr.push(chartTit);
                    //}
                    chartTitle += (chartTit) + " VS ";
                }
                return elem;
            })
            const groupbyVal: any = this.props.rootFlag === 13 ? "in_channelname" : this.props.rootFlag === 14 ? "in_modename" : this.props.rootFlag === 8 ? "in_terminalid" :
                this.props.rootFlag === 1 || this.props.rootFlag === 2 || this.props.rootFlag === 3 ? "in_gamename" :
                    this.props.rootFlag === 9 ? "in_gametype" : "in_organizername";
            resChartDet = this.loadSubCountValues(rowDataArr, groupbyVal, chartEnableFields)
            //if(chartType !== "pie"){               
            series = resChartDet[1];
            chartOptions = {
                chart: {
                    type: chartType,
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        }
                    }
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    width: 5
                },
                markers: {
                    size: 5
                },
                title: { text: "" },
                xaxis: { categories: resChartDet[0] }
            }
            //}      
            //else{ 
            chartOptions1 = {
                chart: {
                    type: "pie",
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        }
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 280
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                title: { text: "" },
                labels: chartHeadArr
            }
            //}
            
            return [chartOptions, series, resChartDet[2], chartOptions1, series1, chartTitle.substring(0, chartTitle.length - 4), chartType]; //chartType !== "pie" ? resChartDet[2] : null
        }
        catch (ex) {
            alert("Load Chart Data Failed..");
            return
        }
    }
    // chart change properties
    chartChangeClick = (chartType: string) => {
        const resVals: any = this.state;
        if (chartType !== "pie") {
            resVals.aGWReportsCt.options.chart.type = chartType;
            resVals.aGWReportsCt.series.forEach((elem: any) => {
                elem.type = chartType;
                return elem;
            });
        }
        resVals.aGWReportsCt.chartType = chartType;
        this.setState(resVals);
        return
    }
    //load calculate sub count details & subtotal amount and show/hide data 
    loadSubCountValues = (resultVal: any, groupVal: any, filterColArr: any) => {
        try {
            const getGrpSubCountFlds: any = CommonReports.getGrpbySubcountFields();
            let arrXVal: any = [];
            var groupByOrg: any = this.state.aGWReportsDrop.OrganizerName.value === 0 ? _.groupBy(resultVal, function (item) {
                return item["in_organizername"];
            }) :
                _.groupBy(resultVal, function (item) {
                    return item[groupVal];
                });
            var resultSubVal: any = [];
            let indexSno: number = 0;
            const resData = _(groupByOrg)
                .map((platform: any, id: any) => {
                    platform.forEach((elem: any) => { indexSno++; elem.in_sno = indexSno; });
                    let newObj: any = {};
                    Object.entries(resultVal[0]).forEach(([key, value]) => {
                        if (getGrpSubCountFlds.includes(key)) {
                            let resValue: any = _.sumBy(platform, key);
                            if (key === "in_payout") {
                                resValue = (_.sumBy(platform, "in_winamount") / _.sumBy(platform, "in_betamount")) * 100;
                            }
                            else if (key === "in_netamount") {
                                resValue = (_.sumBy(platform, "in_betamount") - _.sumBy(platform, "in_winamount"));
                            }
                            Object.assign(newObj, { [key]: key === "in_transcount" ? resValue : resValue.toFixed(2) });
                                                }
       /// subtotal area              ykarthik 17.12 dont change this line 
                            else if ( key === "in_organizername" && this.props.rootFlag === 25   ) {
                                                
                                Object.assign(newObj, { [key]: "" });
                            }  
                                            
                        else if (key === "in_transactionid" || key === "in_organizername") {
                            Object.assign(newObj, { [key]: "Sub Total" });
                        }
                    
                          // else if(key === groupVal){
                        //     Object.assign(newObj, {[key]: id});
                        // }
                        else {
                            Object.assign(newObj, { [key]: "" });
                        }
                    });
                    resultSubVal = [...resultSubVal, ...platform];
                    //Sub count logic- Start       
                    if (this.state.aGWReportsDrop.OrganizerName.value === 0 || (this.state.aGWReportsDrop.gameDetId.value !== null && this.state.aGWReportsDrop.gameDetId.value.split('_')[0].toString() === "0") || this.state.aGWReportsDrop.gameTypeId.value === 0
                        || this.props.rootFlag === 2 || this.props.rootFlag === 8 || this.props.rootFlag === 13 || this.props.rootFlag === 14) {
                        resultSubVal.push(newObj);
                    }
                    //Sub count logic- End 
                    arrXVal.push(id);
                    return newObj;
                }).value();
            //Chart Data- Start
            let arrYVal: any = [];
            for (let col of filterColArr) {
                let arrVal = [];
                for (let ele of resData) {
                    arrVal.push(parseFloat(ele[col].toString()));
                }
                arrYVal.push({ name: CommonReports.getTableTitle(col), data: arrVal, type: "column" })
            }
            //Chart Data- End 
            return [arrXVal, arrYVal, resultSubVal];
        }
        catch (ex) {
            alert("Load Chart Data Failed..");
            return
        }
    }
    //show hide lightbox
    //plus minus view table
    onShowLightBox = (e: any, index: any) => {
        e.preventDefault();
        const currDisplay: any = index === null ? $('#tabPopupFC_0').css('display') : $('#tabPopupC_' + index).css('display');
        // $('.fa.fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        // $('.fa-minus').addClass('fa-plus').removeClass('fa-minus'); 
        $('.showPlusMinus').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');
        $('.graphPop4').css({ display: "none" })
        if (index !== null) {
            $('#tabPopupIC_' + index).attr('class', (currDisplay === "none" ? "fa showPlusMinus fa-minus RedBgg" : "fa showPlusMinus fa-plus GreenBgg"));
            $('#tabPopupC_' + index).css({ display: currDisplay === "none" ? "block" : "none" })
        }
        else {
            $('#tabPopupIFC_0').attr('class', (currDisplay === "none" ? "fa showPlusMinus fa-minus RedBgg" : "fa showPlusMinus fa-plus GreenBgg"));
            $('#tabPopupFC_0').css({ display: currDisplay === "none" ? "block" : "none" })
        }
        return
    }
    //export datas xls..,csv..,print
    exportXLPdfPrintdownload = (e: any, fileType: any) => {
        try {
            e.preventDefault();
            if (fileType === "chart") {
                $('.overlay').fadeIn();
                $('#lightBox4').fadeIn();
                return
            }
            else {
                const rootFlag: number = this.props.rootFlag;
                const pageTitle: any = CommonReports.getPageTitle(rootFlag);
                const fileName: string = pageTitle.replaceAll(' ', '');
                const genTime: any = this.state.rptGenDate.toString();
                const Orgdata: any = this.state.aGWReportsTab.tabData;
                if (fileType === "pdf") {
                    CommonReports.exportPDF(Orgdata, pageTitle, fileName, this.props.rootFlag, genTime);
                }
                else if (fileType === "csv" || fileType === "xls") {
                    CommonReports.exportExcel(Orgdata, fileName, fileType, this.props.rootFlag, genTime);
                }
                else {
                    CommonReports.exportPrint(Orgdata, pageTitle, this.props.rootFlag, genTime);
                }
            }
            return
        }
        catch (ex) {
            alert("Export Failed..");
            return
        }
    }
    // And in your global search table 
    globalSearch = (e: any) => {
        try {
            let initialState: any = this.state;
            const searchInput: string = e.target.value;
            const skipGT: any = initialState.aGWReportsTab.tabData.filter((elem: any, index: number) => index !== initialState.aGWReportsTab.tabData.length - 1);
            let filteredData = skipGT.filter((value: any) => {
                return CommonReports.getKeyByValue(value, searchInput, this.props.rootFlag);
            });
            initialState.aGWReportsTab.filteredData = filteredData;
            initialState.aGWReportsTab.searchInput = searchInput;
            initialState.succFlag = undefined;
            initialState.errMsg = "";
            this.setState(initialState);
            return
        }
        catch (ex) {
            alert("Load Search filter List Load Failed..");
            return
        }
    };
    onhandleTxtChange = (e: any, fieldFlag: number) => {
        try {
            let resVal: any = this.state;
            resVal.aGWReportsTab.filteredData = [];
            resVal.aGWReportsTab.searchInput = "";
            resVal.aGWReportsTab.tabData = [];
            resVal.aGWReportsCt.options = null;
            resVal.aGWReportsCt.series = null;
            resVal.aGWReportsCt.options1 = null;
            resVal.aGWReportsCt.series1 = null;
            resVal.aGWReportsCt.chartTitle = "";
            resVal.aGWReportsCt.chartType = "";
            resVal.succFlag = undefined;
            resVal.errMsg = "";
            if (fieldFlag === 0) {
                resVal.aGWReportsText.selTxnValue.name = e.target.value;
                resVal.aGWReportsText.selTxnValue.display = e.target.value.trim() === "" ? true : false;
            }
            this.setState(resVal);
            return
        }
        catch (ex) {
            alert("On Transaction Id Change Failed..");
            return
        }
    }
    /* Date change event */
    handleDateChange = (date: any, key: string, format: string) => {
        try {
            let initialState: any = this.state;
            const resVal: any = initialState.aGWReportsDt;
            resVal[key].defValue = moment(date._d);
            resVal[key].value = moment(date._d).format(format);
            //resVal.TdrawDate.display = false;
            if (this.props.rootFlag !== 6 && this.props.rootFlag !== 7) {
                resVal.FdrawDate.display = resVal.FdrawDate.value === null ? true : resVal.TdrawDate.value === null ? false : (resVal.TdrawDate.value !== null && moment(resVal.FdrawDate.value, format) > moment(resVal.TdrawDate.value, format))
                resVal.TdrawDate.display = resVal.TdrawDate.value !== null ? false : true;
            }
            else {
                resVal.FdrawDate.display = false;
            }
            initialState.aGWReportsDt = resVal;
            initialState.aGWReportsTab.filteredData = [];
            initialState.aGWReportsTab.searchInput = "";
            initialState.aGWReportsTab.tabData = [];
            initialState.aGWReportsCt.options = null;
            initialState.aGWReportsCt.series = null;
            initialState.aGWReportsCt.options1 = null;
            initialState.aGWReportsCt.series1 = null;
            initialState.aGWReportsCt.chartTitle = "";
            initialState.aGWReportsCt.chartType = "";
            initialState.succFlag = undefined;
            initialState.errMsg = "";
            let is_superuser: any = null;
            let userId: any = "";
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            if (userInfo !== null) {
                userId = userInfo["userId"];
                is_superuser = userInfo["is_superuser"];
            }
            const OrgUserId: any = is_superuser ? initialState.aGWReportsDrop.OrganizerName.value : userId;
            if (!resVal.FdrawDate.display && !resVal.TdrawDate.display && (this.props.rootFlag === 3 || this.props.rootFlag === 6) && ((is_superuser && initialState.aGWReportsDrop.OrganizerName.value !== null) || !is_superuser)) {
                const callJsonHead: any = {
                    FromDate: moment(resVal.FdrawDate.value, format).format('yyyy-MM-DD') + " 00:00:00",
                    ToDate: (this.props.rootFlag === 6 ? moment(resVal.FdrawDate.value, format).format('yyyy-MM-DD') : moment(resVal.TdrawDate.value, format).format('yyyy-MM-DD')) + " 00:00:00",
                    organizerID: OrgUserId
                }
                this.loadNameMasterdata(initialState, callJsonHead);
                return
            }
            else {
                this.setState(initialState);
            }
        }
        catch (ex) {
            alert("On Date Change Failed..");
            return
        }
    };
    /* Drop down change Print preview */
    handleDropChange = (e: any, type: number) => {
        try {
            const resVals: any = this.state;
            resVals.aGWReportsTab.filteredData = [];
            resVals.aGWReportsTab.searchInput = "";
            resVals.aGWReportsTab.tabData = [];
            resVals.aGWReportsCt.options = null;
            resVals.aGWReportsCt.series = null;
            resVals.aGWReportsCt.options1 = null;
            resVals.aGWReportsCt.series1 = null;
            resVals.aGWReportsCt.chartTitle = "";
            resVals.aGWReportsCt.chartType = "";
            resVals.succFlag = undefined;
            resVals.errMsg = "";
            if (type === 1)
             {
                resVals.aGWReportsDrop.OrganizerName.value = e.value;
                resVals.aGWReportsDrop.OrganizerName.display = false;
                //resVals.aGWReportsDrop.OrganizerName.userId = resVals.aGWReportsDrop.OrganizerNames.filter((option: any) => option.value.toString() === e.value.toString())[0].userId
            }
            else if (type === 2) {
                resVals.aGWReportsDrop.gameDetId.value = e.value;
                resVals.aGWReportsDrop.gameDetId.display = false;
            }
            else if (type === 3) {
                resVals.aGWReportsDrop.gameTypeId.value = e.value;
                resVals.aGWReportsDrop.gameTypeId.display = false;
            }
            
            if (type === 1 && !resVals.aGWReportsDt.FdrawDate.display && !resVals.aGWReportsDt.TdrawDate.display && (this.props.rootFlag === 3 || this.props.rootFlag === 6)) {
                const callJsonHead: any = {
                    FromDate: moment(resVals.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    ToDate: (this.props.rootFlag === 6 ? moment(resVals.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') : moment(resVals.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD')) + " 00:00:00",
                    organizerID: resVals.aGWReportsDrop.OrganizerName.value
                }
                this.loadNameMasterdata(resVals, callJsonHead);
                return
            }
            else {
                this.setState(resVals);
            }
            return
        }
        catch (ex) {
            alert("On Dropdown Change Failed..");
            return
        }
    }
    handleToggleClick = (e: any) => {
        try {
            e.preventDefault();
            const resVals: any = this.state;
            resVals.aGWReportsTab.filteredData = [];
            resVals.aGWReportsTab.searchInput = "";
            resVals.aGWReportsTab.tabData = [];
            resVals.aGWReportsCt.options = null;
            resVals.aGWReportsCt.series = null;
            resVals.aGWReportsCt.options1 = null;
            resVals.aGWReportsCt.series1 = null;
            resVals.aGWReportsCt.chartTitle = "";
            resVals.aGWReportsCt.chartType = "";
            resVals.succFlag = undefined;
            resVals.errMsg = "";
            resVals.aGWReportsRadio.isSelSwitch = resVals.aGWReportsRadio.isSelSwitch === 1 ? 0 : 1;
            this.setState(resVals);
        }
        catch (ex) {
            alert("On Change Toggle button updation Failed..");
            return
        }
    }
    redirectPage = () => {
        this.props.backClick(0, null);
        return
    }
    render() {
        const { t } = this.props;
        const resVal: any = this.state;
        const themeClr = CommonReports.ThemeColour()
        const pageSize: number = resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 50 ? 50 : resVal.aGWReportsTab.tabData.length > 25 ? 25 : 10;
        const defaultMaterialTheme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: themeClr,
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
        });
        const DropdownIndicator = (props: any) => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <FontAwesomeIcon style={{ fontSize: "20px" }} icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"} />
                    </components.DropdownIndicator>
                )
            );
        };
        const styles = {
            menu: (base: any) => ({
                ...base,
                marginTop: 0
            })
        };
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if (userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        const headerTitle: any = CommonReports.getPageTitle(this.props.rootFlag);
             // Remove Chart Icon
    const RemoveChartIcon=CommonReports.ChartIcon(this.props.rootFlag);
        return (
            <div className="ContainerChart scrollbar" style={{ height: $(window).height() - 60 + "px" }} onClick={(e: any) => config.onBodyClick(e)}>
                <ListChart
                    options={resVal.aGWReportsCt.options}
                    series={resVal.aGWReportsCt.series}
                    options1={resVal.aGWReportsCt.options1}
                    series1={resVal.aGWReportsCt.series1}
                    chartType={resVal.aGWReportsCt.chartType}
                    chartTitle={resVal.aGWReportsCt.chartTitle}
                    chartChangeClick={this.chartChangeClick.bind(this)}
                />
                <Lightbox errMsg={resVal.errMsg} succFlag={resVal.succFlag} />
                <div className="contentclouds">
                    <div className="userLogHead">
                        <div className="userLogHeadinner">
                            <h2>{headerTitle}</h2>
                            <small>{t('lblViewSonalDetails')}</small>
                        </div>
                        <Breadcrumb Menu={this.props.dashJsondata !== undefined && this.props.dashJsondata !== null ? "Dashboard" : "Reports"} subMenu={headerTitle} />
                    </div>
                    <div className="UseformNew">
                        {this.props.dashJsondata !== undefined && this.props.dashJsondata !== null ?
                            (<div className="TblError">
                                <a href="#section" onClick={() => this.redirectPage()}><b>{t('lblBackToDash')}</b></a>
                            </div>) : null}
                        <div className="Dateform">
                            {/* by thamil */}
                            {this.props.rootFlag !== 13 && this.props.rootFlag !== 14 && this.props.rootFlag !== 17 && this.props.rootFlag !== 20
                // Drawdate & Saledate  show/hide                                                   //ykarthik
                                && this.props.rootFlag !== 21 && this.props.rootFlag !== 22 && this.props.rootFlag !== 25 && this.props.rootFlag !== 23 ?
                                (<div className="toogleSwitch">
                                    <label className="toggleSwitch nolabel" onClick={(e: any) => this.handleToggleClick(e)}>
                                        <input type="checkbox" id="#toggleIds" checked={resVal.aGWReportsRadio.isSelSwitch === 1} onChange={(e: any) => this.handleToggleClick(e)} />
                                        <span>
                                            <span>{t('lblSaledate')}</span>
                                            <span>{t('lblDrawDate')}</span>
                                        </span>
                                        <a href="#section"> </a>
                                    </label>
                                </div>) : null}
                            <div className="userLogformTop">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <ul>
                                        {is_superuser !== null && is_superuser ? (
                                            <li style={{ width: this.props.rootFlag === 3 || this.props.rootFlag === 9 ? "25%" : "33.33333%" }}>
                                                <div className="userLogCloudtop">
                                                    <Select
                                                        value={resVal.aGWReportsDrop.OrganizerName.value === null ? null : resVal.aGWReportsDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.aGWReportsDrop.OrganizerName.value.toString())}
                                                        onChange={(e: any) => { this.handleDropChange(e, 1) }}
                                                        //isDisabled={this.props.rowData === null ? false : true}
                                                        placeholder={t('lblSelectName')}
                                                        options={this.props.rootFlag === 6 || this.props.rootFlag === 7 ? resVal.aGWReportsDrop.OrganizerNames.filter((elem: any) => elem.value.toString() !== "0") : resVal.aGWReportsDrop.OrganizerNames}
                                                        // maxMenuHeight={110}
                                                        className="userlogSelect"
                                                        components={{ DropdownIndicator }}
                                                        styles={styles}
                                                    />
                                                    <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsDrop.OrganizerName.display ? "block" : "none" }}>{t('lblOrgNameProvided')}</label>
                                                </div>
                                            </li>) : null}
                                        <li style={{ width: is_superuser !== null && is_superuser && (this.props.rootFlag === 3 || this.props.rootFlag === 9) ? "25%" : "33.33333%" }}>
                                            <div className="userLogCloudtop">
                                                <ThemeProvider theme={defaultMaterialTheme}>
                                                    <DatePicker
                                                        placeholder={this.props.rootFlag !== 6 && this.props.rootFlag !== 7 ? t('lblPickFrom') : t('lblPickDate')}
                                                        className="rptdatePicker"
                                                        format="DD-MM-yyyy"
                                                        value={resVal.aGWReportsDt.FdrawDate.defValue}
                                                        onChange={(date: any) => this.handleDateChange(date, "FdrawDate", "DD-MM-yyyy")}
                                                    />
                                                </ThemeProvider>
                                                <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsDt.FdrawDate.display ? "block" : "none" }}>{this.props.rootFlag !== 6 && this.props.rootFlag !== 7 && resVal.aGWReportsDt.FdrawDate.value === null ? t('lblFromDateProvided') : resVal.aGWReportsDt.FdrawDate.value === null ? t('lblDrawDateProvided') : t('lblGraterthanTodate')}</label>
                                            </div>
                                        </li>
                                        {this.props.rootFlag !== 6 && this.props.rootFlag !== 7 ?
                                            (<li style={{ width: is_superuser !== null && is_superuser && (this.props.rootFlag === 3 || this.props.rootFlag === 9) ? "25%" : "33.33333%" }}>
                                                <div className="userLogCloudtop">
                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                        <DatePicker
                                                            placeholder={t('lblPickTo')}
                                                            className="rptdatePicker"
                                                            format="DD-MM-yyyy"
                                                            value={resVal.aGWReportsDt.TdrawDate.defValue}
                                                            onChange={(date: any) => this.handleDateChange(date, "TdrawDate", "DD-MM-yyyy")}
                                                        />
                                                    </ThemeProvider>
                                                    <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsDt.TdrawDate.display ? "block" : "none" }}>{t('lblToDateProvided')}</label>
                                                </div>
                                            </li>) : null}
                                        {this.props.rootFlag === 7 ?
                                            (<li style={{ width: "33.33333%" }}>
                                                <div className="userLogCloudtop">
                                                    <input className="newUserTxt" value={resVal.aGWReportsText.selTxnValue.name} onChange={(e: any) => this.onhandleTxtChange(e, 0)} placeholder={t('lblEnterTransId')} autoComplete="off" type="text" maxLength={50} />
                                                    <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsText.selTxnValue.display ? "block" : "none" }}>{t('lblTransIdProvided')}</label>
                                                </div>
                                            </li>) : null}
                                        {this.props.rootFlag === 3 || this.props.rootFlag === 6 ?
                                            (<li style={{ width: is_superuser !== null && is_superuser && this.props.rootFlag === 3 ? "25%" : "33.33333%" }}>
                                                <div className="userLogCloudtop">
                                                    <Select
                                                        value={resVal.aGWReportsDrop.gameDetId.value === null ? null : resVal.aGWReportsDrop.gameDet.filter((option: any) => option.value.toString() === resVal.aGWReportsDrop.gameDetId.value.toString())}
                                                        onChange={(e: any) => { this.handleDropChange(e, 2) }}
                                                        placeholder={t('lblSelectGameName')}
                                                        options={this.props.rootFlag === 6 ? resVal.aGWReportsDrop.gameDet.filter((elem: any) => elem.value.toString().split('_')[0] !== "0") : resVal.aGWReportsDrop.gameDet}
                                                        // maxMenuHeight={110}
                                                        className="userlogSelect"
                                                        components={{ DropdownIndicator }}
                                                        styles={styles}
                                                    />
                                                    <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsDrop.gameDetId.display ? "block" : "none" }}>{t('lblGameNameProvided')}</label>
                                                </div>
                                            </li>) : this.props.rootFlag === 9 ?
                                                (<li style={{ width: is_superuser !== null && is_superuser ? "25%" : "33.33333%" }}>
                                                    <div className="userLogCloudtop">
                                                        <Select
                                                            value={resVal.aGWReportsDrop.gameTypeId.value === null ? null : resVal.aGWReportsDrop.gameType.filter((option: any) => option.value.toString() === resVal.aGWReportsDrop.gameTypeId.value.toString())}
                                                            onChange={(e: any) => { this.handleDropChange(e, 3) }}
                                                            placeholder={('lblSelectGame')}
                                                            options={resVal.aGWReportsDrop.gameType}
                                                            // maxMenuHeight={110}
                                                            className="userlogSelect"
                                                            components={{ DropdownIndicator }}
                                                            styles={styles}
                                                        />
                                                        <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsDrop.gameTypeId.display ? "block" : "none" }}>{t('lblGameProvided')}</label>
                                                    </div>
                                                </li>) : null}
                                    </ul>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="userLogBtnTop">
                                <button id="registerBtn" onClick={(e: any) => this.onFilterListTableData(e)}><i className="fa fa-check" style={{ marginRight: "5px" }} aria-hidden="true"></i>{t('lblSubmit')}</button>
                                <button id="registerBtn1" style={{ float: "right" }} onClick={this.resetValues.bind(this)}><i className="fa fa-sync" style={{ marginRight: "5px" }} aria-hidden="true"></i>{t('lblReset')}</button>
                            </div>
                        </div>
                        {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ?
                            (<div className="TblError">
                                <span>{t('lblReportGenOn')} <b>{resVal.rptGenDate}</b></span>
                            </div>) : null}
                        {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ?
                            (<div className="ContainerTable">
                                <div className="tableSearchreport">
                                    <div className="sportSrch">
                                        <input type="text" placeholder={t('lblSearch')} id="myInput" value={resVal.aGWReportsTab.searchInput} onChange={(e: any) => this.globalSearch(e)} autoComplete="off" />
                                    </div>
                                </div>
                                <div className="ButtonArea">
                                {/* //charticon            ykarthik 20.12 */}
                                    {RemoveChartIcon ?
                                        (<button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e, 'chart')}>
                                            <a href="#section"><i className="fas fa-chart-bar" style={{ marginRight: "5px", fontSize: "16px", color: "#f6ae00" }}></i>Chart</a>
                                        </button>) : null}
                                    <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e, 'pdf')}>
                                        <a href="#section"><i className="fas fa-file-export" style={{ marginRight: "5px", fontSize: "16px", color: "#f6ae00" }}></i>PDF</a>
                                    </button>
                                    {/* <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'csv')}>
						            <a href="#section"><i className="fas fa-file-excel" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>CSV</a>
				                 	</button> */}
                                    <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e, 'xls')}>
                                        <a href="#section"><i className="fas fa-file-excel" style={{ marginRight: "5px", fontSize: "16px", color: "#f6ae00" }}></i>Excel</a>
                                    </button>
                                    <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e, 'print')}>
                                        <a href="#section"><i className="fas fa-print" style={{ marginRight: "5px", fontSize: "16px", color: "#f6ae00" }}></i>Print</a>
                                    </button>
                                </div>
                            </div>) : null}
                        {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ? (
                            <div className="TableReact">
                                {/*  all report table  */}
                                <ListTable
                                    sorting={false}
                                    columns={resVal.aGWReportsTab.colData}
                                    pageSize={pageSize}
                                    data={resVal.aGWReportsTab.filteredData && resVal.aGWReportsTab.searchInput !== "" ? resVal.aGWReportsTab.filteredData : resVal.aGWReportsTab.tabData.filter((elem: any, index: number) => index !== resVal.aGWReportsTab.tabData.length - 1)}
                                />
                            </div>) : null}
                    </div>
                </div>
                {/* <div className="chartdiv"> <span className="noDataCenter">No Data Available</span>
            {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ? (
                <ListChart
                options= {resVal.aGWReportsCt.options}
                series= {resVal.aGWReportsCt.series}
                chartType= {resVal.aGWReportsCt.chartType}
                />) : null}
            </div>  */}
            </div>
        );
    }
}

export default withTranslation()(AGWReports);