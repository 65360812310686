import React from 'react';
import useAxios from "axios";
import apiHelper from "../../utils/ApiHelper"
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown,faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import moment from "moment"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// import { green } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';
import excel from 'xlsx';
import _ from "lodash"

import ListTable from "../Shared/listtable";
import Tablistview from "../Shared/tablistview";
import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import ListSubTable from "../Shared/listsubtableAudit";
import Breadcrumb from "../Shared/breadcrumb";
import CAuditmodel from "./Child/c_auditmodel";
import CommonAuditTrail from "./Child/c_auditcommon";
// import TooltipData from "../../assets/img/icons/Arrow_new.png"
// import PlusIcon from "../../assets/img/icons/Plus_Icon.png"
// import MinusIcon from "../../assets/img/icons/Minus_Icon.png"
import { withTranslation  } from 'react-i18next';
import { t } from 'i18next';
import CommonReports from '../Reports/Child/c_reportscommon';

var $ = require('jquery')
library.add(faCaretDown);
library.add(faCaretUp);

export interface IProps {
    rootFlag: number,
    t:any
}
export interface IState {
    succFlag: any,
    errMsg: string,
    aGAuditsDt: any,
    aGAuditsDrop: any,
    aGAuditsTab: any,
    fileAttach: any,
    aGAuditsText: any
}
//Game Type
// 4 - LOTTO
// 5 - GHANA
// 9 - SUPER LOTTO
// 10 - Paper Lottery
  class CAuditTrail extends React.Component<IProps, IState> {
    private subTabStateReset: any = React.createRef;
  // ------------------------------------------^
    constructor(props: IProps) {
      super(props);  
      this.subTabStateReset = React.createRef();
      this.state = CAuditmodel();
      this.resetValues = this.resetValues.bind(this);
      this.globalSearch = this.globalSearch.bind(this);
      this.onhandleTxtChange = this.onhandleTxtChange.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);
      this.handleDropChange = this.handleDropChange.bind(this);
      this.onFilterListTableData = this.onFilterListTableData.bind(this);
      this.onhandleFileChange = this.onhandleFileChange.bind(this);
      this.exportXLPdfPrintdownload = this.exportXLPdfPrintdownload.bind(this);
      this.updateListTableRow = this.updateListTableRow.bind(this);
      this.updateSchemeData = this.updateSchemeData.bind(this);
      this.onMultiInputChange = this.onMultiInputChange.bind(this);
    }
    componentDidMount(){
        $('#myfileExp').val('');
        let resVals: any = this.state;
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        const callJsonHead: any = { 
            flag_id: 1
        }
        if(is_superuser !== null && is_superuser){
            const _this: any = this;
            _this.loadOrgNameMasterdata(resVals).then((res: any) => {
                _this.loadNameMasterdata(res,callJsonHead,"load_gameDetails","cm_game",2).then((res1: any) => {
                    this.setState(res1);
                    return  
                })
            })
        }
        else{ 
            const _this: any = this;
            _this.loadNameMasterdata(resVals,callJsonHead,"load_gameDetails","cm_game",2).then((res: any) => {            
                this.setState(res);
                return              
            })          
        }        
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps:any) {
        // Typical usage (don't forget to compare props):        
        if (this.props.rootFlag !== prevProps.rootFlag) {
            this.resetValues();
        }
    }
    resetValues= (succMsg?: any) => {
        $('#myfileExp').val('');
        const resVals: any = this.state;
        const initialState: any = CAuditmodel();
        initialState.aGAuditsDrop.OrganizerNames = resVals.aGAuditsDrop.OrganizerNames;
        initialState.aGAuditsDrop.gameType = resVals.aGAuditsDrop.gameType;
        if(succMsg !== undefined){
            initialState.errMsg = succMsg;     
            initialState.succFlag = 0; 
        }
        $("#divLoading").attr("style", "display:none") 
        this.setState(initialState);  
        if(succMsg !== undefined){  
            $('.overlay').fadeIn();
            $('#lightBox2').fadeIn(); 
        }
        return
    }
    loadOrgNameMasterdata= (resVals: any) => {
        try{
        $("#divLoading").attr("style", "display:block")
        this.setState(CAuditmodel());
        return new Promise((resolve, reject) => {
            //mani 22/12
       //   useAxios.get(config.Url("cmo_game","get"), config.authHeaderwithParam("OrgName~@#%&1"))
            apiHelper.getAPICall("cmo_game", "get",4,"OrgName~@#%&1")
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let OrganizerNamesArr: any = [];
                if(res.data !== null && res.data.length !== 0){
                    if(res.data["organizer_details"] !== undefined && res.data["organizer_details"] !== null && res.data["organizer_details"].length !== 0){                  
                    for(var prop of res.data["organizer_details"]){
                        OrganizerNamesArr.push({value: prop["dl_organizerid"],label: prop["dl_organizername"],userId: prop["dl_userid"]})
                    }
                }
                }         
                $("#divLoading").attr("style", "display:none") 
                //$("#templaTBlid").attr("style", "display:none") 
                resVals.aGAuditsDrop.OrganizerNames = OrganizerNamesArr;
                resolve(resVals)
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none") 
                reject(err)
            })
        }); 
        }
        catch(ex) {
        alert("Master Data Load Failed..");
        return
        }
    }
    /* load game/gametype names */
    loadNameMasterdata = (initialState: any,callJsonHead: any,getDtlPoint: string,methodName: string,flag: number) => {
        try{
            $("#divLoading").attr("style", "display:block")
            return new Promise((resolve, reject) => {
                //MANI 22/12
                //   useAxios.get(config.Url(methodName,"get"), config.authHeaderwithParamRpt(callJsonHead))
             apiHelper.getAPICall(methodName, "get",5,callJsonHead)
                .then((res) => {
                    console.log("RESPONSE RECEIVED: ", res);
                    let fieldNamesArr: any = [];
                    if(res.data !== null && res.data.length !== 0){
                        if(res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0){                  
                        for(var prop of res.data[getDtlPoint]){
                            if(flag === 0)
                                fieldNamesArr.push({value: prop["dl_gameid"] + "_" + prop["dl_organizerid"],label: prop["dl_gamename"],orgId: prop["dl_organizerid"],orgName: prop["dl_organizerid"]});
                            else if(flag === 2)
                                fieldNamesArr.push({value: prop["in_gamegroupid"],label: prop["in_gamegroupdesc"]})
                        }
                    }
                    }         
                    $("#divLoading").attr("style", "display:none")
                    if(flag === 0){
                        initialState.aGAuditsDrop.gameDet = fieldNamesArr;
                        initialState.aGAuditsDrop.gameDetId.value = null;
                    }
                    else if(flag === 2)
                        initialState.aGAuditsDrop.gameType = fieldNamesArr;
                    //this.setState(initialState);
                    resolve(initialState);
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none") 
                    reject(null)
                }) 
            });
        }
        catch(ex) {
            alert("Load Master Data Failed..");
            return
        }
    }
    /* event triggers on click the submit button */
    onFilterListTableData = (e: any, type: string) => {
    try{
        e.preventDefault(); 
        const resVal : any = this.state;
        let isErrData: boolean = false;
        let errMsgs: string = "";
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        resVal.fileAttach.display = false
        resVal.aGAuditsText.selTxnValue.display = false;
        resVal.aGAuditsDrop.gameDetId.display = false;
        resVal.aGAuditsDrop.gameTypeId.display = false;
        resVal.aGAuditsDrop.OrganizerName.display = false;
        resVal.aGAuditsDt.FdrawDate.display = false;
        if((this.props.rootFlag === 1 || (this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value === 10)) && (resVal.fileAttach.name === null || resVal.fileAttach.name === "")){ 
            $('#myfileExp').val('');
            resVal.fileAttach.display = true
            isErrData = true
        }
        if(this.props.rootFlag !== 6 && this.props.rootFlag !== 7 && resVal.aGAuditsDrop.gameTypeId.value === null){ //drop down validation
            resVal.aGAuditsDrop.gameTypeId.display = true
            isErrData = true
        }
        if(type !== "View" || this.props.rootFlag === 4){
            if((this.props.rootFlag === 3 || this.props.rootFlag === 4 || this.props.rootFlag === 5) && (resVal.aGAuditsDt.FdrawDate.value === null || resVal.aGAuditsDt.FdrawDate.value === "")){ 
                resVal.aGAuditsDt.FdrawDate.display = true
                isErrData = true
            }
            if((this.props.rootFlag === 1 || this.props.rootFlag === 6 || this.props.rootFlag === 7) && resVal.aGAuditsText.selTxnValue.name === ""){ //text box validation
                resVal.aGAuditsText.selTxnValue.display = true
                isErrData = true
            }
            else if((this.props.rootFlag === 3 || this.props.rootFlag === 4 || this.props.rootFlag === 5) && resVal.aGAuditsDrop.gameDetId.value === null){ //drop down validation
                resVal.aGAuditsDrop.gameDetId.display = true
                isErrData = true
            } 
            if(is_superuser && resVal.aGAuditsDrop.OrganizerName.value === null){ //drop down validation
                resVal.aGAuditsDrop.OrganizerName.display = true
                isErrData = true
            }
            if(!isErrData && this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value !== 10){ //result input validation
                if(Object.keys(resVal.aGAuditsTab.inputChanged).length !== (resVal.aGAuditsDrop.gameTypeId.value === 9 ? 6 : 10) || Object.values(resVal.aGAuditsTab.inputChanged).includes('')){
                    errMsgs = (resVal.aGAuditsDrop.gameTypeId.value === 9 ? "Primary" : "Result & Machine") + " Numbers should not be empty.";
                    isErrData = true
                }             
            }         
        }
        if(isErrData){
            $("#divLoading").attr("style", "display:none") 
            if(errMsgs !== ""){
                resVal.errMsg = errMsgs;
                resVal.succFlag = undefined;
            }
            this.setState(resVal);
            if(errMsgs !== ""){
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
            }           
            return 
        }else{            
            if(type === "View"){
                if(this.props.rootFlag === 4){
                    if(resVal.aGAuditsTab !== undefined && resVal.aGAuditsTab.tabData !== undefined && resVal.aGAuditsTab.tabData.length > 0){
                        $("#divLoading").attr("style", "display:none") 
                        resVal.errMsg = "Page already rendered..!"
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                        return;
                    }
                    else{
                        this.loadListTableData();
                        return
                    } 
                }
                else{
                    this.setState(resVal);
                    this.subTabStateReset.current.subTabStateReset();
                    $('#lightBox7').fadeIn();
                    $('.overlay').fadeIn();
                    return
                }
            }
            else{
                if(this.props.rootFlag === 1){
                    if(resVal.fileAttach.name !== "" && resVal.fileAttach.fileResult !== null){
                        this.createAuditTrailData(); 
                        return
                    }
                    else{
                        $("#divLoading").attr("style", "display:none") 
                        resVal.errMsg = "No Data available.";
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn();
                        return
                    }
                }
                else if(this.props.rootFlag === 3 || this.props.rootFlag === 4){
                    this.createAuditTrailData(); 
                    return
                }
                else{
                    if(resVal.aGAuditsTab !== undefined && resVal.aGAuditsTab.tabData !== undefined && resVal.aGAuditsTab.tabData.length > 0){
                        $("#divLoading").attr("style", "display:none") 
                        resVal.errMsg = "Page already rendered..!"
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                        return;
                    }
                    else{
                        this.loadListTableData();
                        return
                    } 
                }
            }            
        }
    }
    catch(ex) {
      alert("Load List Failed..");
      return
    }
    }
    updateSchemeData= (e: any) => {
        e.preventDefault(); 
        const resVal: any = this.state;
        if(resVal.aGAuditsTab.rowData !== null && resVal.aGAuditsTab.rowData.dl_prizeamount !== null && resVal.aGAuditsTab.rowData.dl_prizeamount.toString() !== ""){
            this.createAuditTrailData();
            return
        }
        else{
            this.setState(resVal);
            return
        }
    }
    updateListTableRow =(e: any,rowData: any) =>{
        e.preventDefault();  
        const resVal: any = this.state;
        resVal.aGAuditsTab.rowData = {
            "dl_organizerid": rowData.dl_organizerid,
            "dl_gametypeid": rowData.dl_gametypeid,
            "dl_gametypename": rowData.dl_gametypename,
            "dl_organizername": rowData.dl_organizername,
            "dl_schemeid": rowData.dl_schemeid,
            "dl_selectedno": resVal.aGAuditsDrop.gameTypeId.value === 10 ? rowData.dl_rank : rowData.dl_selectedno,
            "dl_matchno": resVal.aGAuditsDrop.gameTypeId.value === 10 ? rowData.dl_noofwinner : rowData.dl_matchno,
            "dl_prizeamount": rowData.dl_prizeamount,
            "dl_flagid": rowData.dl_flagid
        };
        this.setState(resVal);
        $('.overlay').fadeIn();
        $('#lightBox8').fadeIn();
        return 
    } 
    //load list details ~ Audit trail data Get
    loadListTableData= (succMsg?: any) => {
        try{
            $("#divLoading").attr("style", "display:block")
            //this.setState(initialState);
            const windowWidth: number = $(window).width();
            let is_superuser: any = null;
            let userId: any = "";
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            if(userInfo !== null){
                userId = userInfo["userId"];
                is_superuser = userInfo["is_superuser"];
            }
            const initialState: any = this.state;
            const drawDateVal: any = initialState.aGAuditsDt; 
            let callMethod: string = "";
            let callJsonHead: any = null;
            let getDtlPoint: any = "";
            if(this.props.rootFlag === 2){
                callMethod = "c_AuditVS";
                getDtlPoint = "audit_info"
                callJsonHead = {
                    organizerID: is_superuser ? initialState.aGAuditsDrop.OrganizerName.value : userId,
                    GameTypeID: initialState.aGAuditsDrop.gameTypeId.value
                };
            } 
            else if(this.props.rootFlag === 4 || this.props.rootFlag === 5){
                callMethod = this.props.rootFlag === 4 ? "c_AuditRIG" : "c_AuditCW";
                getDtlPoint = "result_info"
                callJsonHead = {
                    organizerID: is_superuser ? initialState.aGAuditsDrop.OrganizerName.value : userId,
                    GameTypeID: initialState.aGAuditsDrop.gameTypeId.value,
                    GameID: parseInt(initialState.aGAuditsDrop.gameDetId.value.split('_')[0]),
                    DrawDate: moment(drawDateVal.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00"
                };
            }  
            else if(this.props.rootFlag === 6 || this.props.rootFlag === 7){
                callMethod = this.props.rootFlag === 6 ? "c_AuditTV" : "c_AuditWV";
                getDtlPoint = "result_info"
                callJsonHead = {
                    organizerID: is_superuser ? initialState.aGAuditsDrop.OrganizerName.value : userId,
                    TransactionID: initialState.aGAuditsText.selTxnValue.name
                };
            }              
            else{
            }
            //MANI 22/12
           //     useAxios.get(config.Url(callMethod,"get"), config.authHeaderwithParamRpt(callJsonHead))
         apiHelper.getAPICall(callMethod, "get",5,callJsonHead)
            .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                let colDataArr: any = [];
                let rowDataArr: any = [];
                let errText: string = "";
                if(res !== null && res.data !== null && res.data.result === undefined){
                  if(res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0){
                        rowDataArr = res.data[getDtlPoint];
                        rowDataArr.forEach((elem: any, index: number) => { elem.in_sno = index + 1; });  
                        if(this.props.rootFlag === 2){
                            colDataArr.push({
                                Visible: false,
                                width: windowWidth > 800 ? 120 : 75,
                                Header: "Actions",
                                accessor: "Edit Actions",
                                //resizable: false,
                                sortable: false,
                                className: "wordwrap",
                                Cell: (row: any) => (
                                <div className="userLogBtnCont3" style={{padding: 0}}>							
                                {windowWidth > 800 ? <button className="BlueBg" id="registerBtn" style={{fontSize: "12px",padding: "7px"}} onClick={(e: any) => this.updateListTableRow(e, row.original)}><i className="fa fa-edit" style={{marginRight: "5px"}} aria-hidden="true"></i>Edit</button> :
                                <i className="fa fa-edit BlueC" style={{fontSize: "12px"}} aria-hidden="true" onClick={(e: any) => this.updateListTableRow(e, row.original)}></i>}	
                                </div> 
                                )
                            });
                        }
                        colDataArr.push({className: "wordwrap",Visible: false,width: 40,Header: "#",accessor: "#",Cell: (row: any) => {                       
                            return <div style={{ textAlign: "right" }}>{row.original.in_sno}</div>;
                        }});
                        let cntIndex: number = 0;
                        for(var prop of Object.keys(res.data[getDtlPoint][0])){
                            const headerTitle: string = CommonAuditTrail.getTableTitle(prop);                     
                            const propValue: string = prop;                     
                            const isVisible: any = cntIndex === 0 ? false : true;
                            if(propValue === "in_sno" || propValue === "dl_flagid" || propValue === "dl_gametypeid" || propValue === "dl_organizerid" || propValue === "dl_gametypename" || propValue === "dl_organizername"
                            || (this.props.rootFlag === 4 && initialState.aGAuditsDrop.gameTypeId.value !== 10 && propValue === "dl_rank")
                            || (this.props.rootFlag === 4 && (initialState.aGAuditsDrop.gameTypeId.value === 9 || initialState.aGAuditsDrop.gameTypeId.value === 10) && propValue === "dl_machno")
                            || (this.props.rootFlag === 2 && ((initialState.aGAuditsDrop.gameTypeId.value === 10 && (propValue === "dl_selectedno" || propValue === "dl_matchno" || propValue === "dl_schemename")) 
                            || (initialState.aGAuditsDrop.gameTypeId.value !== 10 && (propValue === "dl_rank" || propValue === "dl_noofwinner" || propValue === "dl_totprize"))))
                            ){                            
                            }
                            else if((this.props.rootFlag === 2 || this.props.rootFlag === 5) && (propValue === "dl_prizeamount" || propValue === "dl_totprize" || propValue === "dl_auditwinamount" || propValue === "dl_winamount")){
                                const totValue: number= _.sum(_.map(rowDataArr, (data: any) => data[propValue]));                            
                                colDataArr.push({Visible: isVisible,Header: headerTitle,accessor: propValue,
                                    show: !isVisible,className: "wordwrap alignRight",headerClassName: "wordwrap",
                                    Footer: <span className="graphPop4Table">{totValue.toFixed(2)}</span>
                                    })
                            }
                            else if((this.props.rootFlag === 2 || this.props.rootFlag === 5) && (propValue === "dl_schemeid" || propValue === "dl_transactionid")){
                                colDataArr.push({Visible: isVisible,Header: headerTitle,accessor: propValue,
                                show: !isVisible,className: "wordwrap",headerClassName: "wordwrap",
                                Footer: <span className="graphPop4Table">Grand Total</span>
                                })
                            }
                            else{
                                colDataArr.push({Visible: isVisible,Header: headerTitle,accessor: propValue,
                                show: !isVisible,className: "wordwrap",headerClassName: "wordwrap"})
                            }
                            //responsive block
                            // if(windowWidth > 800 && propValue === "dl_unsoldcount" && this.props.rootFlag === 6){
                            //     cntIndex++;
                            // }
                            if(windowWidth < 600 && propValue === (this.props.rootFlag === 2 ? "dl_schemeid" : this.props.rootFlag === 4 ? "dl_gamename" : "dl_transactionid")){
                                cntIndex++;
                            }
                            else if(windowWidth > 600 && windowWidth <= 800 && propValue === (this.props.rootFlag === 2 && initialState.aGAuditsDrop.gameTypeId.value === 10 ? "dl_noofwinner" : this.props.rootFlag === 2 ? "dl_matchno" : this.props.rootFlag === 4 ? "dl_drawtime" : this.props.rootFlag === 6 ? "dl_transamount" : this.props.rootFlag === 5 ? "dl_auditwinamount" : "dl_winamount")){
                                cntIndex++;
                            }
                            //responsive block
                        }
                        if(windowWidth > 800){ 
                        }
                        else{                 
                        colDataArr.push({
                        width: 50,
                        Header: "View",
                        accessor: "View",
                        //resizable: false,
                        sortable: false,
                        className: "wordwrap",
                        Footer:
                            this.props.rootFlag === 2 || this.props.rootFlag === 5 ? (<div className="userLogBtnCont3" style={{padding: 0}}>	
                                <small>
                                {/* <img key="tabPopupIF_0" id="tabPopupIFC_0" className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,null)}></img> */}
                                    <i  key="tabPopupIF_0" id="tabPopupIFC_0" className="fa showPlusMinus fa-plus GreenBgg" style={{borderRadius: "100px",padding: "5px",background: "#fff"}} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e,null)}></i>
                                </small>          
                                <div key="tabPopupF_0" id="tabPopupFC_0" className="graphPop4 graphPop-1">
                                <table key="tabPopupVF_0">
                                    <tbody>
                                    {
                                        colDataArr.filter((elem: any) => elem.Visible).map((elem: any,index: number) => {
                                        return (elem.Footer ? (<tr key={"tabIVIndex_" + index}>
                                            <td style={{fontWeight: "bold"}} className="graphPop4Table">{elem.Header}</td>
                                            <td className="graphPop4Table">{elem.Footer}</td>
                                            </tr>) : null)
                                        })
                                    } 
                                    </tbody>     
                                </table>
                                {/* <img alt="" src={TooltipData}></img>	 */}
                                </div>	
                            </div>) : null,
                        Cell: (row: any) => (
                        <div className="userLogBtnCont3" style={{padding: 0}}>	
                            <small>
                                {/* <img key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,row.index)}></img> */}
                                <i  key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="fa showPlusMinus fa-plus GreenBgg" style={{borderRadius: "100px",padding: "5px"}} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e,row.index)}></i>
                            </small>          
                            <div key={"tabPopup_" + row.index} id={"tabPopupC_" + row.index} className="graphPop4 graphPop-1">
                            <Tablistview keys={"tabPopupV_" + row.index} rowData={row.original} colData={this.state.aGAuditsTab.colData} pageTitle="PaperLottery" />
                            {/* <img alt="" src={TooltipData}></img>	 */}
                            </div>
                        </div>
                        )
                        });
                    }
                  }
                  else{
                    errText = t('lblNodata')
                  }  
                }
                else{
                    errText= res.data.result.toString();
                }          
                //$("#templaTBlid").attr("style", "display:none") 
                //Grand Total- Start
                if(rowDataArr !== null && rowDataArr.length > 0){
                    let newObj: any = {};
                    colDataArr.forEach((elem: any) => {
                        if(elem.accessor !== "View" && elem.accessor !== "#" && elem.accessor !== "Edit Actions"){
                        if(elem.Footer)
                            Object.assign(newObj, {[elem.accessor]: elem.Footer.props.children});
                        else
                            Object.assign(newObj, {[elem.accessor]: ""});
                        }
                        return elem;
                    });
                    rowDataArr.push(newObj);
                }
                //Grand Total- End
                if(errText === undefined || errText === ""){
                    initialState.aGAuditsTab.colData = colDataArr;               
                    initialState.aGAuditsTab.tabData = rowDataArr; 
                }
                initialState.aGAuditsTab.filteredData = [];
                initialState.aGAuditsTab.searchInput = "";
                errText = succMsg !== undefined ? succMsg : errText;
                initialState.succFlag = succMsg !== undefined ? 0 : undefined;
                initialState.errMsg = errText;
                $("#divLoading").attr("style", "display:none") 
                this.setState(initialState);
                if(errText !== undefined && errText !== ""){
                    $('.overlay').fadeIn();
                    if(succMsg !== undefined)
                        $('#lightBox8').fadeOut();
                    $('#lightBox2').fadeIn();
                }
                return
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none")
                this.setState({succFlag: undefined,errMsg: t('lblNodata')});
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return false
            })
        }
        catch(ex) {
          alert("Load List Data Failed..");
          return
        } 
    }
    // Paper lott data Insert/Update/Delete
    createAuditTrailData = () => {
        const resVal: any = this.state;
        try{
            $("#divLoading").attr("style", "display:block")
            let is_superuser: any = null;
            let machNos: string = "";
            let resNos: string = "";
            let userId: any = "";
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            if(userInfo !== null){
                userId = userInfo["userId"];
                is_superuser = userInfo["is_superuser"];
            }
            if(this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value !== 10){
                const indexVal: number  = resVal.aGAuditsDrop.gameTypeId.value === 9 ? 6 : 5;
                const type: string  = resVal.aGAuditsDrop.gameTypeId.value === 9 ? "Primary" : "WinMach";
                Object.values(resVal.aGAuditsTab.inputChanged).forEach((elem: any,index: number) => {
                    let val = resVal.aGAuditsTab.inputChanged["Input_" + type + "_" + index];
                    if(index < indexVal){
                        resNos += ((val.length === 1 ? "0" : "") + val + ",");
                    }
                    else{
                        machNos += ((val.length === 1 ? "0" : "") + val + ",");
                    }
                    return
                })
            }
            const userIp: string = $("#userIp").text();    
            const jsonData: any = this.props.rootFlag === 1 ? {
                "SchemeID": resVal.aGAuditsText.selTxnValue.name,
                "GameTypeID": resVal.aGAuditsDrop.gameTypeId.value,
                "Scheme_Json": resVal.aGAuditsTab.tabDbData[0],
                "Turbo_Json": resVal.aGAuditsDrop.gameTypeId.value === 4 || resVal.aGAuditsDrop.gameTypeId.value === 5 ? resVal.aGAuditsTab.tabDbData[1] : [{"selectedno":""}],
                "Extra_Json": resVal.aGAuditsDrop.gameTypeId.value === 4 || resVal.aGAuditsDrop.gameTypeId.value === 5 ? resVal.aGAuditsTab.tabDbData[2] : [{"selectedno":""}],
                "organizerID": is_superuser ? resVal.aGAuditsDrop.OrganizerName.value : userId,
                "ipaddress": userIp
            } : this.props.rootFlag === 2 ? {
                "organizerID": resVal.aGAuditsTab.rowData.dl_organizerid,
                "GameTypeID": resVal.aGAuditsTab.rowData.dl_gametypeid,
                "SchemeID": resVal.aGAuditsTab.rowData.dl_schemeid,
                "SelectedNo": resVal.aGAuditsTab.rowData.dl_selectedno,
                "MatchNo": resVal.aGAuditsTab.rowData.dl_matchno,
                "Price_Amount": parseFloat(resVal.aGAuditsTab.rowData.dl_prizeamount).toFixed(2),
                "flag_id": resVal.aGAuditsTab.rowData.dl_flagid,
                "ipaddress": userIp
            } : this.props.rootFlag === 4 ? {
                "DrawDate": moment(resVal.aGAuditsDt.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                "GameID": parseInt(resVal.aGAuditsDrop.gameDetId.value.split('_')[0]),
                "GameTypeID": resVal.aGAuditsDrop.gameTypeId.value,
                "organizerID": is_superuser ? resVal.aGAuditsDrop.OrganizerName.value : userId,
                "ipaddress": userIp
            } : {
                "DrawDate": moment(resVal.aGAuditsDt.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                "GameID": parseInt(resVal.aGAuditsDrop.gameDetId.value.split('_')[0]),
                "GameTypeID": resVal.aGAuditsDrop.gameTypeId.value,
                "organizerID": is_superuser ? resVal.aGAuditsDrop.OrganizerName.value : userId,
                "WinNo": (resVal.aGAuditsDrop.gameTypeId.value === 10 ? JSON.stringify(resVal.aGAuditsTab.tabDbData[0]) : resNos.substring(0, resNos.length - 1)),
                "MachNo": machNos.substring(0, machNos.length - 1),
                "ipaddress": userIp
            };
            const callApiUrl: string = this.props.rootFlag === 1 ? "c_AuditSI" : this.props.rootFlag === 2 ? "c_AuditSIPut" : this.props.rootFlag === 4 ? "c_AuditWP" : "c_AuditRI";
            //useAxios[this.props.rootFlag === 2 ? "put" : "post"](config.Url(callApiUrl,this.props.rootFlag === 2 ? "put" : "post"), jsonData, config.authHeader())
            (this.props.rootFlag === 2 ?apiHelper.putAPICall(callApiUrl,"put",2,jsonData):apiHelper.postAPICall(callApiUrl,"post",2,jsonData))
            .then((res: any) => {
                console.log("RESPONSE RECEIVED: ", res);
                if(res.data !== null && res.data !== undefined && (res.data.machine_result !== undefined || res.data.win_result !== undefined || res.data.result !== undefined)){               
                    //error message based stop the update process- shows an error pop
                    const succMsg: any = res.data.machine_result === undefined ? (res.data.win_result === undefined ? res.data.result : res.data.win_result) : res.data.machine_result;            
                    if(!succMsg.toString().includes('successfully')){
                        $("#divLoading").attr("style", "display:none") 
                        const errMsg: string = succMsg.toString();     
                        this.setState({errMsg: errMsg,succFlag: undefined})
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                        return
                    }
                    else{ 
                        if(this.props.rootFlag === 2){
                            this.loadListTableData(succMsg.toString().replace('successfully',''));            
                            return
                        }
                        else{                       
                            this.resetValues(succMsg.toString().replace('successfully',''));            
                            return
                        }
                    }
                }
                else{
                    $("#divLoading").attr("style", "display:none")
                    return 
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                $("#divLoading").attr("style", "display:none")
                return 
            }) 
            }
            catch(ex) {
            alert("Data Insertion Failed..");
            return
            }  
    }
    // plus minus expand/collapse functioanlity
    onShowLightBox= (e: any, index: any) => {
        e.preventDefault();         
        const currDisplay: any = index === null ? $('#tabPopupFC_0').css('display') : $('#tabPopupC_' + index).css('display');     
        // $('.fa.fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        // $('.fa-minus').addClass('fa-plus').removeClass('fa-minus');  
        $('.showPlusMinus').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        $('.graphPop4').css({display: "none"})   
        if(index !== null){  
            $('#tabPopupIC_' + index).attr('class', (currDisplay === "none" ? "fa showPlusMinus fa-minus RedBgg" : "fa showPlusMinus fa-plus GreenBgg"));  
            $('#tabPopupC_' + index).css({display: currDisplay === "none" ? "block" : "none"})
            }
        else{
            $('#tabPopupIFC_0').attr('class', (currDisplay === "none" ? "fa showPlusMinus fa-minus RedBgg" : "fa showPlusMinus fa-plus GreenBgg"));      
            $('#tabPopupFC_0').css({display: currDisplay === "none" ? "block" : "none"})
        }  
        return 
    } 
    //export datas xls..,csv..,print
    exportXLPdfPrintdownload = (e: any,fileType: any) => {
        try{
        e.preventDefault();
        if(fileType === "chart"){
            $('.overlay').fadeIn();
            $('#lightBox4').fadeIn();
            return
        }
        else{
            const rootFlag: number = this.props.rootFlag;
            const initialState: any = this.state;
            const pageTitle: any = CommonAuditTrail.getPageTitle(rootFlag);
            const fileName: string = pageTitle.replaceAll(' ','');  
            const Orgdata: any = initialState.aGAuditsTab.tabData;
            const headerFlag: number = initialState.aGAuditsDrop.gameTypeId.value === 4 || initialState.aGAuditsDrop.gameTypeId.value === 5 ? 1 : 
            initialState.aGAuditsDrop.gameTypeId.value === 9 ? 2 : 3; 
            if(fileType === "pdf"){
                CommonAuditTrail.exportPDF(Orgdata,pageTitle,fileName,this.props.rootFlag,headerFlag);
            }
            else if(fileType === "csv" || fileType === "xls"){  
                CommonAuditTrail.exportExcel(Orgdata,fileName,fileType,this.props.rootFlag,headerFlag);   
            }
            else{
                CommonAuditTrail.exportPrint(Orgdata,pageTitle,this.props.rootFlag,headerFlag);
            }
        }
        return
        }
        catch(ex) {
        alert("Export Failed..");
        return
        }
    }
    // And in your global search
    globalSearch = (e: any) => {
    try{
        let initialState : any = this.state;
        $('.showPlusMinus').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');  
        $('.graphPop4').css({display: "none"})   
        const searchInput: string = e.target.value;
        const skipGT: any = initialState.aGAuditsTab.tabData.filter((elem: any,index: number) => index !== initialState.aGAuditsTab.tabData.length - 1);
        const headerFlag: number = initialState.aGAuditsDrop.gameTypeId.value === 4 || initialState.aGAuditsDrop.gameTypeId.value === 5 ? 1 : 
        initialState.aGAuditsDrop.gameTypeId.value === 9 ? 2 : 3; 
        let filteredData = skipGT.filter((value: any) => {
        return CommonAuditTrail.getKeyByValue(value, searchInput,this.props.rootFlag,headerFlag);
        });
        initialState.aGAuditsTab.filteredData = filteredData;
        initialState.aGAuditsTab.searchInput = searchInput;
        initialState.succFlag = undefined;
        initialState.errMsg = "";
        this.setState(initialState);
        return
    }
    catch(ex) {
        alert("Load Search filter List Load Failed..");
        return
    }        
    }; 
    /* From/To Date change event */
    handleDateChange = (date: any,key: string,format: string) => {
    try{
        let _this: any = this;
        let initialState : any = _this.state;
        const resVal: any = initialState.aGAuditsDt;
        resVal[key].defValue = moment(date._d);
        resVal[key].value = moment(date._d).format(format);
        resVal.FdrawDate.display = false;
        initialState.aGAuditsDt = resVal;
        initialState.aGAuditsTab.filteredData = [];
        initialState.aGAuditsTab.searchInput = "";
        if(_this.props.rootFlag !== 1){
            initialState.aGAuditsTab.tabData = [];
            initialState.aGAuditsTab.tabDataSub = [];
            initialState.aGAuditsTab.tabDbData = [];
        }
        initialState.succFlag = undefined;
        initialState.errMsg = "";
        let is_superuser: any = null;
        let userId: any = "";
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null){
            userId = userInfo["userId"];
            is_superuser = userInfo["is_superuser"];
        }
        if((_this.props.rootFlag === 3 || _this.props.rootFlag === 4 || _this.props.rootFlag === 5) && !resVal.FdrawDate.display && initialState.aGAuditsDrop.gameTypeId.value !== null &&
            (!is_superuser || (is_superuser && initialState.aGAuditsDrop.OrganizerName.value !== null))){
            const callJsonHead: any = { 
                DrawDate: moment(resVal.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                organizerID: is_superuser ? initialState.aGAuditsDrop.OrganizerName.value : userId,
                GameTypeID: initialState.aGAuditsDrop.gameTypeId.value
            }
            _this.loadNameMasterdata(initialState,callJsonHead,"load_game","cam_game",0).then((res1: any) => {
                _this.setState(res1);
                return 
            })
            return
        }
        else{
            _this.setState(initialState);
        }
    }
    catch(ex) {
      alert("On Date Change Failed..");
      return
    }        
    };  
    // event triggers on key press on input controls
    handleKeypress = (evt: any,type: string) => {
        if (type === "integer" && evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
        //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
            evt.preventDefault();
        }
        else if (evt.which !== 46 && evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
            //if(evt.which !== 8 && evt.which !== 0 && (evt.which < 96 || evt.which > 105)){          
                evt.preventDefault();
        }
    }
    maxLengthCheck = (object: any) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
    }
    //ticket no chnage for winners/transaction validation and scheme id
    onhandleTxtChange= (e: any,fieldFlag: number) => {
        try{
            const resVal: any = this.state;
            if(fieldFlag !== 1){
                resVal.aGAuditsTab.filteredData = [];
                resVal.aGAuditsTab.searchInput = "";
                if(this.props.rootFlag !== 1){
                resVal.aGAuditsTab.tabData = [];
                resVal.aGAuditsTab.tabDataSub = [];
                resVal.aGAuditsTab.tabDbData = [];
                }
                resVal.succFlag = undefined;
                resVal.errMsg = "";
                if(fieldFlag === 0){
                    resVal.aGAuditsText.selTxnValue.name = e.target.value;
                    resVal.aGAuditsText.selTxnValue.display = e.target.value.trim() === "" ? true : false;
                }
            }
            else{
                resVal.aGAuditsTab.rowData.dl_prizeamount = e.target.value;
            }
            this.setState(resVal);
            return
        }
        catch(ex) {
          alert("On Ticket Number Change Failed..");
          return
        }
    } 
    /* Game Drop down change */
    handleDropChange = (e: any,type: number) => {
    try{
        const _this: any = this;
        let is_superuser: any = null;
        let userId: any = "";
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if(userInfo !== null){
            userId = userInfo["userId"];
            is_superuser = userInfo["is_superuser"];
        }
        const resVals: any = _this.state;
        resVals.aGAuditsTab.filteredData = [];
        resVals.aGAuditsTab.searchInput = "";
        if(_this.props.rootFlag !== 1){
            resVals.aGAuditsTab.tabData = [];
            resVals.aGAuditsTab.tabDataSub = [];
            resVals.aGAuditsTab.tabDbData = [];
        }
        resVals.succFlag = undefined;
        resVals.errMsg = "";
        if(type === 1){
            resVals.aGAuditsDrop.OrganizerName.value = e.value;
            resVals.aGAuditsDrop.OrganizerName.display= false;
        }
        else if(type === 2){
            resVals.aGAuditsDrop.gameTypeId.value = e.value;
            resVals.aGAuditsDrop.gameTypeId.display= false;
            if(this.props.rootFlag === 1 || this.props.rootFlag === 3){
                $('#myfileExp').val(''); 
                const initilaState: any = CAuditmodel();          
                resVals.aGAuditsTab = initilaState.aGAuditsTab;
                resVals.fileAttach.name = "";    
                resVals.fileAttach.fileResult = null;
                resVals.aGAuditsTab.inputChanged = {};     
            }         
        }
        else if(type === 3){
            resVals.aGAuditsDrop.gameDetId.value = e.value;
            resVals.aGAuditsDrop.gameDetId.display= false;
        }
        if(type !== 3 && (_this.props.rootFlag === 3 || _this.props.rootFlag === 4 || _this.props.rootFlag === 5) && !resVals.aGAuditsDt.FdrawDate.display && resVals.aGAuditsDrop.gameTypeId.value !== null && 
        (!is_superuser || (is_superuser && resVals.aGAuditsDrop.OrganizerName.value !== null))){
            const callJsonHead: any = { 
                DrawDate: moment(resVals.aGAuditsDt.FdrawDate.value,'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                organizerID: is_superuser ? resVals.aGAuditsDrop.OrganizerName.value : userId,
                GameTypeID: resVals.aGAuditsDrop.gameTypeId.value
            }
            _this.loadNameMasterdata(resVals,callJsonHead,"load_game","cam_game",0).then((res1: any) => {
                _this.setState(res1);
                return 
            })
            return
        }
        else{
            _this.setState(resVals);
            return
        }
    }
    catch(ex) {
      alert("On Dropdown Change Failed..");
      return
    }
    }
    //on change the paper lott file upload data
    onhandleFileChange = (e: any) => {
        let file:any = (e.target as HTMLInputElement).files;
        $("#divLoading").attr("style", "display:block") 
        const resVal: any = this.state;
        const initilaState: any = CAuditmodel();
        if(file.length !== 0){           
            let fileNam:string = file[0].name.toString();
            let sizeInBytes:any = file[0].size;
            let fileType: string = fileNam.split('.')[1];
            let fileName: string = fileNam.split('.')[0];    
            const sizeInMB = Math.round(sizeInBytes / (1024*1024));
            if(resVal.aGAuditsDrop.gameTypeId.value === null){     
                $('#myfileExp').val('');           
                $("#divLoading").attr("style", "display:none") 
                resVal.errMsg = "";
                resVal.aGAuditsDrop.gameTypeId.display = true;
                resVal.aGAuditsTab = initilaState.aGAuditsTab;
                resVal.fileAttach = initilaState.fileAttach;                
                this.setState(resVal);
                return 
            }
            else if(fileType === undefined){     
                $('#myfileExp').val('');           
                $("#divLoading").attr("style", "display:none") 
                resVal.errMsg = "Invalid file..!";
                resVal.aGAuditsTab = initilaState.aGAuditsTab;
                resVal.fileAttach = initilaState.fileAttach;             
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return 
            }
            else if(sizeInMB <= 10 && (fileType.includes('xls') || fileType.includes('csv'))){   
                resVal.fileAttach.fileResult = file[0];
                resVal.fileAttach.name = fileName + "." + fileType;
                resVal.fileAttach.display = false;
                resVal.aGAuditsTab = initilaState.aGAuditsTab;           
                return this.convertExcelCsvFiletoJson(resVal.fileAttach.fileResult, (err:any, response:any) => {
                    if(err || response === undefined || response === null){
                        console.log(err); 
                        $('#myfileExp').val(''); 
                        $("#divLoading").attr("style", "display:none")  
                        resVal.aGAuditsTab = initilaState.aGAuditsTab;
                        resVal.fileAttach = initilaState.fileAttach;             
                        resVal.errMsg = "Invalid data..!";
                        this.setState(resVal);
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn(); 
                        return  
                    }
                    else{
                      $("#divLoading").attr("style", "display:none")                        
                        resVal.aGAuditsTab.tabDataSub = response.data;
                        resVal.aGAuditsTab.colDataSub = response.colData;
                        resVal.aGAuditsTab.tabDbData = response.dataDb;
                        this.setState(resVal);
                        return
                    }
                });    
            }
            else if(sizeInMB <= 10){
                resVal.errMsg = "Invalid file..!";
                resVal.aGAuditsTab = initilaState.aGAuditsTab;
                resVal.fileAttach = initilaState.fileAttach;             
                $('#myfileExp').val('');
                $("#divLoading").attr("style", "display:none") 
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return 
            }
            else{
                resVal.errMsg = "Data size should be less than or equals 10 MB";
                resVal.aGAuditsTab = initilaState.aGAuditsTab;
                resVal.fileAttach = initilaState.fileAttach;      
                $('#myfileExp').val('');
                $("#divLoading").attr("style", "display:none") 
                this.setState(resVal);
                $('.overlay').fadeIn();
                $('#lightBox2').fadeIn(); 
                return 
            }                             
        }
        else{
            resVal.aGAuditsTab = initilaState.aGAuditsTab;
            resVal.fileAttach = initilaState.fileAttach;      
            resVal.errMsg = "No files selected..";
            $('#myfileExp').val('');
            $("#divLoading").attr("style", "display:none") 
            this.setState(resVal);
        }
        return
    }
    // convert excel and csv file into json obj
    convertExcelCsvFiletoJson(file:any,callback:any) { 
        let _this: any = this;
        return new Promise<void>(function(resolve, reject) {
        try {
            var reader = new FileReader();
            var rABS = !!reader.readAsBinaryString;
            reader.onload = function(e:any) {
            try{
                /* Parse data */
                var bstr = e.target.result;
                var wb = excel.read(bstr, { type: rABS ? "binary" : "array",
                cellDates: true //, dateNF: 'dd/mm/yyyy;@' 
                });
                var datasValMain: any= [];
                var datasDbVal: any= [];                
                var errCount: number = 0;
                const sheetCnt: number = _this.state.aGAuditsDrop.gameTypeId.value === 4 || _this.state.aGAuditsDrop.gameTypeId.value === 5 ? 3 : 1;
                if((wb.SheetNames.length >= 3 && (_this.state.aGAuditsDrop.gameTypeId.value === 4 || _this.state.aGAuditsDrop.gameTypeId.value === 5)) 
                || _this.state.aGAuditsDrop.gameTypeId.value === 9 || _this.state.aGAuditsDrop.gameTypeId.value === 10){                  
                    for(let i = 0;i < sheetCnt; i++){ //wb.SheetNames.length
                    /* Get all worksheets */
                    var datasVal: any= [];
                    var datasDbVal1: any= [];
                    var datasDbVal2: any= [];
                    var datasDbVal3: any= [];
                    var wsname = wb.SheetNames[i];
                    var ws = wb.Sheets[wsname];
                    /* Convert array of arrays */
                    const jsonArr: any = excel.utils.sheet_to_json(ws, {
                        header: 1,
                        raw: false,defval: '',blankrows: false
                    });
                    
                    if(jsonArr.length > 0){                  
                        // const tabName: string = wsname.toString();
                        // sheetName.push(tabName); 
                        jsonArr.shift();
                        for(let i = 0; i < jsonArr.length; i++){
                            let datasObj: any = {}
                            if(_this.props.rootFlag === 1){
                                if(jsonArr[i].length >= 3){
                                if(_this.state.aGAuditsDrop.gameTypeId.value === 10)
                                    Object.assign(datasObj, {"in_sno": i + 1,"rank": jsonArr[i][0],"noofwinner": jsonArr[i][1],"prizeamount": jsonArr[i][2]})
                                else
                                    Object.assign(datasObj, {"in_sno": i + 1,"selectedno": jsonArr[i][0],"matchno": jsonArr[i][1],"prizeamount": jsonArr[i][2]})
                                datasDbVal1.push(jsonArr[i][0]);
                                datasDbVal2.push(jsonArr[i][1]);
                                datasDbVal3.push(jsonArr[i][2]);
                                }
                                else{
                                    errCount = 1;
                                    break;
                                }
                            }
                            else if(_this.props.rootFlag === 3){
                                if(jsonArr[i].length >= 2){
                                Object.assign(datasObj, {"in_sno": i + 1,"rank": jsonArr[i][0],"winningno": jsonArr[i][1]})
                                datasDbVal1.push(jsonArr[i][0]);
                                datasDbVal2.push(jsonArr[i][1]);
                                }
                                else{
                                    errCount = 1;
                                    break;
                                }
                            }
                            datasVal.push(datasObj)
                        }
                        if(errCount === 0){
                            if(_this.props.rootFlag === 1){
                                if(_this.state.aGAuditsDrop.gameTypeId.value === 10)
                                    datasDbVal.push({"rank": datasDbVal1,"noofwinner": datasDbVal2,"prizeamount": datasDbVal3});
                                else
                                    datasDbVal.push({"selectedno": datasDbVal1,"matchno": datasDbVal2,"prizeamount": datasDbVal3});
                            }
                            else if(_this.props.rootFlag === 3)
                                datasDbVal.push({"rank": datasDbVal1,"winningno": datasDbVal2});
                            datasValMain.push(datasVal);
                        }                           
                    }
                    else{
                        errCount = 1;
                        break;
                    }
                    }
                }
                else{
                    errCount = 1;
                }
                const resData: any = errCount === 0 ?
                {
                    data: datasValMain,
                    colData: _this.loadColDatas(datasValMain),
                    dataDb: datasDbVal
                } : null;
                resolve(resData);
                return callback(null, resData);
            }
            catch(ex){
                return callback(null);
            }
            };
            if (file && rABS) reader.readAsBinaryString(file);
            else reader.readAsArrayBuffer(file);
        } catch(err){
            $("#divLoading").attr("style", "display:none")
            reject(err);
        }
        });
    } 
    // load view column heeder data
    loadColDatas = (datasValMain: any) => {
        const colDataArr: any = [];
        const windowWidth: number = $(window).width();
        for(var i = 0; i < datasValMain.length; i++){
            const colDataArr1: any = [];
            const indexVal: number = i ;
            let cntIndex: number = 0;
            for(var prop of Object.keys(datasValMain[i][0])){
                const headerTitle: string = CommonAuditTrail.getTableTitle(prop);                     
                const propValue: string = prop; 
                const isVisible: any = cntIndex === 0 ? false : true;
                if(propValue === "in_sno" || propValue === "rank"){
                    colDataArr1.push({Visible: isVisible,width: propValue === "rank" ? 60 : 40,Header: headerTitle,accessor: propValue,
                        show: !isVisible,className: "wordwrap",headerClassName: "wordwrap"})
                } 
                else{                   
                    colDataArr1.push({Visible: isVisible,Header: headerTitle,accessor: propValue,
                        show: !isVisible,className: "wordwrap",headerClassName: "wordwrap"})
                }
                //responsive block
                // if(windowWidth > 800 && propValue === "Location"){
                //     cntIndex++;
                // }
                if(windowWidth < 600 && (propValue === "selectedno" || propValue === "rank")){
                    cntIndex++;
                }
                // else if(windowWidth > 600 && windowWidth <= 800 && propValue === (type === 2 ? "Name" : this.props.rootFlag === 3 ? "ContactNumber" : "Address")){
                //     cntIndex++;
                // }
            }
            if(windowWidth > 600){}
            else{
                colDataArr1.push({
                    width: 50,
                    Header: "View",
                    accessor: "View",
                    //resizable: false,
                    // sortable: false,
                    className: "wordwrap",
                    Cell: (row: any) => (
                    <div className="userLogBtnCont3" style={{padding: 0}}>	
                        <small>
                            <i  key={"tabPopupI_" + indexVal + "_" + row.index} id={"tabPopupIC_" + indexVal + "_" + row.index} className="fa showPlusMinus1 fa-plus GreenBgg" style={{borderRadius: "100px",padding: "5px"}} aria-hidden="true" onClick={(e: any) => this.onShowLightBox1(e,row.index,indexVal)}></i>
                        </small>          
                        <div key={"tabPopup_" + indexVal + "_" + row.index} id={"tabPopupC_" + indexVal + "_" + row.index} className="graphPop6 graphPop-1">
                        <Tablistview keys={"tabPopupV_" + indexVal + "_" + row.index} rowData={row.original} colData={colDataArr1} />
                        </div>	                            
                    </div>
                    )
                });
            }
            colDataArr.push(colDataArr1);
        }
        return colDataArr;
    } 
    //show hide lightbox
    onShowLightBox1= (e: any, index: any,indexVal: number) => {
        e.preventDefault();         
        const currDisplay: any = $('#tabPopupC_' + indexVal + '_' + index).css('display');    
        // $('.fa.fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        // $('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
        $('.showPlusMinus1').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');  
        $('.graphPop6').css({display: "none"})   
        $('#tabPopupIC_' + indexVal + '_' + index).attr('class', (currDisplay === "none" ? "fa showPlusMinus1 fa-minus RedBgg" : "fa showPlusMinus1 fa-plus GreenBgg"));  
        $('#tabPopupC_' + indexVal + '_' + index).css({display: currDisplay === "none" ? "block" : "none"})
        return 
    }
    closePopup =() => {
        $('.overlay').fadeOut();
        $('#lightBox8').fadeOut();
    }
    onMultiInputChange(e: any,in_winno: any) {
        const resVal: any = this.state;
        if(e.target.value === "" || parseInt(e.target.value) <= 90){
            const newSelected = Object.assign({}, resVal.aGAuditsTab.inputChanged);
            newSelected[in_winno] = e.target.value;
            resVal.aGAuditsTab.inputChanged = newSelected;   
        }     
        this.setState(resVal);
        return
    } 
    renderMultiInputs(index: number,type: string,iVal: number) {
        const resVal: any = this.state;
        let multiInputs = [];      
        for (let i = iVal; i < index; i++) {
        multiInputs.push(
            <input key={"Input_" + type + "_" + i} className="input_Number disableCtrl" min="0" onKeyPress= {(e:any) => this.handleKeypress(e,"integer")} value={resVal.aGAuditsTab.inputChanged !== undefined && resVal.aGAuditsTab.inputChanged !== null && resVal.aGAuditsTab.inputChanged["Input_" + type + "_" + i] !== undefined && resVal.aGAuditsTab.inputChanged["Input_" + type + "_" + i] !== null ? resVal.aGAuditsTab.inputChanged["Input_" + type + "_" + i] : ""} onChange={(e: any) => this.onMultiInputChange(e,"Input_" + type + "_" + i)} autoComplete="off" type="number" maxLength={2} onInput={this.maxLengthCheck} />
          );
        }        
        return multiInputs;
      }
    render() {
    const { t } = this.props;
    const resVal: any = this.state;    
    const themeClr = CommonReports.ThemeColour()
    const defaultMaterialTheme = createMuiTheme({
        palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: themeClr,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
          }
        },
    });
    const DropdownIndicator = (props: any) => {
        return (
          components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
              <FontAwesomeIcon style={{fontSize: "20px"}} icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>
            </components.DropdownIndicator>
          )
        );
      };
    const styles = {
        menu: (base: any) => ({
            ...base,
            marginTop: 0
        })
    };
    let is_superuser: any = null;
    const userInfoString: any = sessionStorage.getItem('userInfo');
    const userInfo: any = JSON.parse(userInfoString);
    if(userInfo !== null)
        is_superuser = userInfo["is_superuser"];
    const pageSize: number = resVal.aGAuditsTab !== undefined && resVal.aGAuditsTab.tabData !== undefined && resVal.aGAuditsTab.tabData.length > 50 ? 50 : resVal.aGAuditsTab.tabData.length > 25 ? 25 : 10;
    const headerTitle: any = CommonAuditTrail.getPageTitle(this.props.rootFlag);
      return(
        <div className="ContainerChart scrollbar" style={{height: $(window).height() - 60 + "px"}}>
            <div id="lightBox8" className="lightBox8" style={{marginLeft: '-'+ ($('#lightBox8').width() / 2) +'px',marginTop: '0px'}}>	
                <div className="userLog2">					                         
                    <div className="PreviewHead">
                        <h2>{t('lblEditScheme')}</h2>
                        <a href="#section" className="CancelBtn1" onClick={this.closePopup}><i className="fas fa-times"></i></a>
                    </div>
                    <ul>
                        {is_superuser !== null && is_superuser ? 
                        (<li>
                            <label>{t('lblOrganizerName')}</label>
                            <span>{resVal.aGAuditsTab.rowData !== null && resVal.aGAuditsTab.rowData.dl_organizername !== null ? resVal.aGAuditsTab.rowData.dl_organizername : ""}</span>
                        </li>) : null}
                        <li>
                            <label>{t('lblGameType')}</label>
                            <span>{resVal.aGAuditsTab.rowData !== null && resVal.aGAuditsTab.rowData.dl_gametypename !== null ? resVal.aGAuditsTab.rowData.dl_gametypename : ""}</span>
                        </li>
                        <li>
                            <label>{t('lblSchemeID')}</label>
                            <span>{resVal.aGAuditsTab.rowData !== null && resVal.aGAuditsTab.rowData.dl_schemeid !== null ? resVal.aGAuditsTab.rowData.dl_schemeid : ""}</span>
                        </li>
                        <li>
                            <label>{resVal.aGAuditsDrop.gameTypeId.value === 10 ? t('lblRank') : t('lblSelectNo')}</label>
                            <span><input className="disableCtrl" type="text" placeholder={resVal.aGAuditsDrop.gameTypeId.value === 10 ? t('lblEnterRank') : t('lblEnterSelectNo')} autoComplete="off" defaultValue={resVal.aGAuditsTab.rowData !== null && resVal.aGAuditsTab.rowData.dl_selectedno !== null ? resVal.aGAuditsTab.rowData.dl_selectedno : ""} disabled /></span>
                        </li>
                        <li>
                            <label>{resVal.aGAuditsDrop.gameTypeId.value === 10 ? "No of Winners" : "Match Number"}</label>
                            <span><input className="disableCtrl" type="text" placeholder={resVal.aGAuditsDrop.gameTypeId.value === 10 ? t('lblEnterNoOfWinners') : t('lblEnterMatchNo')} autoComplete="off" defaultValue={resVal.aGAuditsTab.rowData !== null && resVal.aGAuditsTab.rowData.dl_matchno !== null ? resVal.aGAuditsTab.rowData.dl_matchno : ""} disabled /></span>
                        </li>
                        <li>
                            <label>{t('lblPriceAmt')}</label>
                            <span>
                                <input className="input_Number disableCtrl" id="colValL1" min="0" onKeyPress= {(e:any) => this.handleKeypress(e,"decimal")} value={resVal.aGAuditsTab.rowData !== null && resVal.aGAuditsTab.rowData.dl_prizeamount !== null ? resVal.aGAuditsTab.rowData.dl_prizeamount : ""} onChange={(e: any) => this.onhandleTxtChange(e,1)} placeholder={t('lblEnterAmt')} autoComplete="off" type="number" maxLength={10} onInput={this.maxLengthCheck} />
                                <span style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGAuditsTab.rowData === null || resVal.aGAuditsTab.rowData.dl_prizeamount === null || resVal.aGAuditsTab.rowData.dl_prizeamount === "" ? "block" : "none"}}>{t('lblPriceAmtProvided')}</span>
                            </span>
                        </li>
                    </ul>                     
                    <div className="Btninfo">						
                        <button id="registerBtn" onClick={(e: any) => this.updateSchemeData(e)}>{t('lblOk')}</button>		
                    </div>
                </div>
            </div>
            <ListSubTable
                tabData= {resVal.aGAuditsTab.tabDataSub}
                colData= {resVal.aGAuditsTab.colDataSub}
                rootFlag= {this.props.rootFlag}
                sorting={true}
                ref ={this.subTabStateReset}
            />                   					          
            <Lightbox errMsg= {resVal.errMsg} succFlag= {resVal.succFlag} />   
            <div className="contentclouds">         
            <div className="userLogHead">                
                <div className="userLogHeadinner">
				<h2>{headerTitle}</h2>
				<small>{t('lblTrailDetails')}</small>
			    </div>
                <Breadcrumb Menu= "Audit Trail" subMenu= {headerTitle} /> 
            </div>
            <div className="UseformNew">
            <div className="Dateform">											
            <div className="userLogformTop">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <ul>
                    {is_superuser !== null && is_superuser ? (
                    <li style={{width: this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value === 10 ? "20%" : this.props.rootFlag === 1 || this.props.rootFlag === 3 || this.props.rootFlag === 4 || this.props.rootFlag === 5 ? "25%" : "33.3333%"}}>                        
                        <div className="userLogCloudtop">
                            <Select
                            value={resVal.aGAuditsDrop.OrganizerName.value === null ? null : resVal.aGAuditsDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.aGAuditsDrop.OrganizerName.value.toString())}
                            onChange={(e: any) => {this.handleDropChange(e, 1)}}
                            //isDisabled={this.props.rowData === null ? false : true}
                            placeholder={t('lblSelectName')}
                            options={resVal.aGAuditsDrop.OrganizerNames}
                            // maxMenuHeight={110}
                            className="userlogSelect"
                            components={{ DropdownIndicator }}
                            styles={styles}
                            />
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGAuditsDrop.OrganizerName.display ? "block" : "none"}}>{t('lblOrgNameProvided')}</label>
                        </div>
                    </li>) : null}
                    {this.props.rootFlag !== 6 && this.props.rootFlag !== 7 ? 
                    (<li style={{width: (is_superuser !== null && is_superuser && this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value === 10 ? "20%" : 
                        is_superuser !== null && is_superuser && (this.props.rootFlag === 1 || this.props.rootFlag === 3 || this.props.rootFlag === 4 || this.props.rootFlag === 5) ? "25%" : "33.3333%")}}>                        
                        <div className="userLogCloudtop">
                            <Select
                            value={resVal.aGAuditsDrop.gameTypeId.value === null ? null : resVal.aGAuditsDrop.gameType.filter((option: any) => option.value.toString() === resVal.aGAuditsDrop.gameTypeId.value.toString())}
                            onChange={(e: any) => {this.handleDropChange(e, 2)}}
                            placeholder={t('lblSelectGame')}
                            options={resVal.aGAuditsDrop.gameType.filter((elem: any) => elem.value.toString() === "4" || elem.value.toString() === "5" || elem.value.toString() === "9" || elem.value.toString() === "10")}
                            // maxMenuHeight={110}
                            className="userlogSelect"
                            components={{ DropdownIndicator }}
                            styles={styles}
                            />
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGAuditsDrop.gameTypeId.display ? "block" : "none"}}>{t('lblGameProvided')}</label>
                        </div>
                    </li>) : null}
                    {this.props.rootFlag === 3 || this.props.rootFlag === 4 || this.props.rootFlag === 5 ?
                    (<li style={{width: is_superuser !== null && is_superuser && this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value === 10 ? "20%" : is_superuser !== null && is_superuser ? "25%" : "33.3333%"}}>
                        <div className="userLogCloudtop">
                        <ThemeProvider theme={defaultMaterialTheme}>                                        
                            <DatePicker
                                placeholder="Pick Draw Date"
                                className="rptdatePicker"
                                format="DD-MM-yyyy"
                                value={resVal.aGAuditsDt.FdrawDate.defValue}
                                onChange={(date: any) => this.handleDateChange(date,"FdrawDate","DD-MM-yyyy")}
                            />                                        
                            </ThemeProvider>
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGAuditsDt.FdrawDate.display ? "block" : "none"}}>Draw Date has to be provided</label>
                        </div>
                    </li>) : null} 
                    {this.props.rootFlag === 3 || this.props.rootFlag === 4 || this.props.rootFlag === 5 ?
                    (<li style={{width: (is_superuser !== null && is_superuser && this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value === 10 ? "20%" : is_superuser !== null && is_superuser ? "25%" : "33.3333%")}}>                        
                        <div className="userLogCloudtop">
                            <Select
                            value={resVal.aGAuditsDrop.gameDetId.value === null ? null : resVal.aGAuditsDrop.gameDet.filter((option: any) => option.value.toString() === resVal.aGAuditsDrop.gameDetId.value.toString())}
                            onChange={(e: any) => {this.handleDropChange(e, 3)}}
                            placeholder={t('lblSelectGameName')}
                            options={resVal.aGAuditsDrop.gameDet}
                            // maxMenuHeight={110}
                            className="userlogSelect"
                            components={{ DropdownIndicator }}
                            styles={styles}
                            />
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGAuditsDrop.gameDetId.display ? "block" : "none"}}>{t('lblGameNameProvided')}</label>
                        </div>
                    </li>) : null}
                    {this.props.rootFlag === 6 || this.props.rootFlag === 7 ?
                    (<li style={{width: "33.3333%"}}>                        
                        <div className="userLogCloudtop">
                            <input className="newUserTxt" value={resVal.aGAuditsText.selTxnValue.name} onChange={(e: any) => this.onhandleTxtChange(e,0)} placeholder={t('lblEnterTicketNo')} autoComplete="off" type="text" maxLength={50}/>
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGAuditsText.selTxnValue.display ? "block" : "none"}}>{t('lblTicketNoProvided')}</label>
                        </div>
                    </li>) : this.props.rootFlag === 1 ?
                    (<li style={{width: is_superuser !== null && is_superuser ? "25%" : "33.3333%"}}>                        
                        <div className="userLogCloudtop">
                            <input className="input_Number" id="colValL2" min="0" onKeyPress= {(e:any) => this.handleKeypress(e,"integer")} value={resVal.aGAuditsText.selTxnValue.name} onChange={(e: any) => this.onhandleTxtChange(e,0)} placeholder={t('lblEnterSchemeId')} autoComplete="off" type="number" maxLength={5} onInput={this.maxLengthCheck} />
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.aGAuditsText.selTxnValue.display ? "block" : "none"}}>{t('lblSchemeidprovided')}</label>
                        </div>
                    </li>) : null}
                    {this.props.rootFlag === 1 || (this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value === 10) ?
                    (<li style={{width: is_superuser !== null && is_superuser && this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value === 10 ? "20%" : is_superuser !== null && is_superuser ? "25%" : "33.3333%"}}>                        
                        <div className="userLogCloudtop">
                            <div className="userLogBtnTop3">
                            <input className="newUserTxt" style= {{color: "transparent"}} type="file" id="myfileExp" name="myfile1" accept=".csv,.xlsb,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                             onChange={(e: any) => this.onhandleFileChange(e)} hidden />
                            <label htmlFor="myfileExp" id="myfileExport"><i className="fas fa-file-import" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblChooseFile')}</label>
                            {resVal.aGAuditsDrop.gameTypeId.value !== null ?
                            (<a title="Sample Excel Data" href={resVal.aGAuditsDrop.gameTypeId.value === 4 || resVal.aGAuditsDrop.gameTypeId.value === 5 ? '/excel/scheme_4_5.xlsx' : resVal.aGAuditsDrop.gameTypeId.value === 9 ? "/excel/scheme_9.xlsx" : this.props.rootFlag === 3 ? "/excel/scheme_10_2.xlsx" : "/excel/scheme_10_1.xlsx"} target="_blank" rel="noreferrer" download={(resVal.aGAuditsDrop.gameTypeId.value === 4 ? "Lotto" : resVal.aGAuditsDrop.gameTypeId.value === 5 ? 'Ghana' : resVal.aGAuditsDrop.gameTypeId.value === 9 ? "SuperLotto" : this.props.rootFlag === 3 ? "PaperLotterySchemeInput" : "PaperLotteryResultInput") + "_sample"}><i className="fa fa-download" style={{color:"#fff"}} aria-hidden="true"></i> </a>) :
                            null}
                            </div>
                            {resVal.fileAttach.name === null || resVal.fileAttach.name === "" ? null : (<label style={{fontSize: "12px",color: "#fff"}}><b>{resVal.fileAttach.name}</b></label>)}
                            <label style={{color: "#ff0000",fontWeight: "bold",fontSize: "12px",display: resVal.fileAttach.display ? "block" : "none"}}>{t('lblMustExcelData')}</label>
                        </div>
                    </li>) : null}
                </ul>
                </MuiPickersUtilsProvider>
            </div>            
            <div className="userLogBtnTop">							
                {this.props.rootFlag === 1 || this.props.rootFlag === 4 || (this.props.rootFlag === 3 && resVal.aGAuditsDrop.gameTypeId.value === 10) ?
                (<button id="registerBtn" onClick={(e: any) => this.onFilterListTableData(e,"View")}><i className="fas fa-eye" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblView')}</button>) : null}		
                <button id="registerBtn" onClick={(e: any) => this.onFilterListTableData(e,"Create")}><i className="fa fa-check" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblSubmit')}</button>		
                <button id="registerBtn1" style={{float: "right"}} onClick={() => this.resetValues()}><i className="fa fa-sync" style={{marginRight: "5px"}} aria-hidden="true"></i>{t('lblReset')}</button>		
            </div>            
            </div> 
            {/* {resVal.aGAuditsTab !== undefined && resVal.aGAuditsTab.tabData !== undefined && resVal.aGAuditsTab.tabData.length > 0 ?
            (<div className="TblError">
				<span>Report Generated On: <b>{resVal.rptGenDate}</b></span>
			</div>) : null} */}
            {resVal.aGAuditsTab !== undefined && resVal.aGAuditsTab.tabData !== undefined && resVal.aGAuditsTab.tabData.length > 0 ? 
            (<div className="ContainerTable">
                <div className="tableSearchreport">
                    <div className="sportSrch">
                        <input type="text" placeholder="Search here..!" id="myInput" value={resVal.aGAuditsTab.searchInput} onChange={(e: any) => this.globalSearch(e)} autoComplete="off" />
                    </div>
                </div>
                <div className="ButtonArea">  
					<button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'pdf')}>
						<a href="#section"><i className="fas fa-file-export" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>PDF</a>
					</button> 
					{/* <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'csv')}>
						<a href="#section"><i className="fas fa-file-excel" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>CSV</a>
					</button> */}
                    <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'xls')}>
						<a href="#section"><i className="fas fa-file-excel" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>Excel</a>
					</button>
					<button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'print')}>
						<a href="#section"><i className="fas fa-print" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>Print</a>
					</button>
				</div>
			</div>) : null}	
            {resVal.aGAuditsTab !== undefined && resVal.aGAuditsTab.tabData !== undefined && resVal.aGAuditsTab.tabData.length > 0 ? (
			<div className="TableReact">            
                <ListTable
                sorting={true}
                columns={resVal.aGAuditsTab.colData}
                pageSize={pageSize}
                data={resVal.aGAuditsTab.filteredData && resVal.aGAuditsTab.searchInput !== "" ? resVal.aGAuditsTab.filteredData : resVal.aGAuditsTab.tabData.filter((elem: any,index: number) => index !== resVal.aGAuditsTab.tabData.length - 1)}           
                />
            </div>) : null}   
            </div>
            {this.props.rootFlag === 3 && (resVal.aGAuditsDrop.gameTypeId.value === 4 || resVal.aGAuditsDrop.gameTypeId.value === 5 || resVal.aGAuditsDrop.gameTypeId.value === 9) ?
            (<div className="userLogform scrollbar">
                <div className="userLogformLft">
                    <ul>
                        <li>
                        <div className="userLogCloud5">
                            <h5>{resVal.aGAuditsDrop.gameType.filter((elem: any) => elem.value === resVal.aGAuditsDrop.gameTypeId.value)[0].label}</h5>
                            <div className="LottoRst">
                                <span>{resVal.aGAuditsDrop.gameTypeId.value === 9 ? "Primary Numbers" : "Result Numbers"}</span>
                                <div className="LottoRst2">
                                {this.renderMultiInputs(resVal.aGAuditsDrop.gameTypeId.value === 9 ? 6 : 5, resVal.aGAuditsDrop.gameTypeId.value === 9 ? "Primary" : "WinMach", 0)}
                            </div>
                            </div>
                            {resVal.aGAuditsDrop.gameTypeId.value === 9 ? null :
                            (<div className="LottoRst">
                                <span>{t('lblMachineNo')}</span>
                                <div className="LottoRst2">
                                    {this.renderMultiInputs(10, "WinMach", 5)}
                                </div>
                            </div>)}
                        </div>
                        </li>                                   
                    </ul>                                                
                </div>
            </div>) : null}
            </div>
        </div>
    );
  }
}

export default withTranslation()(CAuditTrail);