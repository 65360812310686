import apiHelper from "../../../utils/ApiHelper"
import React from "react";
import useAxios from "axios";
import axios from 'axios';
import config from "../../../utils/config"
import moment from "moment"
import { Bar } from "react-chartjs-2";
import { t } from "i18next";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
var $ = require('jquery')
export interface IProps {
    // pupUpOpenClick: any,
    // flog: number //to show date or hour based report
    startdate: any,
    enddate: any,    
    gameID:any,
    organizername:any
}
export interface IState {
    //createOrganizer: any
    chartData: any,
    myTitle: any
}
const data: any = {
    labels: ['GI', 'PMC', 'EDITEC'],
    datasets: [
        {
            label: "Sale",
            backgroundColor: '#88b65e',
            borderColor: '#6050FF',
            data: [20, 60, 25],
        }, {
            label: "Winning",
            backgroundColor: '#5095f0',
            borderColor: '#10EE90',
            data: [60, 50, 40],
        }, {
            label: "Claim",
            backgroundColor: '#64d0e4',
            borderColor: '#41B283',
            data: [30, 40, 70],
        },
        {
            label: "Payout",
            backgroundColor: '#005aa2',
            borderColor: '#41B883',
            data: [40, 25, 50],
        }
    ]
}
const options: any = {
    type: "bar",
    data: data,
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                // color:"#0100b0" ,
            },
        }
    },
    interaction: {
        mode: 'index',
        intersect: true
    },
    scales: {
        // tickColor:"#0100b0",
        x: {
            // ticks:{
            //     color:"#0100b0"
            // },
            display: true,
            title: {
                display: true,
                text: 'Provider',
            }
        },
        y: {
            //    ticks:{
            //    color:"#0100b0"
            //     },
            display: true,
            title: {
                display: true,
                text: 'Amount',
            }
        }
    }
};
class ProWiseWinSale extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            chartData: { labels: [], datasets: [] },
            myTitle: "Provider Wise Sale, Claim, Winning And Payout"
        };
        // options.onClick = this.handleClick;
    }
    initData = () => {
        let Gameid = typeof (this.props.gameID) === 'string' ? (this.props.gameID).split('_')[0] : 0;
        //_fg=> 0-datebased|1-houre based
        const callJsonHead: any = {
            organizerID: this.props.organizername,
            FromDate:moment(this.props.startdate).format('YYYY-MM-DD')+ " 00:00:00",
            ToDate:moment(this.props.enddate).format('YYYY-MM-DD')+ " 00:00:00",
            GameId:Gameid,
            //Status:0  //0-daywise|1-week wise
        }
        // mano 22/12
        //useAxios.get(config.Url("providerwise-SWCP", "get"), config.authHeaderwithParamRpt(callJsonHead))
        apiHelper.getAPICall("providerwise-SWCP", "get",5,callJsonHead)
        .then((res) => {
                console.log(" RESPONSE RECEIVED: providerwise-SWCP  ", res);
                if (res !== null && res.data !== null && res.data.result === undefined) {
                    let xAxis: any = [];
                    let Winning: any = [];
                    let Claim: any = [];
                    let Payout: any = [];
                    let Sale: any = [];
                    let tempArr: any = [];
                    let tempArr2: any = [];
                    let tempArr3: any = [];
                    let tempArr4: any = [];
                    let ydatasets: any = [];
                    let colDataArr: any = res.data["get_rptDetails"];
                    // xAxis =  colDataArr.map((item:any) =>item.in_organizername).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    // const yAxis =  colDataArr.map((item:any) => item.in_winamount).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    // yAxis.forEach((fobj: any) => {
                    //       xAxis.forEach(( fdate: any) => {
                    //             const objLst = colDataArr.filter((item: any) => { return item.in_organizername == fobj && item.in_winamount == fdate });
                    //             if (objLst.length > 0)
                    //             {
                    //             Winning.push(objLst[0].in_betamount);
                    //             Claim.push(objLst[0].in_claimedamount);
                    //             Payout.push(objLst[0].in_winamount);
                    //             Sale.push(objLst[0].in_payount);
                    //                }
                    //             else
                    //             Winning.push(0);
                    //             Claim.push(0);
                    //             Payout.push(0);
                    //             Sale.push(0);
                    //         });
                    //         var color = Math.floor(Math.random() * 16777216).toString(16);
                    //         var colour1= '#000000'.slice(0, -color.length) + color;
                    //         ydatasets.push(
                    //             {
                    //                 label: "Winning",//yAxis[yname],
                    //                 fill: false,
                    //                 backgroundColor: colour1,
                    //                 borderColor: colour1,
                    //                 data: Winning
                    //             }) 
                    //             yname++;
                    //             Winning = [];
                    //             Claim = [];
                    //             Payout = [];
                    //             Sale = [];
                    //     })
                    //xAxis = [...colDataArr.map((item: any) => item.in_organizername)];
                    //   Sale = [...colDataArr.map((item: any) => item.in_winamount)];
                    //   Claim = [...colDataArr.map((item: any) => item.in_claimedamount)];
                    //   Payout = [...colDataArr.map((item: any) => item.in_payount)];
                    //   Winning = [...colDataArr.map((item: any) => item.in_betamount)];

                    //  Claim   =  colDataArr.map((item:any) => item.in_organizername).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    //    Winning =  colDataArr.map((item:any) => item.in_winamount).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    //    Payout  =  colDataArr.map((item:any) => item.in_payount).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    //    Sale    =  colDataArr.map((item:any) => item.in_betamount).filter((value:any, index:any, self:any) => self.indexOf(value) === index)

                    const yAxis = colDataArr.map((item: any) => item.in_organizername).filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                    yAxis.forEach((fobj: any) => {
                        const objLst = colDataArr.filter((item: any) => { return item.in_organizername == fobj });
                        if (objLst.length > 0) {
                            tempArr.push(objLst[0].in_betamount);
                            tempArr2.push(objLst[0].in_payount);
                            tempArr3.push(objLst[0].in_winamount);
                            tempArr4.push(objLst[0].in_claimedamount);
                        }
                        else {
                            tempArr.push(0);
                            tempArr2.push(0);
                            tempArr3.push(0);
                            tempArr4.push(0);
                        }
                    })
                    ydatasets.push(
                        {
                            label: "Sale",
                            fill: true,
                            backgroundColor: '#88b65e',
                            borderColor: '#6050FF',
                            data: tempArr
                        });
                    ydatasets.push({
                        label: "Claimed",
                        fill: false,
                        backgroundColor: '#5095f0',
                        borderColor: '#10EE90',
                        data: tempArr4
                    })
                    ydatasets.push({
                        label: "Winning",
                        fill: false,
                        backgroundColor: '#005aa2',
                        borderColor: '#41B883',
                        data: tempArr3
                    })
                    ydatasets.push({
                        label: "Payout",
                        fill: false,
                        backgroundColor: '#64d0e4',
                        borderColor: '#41B283',
                        data: tempArr2
                    })

                    this.setState({ chartData: { labels: yAxis, datasets: ydatasets } });
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                return { labels: [], datasets: [] };
            })
    }
    componentDidMount(): void {
        this.initData();
        return;
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.startdate !== this.props.startdate) {
            this.initData()
        }
    }

    // handleClick = (e: any, i: any) => {
    //     debugger;
    //     let inx: any = i[0].index;
    //      let ds =  this.state.chartData.labels[inx];
    //      var obj = {
    //          Id: ds,
    //          fromDate: "",
    //          toDate: ""
    //      }
    //   //  this.props.pupUpOpenClick(obj);
    // }
    render(): React.ReactNode {
        return (
            <div>
                <h4>{t('lblProvideSaleClaimWinAndPayOut')}</h4>
                <Bar options={options} data={this.state.chartData} />
                {/* <Bar   options={options} data={data} />  */}
            </div>
        )
    }
};
export default ProWiseWinSale;