import React from 'react';
import useAxios from "axios";
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import moment from "moment"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// import { green } from "@material-ui/core/colors";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';
import _ from "lodash"

import config from "../../utils/config"
import Lightbox from "../Shared/lightbox";
import ListTable from "../Shared/listtable";
import ListChart from "../Shared/listchart";
import ListSubTable from "../Shared/listsubtable";
import Breadcrumb from "../Shared/breadcrumb";
import CommonReports from './Child/c_reportscommon';
import Tablistview from "../Shared/tablistview";
import CReportsmodel from "./Child/c_reportsmodel";
// import TooltipData from "../../assets/img/icons/Arrow_new.png"
// import PlusIcon from "../../assets/img/icons/Plus_Icon.png"
// import MinusIcon from "../../assets/img/icons/Minus_Icon.png"
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import apiHelper from '../../utils/ApiHelper';

var $ = require('jquery')
library.add(faCaretDown);
library.add(faCaretUp);

export interface IProps {
    rootFlag: number,
    t: any
}
export interface IState {
    succFlag: any,
    errMsg: string,
    aGWReportsDt: any,
    aGWReportsDrop: any,
    aGWReportsCt: any,
    aGWReportsTab: any,
    rptGenDate: any
    rptGenDateSub: any
}
// 1- draw date 0- sale date
// RootFlag Note:
// 10. Claimed reports
// 11. UnClaimed Reports
// 12. Operator Sales Vs Payout
// 4: Game Type wise Profit Vs Loss
// 5: Game Type wise Profit Vs Loss(Filter)
// 16. Player wise Reports
// 18. Overall sales for the day
// 19. Revenue for Government
class AGWHReports extends React.Component<IProps, IState> {
    // ------------------------------------------^
    private subTabStateReset: any = React.createRef;
    constructor(props: IProps) {
        super(props);
        this.subTabStateReset = React.createRef();
        this.state = CReportsmodel();
        this.globalSearch = this.globalSearch.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDropChange = this.handleDropChange.bind(this);
        this.onFilterListTableData = this.onFilterListTableData.bind(this);
        this.exportXLPdfPrintdownload = this.exportXLPdfPrintdownload.bind(this);
        this.loadListSubTableData = this.loadListSubTableData.bind(this);
        this.handleToggleClick = this.handleToggleClick.bind(this);
    }
    componentDidMount() {
        let is_superuser: any = null;
        let userId: any = "";
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if (userInfo !== null) {
            is_superuser = userInfo["is_superuser"];
            userId = userInfo["userId"];
        }
        this.loadTypeMasterdata(is_superuser, userId);
    }
    // main Templates dropdown change event
    componentDidUpdate(prevProps: any) {
        // Typical usage (don't forget to compare props):
        if (this.props.rootFlag !== prevProps.rootFlag) {
            this.resetValues();
        }
    }
    resetValues = () => {
        const resVals: any = this.state;
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        if (userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        const initialState: any = CReportsmodel();
        initialState.aGWReportsDrop.OrganizerNames = resVals.aGWReportsDrop.OrganizerNames;
        if (is_superuser)
            initialState.aGWReportsDrop.OrganizerName = { value: 0, display: false };
        else
            initialState.aGWReportsDrop.OrganizerName = { value: null, display: false };
        initialState.aGWReportsDrop.gameType = resVals.aGWReportsDrop.gameType;
        if (this.props.rootFlag === 5)
            initialState.aGWReportsDrop.gameTypeId = { value: 0, display: false };
        else
            initialState.aGWReportsDrop.gameTypeId = { value: null, display: false };
        this.setState(initialState);
    }
    //load game type details
    loadTypeMasterdata = (is_superuser: any, userId: any) => {
        try {
            $("#divLoading").attr("style", "display:block")
            this.setState(CReportsmodel());
            //ykarthik 22.12
           //useAxios.get(config.Url("cm_game", "get"), config.authHeaderwithParam("GameType~@#%&0"))
           apiHelper.getAPICall("cm_game","get",4,"GameType~@#%&0")     
           .then((res) => {
                    console.log("RESPONSE RECEIVED gwhGT : ", res);
                    let gameTypeArr: any = [];
                    if (res.data !== null && res.data.length !== 0) {
                        if (res.data["load_gameDetails"] !== undefined && res.data["load_gameDetails"] !== null && res.data["load_gameDetails"].length !== 0) {
                            for (var prop of res.data["load_gameDetails"]) {
                                gameTypeArr.push({ value: prop["in_gamegroupid"], label: prop["in_gamegroupdesc"] })
                            }
                        }
                    }
                    $("#divLoading").attr("style", "display:none")
                    if (is_superuser) {
                        this.loadOrgNameMasterdata(gameTypeArr);
                    } else {
                        const resVals: any = this.state;
                        resVals.aGWReportsDrop.gameType = gameTypeArr;
                        if (this.props.rootFlag === 5)
                            resVals.aGWReportsDrop.gameTypeId = { value: 0, display: false };
                        else
                            resVals.aGWReportsDrop.gameTypeId = { value: null, display: false };
                        this.setState(resVals);
                        return;
                    }
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    return false
                })
        }
        catch (ex) {
            alert("Load Master Data Failed..");
            return
        }
    }
    //load organizer details
    loadOrgNameMasterdata = (gameTypeArr: any) => {
        try {
            $("#divLoading").attr("style", "display:block")
            //ykarthik 22.12
            //useAxios.get(config.Url("cmo_game", "get"), config.authHeaderwithParam("OrgName~@#%&0"))
            apiHelper.getAPICall("cmo_game","get",4,"OrgName~@#%&0")  
            .then((res) => {
                    console.log("RESPONSE RECEIVED gwhOrg: ", res);
                    let OrganizerNamesArr: any = [];
                    if (res.data !== null && res.data.length !== 0) {
                        if (res.data["organizer_details"] !== undefined && res.data["organizer_details"] !== null && res.data["organizer_details"].length !== 0) {
                            for (var prop of res.data["organizer_details"]) {
                                OrganizerNamesArr.push({ value: prop["dl_organizerid"], label: prop["dl_organizername"], userId: prop["dl_userid"] })
                            }
                        }
                    }
                    $("#divLoading").attr("style", "display:none")
                    //$("#templaTBlid").attr("style", "display:none") 
                    const resVals: any = this.state;
                    resVals.aGWReportsDrop.OrganizerNames = OrganizerNamesArr;
                    resVals.aGWReportsDrop.OrganizerName = { value: 0, display: false };
                    resVals.aGWReportsDrop.gameType = gameTypeArr;
                    if (this.props.rootFlag === 5)
                        resVals.aGWReportsDrop.gameTypeId = { value: 0, display: false };
                    else
                        resVals.aGWReportsDrop.gameTypeId = { value: null, display: false };
                    this.setState(resVals);
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    return false
                })
        }
        catch (ex) {
            alert("Load Master Data Failed..");
            return
        }
    }
    /* event triggers on click the submit button */
    onFilterListTableData = (e: any) => {
        try {
            e.preventDefault();
            const resVal: any = this.state;
            let isErrData: boolean = false;
            Object.entries(resVal.aGWReportsDt).forEach(([key, value]) => { //date field validation
                let val = resVal.aGWReportsDt[key].value;
                if (val === null || val === "" || resVal.aGWReportsDt[key].display) {
                    resVal.aGWReportsDt[key].display = true
                    isErrData = true
                } else { return }
            })
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            if (this.props.rootFlag === 5 && resVal.aGWReportsDrop.gameTypeId.value === null) { //drop down validation
                resVal.aGWReportsDrop.gameTypeId.display = true
                isErrData = true
            }
            if (userInfo["is_superuser"] && resVal.aGWReportsDrop.OrganizerName.value === null) { //drop down validation
                resVal.aGWReportsDrop.OrganizerName.display = true
                isErrData = true
            }
            if (isErrData) {
                $("#divLoading").attr("style", "display:none")
                //resVal.errMsg = "Invalid data..!"
                this.setState(resVal);
                // $('.overlay').fadeIn();
                // $('#lightBox2').fadeIn(); 
                return
            } else {
                if (resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0) {
                    $("#divLoading").attr("style", "display:none")
                    resVal.errMsg = "Page already rendered..!"
                    this.setState(resVal);
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return;
                }
                else {
                    this.loadListTableData();
                    return
                }
            }
        }
        catch (ex) {
            alert("Load List Failed..");
            return
        }
    }

    //subtotal amt
    loadListSubTableData = (e: any, rowData: any, column: any = null) => {
        try {
            e.preventDefault();
            $("#divLoading").attr("style", "display:block")
            const windowWidth: number = $(window).width();
            let userId: any = "";
            let is_superuser: any = null;
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            if (userInfo !== null) {
                userId = userInfo["userId"];
                is_superuser = userInfo["is_superuser"];
            }
            const initialState: any = this.state;
            let callMethod: string = "";
            let callJsonHead: any = null;
            let getDtlPoint: any = "get_rptDetails";
            let getDtlDtPoint: any = "get_rptGenTime";


  //ykarthik 26.12
  switch(this.props.rootFlag){
    case 10: case 11: case 12:{
            callMethod = this.props.rootFlag === 10 ? "GCH_Reports" : this.props.rootFlag === 11 ? "GUCH_Reports" : "GOH_Reports";
            callJsonHead = this.props.rootFlag === 12 ?
                {
                    organizerID: rowData.in_orgsid,
                    GameID: rowData.in_gameid,
                    DrawDate: moment(rowData.in_drawdate, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    TerminalID: rowData.in_terminalid
                } :
                {
                    organizerID: rowData.in_organsid,
                    GameID: rowData.in_gameid,
                    DrawDate: moment(rowData.in_drawdate, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00"
                };
        }
        break;

        case 18:{
            callMethod = "GOS_Reports";
            callJsonHead = {
                FromDate: moment(initialState.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                ToDate: moment(initialState.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                flag_id: initialState.aGWReportsRadio.isSelSwitch,
                organizerID: is_superuser ? initialState.aGWReportsDrop.OrganizerName.value : userId,
                status: 1 //1-organizer based
            };

        }
        break;

        case 19: {
                callMethod = "GRGH_Reports";
                callJsonHead = {
                    FromDate: moment(initialState.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    ToDate: moment(initialState.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    flag_id: initialState.aGWReportsRadio.isSelSwitch,
                    organizerID: rowData.in_organsid
                };
            }
            break;

            case 4: case 5:{
                    callMethod = "GTWPLH_Reports";
                    callJsonHead =
                    {
                        organizerID: rowData.in_organsid,
                        GameTypeID: rowData.in_gamegroupid
                    }
                }
                break;

           case  16:{
                callMethod = "GPWH_Reports";
                callJsonHead =
                {
                    organizerID: rowData.in_organsid,
                    playerID: rowData.in_playerid
                }
            }
            break;

            case 23: case 24:{
                callMethod = "pg-pgrpt-lnk";
                callJsonHead = {
                    organizerID: is_superuser ? initialState.aGWReportsDrop.OrganizerName.value : userId,
                    FromDate: moment(initialState.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    ToDate: moment(initialState.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
                    Status: 0,
                    flag: column == "addmoney" ? 0 : 1
                }
            }
            break;


            default: { }

}

            // if (this.props.rootFlag === 10 || this.props.rootFlag === 11 || this.props.rootFlag === 12) {
            //     callMethod = this.props.rootFlag === 10 ? "GCH_Reports" : this.props.rootFlag === 11 ? "GUCH_Reports" : "GOH_Reports";
            //     callJsonHead = this.props.rootFlag === 12 ?
            //         {
            //             organizerID: rowData.in_orgsid,
            //             GameID: rowData.in_gameid,
            //             DrawDate: moment(rowData.in_drawdate, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            //             TerminalID: rowData.in_terminalid
            //         } :
            //         {
            //             organizerID: rowData.in_organsid,
            //             GameID: rowData.in_gameid,
            //             DrawDate: moment(rowData.in_drawdate, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00"
            //         };
            // }
            // else if (this.props.rootFlag === 18) {
            //     callMethod = "GOS_Reports";
            //     callJsonHead = {
            //         FromDate: moment(initialState.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            //         ToDate: moment(initialState.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            //         flag_id: initialState.aGWReportsRadio.isSelSwitch,
            //         organizerID: is_superuser ? initialState.aGWReportsDrop.OrganizerName.value : userId,
            //         status: 1 //1-organizer based
            //     };
            // }
            // else if (this.props.rootFlag === 19) {
            //     callMethod = "GRGH_Reports";
            //     callJsonHead = {
            //         FromDate: moment(initialState.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            //         ToDate: moment(initialState.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            //         flag_id: initialState.aGWReportsRadio.isSelSwitch,
            //         organizerID: rowData.in_organsid
            //     };
            // }
            // else if (this.props.rootFlag === 4 || this.props.rootFlag === 5) {
            //     callMethod = "GTWPLH_Reports";
            //     callJsonHead =
            //     {
            //         organizerID: rowData.in_organsid,
            //         GameTypeID: rowData.in_gamegroupid
            //     }
            // }
            // else if (this.props.rootFlag === 16) {
            //     callMethod = "GPWH_Reports";
            //     callJsonHead =
            //     {
            //         organizerID: rowData.in_organsid,
            //         playerID: rowData.in_playerid
            //     }
            // }
            // else if (this.props.rootFlag === 23 || this.props.rootFlag === 24) {
            //     callMethod = "pg-pgrpt-lnk";
            //     callJsonHead = {
            //         organizerID: is_superuser ? initialState.aGWReportsDrop.OrganizerName.value : userId,
            //         FromDate: moment(initialState.aGWReportsDt.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            //         ToDate: moment(initialState.aGWReportsDt.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00",
            //         Status: 0,
            //         flag: column == "addmoney" ? 0 : 1
            //     }
            // }
            // else {
            // }

            //ykarhtik 22.12
            //useAxios.get(config.Url(callMethod, "get"), config.authHeaderwithParamRpt(callJsonHead))
            apiHelper.getAPICall(callMethod,"get",5,callJsonHead)  
            .then((res) => {
                    console.log("RESPONSE RECEIVED gwhSubListTable: ", res);
                    let colDataArr: any = [];
                    let rowDataArr: any = [];
                    let rptGenDt: any = "";
                    let errText: string = "";
                    if (res !== null && res.data !== null && res.data.result === undefined) {
                        if (res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0) {
                            rowDataArr = res.data[getDtlPoint];
                            rowDataArr.forEach((elem: any, index: number) => { elem.in_sno = index + 1; });
                            rptGenDt = res.data[getDtlDtPoint].toString();
                            colDataArr.push({
                                className: "wordwrap", Visible: false, width: 40, Header: "#", accessor: "#", Cell: (row: any) => {
                                    return <div style={{ textAlign: "right" }}>{row.original.in_sno}</div>;
                                }
                            });
                            let cntIndex: number = 0;
                            for (var prop of Object.keys(res.data[getDtlPoint][0])) {
                                const headerTitle: string = CommonReports.getTableTitle(prop);
                                const propValue: string = prop;
                                const isVisible: any = cntIndex === 0 ? false : true;
                                if (propValue === "in_sno" || propValue === "in_organsid" || propValue === "in_orgsid" || propValue === "in_gameid" || propValue === "in_gamegroupid" || (this.props.rootFlag === 19 && initialState.aGWReportsRadio.isSelSwitch === 1 && propValue === "in_terminalid")) {
                                }
                                //totalamount of table
                                else if (propValue === "in_betamount" || propValue === "in_winamount" || propValue === "in_netamount" || propValue === "in_payout" || propValue === "in_claimamount" || propValue === "in_unclaimamount" || propValue === "in_transamount" || propValue === "in_paymentamount" || propValue === "in_revenueamount" || propValue === "in_salecommission" || propValue === "in_claimcommission") {
                                    const totValue: number = propValue === "in_netamount" ? (_.sum(_.map(rowDataArr, (data: any) => data["in_betamount"])) - _.sum(_.map(rowDataArr, (data: any) => data["in_winamount"]))) :
                                        propValue === "in_payout" ? (_.sum(_.map(rowDataArr, (data: any) => data["in_winamount"])) / _.sum(_.map(rowDataArr, (data: any) => data["in_betamount"])) * 100) :
                                            _.sum(_.map(rowDataArr, (data: any) => data[propValue]));
                                    colDataArr.push({
                                        Visible: isVisible, Header: headerTitle, accessor: propValue,
                                        show: !isVisible, className: "wordwrap alignRight", headerClassName: "wordwrap",
                                        Footer: <span className="graphPop4Table">{totValue.toFixed(2)}</span>
                                    })
                                }
                                //grandtotal
                                else if (propValue === "in_transactionid" || (this.props.rootFlag === 16 && propValue === "in_gamename") || (this.props.rootFlag === 18 && propValue === "in_organizername") || (this.props.rootFlag === 19 && propValue === "in_sstockistname")) {
                                    colDataArr.push({
                                        Visible: isVisible, Header: headerTitle, accessor: propValue,
                                        show: !isVisible, className: "wordwrap", headerClassName: "wordwrap",
                                        Footer: <span className="graphPop4Table">Grand Total</span>
                                    })
                                }
                                else if (propValue === "in_netsale" || propValue === "in_commissiontype") {
                                    colDataArr.push({
                                        width: propValue === "in_commissiontype" ? 120 : 100, Visible: isVisible, Header: headerTitle, accessor: propValue,
                                        show: !isVisible, className: "wordwrap", headerClassName: "wordwrap"
                                    })
                                }
                                else {
                                    colDataArr.push({
                                        Visible: isVisible, Header: headerTitle, accessor: propValue,
                                        show: !isVisible, className: "wordwrap", headerClassName: "wordwrap"
                                    })
                                }
                                //responsive block
                                // if(windowWidth > 800 && this.props.rootFlag === 19 && (propValue === "in_betamount")){
                                //     cntIndex++;
                                // }
                                if (windowWidth < 600 && propValue === (this.props.rootFlag === 18 ? "in_organizername" : this.props.rootFlag === 16 ? "in_gamename" : this.props.rootFlag === 19 ? "in_sstockistname" : "in_transactionid")) {
                                    cntIndex++;
                                }
                                else if (windowWidth > 600 && windowWidth <= 800 && propValue === (this.props.rootFlag === 18 ? "in_winamount" : this.props.rootFlag === 19 ? "in_commissiontype" : this.props.rootFlag === 16 ? "in_drawtime" : "in_transdatetime")) {
                                    cntIndex++;
                                }
                                //responsive block
                            }
                            if (windowWidth > 800) {
                            }
                            else {
                                colDataArr.push({
                                    width: 50,
                                    Header: "View",
                                    accessor: "View",
                                    //resizable: false,
                                    // sortable: false,
                                    className: "wordwrap",
                                    Footer:
                                        <div className="userLogBtnCont3" style={{ padding: 0 }}>
                                            <small>
                                                {/* <img key="tabPopupIF_0" id="tabPopupIFC_0" className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,null)}></img> */}
                                                <i key="tabPopupIF0_0" id="tabPopupIFC0_0" className="fa showPlusMinus1 fa-plus GreenBgg" style={{ borderRadius: "100px", padding: "5px", background: "#fff" }} aria-hidden="true" onClick={(e: any) => this.onShowLightBox1(e, null)}></i>
                                            </small>
                                            <div key="tabPopupF0_0" id="tabPopupFC0_0" className="graphPop6 graphPop-1">
                                                <table key="tabPopupVF0_0">
                                                    <tbody>
                                                        {
                                                            colDataArr.filter((elem: any) => elem.Visible).map((elem: any, index: number) => {
                                                                return (elem.Footer ? (<tr key={"tabIVIndex0_" + index}>
                                                                    <td style={{ fontWeight: "bold" }} className="graphPop4Table">{elem.Header}</td>
                                                                    <td className="graphPop4Table">{elem.Footer}</td>
                                                                </tr>) : null)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                {/* <img alt="" src={TooltipData}></img>	 */}
                                            </div>
                                        </div>,
                                    Cell: (row: any) => (
                                        <div className="userLogBtnCont3" style={{ padding: 0 }}>
                                            <small>
                                                {/* <img key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,row.index)}></img> */}
                                                <i key={"tabPopupI0_" + row.index} id={"tabPopupIC0_" + row.index} className="fa showPlusMinus1 fa-plus GreenBgg" style={{ borderRadius: "100px", padding: "5px" }} aria-hidden="true" onClick={(e: any) => this.onShowLightBox1(e, row.index)}></i>
                                            </small>
                                            <div key={"tabPopup0_" + row.index} id={"tabPopupC0_" + row.index} className="graphPop6 graphPop-1">
                                                <Tablistview keys={"tabPopupV0_" + row.index} rowData={row.original} colData={this.state.aGWReportsTab.colDataSub} rootFlag={this.props.rootFlag} />
                                                {/* <img alt="" src={TooltipData}></img>	 */}
                                            </div>
                                        </div>
                                    )
                                });
                            }
                        }
                        else {
                            errText = "No Data Available"
                        }
                    }
                    else {
                        errText = res.data.result.toString();
                    }
                    //$("#templaTBlid").attr("style", "display:none") 
                    if (errText === undefined || errText === "") {
                        initialState.aGWReportsTab.colDataSub = colDataArr;
                        initialState.rptGenDateSub = rptGenDt;
                        // let resChartData: any= null;
                        // resChartData = this.loadChartData(colDataArr,"pie");              
                        //Grand Total- Start
                        if (rowDataArr !== null && rowDataArr.length > 0) {
                            let newObj: any = {};
                            colDataArr.forEach((elem: any) => {
                                if (elem.accessor !== "View" && elem.accessor !== "#") {
                                    if (elem.Footer)
                                        Object.assign(newObj, { [elem.accessor]: elem.Footer.props.children });
                                    else
                                        Object.assign(newObj, { [elem.accessor]: "" });
                                }
                                return elem;
                            });
                            rowDataArr.push(newObj);
                        }
                        //Grand Total- End
                        initialState.aGWReportsTab.tabDataSub = rowDataArr;
                        // if(resChartData !== null && resChartData.length > 0){
                        //     initialState.aGWReportsCt.options = resChartData[0];
                        //     initialState.aGWReportsCt.series = resChartData[1];
                        //     if(resChartData[2] !== null){
                        //         initialState.aGWReportsTab.tabData = resChartData[2];
                        //     }
                        // }
                    }
                    initialState.succFlag = undefined;
                    initialState.errMsg = errText;
                    $("#divLoading").attr("style", "display:none")
                    this.setState(initialState);
                    $('.overlay').fadeIn();
                    if (errText !== undefined && errText !== "") {
                        $('#lightBox2').fadeIn();
                    }
                    else {
                        this.subTabStateReset.current.subTabStateReset();
                        $('#lightBox5').fadeIn();
                    }
                    return
                })
                .catch((err) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    this.setState({ succFlag: undefined, errMsg: "No Data Available" });
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return false
                })
        }
        catch (ex) {
            alert("Load Sub List Failed..");
            return
        }
    }
    //load list details
    loadListTableData = () => {
        try {
            $("#divLoading").attr("style", "display:block")
            //this.setState(initialState);
            const windowWidth: number = $(window).width();
            const initialState: any = this.state;
            const drawDateVal: any = initialState.aGWReportsDt;
            let userId: any = "";
            let is_superuser: any = null;
            const userInfoString: any = sessionStorage.getItem('userInfo');
            const userInfo: any = JSON.parse(userInfoString);
            if (userInfo !== null) {
                userId = userInfo["userId"];
                is_superuser = userInfo["is_superuser"];
            }
            let callMethod: string = "";
            let callJsonHead: any = null;
            let getDtlPoint: any = "get_rptDetails";
            let getDtlDtPoint: any = "get_rptGenTime";
            const OrgUserId: any = is_superuser ? initialState.aGWReportsDrop.OrganizerName.value : userId;
            const FdrawDate: any = moment(drawDateVal.FdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00";
            const TdrawDate: any = moment(drawDateVal.TdrawDate.value, 'DD-MM-yyyy').format('yyyy-MM-DD') + " 00:00:00";
           
            //ykarthik 26.12
            switch(this.props.rootFlag){
                case 10: case 11: case 12: case 16:{
                    callMethod = this.props.rootFlag === 10 ? "GC_Reports" : this.props.rootFlag === 11 ? "GUC_Reports" :
                this.props.rootFlag === 16 ? "GPW_Reports" : "GO_Reports";
            callJsonHead = {
                organizerID: OrgUserId,
                FromDate: FdrawDate,
                ToDate: TdrawDate

                }; }
                break;
                case 4:{
                    callMethod = "GTWPL_Reports";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate,
                        FlagID: initialState.aGWReportsRadio.isSelSwitch
                    };
                }
                break;
                case 18: case 19:{
                    callMethod = this.props.rootFlag === 19 ? "GRG_Reports" : "GOS_Reports";
                    callJsonHead = {
                        FromDate: FdrawDate,
                        ToDate: TdrawDate,
                        flag_id: initialState.aGWReportsRadio.isSelSwitch,
                        organizerID: OrgUserId,
                        status: this.props.rootFlag === 19 ? 2 : 0 //0-overall 2- Agent&Terminal
                    };
                }
                break;

                case 5: {
                    callMethod = "GTWPLF_Reports";
                    const paramKey: any = "GameID";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate,
                        FlagID: initialState.aGWReportsRadio.isSelSwitch,
                        [paramKey]: initialState.aGWReportsDrop.gameTypeId.value
                    };
                }
                break;
                case 23:{
                    callMethod = "pg-pgrpt";
                    callJsonHead = {
                        organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate,
                        //FlagID: initialState.aGWReportsRadio.isSelSwitch
                    };
                }
                break;
                case 24 : {

                    callMethod = "pg-pgrptsum";
                    const paramKey: any = "GameID";
                    callJsonHead = {
                        //organizerID: OrgUserId,
                        FromDate: FdrawDate,
                        ToDate: TdrawDate,
                        //FlagID: initialState.aGWReportsRadio.isSelSwitch,                
                    };
                }
                break;
                default : {}

     

    }
           
            // if (this.props.rootFlag === 10 || this.props.rootFlag === 11 || this.props.rootFlag === 12 || this.props.rootFlag === 16) {
            //     callMethod = this.props.rootFlag === 10 ? "GC_Reports" : this.props.rootFlag === 11 ? "GUC_Reports" :
            //         this.props.rootFlag === 16 ? "GPW_Reports" : "GO_Reports";
            //     callJsonHead = {
            //         organizerID: OrgUserId,
            //         FromDate: FdrawDate,
            //         ToDate: TdrawDate
            //     };
            // }
            // else if (this.props.rootFlag === 4) {
            //     callMethod = "GTWPL_Reports";
            //     callJsonHead = {
            //         organizerID: OrgUserId,
            //         FromDate: FdrawDate,
            //         ToDate: TdrawDate,
            //         FlagID: initialState.aGWReportsRadio.isSelSwitch
            //     };
            // }
            // else if (this.props.rootFlag === 18 || this.props.rootFlag === 19) {
            //     callMethod = this.props.rootFlag === 19 ? "GRG_Reports" : "GOS_Reports";
            //     callJsonHead = {
            //         FromDate: FdrawDate,
            //         ToDate: TdrawDate,
            //         flag_id: initialState.aGWReportsRadio.isSelSwitch,
            //         organizerID: OrgUserId,
            //         status: this.props.rootFlag === 19 ? 2 : 0 //0-overall 2- Agent&Terminal
            //     };
            // }
            // else if (this.props.rootFlag === 5) {
            //     callMethod = "GTWPLF_Reports";
            //     const paramKey: any = "GameID";
            //     callJsonHead = {
            //         organizerID: OrgUserId,
            //         FromDate: FdrawDate,
            //         ToDate: TdrawDate,
            //         FlagID: initialState.aGWReportsRadio.isSelSwitch,
            //         [paramKey]: initialState.aGWReportsDrop.gameTypeId.value
            //     };
            // }
            // else if (this.props.rootFlag === 23) {
            //     callMethod = "pg-pgrpt";
            //     callJsonHead = {
            //         organizerID: OrgUserId,
            //         FromDate: FdrawDate,
            //         ToDate: TdrawDate,
            //         //FlagID: initialState.aGWReportsRadio.isSelSwitch
            //     };
            // }
            // else if (this.props.rootFlag === 24) {

            //     callMethod = "pg-pgrptsum";
            //     const paramKey: any = "GameID";
            //     callJsonHead = {
            //         //organizerID: OrgUserId,
            //         FromDate: FdrawDate,
            //         ToDate: TdrawDate,
            //         //FlagID: initialState.aGWReportsRadio.isSelSwitch,                
            //     };
            // }
            // else {
            // }

            // //ykarthik 22.12
           // useAxios.get(config.Url(callMethod, "get"), config.authHeaderwithParamRpt(callJsonHead))
           apiHelper.getAPICall(callMethod,"get",5,callJsonHead)  
           .then((res) => {
                    console.log("RESPONSE RECEIVED gwhListTable: ", res);
                    let colDataArr: any = [];
                    let rowDataArr: any = [];
                    let rptGenDt: any = "";
                    let errText: string = "";
                    if (res !== null && res.data !== null && res.data.result === undefined) {
                        if (res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0) {
                            rowDataArr = res.data[getDtlPoint];
                            rptGenDt = res.data[getDtlDtPoint].toString();
                            colDataArr.push({
                                className: "wordwrap", Visible: false, width: 40, Header: "#", accessor: "#", Cell: (row: any) => {
                                    // return <div style={{ textAlign: "right" }}>{row.original[Object.keys(row.original)[0]] !== "Sub Total" ? row.index + 1 : ""}</div>;
                                    return <div style={{ textAlign: "right" }}>{row.original.in_sno}</div>;
                                }
                            });
                            let cntIndex: number = 0;
                            for (var prop of Object.keys(res.data[getDtlPoint][0])) {
                                const headerTitle: string = CommonReports.getTableTitle(prop);
                                const propValue: string = prop;
                                const isVisible: any = cntIndex === 0 ? false : true;
                                if (propValue === "in_sno" || propValue === "in_organsid" || propValue === "in_orgsid" || propValue === "in_gameid" || propValue === "in_gamegroupid" || (this.props.rootFlag === 18 && propValue === "in_organizername")) {
                                }
                                //total amount calculation
                                else if (propValue === "in_betamount" || propValue === "in_winamount" || propValue === "in_payout" || propValue === "in_netamount" || propValue === "in_claimedamount" || propValue === "in_unclaimedamount" || propValue === "in_transamount" || propValue === "in_revenueamount" || propValue === "in_salecommission" || propValue === "in_claimcommission" || propValue === "addmoney" || propValue === "withdraw") {
                                    const totValue: number = propValue === "in_netamount" ? (_.sum(_.map(rowDataArr, (data: any) => data["in_betamount"])) - _.sum(_.map(rowDataArr, (data: any) => data["in_winamount"]))) :
                                        propValue === "in_payout" ? (_.sum(_.map(rowDataArr, (data: any) => data["in_winamount"])) / _.sum(_.map(rowDataArr, (data: any) => data["in_betamount"])) * 100) :
                                            _.sum(_.map(rowDataArr, (data: any) => data[propValue]));
                                    if (propValue === "addmoney" || propValue === "withdraw" || propValue === "in_claimedamount" || propValue === "in_unclaimedamount" || propValue === (this.props.rootFlag === 4 || this.props.rootFlag === 5 || this.props.rootFlag === 16 || this.props.rootFlag === 18 ? "in_betamount" : this.props.rootFlag === 19 ? "in_revenueamount" : "in_payout")) {
                                        if (this.props.rootFlag !== 18) {
                                            colDataArr.push({
                                                Visible: isVisible, Header: headerTitle, accessor: propValue,
                                                show: !isVisible, className: "wordwrap alignRight", headerClassName: "wordwrap",
                                                Cell: (row: any) => (
                                                    row.original[propValue].toString() !== "0" && (row.original["in_organizername"] !== undefined && row.original["in_organizername"].toString() !== "Sub Total" || row.original["provider"] !== undefined) ?
                                                        (<a href="#section" className="graphPop4Table" style={{ textDecoration: "underline" }} onClick={(e: any) => this.loadListSubTableData(e, row.original, propValue)}>{row.original[propValue]}</a>) : row.original[propValue]
                                                ),
                                                Footer: <span className="graphPop4Table">{totValue.toFixed(2)}</span>
                                            })
                                        }
                                        else {
                                            colDataArr.push({
                                                Visible: isVisible, Header: headerTitle, accessor: propValue,
                                                show: !isVisible, className: "wordwrap alignRight", headerClassName: "wordwrap",
                                                Cell: (row: any) => (
                                                    row.original[propValue].toString() !== "0" && row.original["in_organizername"].toString() !== "Sub Total" && is_superuser ?
                                                        (<a href="#section" className="graphPop4Table" style={{ textDecoration: "underline" }} onClick={(e: any) => this.loadListSubTableData(e, row.original)}>{row.original[propValue]}</a>) : row.original[propValue]
                                                )
                                            })
                                        }
                                    }
                                    else {
                                        if (this.props.rootFlag !== 18) {
                                            colDataArr.push({
                                                Visible: isVisible, Header: headerTitle, accessor: propValue,
                                                show: !isVisible, className: "wordwrap alignRight", headerClassName: "wordwrap",
                                                Footer: <span className="graphPop4Table">{totValue.toFixed(2)}</span>
                                            })
                                        }
                                        else {
                                            colDataArr.push({
                                                Visible: isVisible, Header: headerTitle, accessor: propValue,
                                                show: !isVisible, className: "wordwrap alignRight", headerClassName: "wordwrap"
                                            })
                                        }
                                    }
                                }
                                else if (( propValue === "in_organizername"||(this.props.rootFlag ===24 &&propValue === "provider"))){
                                    // if(windowWidth > 800 && (this.props.rootFlag === 1 || this.props.rootFlag === 2 || this.props.rootFlag === 3 || this.props.rootFlag === 8 || this.props.rootFlag === 9)){  
                                    //     colDataArr.push({width: 170, Visible: isVisible,Header: headerTitle,accessor: propValue,
                                    //         show: !isVisible,className: "wordwrap",headerClassName: "wordwrap",
                                    //         Footer: <span className="graphPop4Table">Grand Total</span>
                                    //         })
                                    // }
                                    // else{
                                    colDataArr.push({
                                        Visible: isVisible, Header: headerTitle, accessor: propValue,
                                        show: !isVisible, className: "wordwrap", headerClassName: "wordwrap",
                                        Footer: <span className="graphPop4Table">Grand Total</span>
                                    })
                                    //}
                                }
                                else {
                                    // if(windowWidth > 800 && (this.props.rootFlag === 1 || this.props.rootFlag === 2 || this.props.rootFlag === 3 || this.props.rootFlag === 8 || this.props.rootFlag === 9) 
                                    // && (propValue === "in_drawdate" || propValue === "in_drawtime")){  
                                    //     colDataArr.push({width: 115, Visible: isVisible,Header: headerTitle,accessor: propValue,
                                    //         show: !isVisible,className: "wordwrap",headerClassName: "wordwrap"})
                                    // }
                                    // else{
                                    colDataArr.push({
                                        Visible: isVisible, Header: headerTitle, accessor: propValue,
                                        show: !isVisible, className: "wordwrap", headerClassName: "wordwrap"
                                    })
                                    //}
                                }
                                //responsive block
                                // if(windowWidth > 800 && (propValue === "in_claimedamount" || propValue === "in_unclaimedamount" || propValue === "in_payout")){
                                //     cntIndex++;
                                // }
                                if (windowWidth < 600 && propValue === (this.props.rootFlag === 18 ? "in_betamount" : "in_organizername")) {
                                    cntIndex++;
                                }
                                else if (windowWidth > 600 && windowWidth <= 800 && (propValue === (this.props.rootFlag === 4 || this.props.rootFlag === 5 ? "in_winamount" : this.props.rootFlag === 12 ? "in_drawdate" : this.props.rootFlag === 19 ? "in_winamount" : "in_drawtime"))) {
                                    cntIndex++;
                                }
                                //responsive block
                            }
                            if (windowWidth > 800 || (windowWidth > 600 && windowWidth <= 800 && (this.props.rootFlag === 16 || this.props.rootFlag === 18))) {
                            }
                            else {
                                colDataArr.push({
                                    width: 50,
                                    Header: "View",
                                    accessor: "",
                                    //resizable: false,
                                    // sortable: false,
                                    className: "wordwrap",
                                    Footer:
                                        this.props.rootFlag === 18 ? null : (<div className="userLogBtnCont3" style={{ padding: 0 }}>
                                            <small>
                                                {/* <img key="tabPopupIF_0" id="tabPopupIFC_0" className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,null)}></img> */}
                                                <i key="tabPopupIF_0" id="tabPopupIFC_0" className="fa showPlusMinus fa-plus GreenBgg" style={{ borderRadius: "100px", padding: "5px", background: "#fff" }} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e, null)}></i>
                                            </small>
                                            <div key="tabPopupF_0" id="tabPopupFC_0" className="graphPop4 graphPop-1">
                                                <table key="tabPopupVF_0">
                                                    <tbody>
                                                        {
                                                            colDataArr.filter((elem: any) => elem.Visible).map((elem: any, index: number) => {
                                                                return (elem.Footer ? (<tr key={"tabIVIndex_" + index}>
                                                                    <td style={{ fontWeight: "bold" }} className="graphPop4Table">{elem.Header}</td>
                                                                    <td className="graphPop4Table">{elem.Footer}</td>
                                                                </tr>) : null)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                                {/* <img alt="" src={TooltipData}></img>	 */}
                                            </div>
                                        </div>),
                                    Cell: (row: any) => (
                                        <div className="userLogBtnCont3" style={{ padding: 0 }}>
                                            <small>
                                                {/* <img key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="tabPopupPNClass" alt="" src={PlusIcon} onClick={(e: any) => this.onShowLightBox(e,row.index)}></img> */}
                                                <i key={"tabPopupI_" + row.index} id={"tabPopupIC_" + row.index} className="fa showPlusMinus fa-plus GreenBgg" style={{ borderRadius: "100px", padding: "5px" }} aria-hidden="true" onClick={(e: any) => this.onShowLightBox(e, row.index)}></i>
                                            </small>
                            {/* all report table */}
                                            <div key={"tabPopup_" + row.index} id={"tabPopupC_" + row.index} className="graphPop4 graphPop-1">
                                                <Tablistview keys={"tabPopupV_" + row.index} rowData={row.original} colData={this.state.aGWReportsTab.colData}
                                                    loadListSubTableData={this.loadListSubTableData.bind(this)} isHyperLink={true} rootFlag={this.props.rootFlag} />
                                                {/* <img alt="" src={TooltipData}></img>	 */}
                                            </div>
                                        </div>
                                    )
                                });
                            }
                        }
                        else {
                            errText = "No Data Available"
                        }
                    }
                    else {
                        errText = res.data.result.toString();
                    }
                    //$("#templaTBlid").attr("style", "display:none") 
                    if (errText === undefined || errText === "") {
                        initialState.aGWReportsTab.colData = colDataArr;
                        initialState.rptGenDate = rptGenDt;
                        let resChartData: any = null;
                        if (this.props.rootFlag !== 18) {
                            resChartData = this.loadChartData(rowDataArr, colDataArr, this.state.aGWReportsDrop.OrganizerName.value === 0 || this.state.aGWReportsDrop.gameTypeId.value === 0
                                || this.props.rootFlag === 4 || this.props.rootFlag === 12 ? "column" : "pie");
                        }
                        if (resChartData !== null && resChartData.length > 0) {
                            initialState.aGWReportsCt.options = resChartData[0];
                            initialState.aGWReportsCt.series = resChartData[1];
                            if (resChartData[2] !== null) {
                                rowDataArr = resChartData[2];
                            }
                            initialState.aGWReportsCt.options1 = resChartData[3];
                            initialState.aGWReportsCt.series1 = resChartData[4];
                            initialState.aGWReportsCt.chartTitle = resChartData[5];
                            initialState.aGWReportsCt.chartType = resChartData[6];
                        }
                        else {
                            rowDataArr.forEach((elem: any, index: number) => { elem.in_sno = index + 1; });
                        }
                        //Grand Total- Start
                        if (rowDataArr !== null && rowDataArr.length > 0) {
                            let newObj: any = {};
                            colDataArr.forEach((elem: any) => {
                                if (elem.accessor !== "View" && elem.accessor !== "#") {
                                    if (elem.Footer)
                                        Object.assign(newObj, { [elem.accessor]: elem.Footer.props.children });
                                    else
                                        Object.assign(newObj, { [elem.accessor]: "" });
                                }
                                return elem;
                            });
                            rowDataArr.push(newObj);
                        }
                        //Grand Total- End
                        initialState.aGWReportsTab.tabData = rowDataArr;
                    }
                    initialState.aGWReportsTab.filteredData = [];
                    initialState.aGWReportsTab.searchInput = "";
                    initialState.succFlag = undefined;
                    initialState.errMsg = errText;
                    $("#divLoading").attr("style", "display:none")
                    this.setState(initialState);
                    if (errText !== undefined && errText !== "") {
                        $('.overlay').fadeIn();
                        $('#lightBox2').fadeIn();
                    }
                    return
                })
                .catch((err:any) => {
                    console.log("AXIOS ERROR: ", err.response);
                    $("#divLoading").attr("style", "display:none")
                    this.setState({ succFlag: undefined, errMsg: "No Data Available" });
                    $('.overlay').fadeIn();
                    $('#lightBox2').fadeIn();
                    return false
                })
        }
        catch (ex) {
            alert("Load List Data Failed..");
            return
        }
    }
    //load chart details
    loadChartData = (rowDataArr: any, colDataArr: any, chartType: any) => {
        try {
            let series: any = []; //column
            let series1: any = []; //pie
            let chartTitle: any = "";
            let chartHeadArr: any = [];
            let resChartDet: any = [];
            let chartOptions: any = null; //column
            let chartOptions1: any = null; //pie
            const chartEnableFields: any = CommonReports.getChartEnableFieldsArr(this.props.rootFlag);
            colDataArr.filter((elem: any) => chartEnableFields.includes(elem.accessor)).map((elem: any) => {
                if (elem.Footer) {
                    const chartTit: any = CommonReports.getTableTitle(elem.accessor);
                    //if(chartType === "pie"){
                    series1.push(parseFloat(elem.Footer.props.children));
                    chartHeadArr.push(chartTit);
                    //}
                    chartTitle += (chartTit) + " VS ";
                }
                return elem;
            })
            const groupbyVal: any = this.props.rootFlag === 12 ? "in_gamename" : this.props.rootFlag === 4 || this.props.rootFlag === 5 ? "in_gamegroupname" : "in_organizername";
            resChartDet = this.loadSubCountValues(rowDataArr, groupbyVal, chartEnableFields)
            //if(chartType !== "pie"){               
            series = resChartDet[1];
            chartOptions = {
                chart: {
                    type: chartType,
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        }
                    }
                },
                stroke: {
                    show: true,
                    curve: 'smooth',
                    width: 5
                },
                markers: {
                    size: 5
                },
                title: { text: "" },
                xaxis: { categories: resChartDet[0] }
            }
            //}      
            //else{ 
            chartOptions1 = {
                chart: {
                    type: "pie",
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        }
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 280
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                title: { text: "" },
                labels: chartHeadArr
            }
            //}
            return [chartOptions, series, resChartDet[2], chartOptions1, series1, chartTitle.substring(0, chartTitle.length - 4), chartType]; //chartType !== "pie" ? resChartDet[2] : null
        }
        catch (ex) {
            alert("Load Chart Data Failed..");
            return
        }
    }
    // chart change properties
    chartChangeClick = (chartType: string) => {
        const resVals: any = this.state;
        if (chartType !== "pie") {
            resVals.aGWReportsCt.options.chart.type = chartType;
            resVals.aGWReportsCt.series.forEach((elem: any) => {
                elem.type = chartType;
                return elem;
            });
        }
        resVals.aGWReportsCt.chartType = chartType;
        this.setState(resVals);
        return
    }
    //load calculate sub count details
    loadSubCountValues = (resultVal: any, groupVal: any, filterColArr: any) => {
        try {
            const getGrpSubCountFlds: any = CommonReports.getGrpbySubcountFields();
            let arrXVal: any = [];
            var groupByOrg: any = this.state.aGWReportsDrop.OrganizerName.value === 0 ?
                _.groupBy(resultVal, function (item) {
                    return item["in_organizername"];
                }) :
                _.groupBy(resultVal, function (item) {
                    return item[groupVal];
                });
            var resultSubVal: any = [];
            let indexSno: number = 0;
            const resData = _(groupByOrg)
                .map((platform: any, id: any) => {
                    platform.forEach((elem: any) => { indexSno++; elem.in_sno = indexSno; });
                    let newObj: any = {};
                    Object.entries(resultVal[0]).forEach(([key, value]) => {
                        if (getGrpSubCountFlds.includes(key)) {
                            let resValue: any = _.sumBy(platform, key);
                            if (key === "in_payout") {
                                resValue = (_.sumBy(platform, "in_winamount") / _.sumBy(platform, "in_betamount")) * 100;
                            }
                            else if (key === "in_netamount") {
                                resValue = (_.sumBy(platform, "in_betamount") - _.sumBy(platform, "in_winamount"));
                            }
                            Object.assign(newObj, { [key]: key === "in_transcount" ? resValue : resValue.toFixed(2) });
                        }
                        // subtotal added based on key in table
                        else if (key === "in_transactionid" || key === "in_organizername") {
                            Object.assign(newObj, { [key]: "Sub Total" });
                        }
                        // else if(key === groupVal){
                        //     Object.assign(newObj, {[key]: id});
                        // }
                        else {
                            Object.assign(newObj, { [key]: "" });
                        }
                    });
                    resultSubVal = [...resultSubVal, ...platform];
                    //Sub count logic- Start       
                    if ((this.state.aGWReportsDrop.OrganizerName.value === 0 || this.props.rootFlag === 12) && this.props.rootFlag !== 19) {
                        resultSubVal.push(newObj);
                    }
                    //Sub count logic- End 
                    arrXVal.push(id);
                    return newObj;
                }).value();
            //Chart Data- Start
            let arrYVal: any = [];
            for (let col of filterColArr) {
                let arrVal = [];
                for (let ele of resData) {
                    arrVal.push(parseFloat(ele[col].toString()));
                }
                arrYVal.push({ name: CommonReports.getTableTitle(col), data: arrVal, type: "column" })
            }
            //Chart Data- End 
            return [arrXVal, arrYVal, resultSubVal];
        }
        catch (ex) {
            alert("Load Chart Data Failed..");
            return
        }
    }
    //show hide lightbox 1
    onShowLightBox = (e: any, index: any) => {
        e.preventDefault();
        const currDisplay: any = index === null ? $('#tabPopupFC_0').css('display') : $('#tabPopupC_' + index).css('display');
        // $('.fa.fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        // $('.fa-minus').addClass('fa-plus').removeClass('fa-minus'); 
        $('.showPlusMinus').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');
        $('.graphPop4').css({ display: "none" })
        if (index !== null) {
            $('#tabPopupIC_' + index).attr('class', (currDisplay === "none" ? "fa showPlusMinus fa-minus RedBgg" : "fa showPlusMinus fa-plus GreenBgg"));
            $('#tabPopupC_' + index).css({ display: currDisplay === "none" ? "block" : "none" })
        }
        else {
            $('#tabPopupIFC_0').attr('class', (currDisplay === "none" ? "fa showPlusMinus fa-minus RedBgg" : "fa showPlusMinus fa-plus GreenBgg"));
            $('#tabPopupFC_0').css({ display: currDisplay === "none" ? "block" : "none" })
        }
        return
    }
    //show hide lightbox 2
    onShowLightBox1 = (e: any, index: any) => {
        e.preventDefault();
        const currDisplay: any = index === null ? $('#tabPopupFC0_0').css('display') : $('#tabPopupC0_' + index).css('display');
        // $('.fa.fa-minus').addClass('GreenBgg').removeClass('RedBgg');     
        // $('.fa-minus').addClass('fa-plus').removeClass('fa-minus');
        $('.showPlusMinus1').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');
        $('.graphPop6').css({ display: "none" })
        if (index !== null) {
            $('#tabPopupIC0_' + index).attr('class', (currDisplay === "none" ? "fa showPlusMinus1 fa-minus RedBgg" : "fa showPlusMinus1 fa-plus GreenBgg"));
            $('#tabPopupC0_' + index).css({ display: currDisplay === "none" ? "block" : "none" })
        }
        else {
            $('#tabPopupIFC0_0').attr('class', (currDisplay === "none" ? "fa showPlusMinus1 fa-minus RedBgg" : "fa showPlusMinus1 fa-plus GreenBgg"));
            $('#tabPopupFC0_0').css({ display: currDisplay === "none" ? "block" : "none" })
        }
        return
    }
    //export datas xls..,csv..,print
    exportXLPdfPrintdownload = (e: any, fileType: any) => {
        try {
            e.preventDefault();
            if (fileType === "chart") {
                $('.overlay').fadeIn();
                $('#lightBox4').fadeIn();
                return
            }
            else {
                const rootFlag: number = this.props.rootFlag;
                const pageTitle: any = CommonReports.getPageTitle(rootFlag);
                const fileName: string = pageTitle.replaceAll(' ', '');
                const genTime: any = this.state.rptGenDate.toString();
                const Orgdata: any = this.state.aGWReportsTab.tabData;
                if (fileType === "pdf") {
                    CommonReports.exportPDF(Orgdata, pageTitle, fileName, this.props.rootFlag, genTime);
                }
                else if (fileType === "csv" || fileType === "xls") {
                    CommonReports.exportExcel(Orgdata, fileName, fileType, this.props.rootFlag, genTime);
                }
                else {
                    CommonReports.exportPrint(Orgdata, pageTitle, this.props.rootFlag, genTime);
                }
            }
            return
        }
        catch (ex) {
            alert("Export Failed..");
            return
        }
    }
    // And in your global search
    globalSearch = (e: any) => {
        try {
            let initialState: any = this.state;
            const searchInput: string = e.target.value;
            const skipGT: any = initialState.aGWReportsTab.tabData.filter((elem: any, index: number) => index !== initialState.aGWReportsTab.tabData.length - 1);
            let filteredData = skipGT.filter((value: any) => {
                return CommonReports.getKeyByValue(value, searchInput, this.props.rootFlag);
            });
            initialState.aGWReportsTab.filteredData = filteredData;
            initialState.aGWReportsTab.searchInput = searchInput;
            initialState.succFlag = undefined;
            initialState.errMsg = "";
            this.setState(initialState);
            return
        }
        catch (ex) {
            alert("Load Search filter List Load Failed..");
            return
        }
    };
    /* Date change event */
    handleDateChange = (date: any, key: string, format: string) => {
        try {
            let initialState: any = this.state;
            const resVal: any = initialState.aGWReportsDt;
            resVal[key].defValue = moment(date._d);
            resVal[key].value = moment(date._d).format(format);
            //resVal.TdrawDate.display = false;
            resVal.FdrawDate.display = resVal.FdrawDate.value === null ? true : resVal.TdrawDate.value === null ? false : (resVal.TdrawDate.value !== null && moment(resVal.FdrawDate.value, format) > moment(resVal.TdrawDate.value, format))
            resVal.TdrawDate.display = resVal.TdrawDate.value !== null ? false : true;
            initialState.aGWReportsDt = resVal;
            initialState.aGWReportsTab.filteredData = [];
            initialState.aGWReportsTab.searchInput = "";
            initialState.aGWReportsTab.tabData = [];
            initialState.aGWReportsTab.tabDataSub = [];
            initialState.aGWReportsCt.options = null;
            initialState.aGWReportsCt.series = null;
            initialState.aGWReportsCt.options1 = null;
            initialState.aGWReportsCt.series1 = null;
            initialState.aGWReportsCt.chartTitle = "";
            initialState.aGWReportsCt.chartType = "";
            initialState.succFlag = undefined;
            initialState.errMsg = "";
            this.setState(initialState);
        }
        catch (ex) {
            alert("On Date Change Failed..");
            return
        }
    };
    /* Drop down change Print preview */
    handleDropChange = (e: any, type: number) => {
        try {
            const resVals: any = this.state;
            resVals.aGWReportsTab.filteredData = [];
            resVals.aGWReportsTab.searchInput = "";
            resVals.aGWReportsTab.tabData = [];
            resVals.aGWReportsTab.tabDataSub = [];
            resVals.aGWReportsCt.options = null;
            resVals.aGWReportsCt.options1 = null;
            resVals.aGWReportsCt.series1 = null;
            resVals.aGWReportsCt.chartTitle = "";
            resVals.aGWReportsCt.chartType = "";
            resVals.aGWReportsCt.series = null;
            resVals.succFlag = undefined;
            resVals.errMsg = "";
            if (type === 1) {
                resVals.aGWReportsDrop.OrganizerName.value = e.value;
                resVals.aGWReportsDrop.OrganizerName.display = false;
                //resVals.aGWReportsDrop.OrganizerName.userId = resVals.aGWReportsDrop.OrganizerNames.filter((option: any) => option.value.toString() === e.value.toString())[0].userId
            }
            else if (type === 3) {
                resVals.aGWReportsDrop.gameTypeId.value = e.value;
                resVals.aGWReportsDrop.gameTypeId.display = false;
            }
            this.setState(resVals)
            return
        }
        catch (ex) {
            alert("On Dropdown Change Failed..");
            return
        }
    }
    handleToggleClick = (e: any) => {
        try {
            e.preventDefault();
            const resVals: any = this.state;
            resVals.aGWReportsTab.filteredData = [];
            resVals.aGWReportsTab.searchInput = "";
            resVals.aGWReportsTab.tabData = [];
            resVals.aGWReportsCt.options = null;
            resVals.aGWReportsCt.series = null;
            resVals.aGWReportsCt.options1 = null;
            resVals.aGWReportsCt.series1 = null;
            resVals.aGWReportsCt.chartTitle = "";
            resVals.aGWReportsCt.chartType = "";
            resVals.succFlag = undefined;
            resVals.errMsg = "";
            resVals.aGWReportsRadio.isSelSwitch = resVals.aGWReportsRadio.isSelSwitch === 1 ? 0 : 1;
            this.setState(resVals);
        }
        catch (ex) {
            alert("On Change Toggle button updation Failed..");
            return
        }
    }
    render() {
        const { t } = this.props;
        const resVal: any = this.state;
        const themeClr = CommonReports.ThemeColour()
        const pageSize: number = resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 50 ? 50 : resVal.aGWReportsTab.tabData.length > 25 ? 25 : 10;
        const defaultMaterialTheme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: themeClr,
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
        });
        const DropdownIndicator = (props: any) => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <FontAwesomeIcon style={{ fontSize: "20px" }} icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"} />
                    </components.DropdownIndicator>
                )
            );
        };
        const styles = {
            menu: (base: any) => ({
                ...base,
                marginTop: 0
            })
        };
        let is_superuser: any = null;
        const userInfoString: any = sessionStorage.getItem('userInfo');
        const userInfo: any = JSON.parse(userInfoString);
        const headerTitle: any = CommonReports.getPageTitle(this.props.rootFlag);
        if (userInfo !== null)
            is_superuser = userInfo["is_superuser"];
        return (
            <div className="ContainerChart scrollbar" style={{ height: $(window).height() - 60 + "px" }} onClick={(e: any) => config.onBodyClick(e)}>
                <ListChart
                    options={resVal.aGWReportsCt.options}
                    series={resVal.aGWReportsCt.series}
                    options1={resVal.aGWReportsCt.options1}
                    series1={resVal.aGWReportsCt.series1}
                    chartType={resVal.aGWReportsCt.chartType}
                    chartTitle={resVal.aGWReportsCt.chartTitle}
                    chartChangeClick={this.chartChangeClick.bind(this)}
                />
                <ListSubTable
                    tabData={resVal.aGWReportsTab.tabDataSub}
                    colData={resVal.aGWReportsTab.colDataSub}
                    rptGenDateSub={resVal.rptGenDateSub}
                    rootFlag={this.props.rootFlag}
                    isSelSwitch={resVal.aGWReportsRadio.isSelSwitch}
                    sorting={false}
                    ref={this.subTabStateReset}
                />
                <Lightbox errMsg={resVal.errMsg} succFlag={resVal.succFlag} />
                <div className="contentclouds">
                    <div className="userLogHead">
                        <div className="userLogHeadinner">
                            <h2>{headerTitle}</h2>
                            <small>{t('lblViewSonalDetails')}</small>
                        </div>
                        <Breadcrumb Menu="Reports" subMenu={headerTitle} />
                    </div>
                    <div className="UseformNew">
                        <div className="Dateform">
                            {this.props.rootFlag === 4 || this.props.rootFlag === 5 || this.props.rootFlag === 18 || this.props.rootFlag === 19 ?
                                (<div className="toogleSwitch">
                                    <label className="toggleSwitch nolabel" onClick={(e: any) => this.handleToggleClick(e)}>
                                        <input type="checkbox" id="#toggleIds" checked={resVal.aGWReportsRadio.isSelSwitch === 1} onChange={(e: any) => this.handleToggleClick(e)} />
                                        <span>
                                            <span>{t('lblSaledate')}</span>
                                            <span>{t('lblDrawDate')}</span>
                                        </span>
                                        <a href="#section"> </a>
                                    </label>
                                </div>) : null}
                            <div className="userLogformTop">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <ul>
                                        {is_superuser !== null && is_superuser ? (
                                            <li style={{ width: this.props.rootFlag === 5 ? "25%" : "33.33333%" }}>
                                                <div className="userLogCloudtop">
                                                    <Select
                                                        value={resVal.aGWReportsDrop.OrganizerName.value === null ? null : resVal.aGWReportsDrop.OrganizerNames.filter((option: any) => option.value.toString() === resVal.aGWReportsDrop.OrganizerName.value.toString())}
                                                        onChange={(e: any) => { this.handleDropChange(e, 1) }}
                                                        //isDisabled={this.props.rowData === null ? false : true}
                                                        placeholder={t('lblSelectName')}
                                                        options={resVal.aGWReportsDrop.OrganizerNames}
                                                        isDisabled={this.props.rootFlag === 18 ? true : false}
                                                        // maxMenuHeight={110}
                                                        className="userlogSelect"
                                                        components={{ DropdownIndicator }}
                                                        styles={styles}
                                                    />
                                                    <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsDrop.OrganizerName.display ? "block" : "none" }}>{t('lblOrgNameProvided')}</label>
                                                </div>
                                            </li>) : null}
                                        <li style={{ width: is_superuser !== null && is_superuser && this.props.rootFlag === 5 ? "25%" : "33.33333%" }}>
                                            {/* is_superuser !== null && is_superuser ? "33.33333%" : "50%" */}
                                            <div className="userLogCloudtop">
                                                <ThemeProvider theme={defaultMaterialTheme}>
                                                    <DatePicker
                                                        placeholder={t('lblPickFromdate')}
                                                        className="rptdatePicker"
                                                        format="DD-MM-yyyy"
                                                        value={resVal.aGWReportsDt.FdrawDate.defValue}
                                                        onChange={(date: any) => this.handleDateChange(date, "FdrawDate", "DD-MM-yyyy")}
                                                    />
                                                </ThemeProvider>
                                                <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsDt.FdrawDate.display ? "block" : "none" }}>{resVal.aGWReportsDt.FdrawDate.value === null ? t('lblFromDrawDateProvided') : t('lblFromDrawGreater')}</label>
                                            </div>
                                        </li>
                                        <li style={{ width: is_superuser !== null && is_superuser && this.props.rootFlag === 5 ? "25%" : "33.33333%" }}>
                                            <div className="userLogCloudtop">
                                                <ThemeProvider theme={defaultMaterialTheme}>
                                                    <DatePicker
                                                        placeholder={t('lblPickToDate')}
                                                        className="rptdatePicker"
                                                        format="DD-MM-yyyy"
                                                        value={resVal.aGWReportsDt.TdrawDate.defValue}
                                                        onChange={(date: any) => this.handleDateChange(date, "TdrawDate", "DD-MM-yyyy")}
                                                    />
                                                </ThemeProvider>
                                                <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsDt.TdrawDate.display ? "block" : "none" }}>{t('lblToDrawDateProvided')}</label>
                                            </div>
                                        </li>
                                        {this.props.rootFlag === 5 ?
                                            (<li style={{ width: is_superuser !== null && is_superuser ? "25%" : "33.33333%" }}>
                                                <div className="userLogCloudtop">
                                                    <Select
                                                        value={resVal.aGWReportsDrop.gameTypeId.value === null ? null : resVal.aGWReportsDrop.gameType.filter((option: any) => option.value.toString() === resVal.aGWReportsDrop.gameTypeId.value.toString())}
                                                        onChange={(e: any) => { this.handleDropChange(e, 3) }}
                                                        placeholder={t('lblSelectGame')}
                                                        options={resVal.aGWReportsDrop.gameType}
                                                        // maxMenuHeight={110}
                                                        className="userlogSelect"
                                                        components={{ DropdownIndicator }}
                                                        styles={styles}
                                                    />
                                                    <label style={{ color: "#ff0000", fontWeight: "bold", fontSize: "12px", display: resVal.aGWReportsDrop.gameTypeId.display ? "block" : "none" }}>{t('lblGameProvided')}</label>
                                                </div>
                                            </li>) : null}
                                    </ul>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="userLogBtnTop">
                                <button id="registerBtn" onClick={(e: any) => this.onFilterListTableData(e)}><i className="fa fa-check" style={{ marginRight: "5px" }} aria-hidden="true"></i>{t('lblSubmit')}</button>
                                <button id="registerBtn1" style={{ float: "right" }} onClick={this.resetValues.bind(this)}><i className="fa fa-sync" style={{ marginRight: "5px" }} aria-hidden="true"></i>{t('lblReset')}</button>
                            </div>
                        </div>
                        {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ?
                            (<div className="TblError">
                                <span> {t('lblReportGenOn')}<b>{resVal.rptGenDate}</b></span>
                            </div>) : null}
                        {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ?
                            (<div className="ContainerTable">
                                <div className="tableSearchreport">
                                    <div className="sportSrch">
                                        <input type="text" placeholder={t('lblSearch')} id="myInput" value={resVal.aGWReportsTab.searchInput} onChange={(e: any) => this.globalSearch(e)} autoComplete="off" />
                                    </div>
                                </div>
                                <div className="ButtonArea">
                                    {this.props.rootFlag !== 18 ?
                                        (<button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e, 'chart')}>
                                            <a href="#section"><i className="fas fa-chart-bar" style={{ marginRight: "5px", fontSize: "16px", color: "#f6ae00" }}></i>Chart</a>
                                        </button>) : null}
                                    <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e, 'pdf')}>
                                        <a href="#section"><i className="fas fa-file-export" style={{ marginRight: "5px", fontSize: "16px", color: "#f6ae00" }}></i>PDF</a>
                                    </button>
                                    {/* <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e,'csv')}>
						<a href="#section"><i className="fas fa-file-excel" style={{marginRight: "5px",fontSize: "16px",color: "#f6ae00"}}></i>CSV</a>
					</button> */}
                                    <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e, 'xls')}>
                                        <a href="#section"><i className="fas fa-file-excel" style={{ marginRight: "5px", fontSize: "16px", color: "#f6ae00" }}></i>Excel</a>
                                    </button>
                                    <button className="ButtonDoc" type="button" onClick={(e: any) => this.exportXLPdfPrintdownload(e, 'print')}>
                                        <a href="#section"><i className="fas fa-print" style={{ marginRight: "5px", fontSize: "16px", color: "#f6ae00" }}></i>Print</a>
                                    </button>
                                </div>
                            </div>) : null}
                        {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ? (
                            <div className="TableReact">
                                <ListTable
                                    sorting={false}
                                    columns={resVal.aGWReportsTab.colData}
                                    pageSize={pageSize}
                                    data={resVal.aGWReportsTab.filteredData && resVal.aGWReportsTab.searchInput !== "" ? resVal.aGWReportsTab.filteredData : resVal.aGWReportsTab.tabData.filter((elem: any, index: number) => index !== resVal.aGWReportsTab.tabData.length - 1)}
                                />
                            </div>) : null}
                    </div>
                </div>
                {/* <div className="chartdiv"> <span className="noDataCenter">No Data Available</span>
            {resVal.aGWReportsTab !== undefined && resVal.aGWReportsTab.tabData !== undefined && resVal.aGWReportsTab.tabData.length > 0 ? (
                <ListChart
                options= {resVal.aGWReportsCt.options}
                series= {resVal.aGWReportsCt.series}
                chartType= {resVal.aGWReportsCt.chartType}
                />) : null}
            </div>  */}
            </div>
        );
    }
}

export default withTranslation()(AGWHReports);