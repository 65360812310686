import React from 'react';

// React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

export default class ListTable extends React.Component {
    constructor(props) {
      super(props); 
      this.state = {
      };
    }
    render() {
      return(
        <ReactTable
            columns={this.props.columns}
            defaultPageSize={this.props.pageSize}
            showPaginationBottom={true}
            className="-striped -highlight"
            data={this.props.data}  
            resizable={false}   
            sortable={this.props.sorting}    
            minRows= {0} 
            pageSizeOptions= {
              this.props.data.length > 50 ? [5, 10, 25, 50] :
              this.props.data.length > 25 ? [5, 10, 25] : [5, 10]
            }
            showPagination= {this.props.data.length > 10 ? true : false}
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo !== undefined) {
                return {
                  className: rowInfo.original["in_organizername"] === "Sub Total" || rowInfo.original["in_transactionid"] === "Sub Total" ? "SubTotalBg" : ""
                };
              }
            }}
        />                  
      );
  }
}