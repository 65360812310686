import { t } from "i18next";

var $ = require('jquery')
//event triggers on overlay div click
function closePopup() {
    $('.overlay').fadeOut();
    $('#lightBox2').fadeOut();
  } 
function Lightbox(errMsg: any) { 
    return(
        <div id="lightBox2" className="lightBox2" style={{marginLeft: '-'+ ($('#lightBox2').width() / 2) +'px',marginTop: '-'+ ($('#lightBox2').height() / 2) +'px'}}>	
        {errMsg["succFlag"] !== undefined && errMsg["succFlag"] === 0 ? 
        (<div className="userLog sucess">
        <div className="sucessmsg">
        <span><i className="fa fa-check" style={{fontSize: "25px",color: "#fff"}} aria-hidden="true"></i></span>
        </div>	
            <div className="enqForm">
                <h2>{t('lblsuccess')}</h2>
                <span>{errMsg["errMsg"]}</span>
                <div className="forCenter">
                    <div className="sucessmsgBtn">						
                        <button id="registerBtn"  onClick= {(e:any) => closePopup()}>OK</button>		
                    </div>
                </div>									
            </div>
        </div>) : 
        (<div className="userLog warning">
        <div className="warningmsg"><span><i style={{fontSize: "25px",color: "#fff"}} className="fas fa-exclamation-triangle"></i></span></div>	
                <div className="enqForm">
                    <h2>{t('lblWarning')}</h2>
                    <span>{errMsg["errMsg"]}</span>
                    <div className="forCenter">
                        <div className="warningBtn">						
                            <button id="registerBtn1" onClick= {(e:any) => closePopup()}>{t('lblClose')}</button>		
                        </div>
                    </div>									
                </div>
        </div>)}
    </div>
    ); 
}

export default Lightbox;
