import React from "react";
import apiHelper from "../../../utils/ApiHelper"
import { Line } from "react-chartjs-2";
import useAxios from "axios";
import axios from 'axios';
import moment from "moment"
import config from "../../../utils/config";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Filler
} from 'chart.js';
import { t } from "i18next";
var $ = require('jquery')
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler
);
export interface IProps {
    // pupUpOpenClick: any,
    // flog: number //to show date or hour based report
    startdate:any,
    enddate:any,
    gameID:any,
    organizername:any
}
 export interface IState {
    //createOrganizer: any
    chartData: any,
    myTitle:any
}
const labels =  ['21 Oct', '22 Oct', '23 Oct', '24 Oct', '25 Oct', '26 Oct','27 Oct'];
const DayChartdata:any = {
  labels: labels,
  datasets: [
    {
      label: 'SALE',
      fill: false,
      backgroundColor:'rgb(53, 12, 135)',
      borderColor:'rgba(53, 12, 135, 0.5)',
       data:[100,20,200,30,100,40,100 ] ,
    }, {
      label: 'WINNING',
      fill: false,
      backgroundColor:'rgb(53, 12, 235)',
      borderColor: 'rgba(53, 12, 235, 0.5)',
      borderDash: [5, 5],
      data:[10,20,150,50,170,10,10 ],
    }, {
      label: 'PAYOUT',
      backgroundColor: '#FF1493',
      borderColor:' #FF69B4',
      data:[40,40,10,150,10,70,10 ],
      fill:true
      }
  ]
  };

const options: any = {
  type: 'line',
  data:DayChartdata,
    responsive: true,
      plugins: {
        filler: {
          propagate: true
      },

       legend:{
        position:'top' ,
       },
      
     },
    interaction: {
      mode: 'index',
      intersect: false
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text:"Date"
           
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Amount'
        }
      }
    
  },
};
 
//Channel-Wise-Sale
class Oswp extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            chartData: { labels: [], datasets: [] } ,            
            myTitle: "Provider Wise Sales By Day"
        };
        options.onClick = this.handleClick;
    }

     initData = () => {
      let Gameid = typeof (this.props.gameID) === 'string' ? (this.props.gameID).split('_')[0] : 0;
        //_fg=> 0-datebased|1-houre based
        const callJsonHead: any = {
            organizerID: this.props.organizername,
            FromDate:moment(this.props.startdate).format('YYYY-MM-DD') + " 00:00:00",
            ToDate: moment(this.props.enddate).format('YYYY-MM-DD') + " 00:00:00",         
            GameId:Gameid,
           // Status:0  //0-daywise|1-week wise
        }
        //mani 22/12
        //useAxios.get(config.Url("Overall-Sale_winning", "get"), config.authHeaderwithParamRpt(callJsonHead))
        apiHelper.getAPICall("Overall-Sale_winning", "get",5,callJsonHead)
         .then((res)=> {
                console.log("RESPONSE RECEIVED: Overall-Sale_winning", res);
              if (res !== null && res.data !== null && res.data.result === undefined) {
                    let xAxis: any = [];
                    let ydatasets: any = [];
                    let tempArr: any = [];
                    let tempArr1: any = [];
                    let tempArr2: any = [];
                    let yname: number = 0;
                    let colDataArr: any = res.data["get_rptDetails"]; 
                     xAxis =  colDataArr.map((item:any) =>item.in_drawdate).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                   
                    const yAxis =  colDataArr.map((item:any) => item.in_organizername).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
                    yAxis.forEach(( fobj: any) => {
                        xAxis.forEach((fdate: any) => {
                            const objLst = colDataArr.filter((item: any) => { return item.in_organizername == fobj && item.in_drawdate == fdate });
                            if (objLst.length > 0)
                              {
                                  tempArr.push(objLst[0].in_betamount);
                                  tempArr1.push(objLst[0].in_winamount);
                                  tempArr2.push(objLst[0].in_payount);
                              }
                        else{
                            tempArr.push(0);
                            tempArr1.push(0);
                            tempArr2.push(0);
                          }
                        });
                        ydatasets.push(
                            {
                                label:"Sale",//yAxis[yname],
                                fill: false,
                                backgroundColor:'rgb(53, 12, 135)',
                                borderColor:'rgba(53, 12, 135, 0.5)',
                                data: tempArr,
                                tension: 0.2,
                                pointStyle: 'circle',
                                pointRadius: 4,
                            },
                            {
                              label:"Winning",//yAxis[yname],
                              fill: false,
                              backgroundColor:'rgb(53, 12, 235)',
                              borderColor: 'rgba(53, 12, 235, 0.5)',
                              data: tempArr1,
                              borderDash: [5, 5],
                              pointStyle: 'circle',
                              pointRadius: 4,
                          },
                          {
                            label:"PayOut",//yAxis[yname],
                            fill: true,
                            backgroundColor: '#FF1493',
                            borderColor:' #FF69B4', 
                            data: tempArr2,
                            tension: 0.2,
                            pointStyle: 'circle',
                            pointRadius: 4,
                        },
                                )
                            yname++;
                        tempArr = [];
                        tempArr1 = [];
                        tempArr2 = [];
                       
                     }
                    )
                    this.setState({ chartData: { labels: xAxis, datasets: ydatasets } });
                    //return  { labels: xAxis, datasets: ydatasets } ;
                }
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err.response);
                return  { labels:[], datasets:[] } ;
            })
    }
    componentDidMount(): void {
        this.initData();
        return;
     }
    componentDidUpdate(prevProps:any) {
      if (prevProps.startdate !== this.props.startdate) {
          this.initData()
      }
    }
   handleClick = (e: any, i: any) => {
        debugger;
        let inx: any = i[0].index;
         let ds =  this.state.chartData.labels[inx];
         var obj = {
             Id: ds,
             fromDate: "",
             toDate: ""
         }
       //this.props.pupUpOpenClick(obj);
    }
render(): React.ReactNode {
     return(
        <div>
        <h4>{t('lblOverallSaleWinPayout')} </h4>
        {/* <Line options={options}  data={DayChartdata} /> */}
        <Line options={options}  data={this.state.chartData} /> 
        </div>
    )
}
};

export default Oswp;;;