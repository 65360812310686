import React,{useEffect,useState} from 'react';
import useAxios from "axios";
import config from "../../../utils/config";
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, LinearProgress } from '@material-ui/core'; 
import axios from 'axios';
import moment from "moment"
import { t } from 'i18next';
import apiHelper from '../../../utils/ApiHelper';
 
 
interface IProps  {
  startdate:any,
  enddate:any,
  //gameiddate:any
  gameID:any,
  orgID:any
   }
   
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 500,
  },
  progress: {
   
    height: '8px',
    borderRadius: '10px',
  },
  difference: {
    
    display: 'flex',
    alignItems: 'center',
  },
  differenceIcon: {
    color:  'rgba(161,10,217)',
  },
   
  green: {
    color: 'rgba(247,244,48)',
  },
  red: {
    color: 'rgba(255, 99, 132, 1)',
  },
  icon:{
    color:"#FFFFFF",
    fontSize:"50px",
    float:"right",
    display:"inline"
      },
      value:{
    color:"#FFFFFF",
    fontWeight: "bold",
    display:"inline"
      },
      custom:{
    color:"#FFFFFF",
    fontWeight: "bold"
      },
}));
 
const Chartkpi: React.FC<IProps> = props => {
 const classes = useStyles();
 const [Betamount, setData] = React.useState();
 const [Winamount, setData1] = React.useState();
 const [profit, setData2] = React.useState();
 const [payount, setData3] = React.useState();
 
React.useEffect(()=>{
//?organizerID=0&FromDate=2022-11-01%2000:00:00&ToDate=2022-11-16%2000:00:00&GameId=0
getchartkpi();

},[props])

 const getchartkpi  =()=>{
  const gameID = typeof (props.gameID) === 'string' ? (props.gameID).split('_')[0] : '0';
  
let callMethod: string = "";
let callJsonHead: any = null;
callMethod = "rpt-Details";
  callJsonHead = {
    organizerID:props.orgID,
    FromDate: moment(props.startdate).format('YYYY-MM-DD') + " 00:00:00",
    ToDate:   moment(props.enddate).format('YYYY-MM-DD') + " 00:00:00",
       
    GameId:gameID
     };
     //useAxios.get(config.Url(callMethod, "get"), config.authHeaderwithParamRpt(callJsonHead))
     apiHelper.getAPICall(callMethod, "get",5,callJsonHead)
   .then((res)=>{
      if(res.data["get_rptDetails"] !== null && res.data["get_rptDetails"].length !== 0){                         
       const resData: any = res.data["get_rptDetails"];
       for(let i=0;i<resData.length;i++){
        setData(resData[i].in_betamount);
        setData1(resData[i].in_winamount);
        setData2(resData[i].in_profit);
        setData3(resData[i].in_payount);
        return
          }
        }
      })
  .catch((error) => console.error(error))
}



const cards = [
  {
    title: 'SALES',
    icon:"fas fa-dollar-sign",
    query: { measures: ['Orders.count'] },
    difference: 'Orders',
    duration: 1.25,
    value:Betamount,
    cardcolour:"#000099"
  } ,
     
 {
  title: 'WINNINGS',
  icon:"fas fa-award",
  query: { measures: ['Users.count'] },
  difference: 'Users',
  duration: 1.5,
 value:Winamount,
    cardcolour:"#e53969"
},
{
  title: 'PROFIT',
  icon:"fas fa-hand-holding-usd",
  query: { measures: ['LineItems.price'] },
  duration: 2.25,
  value:profit, 
  cardcolour:"#13cd4c"
},
  {
       title: 'PAYOUT',
       icon:"fas fa-donate",
       query: { measures: ['Orders.percentOfCompletedOrders'] },
       progress: true,
       duration: 1.75,
        value:payount+"%",
       cardcolour:"#7a5252",
  },
    ]

 
  return (
    
    <div className={classes.root}>
      {/*CArd === 1*/}
      
      <Grid container spacing={4}>
        {cards.map((item, index) => {
           return (
         <Grid
               item
             lg={3}
             sm={6}
             xl={3}
             xs={12}
             //ykarthik 23.12
             key={index}
           >
           
           <Card  style={{backgroundColor:item.cardcolour}}>
        <CardContent>

        {/* ykarthik 23.12 */}
        <Typography variant='h5' className={classes.custom}> {item.title} </Typography> 
              <Typography variant='h6' className={classes.value} >{item.value}</Typography>
              <Typography   align="right" className={classes.icon}>
              <i className={item.icon} /> 
              </Typography>




  
              {/* <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
               <h2 style={{color:"white"}}> {item.title} </h2>
                <p style={{color:"white",float :'right',fontSize:'50px' }}><i className={item.icon} /> </p> 
              </Typography>
              {/* <Typography   color="textSecondary" gutterBottom variant="body2">
               <p style={{color:"white"}} > {item.duration}</p>
              </Typography> */}
              {/* <Typography   color="textSecondary" gutterBottom variant="body2">
                <h1 style={{color:"white",fontSize:'20px'}} >{item.value}</h1>
              </Typography> */}

            </CardContent>
      </Card>
       
     </Grid>
      )//}
    })}
     <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          </Grid>
      </Grid>
  
  </div>
  );
};


 
 
 
   
 export default Chartkpi;