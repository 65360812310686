import useAxios from "axios";
var $ = require("jquery")


//const API_URL = "http://220.225.104.132:7017/"; 
// const API_URL = "https://gamesapi.smartsalez.in/"; 
const API_URL = "https://api.gitechgames.com:8007/";
//const API_URL = "http://192.168.10.134:9000/"; 
//const API_URL = "http://220.225.104.132:7000/";
// const API_URL = process.env.REACT_APP_API_URL;

const getbaseName = () => {
   return '/';
    //return '/SonalMares';
   //return process.env.REACT_APP_BASENAME;
}

const getUrl = (screenName: string,type: string) => {
    switch(screenName){
        case "login":
            return API_URL + "login/";//"api-token-auth/"; //post
        case "userModal":
            return API_URL + "currentUser/"; //get
        case "c_organizer":
            return API_URL + "organizer-detail/"; //post/put
        case "a_organizer":
            return API_URL + (type === "post" ? "organizer-approval/" : "organizer-detail/"); //get/post
        case "gc_organizer":
        case "grc_organizer":
            return API_URL + (type === "post" ? "organizer-token/" : "organizer-detail/"); //get/post
        case "cm_game":
            return API_URL + "game-details-load/"; //get master game type names
        case "cmo_game":
            return API_URL + "organizer-load/"; //get master organizer names
        case "cms_game":
            return API_URL + "game-salesstatus-load/"; //get master sale status
        case "ctm_game":
            return API_URL + "rpt-currentGame/"; //get master game names
        case "cam_game":
            return API_URL + "audit-load~gamename/"; //get master game names(Audit trail)
        case "ctmpl_game":
            return API_URL + "pl~purchase_load/"; //get master game names(Paper Lottery)
        case "c_game":
            return API_URL + "game-create/"; //post
        case "a_game":
            return API_URL + (type === "post" ? "game-approve/" : "game-create/"); //get/post
        case "e_game":
            return API_URL + (type === "post" ? "game-update/" : "game-create/"); //get/post
        case "es_game":
            return API_URL + (type === "post" ? "game-sale-status/" : "game-create/"); //get/post
        case "GWSP_Reports":
            return API_URL + "rpt-gamewise-sales~payout/"; //get
        case "GWPL_Reports":
            return API_URL + "rpt-gamewise-profit~loss/"; //get
        case "GWPLF_Reports":
            return API_URL + "rpt-gamewise-profit~loss~filter"; //get
        case "GTWPL_Reports":
            return API_URL + "rpt-gametypewise-profit~loss/"; //get
        case "GTWPLF_Reports":
            return API_URL + "rpt-gametypewise-profit~loss~filter/"; //get
        case "GTWPLH_Reports":
            return API_URL + "rpt-gametypewise~hyperlink/"; //get hyperlink
        case "GPW_Reports":
            return API_URL + "rpt-playerwise-sales~payout/"; //get
        case "GPWH_Reports":
            return API_URL + "rpt-playerwise-Hyperlink/"; //get hyperlink
        case "GWLT_Reports":
            return API_URL + "rpt-gamewise-lasttransaction/"; //get
        case "GVT_Reports":
            return API_URL + "rpt-viewtransaction/"; //get
        case "GPWPL_Reports":
            return API_URL + "rpt-terminalwise-profit~loss/"; //get
        case "GPWPLF_Reports":
            return API_URL + "rpt-terminalgamewise-profit~loss/"; //get
        case "GGetDet_Reports":
            return API_URL + "rpt-gamedetails~get/"; //get
        case "GC_Reports":
            return API_URL + "rpt-claim/"; //get
        case "GUC_Reports":
            return API_URL + "rpt-unclaim/"; //get
        case "GO_Reports":
            return API_URL + "rpt-operator-sales~payout/"; //get
        case "GCH_Reports":
            return API_URL + "rpt-claim~hyperlink/"; //get hyperlink
        case "GUCH_Reports":
            return API_URL + "rpt-unclaim~hyperlink/"; //get hyperlink
        case "GOH_Reports":
            return API_URL + "rpt-operator-sales~payout~hyperlink/"; //get hyperlink
        case "GCW_Reports":
            return API_URL + "rpt-channelwise-sales~payout/"; //get
        case "GMW_Reports":
            return API_URL + "rpt-modelwise-sales~payout/"; //get
        case "GOW_Reports":
            return API_URL + "rpt-organizerwise-profit~loss/"; //get
        case "GCU_Reports":
            return API_URL + "rpt-claim~unclaim/"; //get
        case "GOS_Reports":
            return API_URL + "rpt-overallsale/"; //get
        case "GRG_Reports":
            return API_URL + "rpt-revenue~govt/"; //get
        case "GRGH_Reports":
            return API_URL + "rpt-revenue~govt~hyperlink/"; //get
        case "c_dashboard": 
            return API_URL + "dashboard/"; //get
        case "GPI_Papers": 
            return API_URL + "pl~purchase_input/"; //post
        case "GUS_Papers": 
            return API_URL + "pl~purchase_unsold_settlement/"; //post
        case "GWI_Papers": 
            return API_URL + "pl~purchase_winners_input/"; //post
        case "GTC_Papers": 
            return API_URL + "pl~purchase_ticket_claim/"; //post
        case "GVP_Papers": 
            return API_URL + "pl~purchase_input/"; //get
        case "GVPBU_Papers":
            return API_URL + "pl~purchase_block_unblock/"; //post
        case "GDP_Papers": 
            return API_URL + "pl~purchase_input/"; //delete
        case "GDPV_Papers": 
            return API_URL + "pl~purchase_get_delete/"; //get
        case "CState": 
            return API_URL + "agent-load~state/"; //get master state names
        case "CStockist": 
            return API_URL + "agent-load~superstockist/"; //get master stockist names
        case "CSStockist": 
            return API_URL + "agent-load~stockist/"; //get master sub stockist names
        case "CTerminal": 
            return API_URL + "commission-load~terminal/"; //get master terminal id names
        case "a_AgentH":
            return API_URL + "agent-register/"; //get
        case "aa_AgentH":
            return API_URL + "commission/"; //get
        case "c_AgentH":
            return API_URL + "agent-register/"; //post
        case "c_AgentHPut":
            return API_URL + "agent-register/"; //put
        case "cc_AgentH":
            return API_URL + "commission/"; //post
        case "cc_AgentHPut":
            return API_URL + "commission/"; //put
        case "c_AgentHImg":
            return API_URL + "kyc-details/"; //post
        case "c_AuditSI":
            return API_URL + "audit-scheme/"; //post
        case "c_AuditSIPut":
            return API_URL + "audit-scheme/"; //put
        case "c_AuditRI":
            return API_URL + "audit-result/"; //post
        case "c_AuditWP":
            return API_URL + "audit-winnerprocess/"; //post
        case "c_AuditVS":
            return API_URL + "audit-scheme/"; //get
        case "c_AuditRIG":
            return API_URL + "audit-result/"; //get
        case "c_AuditCW": 
            return API_URL + "audit-get~trans/"; //get
        case "c_AuditTV": 
            return API_URL + "audit-valid~transaction/"; //get
        case "c_AuditWV": 
            return API_URL + "audit-valid~winning/"; //get

        case "com-tax":
                return API_URL + "audit-valid/"; //get
        case "add_money":
        case "withdraw_money":
                return API_URL + "push-add_with2/";  //get   
        case "pg-pgrptsum":
                return API_URL + "rpt-pgreportsummary/"; //get
        case "pg-pgrpt":
                return API_URL + "rpt-pgreport/"; //get            
        case "pg-pgrpt-lnk":
                return API_URL + "rpt-pgreport-hyperlink/"; //get    
        case "push-payment": 
                return API_URL +"push-payment/"; //save paymethod
        case "tax-govt-rpt":
                return API_URL + "rpt-tax_details/"; //get gov-report
        case "chat-addmoneybyMode":
                return API_URL + "rpt-dashboardprovidewisesale/"; //get gov-report

        case "rpt-Details": 
                return API_URL +"/dashboard_new/"; 
        case "Dashboard-Doughtnut":
                return   API_URL +"/rpt-dashboardgamewisesale/"; 
        case "Channel-Wise-Sale": 
                return   API_URL +"/rpt-dashboardchannelwisebyhour/"; 
        case "providerwise-SWCP": 
                return API_URL +"/rpt-dashboardnew_v1/";    
        case "Provider-Sales-hours":
                return API_URL + "/rpt-dashboardprovidewisebyhour/";  
        case "Overall-Sale_winning":
               return API_URL + "/rpt-dashboardnewdaywise/"; //get gov-report
        case "Channel-Wise-Sales":
                return API_URL + "/rpt-dashboardchanneldaywise/";
        case "cash-paymethod": //get pieChart dashboardnew
                return  API_URL +"rpt-dashboardclaimeddetails/";
        case "providewisegame-day": //get provider&game sale day dashboardnew
                return  API_URL +"rpt-dashboardprovidewisebyday/";
        case "providewisesale-week": //get provider and game sale day dashboardnew
                return  API_URL +"rpt-dashboardprovidewisesale/";
        case "add-withdraw-money": //get provider and game sale day dashboardnew
                return  API_URL +"rpt-dashboardadd_withmoneybyday/";

         case "push-data": //pushdata report
                return  API_URL +"rpt-dashboardlastpush/"; 
            //mani
         case "provider_count": //get provider and game sale day dashboardnew
                return  API_URL +"/rpt-dashboardprovidercount/";
            default:
                return API_URL
    }
};
//allow login request only
const authWOHeader = () => {
    return {
        headers: 
        {
            "accept": "application/json;odata=verbose",
            "content-Type": "application/json;odata=verbose"
        }
    };                
};
//allow all api requests (not in login request)
const authHeader = (token?: any) => {
    const tokenString: any = sessionStorage.getItem('token');
    const userToken: any = token ? token : JSON.parse(tokenString);
    return {
        headers: 
        {
            "accept": "application/json;odata=verbose",
            "content-Type": "application/json;odata=verbose",
            "Authorization": `Bearer ` + userToken?.token 
        }
    };                
};
//allow all api requests (not in login request)
const authHeaderDelete = (jsonData: any,token?: any) => {
    const tokenString: any = sessionStorage.getItem('token');
    const userToken: any = token ? token : JSON.parse(tokenString);
    return {
        headers: 
        {
            "accept": "application/json;odata=verbose",
            "content-Type": "application/json;odata=verbose",
            "Authorization": `Bearer ` + userToken?.token 
        },
        data: jsonData
    };                
};

//allow all api requests (not in login request)
const authHeaderwithParam = (param: any,token?: any) => {
    const tokenString: any = sessionStorage.getItem('token');
    //const userInfoString: any = sessionStorage.getItem('userInfo');
    const userToken: any = token ? token : JSON.parse(tokenString);
    //const userInfo: any = JSON.parse(userInfoString);
    const params: any = param.toString().split('~@#%&');
    const paramObj: any = params[0] === "Organizer" ? {
        flag_id: parseInt(params[1]),
        FromDate: params[2],
        ToDate: params[3],
        organizerID: parseInt(params[4])
    } : params[0] === "Game" ? {
        flag_id: parseInt(params[1]),
        FromDate: params[2],
        ToDate: params[3],
        organizerID: parseInt(params[4])
    } : params[0] === "GameType" || params[0] === "OrgName" ? {
        flag_id: parseInt(params[1])
    } : {};
    return {
        params: paramObj,
        headers: 
        {
            "accept": "application/json;odata=verbose",
            "content-Type": "application/json;odata=verbose",
            "Authorization": `Bearer ` + userToken?.token 
        }
    };                
};

const authHeaderwithParamRpt = (param: any,token?: any) => {
    const tokenString: any = sessionStorage.getItem('token');
    //const userInfoString: any = sessionStorage.getItem('userInfo');
    const userToken: any = token ? token : JSON.parse(tokenString);
    //const userInfo: any = JSON.parse(userInfoString);
    return {
        params: param,
        headers: 
        {
            "accept": "application/json;odata=verbose",
            "content-Type": "application/json;odata=verbose",
            "Authorization": `Bearer ` + userToken?.token 
        }
    };                
};

//get encrypt/decrypt secret key
const secretKey = () => {
    return "_giKamarajP7"
}

const onBodyClick=(e: any) => {
    e.preventDefault();      
    if(e.target.className !== undefined){
      var lightBox5: any = $('#lightBox5');
      if(lightBox5.css("display") !== undefined && lightBox5.css("display") !== "none"){
        if(Object.prototype.toString.call(e.target.className) === "[object String]" && !e.target.className.includes("GreenBgg") && !e.target.className.includes("RedBgg") && e.target.className !=="graphPop4Table"){
          $('.showPlusMinus1').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg'); 
          $('.graphPop6').css({display: "none"})   
        };
      }
      else{
        if(Object.prototype.toString.call(e.target.className) === "[object String]" && !e.target.className.includes("GreenBgg") && !e.target.className.includes("RedBgg") && e.target.className !=="graphPop4Table" && e.target.id !== "myfile"){
            $('.showPlusMinus').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg'); 
            $('.graphPop4').css({display: "none"})   
        }
      }
    }
    return true
}

 //event triggers on logout button click
const logout = (logout?: any) => {
    if(logout !== 1)
        sessionStorage.clear();
    window.location.href = getbaseName();
    return
}

const config = { getbaseName: getbaseName, onBodyClick: onBodyClick, secretKey: secretKey, Url: getUrl, authHeaderDelete: authHeaderDelete, authHeader: authHeader, authWOHeader: authWOHeader, authHeaderwithParam: authHeaderwithParam,
    logout: logout,authHeaderwithParamRpt: authHeaderwithParamRpt};

export default config;