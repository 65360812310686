import React from 'react';
import apiHelper from "../../../utils/ApiHelper"
import useAxios from "axios";
import config from "../../../utils/config"
import axios from 'axios';
import moment from "moment"
import { t } from 'i18next';
 import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CAlert,
    CBadge,
    CCollapse
} from '@coreui/react'
import{
    Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   PointElement,
    LineElement,
   Title,
   Tooltip,
   Legend,
   ArcElement,
  }from 'chart.js'
  import {Bar,Pie,Doughnut,Line} from 'react-chartjs-2'
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  )
ChartJS.register(ArcElement, Tooltip, Legend);
  interface IProps {
    startdate:any,
    enddate:any,
     gameID:any,
     orgID:any

   }
   
    const DoughnutChart: React.FC<IProps> = props => {         
        const [gamename, setData] = React.useState([]);
        const [betamount, setData1] = React.useState([]);
		 const [_color, setData2] = React.useState([]);
        React.useEffect(()=>{
          let loadDonutChartLabel: any = []; 
          let loadDonutChartData: any = []; 
          let callMethod: string = "";
          let callJsonHead: any = null;
          const gameID = typeof (props.gameID) === 'string' ? (props.gameID).split('_')[0] : '0';

          callMethod = "Dashboard-Doughtnut";
           callJsonHead = {
              organizerID:props.orgID,
              FromDate:moment(props.startdate).format('YYYY-MM-DD') + " 00:00:00",
              ToDate:  moment(props.enddate).format('YYYY-MM-DD')+ " 00:00:00",
              GameId:gameID
          };
           //mani 22/12 
           //useAxios.get(config.Url(callMethod, "get"), config.authHeaderwithParamRpt(callJsonHead))
           apiHelper.getAPICall(callMethod, "get",5,callJsonHead)
            .then((res)=>{
                if (res !== null && res.data !== null && res.data.result === undefined) {                       
                     let colDataArr: any = res.data["get_rptDetails"]; 
                  let loadDonutChartLabel: any = [...colDataArr.map((item: any) => item.in_gamename)];
                  let loadDonutChartData: any = colDataArr.map((item: any) => item.in_betamount);
                  let loadColorData: any = colDataArr.map((item: any) => "#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase());
                  setData(loadDonutChartLabel);
                  setData1(loadDonutChartData);
                  setData2(loadColorData);
                  return
                 }})
                   .catch((error) => console.error(error))
          },[props.startdate,props.enddate])
            // console.log("gamename " +gamename)
            // console.log("betamount " +betamount)s
            const DoughnutChartData: any = {
            labels: gamename,
            datasets: [
                {
                    label: 'MOST INTERESTED GAMES',
                    data:betamount,
                    backgroundColor: _color,
                    borderColor: _color,
                    borderWidth: 1,
                },
            ],
        };



    return(
              <div>
                 <CCard className="mb-4">
                 <CCardHeader><h4>{t('lblGameWise')}</h4></CCardHeader>
                <CCardBody>
                <Doughnut data={DoughnutChartData}/>   
                </CCardBody>
                </CCard>
                <div>
                {/* <p>{props.startdate} / {props.enddate}</p> */}
                 {/* {moment(props.startdate).format('YYYY-MM-DD')} 
                 {moment(props.enddate).format('YYYY-MM-DD')}  */}
               </div>
                </div> 
   )
  };
export default DoughnutChart;
