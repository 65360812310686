import React from 'react';
import Chart from "react-apexcharts";
var $ = require("jquery");

export default class ListCharts extends React.Component {
    constructor(props) {
      super(props); 
      this.state = {
      };
    }
    closePopup =() => {
        $('.overlay').fadeOut();
        $('#lightBox4').fadeOut();
    }
    chartChangeClick= (e, chartType) => {
        e.preventDefault();
        if(chartType !== this.props.chartType){
            this.props.chartChangeClick(chartType);
        }
        return
    }
    render() {
        const windowWidth = $(window).width();
        let chartHieght = "280";
        if(windowWidth >= 640){
            chartHieght = "400"
        }
        else if(windowWidth >= 480 && windowWidth < 639){
            chartHieght = "165"
        }
      return(
        <div id="lightBox4" className="lightBox4" style={{marginLeft: '-'+ ($('#lightBox4').width() / 2) +'px',marginTop: '0px'}}>	
        <div className="userLogpreview">
            <div className="PreviewHead">   
            <div className="PreviewHeadinnr">
			<h4>{this.props.chartTitle}</h4>
			</div>
			<div className="graphSecContMain">
				<div className="graphSecCont">
					<ul>
                        <li className={this.props.chartType === "column" ? "activeChart" : ""}><a href="#section" id="chart4" onClick={(e) => this.chartChangeClick(e,"column")}><i className="fas fa-chart-bar"></i></a></li>
                        <li className={this.props.chartType === "pie" ? "activeChart" : ""}><a href="#section" id="chart2" onClick={(e) => this.chartChangeClick(e,"pie")}><i className="fas fa-chart-pie"></i></a></li>
						<li className={this.props.chartType === "line" ? "activeChart" : ""}><a href="#section" id="chart1" onClick={(e) => this.chartChangeClick(e,"line")}><i className="fas fa-chart-line"></i></a></li>
						<li className={this.props.chartType === "area" ? "activeChart" : ""}><a href="#section" id="chart3" onClick={(e) => this.chartChangeClick(e,"area")}><i className="fas fa-chart-area"></i></a></li>						
					</ul>
				</div>
                <a href="#section" className="CancelBtn" onClick={this.closePopup}><i className="fas fa-times" ></i></a>
			</div>
            </div>
            {this.props.options !== null && this.props.options1 !== null ?
            (<div className="ContainerChart2 scrollbar">
                {this.props.chartType === "pie" ? 
                (<Chart
                options={this.props.options1}
                series={this.props.series1}
                type={this.props.chartType}
                //width="500"
                height={chartHieght}
                />) : null}
                {this.props.chartType === "column" ? 
                (<Chart
                options={this.props.options}
                series={this.props.series}
                //width="500"
                height={chartHieght}
                />) : null} 
                {this.props.chartType === "line" ? 
                (<Chart
                options={this.props.options}
                series={this.props.series}
                //width="500"
                height={chartHieght}
                />) : null} 
                {this.props.chartType === "area" ? 
                (<Chart
                options={this.props.options}
                series={this.props.series}
                //width="500"
                height={chartHieght}
                />) : null}                 
            </div>) : null}
        </div>
    </div>               
    );
  }
}