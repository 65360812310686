import moment from "moment";
function CAgenHmodel() {
    return  { 
        errMsg: "",
        succFlag: undefined, 
        cAgentHOpt: {
            contactPerId: {name: "",display: false},
            fileAttach: {name: "",fileResult: null,display: false},
            imgAttach: {name: "",aName: "",aNo: "",dob: "",gender: "",fileResult: null,uri: ""}   
        },
        cAgentHMand: {
            contactPerName: {name: "",display: false},
            contactPerNum: {name: "",display: false},     
            address: {name: "",display: false},
            pinCode: {name: "",display: false},
            location: {name: "",display: false}                
        },
        ccAgentHMand: {
            salesComm: {name: "0",errDisp: "",display: false},
            claimComm: {name: "0",errDisp: "",display: false},
            startDate: {defValue: moment(new Date()),name: moment(new Date()).format('DD-MM-yyyy').toString(),display: false},       
            endDate: {defValue: moment(new Date()).add(25, 'years'),name: moment(new Date()).add(25, 'years').format('DD-MM-yyyy').toString(),display: false}   
        },
        cAgentHDrop: {
            OrganizerNames: [],
            OrganizerName: {value: null,display: false},
            gameType: [],
            gameTypeId: {value: null,display: false},
            terminalType: [],
            terminalTypeId: {value: null,display: false},
            commissionType: [
                {value: 1, label: "Sale"},
                {value: 2, label: "Net (S-W)"},
                {value: 3, label: "Net (S-W-SC)"},
                {value: 4, label: "Net (S-W-SC-WC)"}
            ],
            commissionTypeId: {value: 1,display: false},
            stateType: [],
            stateTypeId: {value: null,display: false},
            stockistType: [],
            stockistTypeId: {value: null,display: false},
            sstockistType: [],
            sstockistTypeId: {value: null,display: false}
        },
        cAgentHRadio: 0,
        aAgentH: {
            tabData: [],
            colData: [],
            tabDataSub: [],
            colDataSub: [],
            tabDbData: [],
            filteredData: [],
            searchInput: "",
            pageIndex: 1,
            rowData: null
        }
    } 
};
export default CAgenHmodel;