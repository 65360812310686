import useAxios from "axios";
import config from "../utils/config"

var getAPICall = (spName: string, httpVerbs: string, paramConfig: number, paramData: any="") => {
    console.log("123456789")
    //debugger;
    var _sp: string = config.Url(spName, httpVerbs);
    var _params: any = null
    switch (paramConfig) {
        case 2:
            _params = config.authHeader();
            break
        case 4:
            _params = config.authHeaderwithParam(paramData);
            break
        case 5:
            _params = config.authHeaderwithParamRpt(paramData);
    }
    return useAxios.get(_sp, _params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

var postAPICall = (spName: string, httpVerbs: string, paramConfig: number, postData: any) => {
    debugger;
    var _sp: string = config.Url(spName, httpVerbs);
    var _params: any = null
    var _postData: any = null
    switch (paramConfig) {
        case 1:
            _params = config.authWOHeader();
            _postData = postData;
            break
        case 2:
            _params = config.authHeader();
            _postData = postData;
            break
    }
    return useAxios.post(_sp, _postData, _params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

var deleteAPICall = (spName: string, httpVerbs: string, paramConfig: number, deleteData: any) => {
    debugger;
    var _sp: string = config.Url(spName, httpVerbs);
    var _params: any = null
    switch (paramConfig) {
        case 3:
            _params = config.authHeaderDelete(deleteData);
    }
    return useAxios.delete(_sp, _params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

var putAPICall = (spName: string, httpVerbs: string, paramConfig: number, postData: any) => {
    debugger;
    var _sp: string = config.Url(spName, httpVerbs);
    var _params: any = null
    var _postData: any = null
    switch (paramConfig) {
        case 1:
            _params = config.authWOHeader();
            _postData = postData;
            break
        case 2:
            _params = config.authHeader();
            _postData = postData;
            break
    }
    return useAxios.put(_sp, _postData, _params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        })
}

const apiHelper = { getAPICall: getAPICall, postAPICall: postAPICall, deleteAPICall: deleteAPICall,putAPICall:putAPICall };

export default apiHelper;