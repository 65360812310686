import moment from "moment";
function COrganizemodel() {
    return  { 
        errMsg: "",
        succFlag: undefined, 
        organizerId: null,
        cOrganizerMand: {
            commission: {name:"",errDisp: "",display: false},
            organzierName: {name: "",display: false},            
            // contactPerLOne: {name: "",display: false},
            // contactNumLOne: {name: "",display: false},
            // emailIdLOne: {name: "",display: "0"},        
            // address: {name: "",display: false}       
        },
        // cOrganizerOpt: {
        //     contactPerLTwo: "",
        //     contactNumLTwo: "",
        //     emailIdLTwo: {name: "",display: "0"},
        //     approvalNum: "",
        //     approvalAtt: {name: "",fileResult: null},
        //     logoAtt: {name: "",fileResult: null,uri: ""}
        // },
         aOrganizer:  {
            tabData: [],
            colData: [],
            filteredData: [],
            searchInput: "",
            succFlag: undefined,
            errMsg: "",
            pageIndex: 1,
            rowData: null,
            drawDate: {
                FdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false},       
                TdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false}  
            }
        }
    } 
};

export default COrganizemodel;