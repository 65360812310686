import React from 'react';
import apiHelper from "../../../utils/ApiHelper"
import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CAlert,
    CBadge,
    CCollapse
} from '@coreui/react'
import{
    Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   PointElement,
    LineElement,
   Title,
   Tooltip,
   Legend,
   ArcElement,
  }from 'chart.js'
  import {Bar,Pie,Doughnut,Line} from 'react-chartjs-2'
  import { extendMoment } from "moment-range";
import axios from 'axios';
import config from '../../../utils/config';
import { IState } from '../DashboardNew';
import { t } from 'i18next';
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  )
   
  ChartJS.register(ArcElement, Tooltip, Legend);


  interface IProps {
    startdate:any,
    enddate:any,
    gameID:any,
    organizername:any
   }
  const Moment = require('moment');
   const moment = extendMoment(Moment);
   const MonthSale: React.FC<IProps> = props => {


        const [chartData, setchartData] = React.useState({ labels: [], datasets: []}); 
        const [chartoption, setoption] = React.useState<any>({
          
           
             type:'line',
            responsive: true,
        interaction: {
           mode: 'index',
           intersect: false,
           axis: 'x'
        },
        stacked: false,
        // plugins: {
        //     title: {
        //         display: true,
        //         text: 'Chart.js Line Chart - Multi Axis',
        //     },
        // },

        tooltips: {
            callbacks: {
              label: function(tooltipItem:any) {
            console.log("hide",tooltipItem)
                return tooltipItem.yLabel;
            }
          }},
        animations: {
            tension: {
              duration: 400,
              easing: 'linear',
              loop: (context:any) => context.active
            }
        },
        scales: {
            x:{
                title:{
                    display: true,
                text:"Date"}
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Amount'
                }, 
                grid: {
                    drawOnChartArea: true,
                },           
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                    display: false,
                    text: 'Amount'
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },

  } );   
  React.useEffect(() => {
            
           
              let getDtlPoint: any = "get_rptDetails";
              let getDtlDtPoint: any = "get_rptGenTime";
              let Gameid = typeof (props.gameID) === 'string' ? (props.gameID).split('_')[0] : 0 ;
              const callMethod = "providewisesale-week";
              const callJsonHead ={ 
               organizerID: props.organizername,
               FromDate:moment(props.startdate).format('yyyy-MM-DD') + " 00:00:00",
               ToDate:moment(props.enddate).format('yyyy-MM-DD') + " 00:00:00",
               GameId:Gameid,
               Status:1
            }
            //mani 22/12
        //axios.get(config.Url(callMethod, "get"), config.authHeaderwithParamRpt(callJsonHead))
        apiHelper.getAPICall(callMethod, "get",5,callJsonHead)
              .then((res)=>{  console.log('weekline', res)
    
              let rptGenDt: any = "";
              let chartDataArr:any= []; 
              let arr:any =[];
              let yAxis:any =[];
              let xAxis:any=[];
              let tempArr:any=[];
              let tempemparr:any=[];
              let yname:number=0;
              let ydatasets:any=[];          
              if (res !== null && res.data !== null && res.data.result === undefined) {
                if (res.data[getDtlPoint] !== undefined && res.data[getDtlPoint] !== null && res.data[getDtlPoint].length !== 0) {
                    chartDataArr = res.data[getDtlPoint];
                    rptGenDt = res.data[getDtlDtPoint].toString();
    // {in_channelname: 'LONASE EDITECH', in_betamount: 366749950, in_drawdate: '1eek November  2022'}
    // {in_channelname: 'Lonase GI', in_betamount: 136656650, in_drawdate: '1eek November  2022'}
    // {in_channelname: 'Lonase GI', in_betamount: 474645550, in_drawdate: '2eek November  2022'}
    // {in_channelname: 'Lonase GI', in_betamount: 72793500, in_drawdate: '3eek November  2022'}
           
             //labels weeks data
             xAxis =  chartDataArr.map((item:any) => item.in_drawdate).filter((value:any, index:any, self:any) => self.indexOf(value) === index)
               const chartdata= chartDataArr.map((elems: any) => elems.in_betamount);    
             //label show   //get all y-axis unique vaule 
             const yAxis =  chartDataArr.map((item:any) => item.in_channelname).filter((value:any, index:any, self:any) => self.indexOf(value) === index)          
            //label show obj LONASE EDITECH 'Lonase GI
            yAxis.forEach(( fobj: any) => {
             //labels weeks x-axis data matching date with x-axis of weeks  1eek 2eek
                          xAxis.forEach((fdate: any) => {
                                                             //filter chanelname ===  chanelname label show fobj of x-axis  &&  objs  1eek ===   1eek 2eek
                            const objLst = chartDataArr.filter((item: any) => { return item.in_channelname == fobj && item.in_drawdate == fdate });
                                if (objLst.length > 0){

                                //data amount of labels of weeks
                                    tempArr.push(objLst[0].in_betamount);
                                    tempemparr.push(objLst[0].in_betamount);
                                }
                                else{
                                    tempArr.push(0);}
                            });

var _colr= "#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase();
                            ydatasets.push(
                                {
                                    label: fobj, //label show obj LONASE EDITECH 'Lonase GI
                                    fill: false,
                                    backgroundColor: _colr,
                                     borderColor:_colr, 
                                     //'rgba(153, 102, 255, 1)' ],
                                    data: tempArr,
                                    yAxisID:'y',
                                    tension: 0.4,

                                })
                           
                                //yname++;
                            tempArr = [];
                            tempemparr=[];
                        })
    
                   
                      setchartData( { labels: xAxis, datasets: ydatasets})
              
                }
              }
            }
            ).catch((error) => console.error(error));
    
      }
    , [props])
    



    const MonthChart:any = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
            axis: 'x'
        },
        tooltips: {
            callbacks: {
              label: function(tooltipItem:any) {
            console.warn(tooltipItem)
                return tooltipItem.yLabel;
            }
          }},
    
        stacked: false,
        animations: {
            tension: {
              duration: 400,
              easing: 'linear',
              loop: (context:any) => context.active
            }
        },
        scales: {
            x:{
                title:{
                    display: true,
                text:"Date"}
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',            
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                    display: true,
                    text: 'Amount'
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    // ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
 const labels =  ['10AM', '11AM', '12AM', '1PM', '2PM', '3PM','4PM'];
const MonthChartdata = {
    //labels: ['21 Oct', '22 Oct', '23 Oct', '24 Oct', '25 Oct', '26 Oct','27 Oct'] ,
    labels:"", // date  ,
    datasets: [
        {
            label: 'GI',
            data: "",
            //betamt,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            yAxisID: 'y',
            tension: 0.4,
        },
        
    ],
};
    return(
              <div>
                 <CCard className="mb-4">
                <CCardHeader><h4>{t('lblProvideSaleWeek')}</h4></CCardHeader>
                <CCardBody>
                <Line data={chartData} options={
                    MonthChart
                    }/>
                </CCardBody>
                </CCard>
                <div>                 
               </div>
                </div>
   )
  };
export default MonthSale;