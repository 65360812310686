import React from 'react';
import PageNotFound from "../assets/img/icons/404-Notfound.png";
import { Link } from "react-router-dom";
// import config from "../utils/config"
import { withTranslation  } from 'react-i18next';

class NotFound extends React.Component<any,any>{
    render(){
		const { t } = this.props;
        return( 
		<div className="ContainerChart scrollbar">
       	 <div className="errorContent">
			<div className="errorContentInner">
				<img alt="" src={PageNotFound}/>
				<h1>404</h1>
				<span>{t('lblPageNotFound')}</span>
                <p style={{fontWeight: "bold"}}><Link to="/">{t('lblGoToHome')}</Link></p>
				<p>{t('lblGameExperience')}</p>
			</div>
		  </div>  
		</div> 
		    
        );
    }
}
export default  withTranslation()( NotFound);