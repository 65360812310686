import moment from "moment";
function CReportsmodel() {
    return  { 
        rptGenerated:false,
        errMsg: "",
        succFlag: undefined,  
        rptGenDate: "",
        rptGenDateSub: "",
        aGWReportsRadio: {
                isSelSwitch: 1   
        }, 
        aGWReportsText: {
            selTxnValue: {name: "",display: false}   
        }, 
        aGWReportsDt: {
                FdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false},           
                TdrawDate: {defValue: moment(new Date()),value: moment(new Date()).format('DD-MM-yyyy').toString(),display: false}          
        },
        aGWReportsDrop: {  //only dropdown values
            gameType: [],
            gameTypeId: {value: null,display: false},
            gameDet: [],
            gameDetId: {value: null,display: false},
            OrganizerNames: [],
            OrganizerName: {value: null,display: false} 
        },
        aGWReportsCt: {
            options: null,
            series: null,
            options1: null,
            series1: null,
            chartTitle: "",
            chartType: ""
        },
        aGWReportsTab: {
            tabData: [],
            colData: [],
            tabDataSub: [],
            colDataSub: [],
            filteredData: [],
            searchInput: "",
        }
    } 
};

export default CReportsmodel;