//import React from 'react';
import ReactDOM from 'react-dom';
import React,{ Suspense }  from 'react';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./serviceworker";
import './i18n';

ReactDOM.render(
<Suspense fallback={<span>Loading...</span>}>
<App />
</Suspense>,
  document.getElementById('root')
);
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
