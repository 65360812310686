import React from 'react';

import ListTable from "./listtable";
import CommonAgentH from "../AgentHierarchy/Child/c_agentHcommon";
var $ = require('jquery')

export default class ListSubTable extends React.Component {
    constructor(props) {
      super(props); 
      this.state = {
        filteredData: [],
        searchInput: ""
      };
      this.globalSearch = this.globalSearch.bind(this);
    }
    subTabStateReset= () => {
        this.setState({filteredData: [],searchInput: ""})
        return;
    }  
    // And in your global search
    globalSearch = (e) => {
    try{
        let initialState = this.state;
        $('.showPlusMinus1').addClass('fa-plus').removeClass('fa-minus').addClass('GreenBgg').removeClass('RedBgg');  
        $('.graphPop6').css({display: "none"})   
        const searchInput = e.target.value;
        const skipGT = this.props.tabData;
        let filteredData = skipGT.filter((value) => {
        return CommonAgentH.getKeyByValue(value, searchInput,this.props.rootFlag,true);
        });
        initialState.filteredData = filteredData;
        initialState.searchInput = searchInput;
        this.setState(initialState);
        return
    }
    catch(ex) {
        alert("Load Search filter Sub List Load Failed..");
        return
    }        
    };
    closePopup =() => {
        $('.overlay').fadeOut();
        $('#lightBox5').fadeOut();
    }
    render() {
    const pageSize = this.props.tabData !== undefined && this.props.tabData.length > 50 ? 50 : this.props.tabData.length > 25 ? 25 : 10;
      return(
        <div id="lightBox5" className="lightBox5" style={{marginLeft: '-'+ ($('#lightBox5').width() / 2) +'px',marginTop: '0px'}}>
        <div className="userLogpreview">
            <div className="PreviewHead">
                <h2>Create {CommonAgentH.getPageTitle(this.props.rootFlag)}</h2>
                <a href="#section" className="CancelBtn" onClick={this.closePopup}><i className="fas fa-times"></i></a>
            </div>                
            <div className="ContainerChart1 scrollbar">
            <div className="contentclouds">
            {this.props.tabData !== undefined && this.props.tabData.length > 0 ?       
            (<div className="UseformNew">            
            <div className="ContainerTable">
                <div className="tableSearchreport">
                        <div className="sportSrch">
                            <input type="text" placeholder="Search here..!" id="myInput" value={this.state.searchInput} onChange={(e) => this.globalSearch(e)} autoComplete="off" />
                        </div>
                </div>
            </div>
            <div className="TableReact">            
                 <ListTable
                    sorting={this.props.sorting}
                    columns={this.props.colData}
                    pageSize={pageSize}
                    data={this.state.filteredData && this.state.searchInput !== "" ? this.state.filteredData : this.props.tabData}           
                />
            </div>
            </div>) : null}  
            </div>   			
            </div>
        </div>
    </div>);
  }
}